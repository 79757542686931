.lottery-cta {
	background: #4C4D4A;
	padding-bottom: rem(50);
	overflow: hidden;
	display: block;

	.button {
		width: rem(200);
	}

	.lottery-cta--image {
		height: 70vw;
		max-height: rem(400);
		position: relative;

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50%;
			background: linear-gradient(to top, #4C4D4A 0, transparent 100%);
		}
	}

	.lottery-card {
		padding: rem(30);

		.lottery-flag {
			width: rem(82);
			height: auto;
		}
	}

	.lottery-cta--countdown {
		margin-top: rem(20);
		margin-bottom: rem(30);

		p {
			color: white;
			margin: 0 0 rem(10);
			letter-spacing: rem(0.5);
			font-size: em(20);
			font-weight: 700;
		}
	}
}

@media only screen and (min-width: 980px) {
	.lottery-cta {
		position: relative;
		padding: rem(50) 0;

		.lottery-card {
			min-width: rem(380);
		}

		.lottery-cta--image {
			width: 100%;
			height: 100%;
			max-height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.container {
			display: flex;
			justify-content: space-between;
			position: relative;
			z-index: 9;

			.lottery-card {
				&.lottery-card-offset {
					margin: 0;
					top: 0;
				}
			}

			.lottery-cta--countdown {
				margin-top: auto;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				min-height: 100%;

				p {
					line-height: 1;
					margin: 0;
				}

				.timer {
					margin-top: rem(10);

					svg {
						max-height: rem(30);
					}
				}
			}
		}

		&.xlarge-banner {
			padding: rem(200) 0 rem(400);
		}
	}
}