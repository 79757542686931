.lottery-card {
	.timer {
		flex-direction: column;
	}
}

p.timer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	font-size: 14px;

	svg {
		width: rem(23);
		flex: 0 0 rem(23);
		margin-right: rem(20);
	}

	&.cta-timer {
		margin-top: rem(20);
		justify-content: center;

		span {
			color: $orange;
		}
		
		svg {
			width: rem(30);
			height: auto;
			flex: 0 0 rem(30);
			margin-right: rem(20);

			* {
				fill: white;
			}
		}
	}

	.timer-countdown {
		color: $blue;
		font-size: em(18);
		font-weight: 700;

		&.cta-countdown {
			font-size: em(16);

			.cta-timer-container {
				> div {
					display: flex;
					flex-direction: column;
					float: left;
					width: rem(65);
					margin-right: rem(15);
					position: relative;

					span {
						color: white;
						font-size: em(18);
						position: absolute;
						top: 100%;
						left: 50%;
						margin-top: rem(10);
						line-height: 1;
						transform: translateX(-50%);

						&.cta-timer-count {
							background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
							font-size: em(40);
							border-radius: rem(10);
							position: static;
							padding: rem(7.5) rem(5);
							margin-top: 0;
							transform: none;
						}
					}

					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 370px) {
	p.timer {
		font-size: 16px;	
	}
}