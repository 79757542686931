.account-draws {
    p.subtitle {
        font-size: em(18);
        font-weight: 700;
        margin: 0 0 rem(20);
        line-height: 1;
        color: white;
        letter-spacing: 0.3px;
    }

    .account-draws--next {
        margin-top: rem(10);

        .lottery-card--draw-next {
            padding: rem(30) rem(15) rem(25);
            margin-bottom: rem(10);


            .draw-card--header {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: rem(10);

                h2 {
                    font-size: em(18);
                    margin: 0;
                }

                .lottery-flag {
                    margin: 0 rem(10) 0 0;
                    width: rem(29);
                    height: rem(29);
                }
            }

            .lottery-card--amount {
                font-size: em(30);
                line-height: 1.3;
            }

            .lottery-card--draw {
                color: $orange;
                font-weight: 700;
                font-size: em(16);
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-bottom: rem(25);

                > * {
                    flex: 0 0 45%;
                    width: 0;
                }
            }
        }

        .lottery-card {
            .svg-container {
                svg {
                    margin-bottom: rem(15);
                    width: rem(100);
                    height: auto;
                }
            }
        }

        .results-expand {
            margin: 0;
            justify-content: center;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .account-draws--upcoming {
        margin-top: rem(25);

        .lottery-card {
            margin-bottom: rem(5);
        }
    }

    .results-expand {
        justify-content: flex-start;
        margin-top: rem(-10);
        line-height: 1;
    }

    .results-content {
        margin-top: 0;
        text-align: center;
        max-width: 100% !important;

        > p {
            font-size: em(16) !important;
            font-weight: 500;
            margin: 0 0 rem(15);
            line-height: 1;

            &.game-type {
                font-size: em(20) !important;
            }

            &.winning-numbers {
                font-weight: 700 !important;
                padding-top: 1rem;
            }
        }

        .lottery-numbers-container {
            max-width: rem(360);
            width: 100%;
            margin: 0 auto rem(20);

            .lottery-numbers {
                .lottery-number {
                    font-size: initial;

                    > span {
                        font-size: initial;

                        > span {
                            font-size: em(14);
                        }
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .results-content-system {
            h3 {
                &.systemName {
                    text-align: center;
                    color: $orange;
                }
            }

            .flex-column {
                flex-direction: column;

                #g-explainer {
                    margin-left: 2rem;
                }
            }

            .results-content {
                max-width: 100% !important;
                margin-top: 0;

                &.system-scroll {
                    height: 320px;
                    overflow-y: scroll;
                    background: #eee;
                    // border: 1px solid #ddd;
                    margin-bottom: .5rem;
                    cursor: n-resize;

                    .winning-numbers {
                        padding-top: 1rem;
                    }

                    > .tables {
                        padding: 0;

                        > .four-col {
                            > .col {
                                .bonus-grid {
                                    background: none;
                                }

                                &:last-child {
                                    ul {
                                        border-right: 0 !important
                                    }
                                }

                                &:nth-child(4n) {
                                    ul {
                                        border-right: 0 !important
                                    }
                                }
                            }
                        }
                    }

                    .joker-winnings-container {
                        margin: 1rem auto 1rem;
                    }
                }

                &.winner-results-content {
                    height: 520px;

                    .tables .four-col .col .bonus-grid {
                        background: transparent !important;
                    }
                }
            }

            > .tables {
                > .four-col {
                    > .col {
                        width: 100%;

                        p.results-type.results-type-joker {
                            margin: 1rem 0;
                        }

                        @media screen and (min-width: 1000px) {
                            &:last-child {
                                ul {
                                    padding: 0 .5rem !important;
                                    border-right: 1px solid #fb8e0c !important;
                                }
                            }
                        }

                        &:nth-child(n+5) {
                            padding-top: 0 !important;
                        }

                        > ul {
                            &.ul-sys {
                                border-right: none !important;
                                padding: 0 !important;
                                max-width: 100%;
                                display: flex;
                                justify-content: center;

                                @media screen and (max-width: 1000px) {
                                    &:after {
                                        margin-left: 10px;
                                    }
                                }

                                @media screen and (max-width: 600px) {

                                    li {
                                        &.rowcount {
                                            flex-wrap: wrap;
                                            justify-content: flex-start;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .winning-numbers {
                        padding-top: 1rem;
                        font-weight: 700;
                        display: block;
                        width: 100%;
                    }
                }
            }

            .purchase-meta {
                width: 97% !important;
            }
        }

        &.syndicates {
            > .tables .four-col .col ul.standard {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
        }
    }
}

.load-more {
	max-width: rem(185);
	line-height: 1;
	margin: rem(30) auto 0;
	font-size: em(14);
	font-weight: 800;
}
