.contact-section {
	padding-top: 0 !important;

	.lottery-card {
		max-width: rem(360);
		width: 100% !important;
		padding: rem(25) rem(15);

		.button {
			display: inline-flex;
		}
	}

	.bg-blue-gradient {
		padding: rem(40) 0;

		.lottery-card {
			min-width: 100%;
		}
	}

	.contact-form {
		margin-top: rem(30);

		h3 {
			font-size: em(20);
			margin-bottom: rem(25);
		}
	}

	form {
		max-width: rem(360);
		margin: 0 auto;
	}

	.contact-form form {
		button {
			width: 100%;
		}
	}
}

@media only screen and (min-width: 980px) {
	.contact-section {
		margin: 0 auto;
		background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);

		.container {
			width: 100%;
			max-width: 100%;
		}

		.contact-container {
			display: inline-flex;
			width: 92.5%;
			max-width: rem(1180);
			justify-content: space-between;
			padding: rem(50) 0;

			.container {
				height: 100%;
			}

			.bg-blue-gradient {
				background: transparent;
				padding: 0;
				display: flex;
				align-items: stretch;
				flex-direction: column;

				.container {
					flex: 1;
					display: flex;
					flex-direction: column;

					.lottery-card {
						flex: 1;
					}
				}
			}

			.lottery-card {
				padding: rem(50) rem(40);
				text-align: left;
				height: 100%;
				margin: 0;

				h3 {
					font-size: em(30);
					margin: 0 0 rem(25);
				}

				p {
					font-size: em(16);
				}

				.button {
					padding: 0 !important;
					text-align: left;

					&:hover {
						background: white;
						color: $orange;
						opacity: 0.8;
					}
				}
			}

			.contact-form {
				background: white;
				border-radius: rem(10);
				flex: 1;
				margin-left: 3%;
				padding: rem(50) rem(40);
				text-align: left;
				margin-top: 0;
				box-shadow:  0 0 5px 2px  rgba(0,0,0,0.2);


				form {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					width: 100%;
					max-width: 100%;

					h3 {
						flex: 0 0 100%;
						font-size: em(30);
						margin: 0 0 rem(30);
						font-weight: 700;
					}

					div {
						flex: 0 0 40%;

						textarea {
							flex: 1;
							overflow: auto;
							margin-bottom: 0;
						}

						+ div {
							flex: 0 0 55%;
							display: flex;
							flex-direction: column;
						}

						label {
							display: flex;
							flex-direction: column;
							flex: 1;

							&:last-of-type {
								margin-bottom: 0;

								input {
									margin-bottom: 0;	
								}

								.input-error {
									margin-top: rem(7.5);
								}
							}
						}

						.input-error {
							flex: 0 0 auto !important;
						}
					}

					.input-orange-solid {
						text-align: right;
					}

					input[type="submit"], button {
						flex: 0 0 auto;
						margin-left: auto;
						width: 30%;
						margin-top: rem(25);
					}
				}
			}
		}
	}
}