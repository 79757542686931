.lottery-contain-four {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 0 0 rem(600);
    padding: 4px 0;

    .lottery-card {
        flex: 0 0 48.5%;
        margin: 0 0 rem(15);
        min-width: 48.5%;
        max-width: 48.5%;
        font-size: 14px;
        padding: rem(20) rem(15) !important;

        &:nth-of-type(3) {
            margin-bottom: 0;
        }
    }

    &.lottery-contains-four--column {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        display: inline-flex;
        flex: 0 0 auto !important;

        .lottery-card {
            width: 100%;
            max-width: 260px;
        }
    }
}

.lottery-play-listing {
    background-image: linear-gradient(to right, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
    padding: rem(15) 0 rem(40);

    .lottery-play--nav {
        margin-bottom: rem(20);

        .container {
            display: flex;
            border-bottom: 1px solid white;
            padding: 0 rem(15);
            max-width: 100%;
            width: 100%;
        }

        ul {
            display: flex;
            justify-content: space-between;
            padding: 0;
        }
    }

    .read-more {
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: rem(35);

        span {
            display: flex;
            align-items: center;
            margin-left: rem(10);
            margin-top: rem(2);

            svg {
                height: rem(12);
            }
        }
    }
}

.lottery-contain-four {
    flex: 0 0 100%;
    margin: rem(10) 0;

    .lottery-card {
        margin-bottom: rem(10);
        padding: rem(25) rem(30);

        .lottery-icon {
            height: rem(40);
            width: rem(40);
        }

        h3 {
            margin: rem(15) 0 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.tab-lottery-play {
    margin: 0 !important;

    &.active {
        display: flex !important;
        flex-wrap: wrap;
    }

    .lottery-card-info {
        background-color: transparent;
        box-shadow: none;
        flex: 0 0 100%;
        min-width: 100%;

        img {
            width: rem(130);
            height: auto;
        }

        * {
            color: white;
        }

        p {
            font-size: em(15);
            max-width: rem(600);
            margin-left: auto;
            margin-right: auto;
        }
    }


    .flipper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lottery-card-flip {
        order: -1;

        img {
            width: rem(60);
            height: auto;
            margin-bottom: rem(10);
        }
    }
}

@media only screen and (min-width: 415px) {
    .lottery-play-listing {
        .tabs-container {
            .lottery-card {
                width: rem(400) !important;
            }
        }

        .lottery-play--nav {
            ul {
                li {
                    font-size: em(15);
                }
            }
        }
    }
}

@media only screen and (min-width: 760px) {

    .tab-lottery-play .lottery-card-flip {
        margin: 0 0.5rem;

        .lottery-card-front {
            width: 100%;
        }
    }

    .tab-lottery-play {
        justify-content: center;

        .lottery-card {
            width: rem(600) !important;
        }
    }
}

@media only screen and (min-width: 870px) {

    .tab-lottery-play {
        .lottery-card-info {
            flex: 0 0 55%;
        }
    }

    .lottery-contains-four.lottery-contains-four--column {
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (min-width: 1020px) {
    .lottery-play-listing {
        padding-bottom: rem(50);
    }

    .tab-lottery-play {
        margin: rem(18) 0 rem(35);

        .lottery-card-info, .lottery-contain-four, .lottery-card-flip {
            flex: 1;
            width: auto !important;
            min-width: 0;
            margin-right: 10px;
        }

        .lottery-card-info {
            padding-top: 0;
            padding-right: 0;

            img {
                width: rem(100);
            }

            p {
                text-align: left;
                line-height: 1.6;
            }
        }

        .lottery-contain-four {
            flex: 2;
            margin: 0 10px 0 0;

            .lottery-card {
                padding: rem(25) rem(10);
                max-width: 210px;
            }
        }

        .lottery-card-flip {
            margin: 0;
            flex: 1.2;
            order: 0;
            min-width: 260px;
            margin-right: 7.5px;

            &:last-of-type {
                margin-right: 0;
            }

            .lottery-card-back {
                p {
                    font-size: em(14);
                    line-height: 1.6;
                }
            }
        }
    }
}

@media only screen and (min-width: 1100px) {
    .tabs-container {
        .lottery-card-flip {
            flex: 1;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .lottery-contain-four {
        .lottery-card {
            padding: rem(25) rem(40);
        }
    }
}

@media only screen and (max-width: 768px) {
    .lottery-contain-four {
        justify-content: center;

        .lottery-card {
            &:nth-of-type(3) {
                margin-bottom: 0.625rem;
            }
        }
    }
}
