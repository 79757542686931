$browser-context: 16;

@function em($pixels, $context: $browser-context) {
    @return #{$pixels/$context}em;
}

@function rem($pixels, $context: $browser-context) {
    @return #{$pixels/$context}rem;
}

@mixin banner($gradientLeft, $gradientRight, $bgUrl, $bgmobileUrl,$bgtabletUrl, $playPage) {

    .container {
        background: url($bgUrl) center right no-repeat;

        @if $playPage == 1 {
            background: linear-gradient(to right, $gradientLeft 0%, $gradientRight 35%, transparent 140%), url($bgUrl) center right no-repeat;
        }

        @media screen and (max-width: 1001px) {
            padding: 1rem 0;            
            flex-direction: column;
            width: 100%;
            background: linear-gradient(to top, $gradientRight 45%, transparent), url($bgUrl) 0% 0 no-repeat;

            @if $playPage == 1 {
                background: linear-gradient(to top, $gradientLeft 53%, $gradientRight 0%, transparent), url($bgUrl) right top no-repeat;
            }
        }

        @if $bgtabletUrl {
            @media (min-width:768px) and (max-width:1000px) {
                background: linear-gradient(to top, $gradientRight 0%, transparent), url($bgtabletUrl) center right no-repeat;
             //   background: linear-gradient(to top, $gradientRight 30%, $gradientLeft 75%, transparent), url($bgtabletUrl) 95% 0 no-repeat !important;
                margin: auto;
            }
        }

        @media screen and (max-width: 600px) {
            background: linear-gradient(to top, $gradientRight 30%, $gradientLeft 75%, transparent), url($bgmobileUrl) 95% 0 no-repeat !important;

            @if $playPage == 1 {
                background: linear-gradient(to top, $gradientLeft 45%, $gradientRight 0%, transparent), url($bgmobileUrl) right top no-repeat !important;
                background-position-x: 85% !important;
            }

            margin: auto;
        }
    }
}

@mixin banner-generic($gradient, $bgUrl, $bgmobileUrl) {
    .container {
        min-height: 272px !important;
        max-width: 100%;
        background: url($bgUrl) center right no-repeat;

        h1 {
            font-size: 2rem;
            padding-left: 3rem;
            margin: 0;
            color: white;
            font-weight: 600;
        }

        @media screen and (max-width: 1000px) {
            min-height: 150px !important;
        }

        @media screen and (max-width: 600px) {
            background: url($bgmobileUrl) center right no-repeat;

            h1 {
                font-size: 1.35rem;
                padding: 0;
                margin: 0 auto;
            }
        }
    }
}
