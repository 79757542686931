.lottery-play-search {

	max-width: rem(560);
	margin: rem(35) auto ;

	p {
		color: white;
		font-weight: 700;
	}

	form {
		input[type="submit"] {
			margin-top: rem(10);
		}

		label {
			flex: 0 0 100%;
		}

		&:first-of-type {
			margin-top: rem(30);
		}
	}
}

.lottery-search-result {
	padding: rem(45) 0 rem(50);

	.container {
		max-width: rem(560);
	}

	h2 {
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			margin-right: rem(10);
		}
	}

	p {
	    color: #4C4D4A;
	    margin: 0.9375rem 0 2rem;
    	font-weight: 600;
	}


	form {
		margin-top: rem(30);

		.lottery-select {
			margin: 0;
		}

		label {
			flex: 0 0 100%;
		}

		.select-dropdown {
			.select-rendered:after {
				background-image: url(../icons/icon-angle-down.svg);
				height: rem(16);
				width: rem(16);
				right: rem(15);
			}
		}
	}
}

.lottery-search-result, .lottery-play-search {
	form {
		.lottery-select {
			margin-right: rem(10);
		}

		label {
			flex: 1;
			margin-top: 0;
		}
	}
}
