.express-checkout {
	flex-direction: column;

	&.active {
		display: flex !important;
	}

	> .container {
		padding: rem(50)  rem(25);
		text-align: center;

		h3 {
			line-height: 1.4;
		}
	}

	.checkout-balance {
		background: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
		width: 100%;

		p {
			text-align: center;
			color: white;
			line-height: 1.5;
			font-size: em(18);
			margin: 0;
			padding: rem(15) 0;

			span {
				display: block;
				font-weight: 700;
				font-size: 125%;
			}
		}
	}

	.checkout-actions {
		background: white;
		align-self: stretch;
		flex: 1;
		padding: rem(30) rem(50) !important;
		text-align: center;
		overflow: hidden;
		border-radius: 0 0 10px 10px;

		p {
			color: $blue;
			font-weight: 700;

			span {
				display: block;
				color: $orange;
				font-size: 125%;
			}
		}

		.button {
			margin-bottom: rem(15);
			width: rem(200);

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}