﻿.matched-ticket {
    max-width: 500px;
    margin: 0 auto;

    #matched-ticket-image {
        width: 100%;
    }

    .matched-ticket-controls {
        .matched-ticket-nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 1rem 0;
            margin: .5rem auto;
            border-top: 1px solid $lightGrey;
            border-bottom: 1px solid $lightGrey;

            &.disabled {
                display: none;
            }

            .matched-ticket-nav-button {
                cursor: pointer;
                color: $blue;
                font-weight: 600;


                &.disabled {
                    visibility: hidden;
                }
            }

            #matched-ticket-nav-numbers {
                span {
                    color: $grey;
                    font-weight: 600;
                    border-radius: 50%;
                    width: 22px;
                    height: 22px;
                    text-indent: -2px;
                    font-size: .9em;

                    &.selected {
                        background: $blue;
                        color: $white;
                    }
                }
            }

            svg {
                fill: $blue !important;
                color: $blue;
                width: rem(15);
                height: rem(15);
                vertical-align: text-top;
            }
        }

        #matched-ticket-print {
            margin: .5rem auto 1rem;
        }
    }

    @media print {
        height: 98vh;

        .matched-ticket-controls {
            display: none;
        }

        #matched-ticket-image {
            width: auto;
            height: 100%;
        }
    }
}
