.lottery-blurb {
	background: linear-gradient(to top left, #4C4D4A 0%, #464D50 100%);
	padding: rem(30) 0;

	.container {
		max-width: rem(750);
	}

	* {
		color: white;
	}

	p {
		font-weight: 700;
		line-height: 1.5;
		font-size: em(18);
		letter-spacing: 0;
	}

	button {
		width: 50%;
	}
}