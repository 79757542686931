﻿
.futureball {
	background: linear-gradient(to right, #F66c1c 0%, #FEA500 70%, #FB8E0C 70%, #FB8E0C 100%);

	@media screen and (max-width: 1080px) {
		background: linear-gradient(to right, #F66c1c 0%, #FEA500 100%);
	}

	.banner {
		padding: 0;
		margin: 0 auto;
		min-height: 400px !important;
		max-height: none !important;

		&.section-image {
			.banner-image {
				left: 20%;
			}
		}
	}

	.banner-logo--container {
		padding-bottom: 2rem;

		&.image-side {
			display: flex;
			align-items: flex-start;

			> img {
				position: static;
				width: 10rem;
				margin-right: 1rem;

				@media screen and (max-width: 560px) {
					max-width: 65%;
				}
			}

			.future-logo {
				h1 {
					font-size: 24px;
				}
			}
		}

		.future-logo {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			margin-top: 3rem;
			transform: translateY(0);

			@media only screen and (min-width: 980px) {
				transform: translateY(-2rem);
				margin: 0;
			}

			img {
				max-width: 3rem;
				margin-right: 1rem;

				@media only screen and (min-width: 980px) {
					max-width: 5rem;
				}
			}

			h1 {
				font-size: 48px;
				letter-spacing: 2px;
				font-weight: 300;
				margin: 0;
				margin-top: 32px;
				text-shadow: 2px 2px 15px rgba(0,0,0,0.2);
			}
		}

		.banner-content {
			display: flex;
			justify-content: space-between;

			.button-container {
				margin: 0;
				font-size: 18px;
			}
		}
	}
}

@media only screen and (max-width: 1080px) {
	.futureball {
		background: linear-gradient(to right, #F66c1c 0%, #FEA500 100%);

		.container {
			display: block;
			background-image: none;
			width: 92.5% !important;
			max-width: 100% !important;
		}

		.banner {
			padding-bottom: 0 !important;

			&.section-image {
				.banner-image {
					left: 0;
				}
			}

			.banner-logo--container.image-side {
				display: block;
				text-align: center;

				img {
					margin-right: 0;
					width: auto;
				}

				.future-logo {
					margin-top: 1rem;
				}

				.future-logo h1 {
					margin-left: 0;
					margin-top: 1rem;
				}
			}
		}
	}
}

.futureball {
	position: relative;
	/* Defaullts */
	.banner {
		max-height: 23rem;
		min-height: 23rem;

		.banner-image {
			left: 50%;
		}
	}

	.section.thick {
		padding: 5rem 0;
	}

	@media only screen and (min-width: 980px) {
		.banner {
			max-height: 23rem;
			min-height: 23rem;
		}
	}

	@media only screen and (min-width: 1080px) {
		.banner .container {
			max-width: 73.75rem;
			width: 92.5%;
		}
	}

	p {
		font-size: 18px;
		line-height: 1.5;
	}

	.blue-bg {
		background-color: #037bdb;
	}

	.two-col {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		@media screen and (max-width: 1050px) {
			width: 100%;
			max-width: 80rem;
		}

		.col {
			width: 45%;
			text-align: left;

			@media screen and (max-width: 1050px) {
				width: 100%;
				margin: 1rem 0;
			}
		}

		.col {
			width: 45%;
			text-align: left;

			@media screen and (max-width: 1050px) {
				width: 100%;
				margin: 1rem 0;
			}
		}
	}

	.orange-gradient {
		background: linear-gradient( 183deg, rgba(246, 113, 31, 1) 6%, rgba(255, 169, 84, 1) 100% );

		.white-boxes {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.col {
				background-color: white;
				border-radius: 20px;
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
				padding: 20px;

				@media screen and (max-width: 980px) {
					width: 100%;
					max-width: 80rem;
					margin: 1rem 0;
				}
			}
		}
	}

	.orange-text {
		color: #f66c1c;
	}

	.blue-text {
		color: #2096eb;
	}

	.button-container {
		text-align: center;
		margin-top: 60px;
	}

	.green-full {
		color: white;
		background-color: #4bc90b;
		font-size: 18px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

		&:hover {
			background-color: #349a00;
		}
	}
	/* Banner */
	.banner-logo--container {
		padding-bottom: 2rem;

		.future-logo {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			margin-top: 3rem;
			transform: translateY(0);

			@media only screen and (min-width: 980px) {
				transform: translateY(-2rem);
				margin: 0;
			}

			img {
				max-width: 3rem;
				margin-right: 1rem;

				@media only screen and (min-width: 980px) {
					max-width: 5rem;
				}
			}

			h3 {
				font-size: 48px;
				letter-spacing: 2px;
				font-weight: 300;
				margin: 0;
				margin-top: 32px;
			}
		}

		.banner-content {
			display: flex;
			justify-content: space-between;

			.button-container {
				margin: 0;
				font-size: 18px;
			}
		}
	}
	/* Page Layout */
	.popular-tab {
		display: inline-block;
		padding: 7px 27px;
		padding-bottom: 9px;
		color: white;
		letter-spacing: 0.5px;
		background-color: #f9993b;
		border-radius: 5px;
		font-size: 16px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	}

	.white-border--container {
		text-align: center;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid white;
		text-align: center;
		color: white;

		h3 {
			margin: 0;
			color: white;
			font-size: 26px;
		}
	}

	.plays-container {
		&.three-col {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 1rem 4rem;

			@media screen and (max-width: 900px) {
				padding: 1rem 0rem;
			}

			.col {
				width: 31%;
				margin: 20px 0;
				text-align: left;
				position: relative;
				padding: 2rem 3rem;

				@media screen and (max-width: 900px) {
					width: 47%;
				}

				@media screen and (max-width: 600px) {
					width: 100%;
				}

				&:nth-child(2) {
					.popular-tab {
						position: absolute;
						top: -15px;
						right: 15px;
					}
				}

				h4 {
					font-size: 26px;
					margin: 0 0 0.4rem 0;
				}

				p {
					font-size: 20px;
					margin: 0;
				}
			}
		}
	}

	.white-box--shadow {
		background-color: white;
		border-radius: 20px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
		padding: 20px;
		position: relative;
		border-radius: 10px;

		.cart-icon {
			max-width: 2.2rem;
			position: absolute;
			right: 1.5rem;
			bottom: 1.5rem;
		}
	}

	.lottery-signup {
		.container {
			display: flex;
			justify-content: center;
			align-items: center;

			.signup-item {
				margin-right: 20px;

				&.s-button {
					margin: 0;
					padding-left: 60px;
				}
			}
		}
	}

	.app-content--section {
		.two-col.wide-left {
			align-items: inherit;
		}
	}

	.content-block {
		h3 {
			font-size: 28px;
		}

		p {
			font-size: 18px;
		}

		.two-col {
			width: 100%;

			.col {
			}
		}
	}

	.division-text {
		display: flex;
		justify-content: center;
		margin-top: 1.5rem;

		p {
			font-size: 16px;
			font-weight: 700;
			color: #045b9c;
			margin: 0;
			margin-right: 1rem;
		}
	}

	.division-bottom {
		text-align: center;
		font-size: 14px;
		color: #7f7f7f;
	}

	.white-box--shadow.numbers {
		padding: 4rem;

		@media screen and (max-width: 900px) {
			padding: 2rem 0;
		}
	}

	.number-title {
		margin: 0 0 3rem;
		color: #1b90e8;
		font-size: 26px;
		letter-spacing: 1px;

		@media screen and (max-width: 600px) {
			margin-bottom: 1.5rem;
			font-size: 22px;
		}
	}

	.number-container {
		margin: 0;
		margin-bottom: 3rem;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		@media screen and (max-width: 900px) {
			margin-bottom: 2rem;
		}

		@media screen and (max-width: 600px) {
			margin-bottom: 1rem;
		}

		li {
			list-style: none;
			margin: 0 0.5rem;
			padding: 0;

			@media screen and (max-width: 900px) {
				list-style: none;
				margin: 0 0.5rem;
				padding: 5px;
				box-sizing: border-box;
			}

			@media screen and (max-width: 860px) {
				padding: 0;
				text-align: center;
				margin: 2% 1%;
			}

			@media screen and (max-width: 500px) {
				margin: 2% 0.5%;
			}


			.circle-number {
				display: block;
				width: 3.5rem;
				height: 3.5rem;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: white;
				font-weight: 700;

				@media screen and (max-width: 860px) {
					margin: auto;
					width: 3rem;
					height: 3rem;
				}

				@media screen and (max-width: 600px) {
					width: 2.5rem;
					height: 2.5rem;
				}

				@media screen and (max-width: 500px) {
					width: 2.25rem;
					height: 2.25rem;
				}

				&.orange {
					background-color: #f66c1c;
				}

				&.blue {
					background-color: #1b90e8;
				}
			}
		}
	}

	.view-all {
		font-size: 20px;
	}

	.small-top--content {
		max-width: 45rem;
		margin: auto;
		margin-bottom: 4rem;
		text-align: left;

		.orange-text {
			color: #f66c1c;
			font-weight: normal;
			margin-bottom: 2rem;
			font-size: 26px;
		}
	}
	/* Resonsive */
	@media screen and (max-width: 1080px) {
		.banner-logo--container {
			padding-top: 2rem;
		}

		.banner {
			padding: 2rem 0;
			max-height: none;
		}

		.banner .banner-image {
			position: absolute;
			left: 0;
			top: 0;
			width: 170% !important;
			min-width: 100%;
			min-height: 100%;
			height: auto;
			width: auto;
			max-width: none;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(0%);
		}

		.banner .container {
			width: 100%;
			max-width: 90%;
			z-index: 2;
			position: relative;
		}

		.banner-logo--container .future-logo {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 2rem 0 2rem 0px;

			@media only screen and (max-width: 450px) {
				margin-bottom: 10px;
			}
		}

		.banner-logo--container .future-logo img {
			max-width: 5rem;
			margin-right: 1rem;

			@media only screen and (max-width: 450px) {
				max-width: 3.5rem;
			}
		}

		.banner-logo--container .future-logo h3 {
			font-size: 30px;

			@media only screen and (max-width: 450px) {
				margin-top: 0;
				font-size: 23px;
				letter-spacing: 1px;
			}
		}

		.banner-logo--container .banner-content {
			display: flex;
			justify-content: space-between;
		}

		.banner-logo--container .banner-content .button-container {
			margin: 0;
			font-size: 18px;
		}
	}

	@media screen and (max-width: 730px) {
		.banner-logo--container .future-logo img {
			max-width: 4rem;
		}

		.banner-logo--container .future-logo {
			margin-top: 0;
		}

		.banner-logo--container {
			padding-top: 0;
		}
	}
}


@media only screen and (min-width: 1560px) {
	.futureball .banner.section-image .banner-image {
		left: 20%;
	}
}

.futureball {

	.futureball-banner {
		max-height: none;
		padding: 30px 0 0 !important;
		background: linear-gradient(to right, #F66c1c 0%, #FEA500 100%);

		.container {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			background-image: url('/assets/images/lottery-banners/futureball-banner.png');
			background-repeat: no-repeat;
			background-position: right bottom;
			background-size: auto 78%;
			padding-bottom: 45px;
			max-width: 66rem;

			@media only screen and (max-width: 980px) {
				display: block;
				background-image: none;
				text-align: center;
				width: 92.5% !important;

				h1 {
					font-size: 56px !important;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					margin-bottom: 10px;

					.currency {
						position: static !important;
						transform: none !important;
						font-size: 34px !important;
						margin-right: 10px;
						width: auto;
					}

					span {
						font-size: 26px !important;
						width: 100%;
					}
				}

				.button-container {
					text-align: center !important;
				}

				.image-side {
					margin: 0 auto;
				}

				ul {
					display: inline-flex;
					flex-direction: column;

					li {
						flex: 0 0 auto;
						display: inline-block;
					}
				}
			}
		}

		.banner-logo--container {
			width: 150px;
			margin-right: 75px;
		}

		.banner-content {
			h1 {
				font-size: 140px;
				max-width: none;
				line-height: 1;

				span {
					font-size: 34px;
					font-weight: 400;
					margin-bottom: 26px;
				}

				.currency {
					position: absolute;
					top: 0;
					left: -10px;
					transform: translate(-100%, 25%);
					font-size: 56px;
					font-weight: 500;
					color: rgba(255,255,255,0.5);
				}
			}

			ul {
				padding: 0;
				margin: 0;

				li {
					font-size: 18px;
					list-style: none;
					position: relative;
					padding-left: 1rem;
					color: white;

					&:before {
						content: url('/assets/icons/checkmark.svg');
						margin-right: 10px;
						margin-left: -10px;
					}
				}
			}

			.button-container {
				text-align: left;
				margin-top: 38px;

				.button {
					margin-top: 0;
					padding: 15px 33px;
					font-size: 18px;
					font-weight: 600;
				}

				p {
					font-size: 14px;
					margin: 10px 15px 0;
					font-weight: 600;
				}
			}
		}
	}
}

.columns {
	display: flex;
	justify-content: space-between;
	padding: 60px 40px;
	margin-top: 40px;
	border-top: 1px solid white;

	.col {
		background-color: white;
		border-radius: 10px;
		padding: 40px;
		flex: 1;
		margin-right: 20px;

		h2 {
			text-align: center;
			font-size: 20px;
			color: $orange;
			margin: 0 0 18px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				margin-left: 10px;
				height: 45px;
				width: auto;
				margin-left: 15px;
			}
		}

		> div {
			display: flex;
			margin-bottom: 10px;
		}

		p {
			flex: 1;
			text-align: left;
			font-size: 14px;
			color: $blue;
			margin: 0;

			&:last-of-type {
				font-weight: 600;
			}
		}

		.futureballs {
			flex: 1;
			display: flex;
			justify-content: flex-start;

			span {
				height: 25px;
				width: 25px;
				border-radius: 100%;
				background-color: #FB8E0C;
				margin-right: 5px;

				&.blue {
					background-color: $blue;
				}

				&:last-of-type {
					margin: 0;
				}
			}
		}

		&:last-of-type {
			margin-right: 0;

			h2 {
				margin-bottom: 35px;
			}

			> div {
				margin-bottom: 22px;
			}

			p:first-of-type {
				flex: .5;
			}
		}
	}
}

.general-content--columns {
	text-align: left;
	padding: 68px 0 45px;
	margin-top: 0;

	.container {
		display: flex;
		flex-wrap: wrap;
	}

	.col {
		padding: 0;
		margin-right: 80px;

		h2 {
			margin-bottom: 21px;

			img {
				width: 33px;
				height: 33px;
			}
		}

		p {
			margin-bottom: 10px;
		}

		&:last-of-type {
			margin-right: 0;

			h2 {
				margin-bottom: 25px;
			}
		}
	}

	ul {
		padding: 0;
		margin: 0;

		li {
			font-size: 14px;
			color: $blue;
			margin: 0 0 10px;
			list-style: none;
			position: relative;
			padding-left: 1rem;

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				width: 9px;
				height: 7px;
				background-image: url('/assets/icons/orange-checkmark.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
}

@media screen and (max-width: 1350px) {
	.columns {
		.col {
			padding: 30px 25px;

			> div {
				margin: 0 0 15px;
			}

			.futureballs {
				min-width: 172px;

				span {
					width: 20px;
					height: 20px;
				}
			}

			&:last-of-type {
				p:first-of-type {
					flex: 0 0 auto;
					min-width: 60px;
				}
			}
		}
	}
}


@media screen and (max-width: 1020px) {
	.columns {
		.col {
			> div {
				flex-wrap: wrap;
				margin-bottom: 15px;

				.futureballs {
					flex: 0 0 100%;
					margin: 5px 0 0;
				}
			}
		}
	}
}


@media screen and (max-width: 768px) {
	.columns {
		flex-wrap: wrap;
		padding: 40px 0 0;

		.col {
			flex: 0 0 92.5%;
			margin: 0 auto 20px !important;

			h2 {
				text-align: center;
				justify-content: center !important;
				flex-direction: column-reverse !important;
				display: flex !important;

				img {
					position: static;
					margin: 0 auto 15px;
					width: 60px;
					height: auto;
				}
			}

			&.info-col {
				padding: 30px 15px;

				> div {
					display: flex;

					p {
						flex: 0 0 60%;
						text-align: left;

						+ p {
							flex: 0 0 40%;
						}
					}
				}
			}

			> div {
				justify-content: center;
				display: block;
				text-align: center;

				p {
					text-align: center;
					display: block;
				}

				> * {
					flex: 0 0 auto;
				}

				.futureballs {
					flex: 2;
					justify-content: center;
				}
			}

			&:last-of-type {
				margin: 0;
			}
		}
	}
}

@media screen and (max-width: 765px) {
	.general-content--columns {
		padding: 40px 0 0;

		.container {
			flex-wrap: wrap;
		}

		.col {
			flex: 0 0 100%;
			margin: 0 0 40px;
			text-align: center;

			p {
				text-align: left;
			}

			ul {
				li {
					padding: 0;
					text-align: left;

					&:before {
						position: relative;
						top: 0;
						transform: translateY(0);
						display: inline-block;
						margin-right: 5px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 550px) {
	.columns {
		.col {
			padding: 30px 10px;

			> div {
				.futureballs {
					flex: 0 0 100%;
				}
			}

			.container-small {
				width: 92.5%;
			}

			&:last-of-type {
				h2 {
					display: flex;
					justify-content: space-between;
					align-items: center;

					img {
						position: relative;
						top: 0;
						transform: translateY(0);
						left: 0;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 425px) {
	.columns {
		.col {
			&:last-of-type {
				h2 {
					font-size: 18px;
					margin-bottom: 20px;

					img {
						height: 35px;
						width: 35px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.columns {
		.col {
			> div {
				p {
					flex: 0 0 100%;
					text-align: center;
				}
			}
		}
	}
}
