
.app {
    max-width: 80rem;
    margin: auto;

    .banner-app {
        .banner {
            padding: 1rem 0;

            .container {
                width: 100%;
                max-width: 80rem;
                border-radius: 8px;
                position: relative;
                margin: auto;
                min-height: 420px;

                .banner-app--container {
                    padding: 1rem 1rem 1rem 2rem;

                    h1 {
                        margin-bottom: 1rem;
                        color: #fff;
                        text-align: center;
                        font-size: 2rem;
                    }

                    h2,span.h2 {
                        font-weight: 500;
                        color: #ffffff;
                        text-align: center;
                        margin-bottom: 10px;
                        font-size:1.3em;
                        line-height:1.2;
                    }

                    span {
                        &.h2{

                        }
                        &.trademarknotice {
                            color: #ffffff;
                            padding: 15px 0;
                            display: block;
                            max-width: 25rem;
                            text-align: center;
                            opacity: 0.5;
                            margin: auto;
                            font-size: 12px;
                        }
                    }
                }

                background: url("/assets/images/app/app-banner-new.jpg") center center no-repeat !important;
            }
        }

        @media screen and (max-width: 1000px) {
            &.banner-has-draws {
                .banner {
                    .container {
                        h1, h3 {
                            text-shadow: 2px 2px 15px rgba(126,197,187,0.2);
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            .banner {
                .container {
                    h1, h3 {
                        text-shadow: 2px 2px 15px rgba(126,197,187,0.2);
                    }
                }
            }
        }

        @media screen and (max-width: 600px) {
            .banner {
                background: #f8f8f8;
                padding: 1rem;

                .container {
                    background: linear-gradient(to top, #1c3295 60%, #1c3295 0%, transparent), url('/assets/images/app/app-banner-mobile-new.jpg') center top no-repeat !important;
                }

                .aussie-owned-app{
                    padding-bottom:1rem;
                }
            }
        }
    }

    .download-button--container {
        padding-top: 30px;
        text-align: center;

        img {
            max-width: 170px;
        }
    }

    .main-app-section {
        padding: 1rem 0 2rem;
    }

    .appinfo-container {
        @media screen and (max-width: 900px) {
            max-width: 800px;
        }

        .three-col {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .col {
                width: 30%;

                @media screen and (max-width: 900px) {
                    width: 100%;
                }

                @media screen and (max-width: 900px) {
                    &:nth-child(2) {
                        img {
                            padding: 2rem 0;
                            max-width: 300px;
                            width: 100%;
                            margin: auto;
                        }
                    }
                }

                ul {
                    &.app-iconlist-left {
                        text-align: right;
                        padding: 0 5rem 0 0;

                        .icon-list-item {
                            text-align: right;
                            position: absolute;
                            right: -3.5rem;
                            top: 0rem;
                            max-width: 2.5rem;
                            width: 100%;
                        }

                        @media screen and (max-width: 900px) {
                            text-align: left;
                            padding: 0 0 0 5rem;
                            padding-top: 0;

                            .icon-list-item {
                                text-align: left;
                                position: absolute;
                                left: -3.5rem;
                                top: 4px;
                                max-width: 2.5rem;
                                width: 100%;
                            }
                        }
                    }

                    &.app-iconlist-right {
                        text-align: left;
                        padding: 0 0 0 5rem;

                        @media screen and (max-width: 900px) {
                            padding-top: 0;
                        }

                        .icon-list-item {
                            text-align: left;
                            position: absolute;
                            left: -3.5rem;
                            top: 0rem;
                            max-width: 2.5rem;
                            width: 100%;

                            @media screen and (max-width: 900px) {
                                top: 4px;
                            }
                        }
                    }

                    li {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        position: relative;

                        @media screen and (max-width: 900px) {
                            padding-bottom: 10px;
                        }

                        &:last-child {
                            padding-bottom: 0;
                        }

                        .icon-list-item {
                            img {
                                max-width: 3.9rem;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .download-button--container.app-bottom {
        transform: translateY(7rem);
        display: flex;
        justify-content: center;

        @media screen and (max-width: 900px) {
            transform: translateY(2rem);
        }

        a.app-button {
            margin: 0 1rem;
            display: inline-block;

            @media screen and (max-width: 900px) {
                margin: 0;
            }

            img {
                max-width: 180px;
            }
        }
    }
}

.app-screenshots--section {
    padding: 0 0 3rem 0;

    .screenshots-grid {
        display: flex;
        justify-content: space-between;

        img {
            max-width: 285px;
            border-radius: 15px;
        }

        h4 {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.app-content--section {
    padding: 6rem 0;

    .two-col.wide-left {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .col {
            &:first-child {
                width: 65%;
                position: relative;
                min-height: 400px;
                border-radius: 20px;
                display: flex;
                align-content: flex-end;
                justify-content: center;
                position: relative;
                overflow: hidden;

                @media screen and (max-width: 900px) {
                    width: 100%;
                    margin-bottom: 20px;
                }

                img {
                    position: absolute;
                    left: 50%;
                    top: 0;
                    min-width: 100%;
                    min-height: 100%;
                    height: auto;
                    width: auto;
                    max-width: none;
                    transform: translateX(-50%);
                    z-index: 1;
                }

                .dark-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    background: linear-gradient(183deg, transparent 20%, black 97%);
                }

                .content-container {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding: 40px 70px 20px;
                    z-index: 20;
                    text-align: left;

                    * {
                        color: white;
                    }

                    h3 {
                        font-size: 24px;
                        line-height: .5;

                        &:last-of-type {
                            line-height: 1;
                        }
                    }

                    p {
                        font-size: 12px;
                    }
                }
            }

            &:last-child {
                width: 33%;
                background: linear-gradient( 183deg, rgba(246, 113, 31, 1) 6%, rgba(255, 169, 84, 1) 100% );
                border-radius: 20px;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                @media screen and (max-width: 900px) {
                    width: 100%;
                }

                .inner {
                    padding: 15px 30px;
                    padding-top: 90px;

                    @media screen and (max-width: 900px) {
                        padding: 20px 30px;
                    }

                    h4 {
                        font-size: 26px;
                        line-height: 1.5;
                        color: white;
                        text-align: center;
                        max-width: 94%;
                        margin: 0 auto 30px auto;
                    }
                }

                .button-wrap {
                    display: block;
                    margin-top: 0;
                }
            }
        }
    }
}


.app-button {
    display: inline-block;
    width: 10rem;
}

.why-tlo {
    padding: 2rem;
    background: #ccc;

    .stars {
        width: 140px;
        margin: 5px 0;
    }
}

.ratings-box-container {
    position: relative;
    margin-top: 2rem;
    max-width: 350px;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    position: absolute;
    left: 30px;
    top: -14px;
}

.ratings-box {
    position: relative;
    height: 230px;
    width: 350px;
    padding: 0.5rem 1rem;
    background: #fff;

    p {
        margin: 10px 0 0;
        text-align: left;
    }

    h4 {
        margin: 0;
    }
}

.app-help {
    background: url('/assets/images/app/help-bg.png') center top no-repeat;
    max-width: 1180px;
    margin: 2rem auto;
    border-radius: 10px;

    .container {
        min-height: 160px;
    }

    .button {
        margin: 0 auto;
        max-width: fit-content;
    }

    .help-icon {
        width: 160px;
        margin-right: 5rem;
    }
}

.download-app-cta {
    padding: 2rem 0;

    h2 {
        margin: .5rem auto;
        font-size: 2.2rem;
        color: #000;
        font-weight: bold;
    }

    img {
        width: 500px;
        max-width: 100%;
    }

    .app-button{
        width:auto;
    }
}

@media screen and (max-width: 1200px) {
    .why-tlo {
        h2 {
            text-align: center;
        }
    }

    .ratings {

        .ratings-box-container {
            justify-content: center;
            margin-left: 5px;
            margin-right: 5px;
        }

        .ratings-box {
            width: 300px;
        }
    }
}


@media screen and (max-width: 1000px) {
    .banner {
        .container {
            .banner-app--container {
                padding: 1rem !important;

                h1 {
                    font-size: 2rem;
                    padding-top: 1rem;
                }

                h2 {
                    font-size: 1.5rem;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .app-screenshots--section {
        .screenshots-grid {
            display: block;
        }
    }

    .ratings {
        flex-direction: column;
        justify-content: center;

        .ratings-box-container {
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .download-app-cta-container {
        display: block !important;
        margin:auto;
        h2 {
            font-size: 2rem;
        }
    }
}

@media screen and (max-width: 900px) {
    .app {
        .main-app-section {
            padding: 2rem 0;
        }

        .appinfo-container {
            .three-col {
                .col {
                    &:first-child {
                        order: 2;
                    }

                    &:nth-child(2) {
                        order: 1;
                    }

                    &:last-child {
                        order: 3;
                    }
                }
            }
        }

        .app-screenshots--section {
            padding: 0 0 2rem 0;
        }

        .app-content--section {
            padding: 2rem 0;
        }
    }
}

@media screen and (max-width: 600px) {
    .banner {
        .container {
            .banner-app--container {
                h2 {
                    font-size: 1.2rem;
                }

                span {
                   // font-size: .6rem;
                }
            }

            .download-button--container {
                padding-top: 10px;
            }
        }
    }

    .site-container {
        h2 {
            max-width: 85%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        .why-tlo {
            h2 {
                max-width: 90%;
            }
        }
    }

    .content-container {
        padding: 1rem !important;
        display: block;
        margin: auto;
        position: relative !important;

        h3 {
            line-height: 1 !important;
            text-align: center;
        }
    }

    .app-help {
        margin-top: 6rem;
        margin: 5rem 1rem 1rem 1rem;
        background: url('/assets/images/app/help-bg-mobile.png') center center no-repeat;

        .container {
            display: block;
            min-height: 230px;
        }

        .help-icon {
            width: 150px;
            margin: -3rem auto auto;
        }
    }


}
