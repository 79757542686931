.select-dropdown {
    position: relative;
    transition: border-radius ease-in-out 0s 0.2s;
    border: 2px solid $blue;
    border-radius: rem(25);
    overflow: hidden;
    cursor: pointer;
    z-index: 99;

    &.has-error {
        border-color: #D0021B;

        .select-rendered {
            color: #D0021B;

            &:after {
                background-color: #D0021B;
            }
        }
    }

    input[type="text"] {
        color: $grey;
        margin: 0 !important;
        width: 100%;
    }

    input[type="text"]::placeholder {
        color: $grey;
    }

    .select-rendered {
        border-bottom: none;
        font-size: 14px !important;
        color: $blue;
        letter-spacing: rem(0.5);
        padding: rem(13) rem(35) rem(14) rem(20);
        position: relative;
        width: 100%;
        text-align: center;
        display: block;
        font-weight: 600;
        overflow: hidden;
        justify-content: center;
        height: 41px;
        line-height: 1;
        font-family: $openSans;


        &:after {
            content: '';
            background-color: $blue;
            background-image: url(../icons/icon-angle-down.svg);
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            width: rem(15);
            height: rem(15);
            position: absolute;
            right: rem(10) !important;
            top: 50%;
            transform: translateY(-50%);
            transition: all ease-in-out 0.2s;
            font-weight: 800;
            overflow: visible;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            &:after {
                background-size: 10px;
            }
        }
    }

    .select-option {
        &.ZeptoPayID {
            span {
                display: block;
                font-size: .75rem;
            }
        }
    }

    .select-rendered {
        &.ZeptoPayID {
            span {
                display: none;
            }

            img {
                width: 4rem !important;
                max-width: 40px !important;
            }
        }

        &.Blueshyft {
            img {
                width: 6rem !important;
                max-width: 65px !important;
            }
        }
    }


    .select-option, .select-rendered {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;

        &.has-image {
            padding-left: rem(64) !important;
        }

        &:focus {
            background: rgba(0,114,0,0.2) !important;
            outline: none;
        }

        img {
            max-width: 35px !important;
            max-height: 25px;
            height: auto !important;
            width: 100%;
            margin-right: rem(30);
            position: absolute;
            left: rem(20);
            top: 50%;
            transform: translateY(-50%);
            border-radius: 0 !important;
            border: none !important;
        }

        &.Blueshyft {
            padding-left: 6rem !important;
        }

        &.Paysafecard {
            padding-left: 6rem !important;

            img {
                width: 5rem !important;
                max-width: 65px !important;
            }
        }
    }

    .select-inner {
        transition: all ease-in-out 0.2s;
        max-height: 0;
        overflow: hidden;
    }

    .select-options {
        width: 100%;
        background: white;
        padding: 0;
        margin: 0;
        list-style: none;
        font-weight: 400;
        position: relative;
        z-index: 0;
        max-height: rem(144);
        overflow-y: auto;

        .select-option {
            text-align: center;
            padding: rem(10) rem(20);
            position: relative;
            font-size: em(14);
            cursor: pointer;
            color: $blue;
            background-color: #E9EFF5;
            display: flex;
            align-items: center;
            transition: all ease-in-out 0.15s;
            text-decoration: none !important;

            &.selected {
                color: $orange;
            }

            &.hidden {
                display: none;
            }

            &:nth-of-type(even) {
                background-color: white;
            }
        }
    }

    &.dropdown-active {
        transition: all ease-in-out 0s;
        border-radius: rem(22.5);

        .select-rendered {
            transition: all ease-in-out 0s;

            &:after {
                transform: translateY(-50%) rotate(180deg)
            }
        }

        .select-inner {
            max-height: rem(200);
        }
    }

    &.select-left {
        .select-rendered {
            text-align: left;
        }
    }

    &.select-white {
        border-color: white;

        * {
            background: transparent !important;
            color: white !important;
        }

        .select-rendered {
            border-color: white;

            &:after {
                background-color: white;
                background-image: url(../icons/icon-angle-down-blue.svg);
            }
        }

        .select-options {
            .select-option {
                border-top: 1px solid white;
            }
        }

        &.dropdown-active {
            .select-options {
                border-color: white;
            }
        }
    }

    &.select-left {
        .select-option, .select-rendered {
            justify-content: flex-start;
            text-align: left;
        }
    }
}

@media only screen and (min-width: 980px) {
    .select-dropdown {
        .select-options {
            .select-option {
                &:hover {
                    background: rgba(0,114,0,0.2);
                }
            }
        }
    }
}

    .account-nav-funds-actions, .account-funds--bottom {
        .select-dropdown {
            .select-options {
                display: flex;
                flex-wrap: wrap;
                max-height: rem(192);

            .select-option {
                &.has-image {
                    flex: 0 50%;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    padding: 10px !important;
                    background-color: white;
                    border-right: 1px solid $orange;
                    border-bottom: 1px solid $orange;

                    &:hover {
                        background-color: #E9EFF5;
                    }

                    img {
                        width: 3.5rem !important;
                        position: unset;
                        max-height: none;
                        max-width: none !important;
                        transform: none;
                        margin: 5px auto !important;
                        left: unset;
                        top: unset;
                    }

                        &[data-methodname="Paysafecard"] {
                            > img {
                                width: 7rem !important;
                            }
                        }

                        &[data-methodname="Blueshyft"] {
                            > img {
                                width: 6rem !important;
                            }
                        }
                    }

                &:not(.has-image) {
                    width: 100%;
                }
            }
        }
    }
}
