.lottery-footer-sponsors {
    background: #f8f8f8;
    padding: 2.1875rem 0;
    margin: 0;
    border-top:1px solid #ccc;
}

.footer-sponsors-container {
    margin-top: 1rem;
    width: 100%;

    img {
        width: 153px;
        height: 72px;
    }
}

 
@media only screen and (max-width: 1000px) {
    .footer-sponsors-container img {
        width: 120px;
        height: 62px;
    }
}


@media only screen and (max-width: 768px) {
    .lottery-footer-sponsors {
        padding: 1rem 0;
    }

    .footer-sponsors-container {
        display: block !important;

        img {
            display: block !important;
            margin: 0rem 1rem .5rem;
        }
    }   
}
@media only screen and (max-width: 600px) {
    .footer-sponsors-container {
        width: 600px;        
    }
}
