.lottery-card-account {
    width: rem(520);
    max-width: 100%;
    padding: rem(35) rem(25) !important;
    cursor: initial;

    .lottery-card-account--header {
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $grey;
        padding-bottom: rem(25);

        li {
            margin: 0;
            text-align: center;
            font-size: em(16);
            flex: 0 0 30%;
            margin-right: rem(30);
            cursor: pointer;
            text-decoration: none !important;
            font-weight: 600;

            &:last-of-type {
                margin-right: 0;
            }

            .card-icon {
                border: 3px solid $grey;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: rem(60);
                flex: 0 0 rem(60);
                height: rem(60);
                align-self: center;
                margin: 0 auto rem(15);
                transition: all ease-in-out 0.15s;

                svg {
                    border: none !important;
                    width: rem(35) !important;
                    flex: 0 0 rem(35) !important;
                    max-height: rem(35) !important;
                    min-height: rem(30);
                    border-radius: 0 !important;
                    margin: 0 !important;

                    * {
                        transition: all ease-in-out 0.15s;
                    }
                }
            }


            &:hover {
                .card-icon {
                    color: $orange;
                    border-color: $orange;

                    svg {
                        path {
                            stroke: $orange;
                            fill: $orange;
                        }

                        circle, polyline {
                            stroke: $orange;
                        }
                    }
                }
            }

            svg {
                * {
                    stroke: $grey;
                    transition: none !important;
                }

                circle {
                    transition: all ease-in-out 0.2s !important;
                }

                path {
                    fill: $grey;
                    transition: all ease-in-out 0.2s !important;
                }

                #Oval-4 {
                    fill: none;
                }

                #ICON-Cart > path {
                    fill: transparent;
                }
            }

            &.active {
                color: $orange;

                svg {
                    border-color: $orange !important;


                    * {
                        stroke: $orange;
                    }

                    path {
                        fill: $orange;
                    }
                }
            }
        }
    }

    .account-funds {
        padding: 0 rem(35);

        .choose-plays {
            h4 {
                text-align: left;
                margin-bottom: rem(10);
                color: $darkGrey;
                padding-left: rem(10);
                font-size: em(18);
            }

            .choose-plays-container {
                margin: 0;

                label {
                    flex: 0 0 24%;
                    max-width: 24%;
                    margin: 0 0 rem(15);

                    &.input-full {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }
        }

        input[type="text"] {
            width: 100% !important;
        }

        .account-nav-funds-actions {
            align-items: center;
            margin: 0 0 rem(15);

            .select-dropdown {
                flex: 2;

                .select-options {
                    max-height: rem(192);
                }
            }
        }
    }

	.account-nav-funds--footer {
		padding: rem(25) rem(35) 0;
		border-top: 1px solid $grey;
		margin-top: rem(25);

        h3 {
            color: $darkGrey;
            text-align: left;
            justify-content: flex-start !important;
            cursor: pointer;
            display: inline-flex !important;
            transition: all ease-in-out 0.2s;
            margin-bottom: 0 !important;

            span {
                border: 3px solid $darkGrey;
                border-radius: 50%;
                width: rem(25);
                height: rem(25);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: rem(10);
                font-size: em(12);
                transition: border-color ease-in-out 0.2s;
            }

            &:hover, &.active {
                color: $orange;

                span {
                    border-color: $orange;
                }
            }
        }

        .tab-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &.active {
                margin-top: rem(15);
            }

            p {
                color: $grey;
                margin: 0;

                span {
                    font-weight: 600;
                }
            }
        }
    }
}
