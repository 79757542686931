.section.thick.about-betting-section {
    @media only screen and (max-width: 700px) {
        padding-top: 1.5rem;
    }
}

.banner-content--container {
    position: absolute;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;

    .banner-list {
        max-width: 75rem;
    }

    &.desktop {
        @media only screen and (max-width: 1100px) {
            display: none;
        }
    }

    &.mobile {
        display: none;

        @media only screen and (max-width: 1100px) {
            display: block;
            position: relative;
            padding: 0;
        }
    }
}

.banner-list {
    margin: auto;
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: flex-end;
    padding-top: 2.5rem;

    .list-items-options {
        width: 100%;
    }

    .list-items-options .row {
        display: flex;

        img {
            max-width: 1.6rem;
        }

        .col {
            padding: 1rem 1rem;
            box-sizing: border-box;

            &.orange {
                background-color: #f66c1c;
                color: white;
                text-align: center;

                p {
                    color: white;
                    text-transform: uppercase;
                    margin: 0;

                    @media only screen and (max-width: 500px) {
                        font-size: 9px;
                    }
                }
            }

            &.dark {
                background-color: #2f2b29;
                color: white;
                text-align: center;

                p {
                    color: white;
                    text-transform: uppercase;
                    margin: 0;

                    @media only screen and (max-width: 500px) {
                        font-size: 9px;
                    }
                }
            }

            &:nth-child(1) {
                width: 66.6666%;

                @media only screen and (max-width: 500px) {
                    width: 50%;
                }

                @media only screen and (max-width: 400px) {
                    width: 47%;
                }
            }

            &:nth-child(2),
            &:nth-child(3) {
                width: 33.3333%;
            }
        }

        .border {
            box-sizing: border-box;
            border-left: solid 1px #cfcfcf;
            border-right: solid 1px #cfcfcf;
        }

        .col p {
            font-size: 12px;
            line-height: 1.4;
            margin: 0;

            strong {
                font-weight: 600;
                margin-bottom: 0.3rem;
                display: block;
            }
        }

        &.two {
            background-color: white;
        }

        &.three {
            background-color: #ececec;
        }

        &.four {
            background-color: #f2f2f2;
        }

        .icon-container {
            text-align: center;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
        }
    }
}

.about-betting-section {
    padding: 3rem 0;
    text-align: left;

    @media only screen and (max-width: 700px) {
        padding: 3rem 0;
    }

    h3 {
        font-size: 26px;
        color: #f66c1c;

        @media only screen and (max-width: 700px) {
            font-size: 23px;
        }
    }

    p {
        font-size: 18px;
        color: #000000;
        letter-spacing: 1px;

        @media only screen and (max-width: 700px) {
            font-size: 16px;
        }
    }

    .top-content {
        padding-left: 5rem;

        .icon-content {
            top: 0;
        }

        @media only screen and (max-width: 900px) {
            padding-left: 3.2rem;

            .icon-content {
                top: 0.1rem;
                max-width: 1.8rem;
                left: 0.2rem;
            }
        }
    }

    .icon-content {
        position: absolute;
        left: 0.8rem;
        top: 1rem;
        max-width: 3rem;
        width: 100%;
        height: auto;

        @media only screen and (max-width: 600px) {
            display:none;
        }

            @media only screen and (max-width: 900px) {
                left: 0;
                top: 1.7rem;
                max-width: 2rem;
            }

            @media only screen and (max-width: 700px) {
                left: 0;
                top: 1.3rem;
                max-width: 2rem;
            }
        }

        .middle-content {
            padding: 3rem 0;

            @media only screen and (max-width: 700px) {
                padding: 1rem 0;
            }

            .two-col {
                .col {
                    &:first-child {
                        padding-left: 5rem;
                        position: relative;

                        @media only screen and (max-width: 900px) {
                            padding-left: 3rem;
                        }
                    }

                    &:last-child {
                        padding-left: 5rem;
                        position: relative;

                        @media only screen and (max-width: 900px) {
                            padding-left: 3rem;
                        }

                        .badge-wrap {
                            position: relative;
                            z-index: 10;
                        }

                        p {
                            padding-right: 7.5rem;

                            @media only screen and (max-width: 700px) {
                                padding-right: 0;
                            }
                        }

                        .content-badge {
                            position: absolute;
                            z-index: 1;
                            max-width: 9rem;
                            right: -10px;
                            top: 50%;
                            transform: translateY(-24%);

                            @media only screen and (max-width: 700px) {
                                max-width: 6rem;
                                right: -10px;
                                top: 90%;
                                transform: translateY(-24%);
                            }
                        }
                    }
                }
            }
        }

        .bottom-content {
            .two-col {
                .col {
                    &:first-child {
                        padding-left: 5rem;
                        position: relative;

                        @media only screen and (max-width: 900px) {
                            padding-left: 3rem;
                        }
                    }

                    &:last-child {
                        padding-left: 5rem;
                        position: relative;

                        @media only screen and (max-width: 900px) {
                            padding-left: 3rem;
                        }
                    }
                }
            }
        }

        .two-col {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .col {
                width: 48%;
                padding-left: 5rem;

                @media only screen and (max-width: 900px) {
                    width: 100%;
                    padding-left: 3rem;

                    &:first-child {
                        margin-bottom: 4rem;
                    }
                }

                @media only screen and (max-width: 700px) {
                    margin-bottom: 1rem;

                    &:first-child {
                        margin-bottom: 1rem;
                    }
                }

                ul {
                    padding-left: 1.5rem;

                    li {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        margin-bottom: 1.5rem;
                        position: relative;

                        a {
                            font-size: 18px;
                            color: #000000;

                            &:hover {
                                opacity: 1;
                                text-decoration: underline;
                            }

                            img {
                                width: 0.7rem;
                                position: absolute;
                                left: -1.5rem;
                                top: 0.35rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .about-betting-blue {
        padding: 6rem 0;
        background-color: #037bdb;

        @media only screen and (max-width: 1280px) {
            padding: 3rem 0;
            padding-top: 4rem;
        }

        .container {
            max-width: 78.75rem;
        }

        .gradient_content {
            background: -webkit-linear-gradient(#f6711f, #ffa954);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 64px;
            margin: 0;

            @media only screen and (max-width: 1080px) {
                font-size: 45px;
            }
        }

        .two-col.wide-left {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .col {
                &.sml-col {
                    width: 25%;
                    position: relative;
                    padding-top: 3.5rem;

                    @media only screen and (max-width: 1280px) {
                        width: 100%;
                        position: relative;
                        padding-top: 0rem;
                        padding-bottom: 1rem;
                    }

                    .blue-icon {
                        max-width: 3.3rem;
                        position: absolute;
                        left: 0;
                        top: 2rem;

                        @media only screen and (max-width: 1280px) {
                            max-width: 3.3rem;
                            position: absolute;
                            left: 0;
                            top: -1rem;
                        }
                    }

                    .left-content {
                        padding-left: 5rem;
                        color: white;
                        text-align: left;

                        @media only screen and (max-width: 1280px) {
                        }
                    }

                    h4 {
                        color: white;
                        margin: 0;
                        font-size: 26px;
                    }

                    p {
                        color: white;
                        line-height: 1.5;
                        font-size: 16px;
                    }
                }

                &:last-child {
                    width: 70%;

                    @media only screen and (max-width: 1280px) {
                        width: 100%;
                    }

                    .three-col {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .col {
                            width: 33.33333%;
                            padding: 0 0.4rem;

                            @media only screen and (max-width: 700px) {
                                width: 100%;
                                padding: 1rem 0rem;
                            }

                            .white-box {
                                padding: 2rem 1rem;
                                border-radius: 2rem;
                                background-color: white;
                                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

                                @media only screen and (max-width: 1280px) {
                                    padding: 2.5rem 3rem;
                                }

                                @media only screen and (max-width: 1080px) {
                                    padding: 2.5rem 1.5rem;
                                    height:220px;
                                }

                                p {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

.top-content {
    position: relative;

    @media only screen and (max-width: 600px) {
        padding-left:0 !important;
    }

        @media only screen and (max-width: 700px) {
            padding-left: 3.1rem;
        }

        img {
            top: -0.8rem;
            width: 2.5rem;
            left: 1.2rem;

            @media only screen and (max-width: 700px) {
                top: -0.4rem;
                width: 1.8rem;
                left: 0.4rem;
            }
        }
    }

 