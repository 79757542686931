.section-site-map {
    padding: 0;
    padding-top: rem(20);
    display: block;
    position: relative;
    background-color: #2DA0F1;

    h1 {
        color: white;
        font-size: em(17);
        font-weight: 600;
        width: 92.5%;
        max-width: rem(1180);
        margin: 0 auto;
        padding: 0 0 rem(12);
        text-align: center !important;
        /*&:after {
            content: '';
            width: 100vw;
            height: 1px;
            background: white;
            display: block;
            position: absolute;
            left: 0;
            right: auto;
            margin-top: rem(15);
        }*/
        img {
            width: 45px;
            height: 45px;
            vertical-align: text-bottom;
            margin-right: 1rem;
        }
    }

    p {
        &.intro {
            max-width: 80%;
            margin: auto;
            color: #ffffff;
            margin-bottom:1rem;
        }
    }

    .lottery-card {
        max-width: 22.5rem;
        text-align: left;

        .container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .nav-listing {
            flex: 0 0 100%;
            margin-bottom: rem(40);
        }

        h3 {
            font-size: em(22);
            position: relative;
            padding-bottom: rem(10);
            margin-bottom: rem(15);

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #979797;
                display: block;
            }
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;

                a {
                    font-size: em(16);
                    color: $darkGrey;
                    font-weight: 400;
                }

                .sub-menu {
                    margin: 0 0 rem(10) rem(20);

                    li {
                        a {
                            color: $grey;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        .list-type-alternate {
            li {
                &:nth-of-type(even) {
                    margin-left: rem(12);
                }
            }
        }
    }
}

.page-site-map {
    .lottery-footer-cards {
        padding-top: rem(20);
    }
}

@media only screen and (min-width: 680px) {
    .section-site-map {
        .lottery-card {
            max-width: 100%;

            h3:after {
                width: 100%;
            }

            .nav-listing {
                flex: 0 0 45%;
            }
        }
    }
}

@media only screen and (min-width: 860px) {
    .section-site-map {
        h1 {
            text-align: left;
            font-size: em(30);
            padding: 0 0 rem(12);

            &:after {
                margin-top: rem(25);
            }
        }

        .container {
            padding-top: rem(20);
        }
    }
}

@media only screen and (min-width: 980px) {
    .section-site-map {
        .lottery-card {
            width: 100%;

            .nav-listing {
                flex: 0 0 28%;
            }

            ul {
                li {
                    a:hover {
                        color: $orange !important;
                        opacity: 1 !important;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1125px) {

    .section-site-map {
        .lottery-card {
            width: 100%;

            .nav-listing {
                flex: 0 0 28%;

                h3 {
                    font-size: em(28);
                }

                ul {
                    li {
                        a {
                            font-size: em(18);
                        }
                    }
                }
            }
        }
    }
}
