﻿
.banner {
    padding: 0;
    max-width: 80rem;

    .container {
        padding: 0 2rem;
        min-height: 375px;
        max-width: 80rem;
        position: relative;
        width: 100%;

        .headers {
            flex: 70%;
            max-width: 45rem;
            justify-content: center;
            text-align: center;

            h1 {
                max-width: 47rem;
                font-size: 4.5rem;
                text-align: left;
                margin: auto;
                line-height: 1;
                color: white;
                font-weight: 600;
                z-index: 1;
                position: relative;
            }

            span {
                font-size: 2.2rem;
                display: block;
                font-weight: 800;

                &.tag-line {
                    font-size: 1.5rem;
                    text-align: left;
                    margin: auto;
                    color: $white;
                    display: block;
                    font-weight: 600;
                    max-width: 45rem;
                }
            }
        }
    }


    @media screen and (max-width: 1000px) {
        .container {

            .headers {
                padding: 5% 0%;
                max-width: 100%;

                h1 {
                    text-align: center;
                    font-size: 4rem;
                }

                span {
                    &.tag-line {
                        text-align: center;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        background: #f8f8f8 !important;
        .container {

            .headers {
                h1 {
                    font-size: 3rem;
                }

                span {
                    &.tag-line {
                        font-size: 1rem;
                        line-height: 1.5;
                    }
                }

                p {
                    font-size: 1rem;
                }
            }
        }
    }
}

.banner-left {
    display: flex;
    flex: 1;
    align-items: center;

    img {
        display: flex;
        width: 128px;
        height: 128px;
        margin-right: 3rem;
    }

    .jackpot {
        display: flex;
        flex-direction: column;

        .jackpot-name {
            transform: translate(0rem, .5rem);

            h1 {
                color: #ffffff;
                font-size: 24px;
                font-weight: 500;
                text-align: left;
                text-shadow: 2px 2px 15px rgba(0,0,0,0.2);
            }

            h2 {
                color: #fff;
                letter-spacing: 2px;
                font-weight: 300;
                text-shadow: 2px 2px 15px rgb(0, 0, 0 / 20%);
                font-size: 24px;
                margin: 1rem auto auto;
            }
        }

        .jackpot-play {
            h2 {
                font-size: 46px;
                text-align: center;
                margin: 0 auto;
                color: white;
                font-family: $proximaSoft;
                letter-spacing: 6px;

                span {
                    display: block;
                    font-family: $proximaSoft;
                    font-size: 120px;
                }
            }

            .pending {
                font-size: 2rem;
            }

            .jackpot-pending {
                color: white;
                font-size: 2rem;
                font-weight: 600;
            }

            .currency {
                font-size: 40px;
                transform: translate(-20px, 5px);

                @media screen and (max-width: 400px) {
                    transform: translate(10px, 5px);
                }

                position: absolute;
            }

            p {
                padding: 1rem;
                color: #fff;
                font-size: 18px;
                line-height: 1;
                font-weight: 700;
                text-shadow: 2px 2px 15px rgba(0,0,0,0.2);
                margin: 0 auto;
                text-align: center;
            }

            .button {
                width: 100%;
                z-index: 1;
            }
        }
    }
}

.banner-right {
    /*        display: flex;
*/
    display: none;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    background: transparent;
    z-index: 9;
    padding-right: 1rem;
    max-width: 335px;

    h2 {
        color: white;
        margin: 10px auto;
    }

    .banner-draw {
        cursor: pointer;
        box-shadow: 0 0 5px 2px rgba(8, 67, 157,.4);
        padding: 10px 25px;
        margin-bottom: 10px;
        padding: 10px 25px;
        border-radius: .3rem;

        p {
            font-family: $proximaSoft;
            font-weight: 600;
            font-size: 1rem;
            margin: auto;
            line-height: 1.5;
            color: $orange;
        }

        span {
            color: $blue;
            margin: 2.5px 0;
        }

        .banner-draw--cart {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1;

            svg {
                height: 38px;
                width: 38px;
                padding-left: 5px;
                position: absolute;
                margin: 15px auto auto;
                transform: scale(0) translate(0,0);
                animation: scaleUp 0.2s ease-in-out 0.05s;
                animation-fill-mode: forwards;
                transform-style: preserve-3d;

                path {
                    &.st0 {
                        fill: $orange;
                        stroke-width: 0;
                    }
                }
            }

            .icon-check-circle {
                stroke: $green;
                stroke-dasharray: 100;
                stroke-dashoffset: 100;
                transform-style: preserve-3d;
                transform: scale(0);
            }
        }

        > div {
            display: flex;
            align-items: flex-start;
        }

        &.active {
            animation: scaleCard 0.75s ease-in-out 0s;

            .icon-cart {
                animation: cartAnimation 0.75s ease-in-out 0.05s;
                animation-fill-mode: forwards;

                #cart-arrow {
                    transform: scaleY(1.5);
                    transition: all ease-in-out 0.3s;
                }
            }

            .icon-check-circle {
                animation-name: dash;
                animation-duration: 0.4s;
                animation-delay: 0.4s;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                transform: scale(1);
            }
        }

        &:hover {
            opacity: 1;
            background-color: rgba(255,255,255,0.85);
        }
    }

    .download-app {
        h2 {
            padding: .5rem;
            margin: 0 auto;
            background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
            font-weight: 500;
            font-size: 1.1rem;
        }

        .download-links {
            padding: .8rem 1rem;
            display: flex;
            background-color: rgba(142, 186, 216, .5);
        }
    }
}

#banner-default {
    $gradient: transparent;
    $bgUrl: '/assets/images/home/home-bay-desktop.jpg';
    $bgmobileUrl: '/assets/images/home/home-bay-mobile.jpg';

    @include banner-generic($gradient, $bgUrl, $bgmobileUrl);
}

#banner-home {
    $gradientLeft: transparent;
    $gradientRight: transparent;
    /*$bgUrl: '/assets/images/home/home-rocket-cover.jpg';
    $bgmobileUrl: '/assets/images/home/home-rocket-cover-mobile.jpg';*/
    $bgUrl: '/assets/images/home/home-ausday-22.jpg';
    $bgmobileUrl: '/assets/images/home/home-ausday-22-mobile.jpg';

    @include banner(transparent, transparent, $bgUrl, $bgmobileUrl,false,0);

    @media screen and (max-width: 600px) {
        .container {
            background-position: center;
        }
    }
}

/*Superdraw Play Banner*/
#banner-euro-super {
    $gradient-left: #4a43a3;
    $gradient-right: #4a43a3;
    /*    $banner-bg: "/assets/images/home/banner-superdraw-jan21.jpg";
    $banner-mobile: "/assets/images/home/banner-superdraw-jan21-mobile.jpg";*/
    $banner-bg: '/assets/images/lottery-banners/superdraw-zero.jpg';
    $banner-mobile: '/assets/images/lottery-banners/superdraw-mobile-zero.jpg';
    @include banner(transparent, transparent, $banner-bg, $banner-mobile, false,1);

    @media screen and (max-width: 1024px) {
        .container {
            background: linear-gradient(to top, transparent 53%, transparent 0%, transparent), url('/assets/images/lottery-banners/superdraw-tablet-zero.jpg') right top no-repeat;
        }
    }

    .tax-free {
        display: none;
        width: 148px;
        height: 114px;
        position: absolute;
        top: 55%;
        left: 35%;
    }
}
/*Euro Play Banner*/
#banner-euro {
    $gradient-left: #da8d00;
    $gradient-right: #da8d00;
    /*        $banner-bg: "/assets/images/lottery-banners/euromillions.jpg";
        $banner-mobile: "/assets/images/lottery-banners/euromillions.jpg";*/
    $banner-bg: "/assets/images/lottery-banners/euromillions-zero.jpg";
    $banner-mobile: "/assets/images/lottery-banners/euromillions-mobile-zero.jpg";
    @include banner(transparent, transparent, $banner-bg, $banner-mobile, false,1);
}
/*Euro Jackpot Play Banner*/
#banner-eurj {
    $gradient-left: #7ec5bb;
    $gradient-right: #7ec5bb;
    /*        $banner-bg: "/assets/images/lottery-banners/eurojackpot.jpg";
        $banner-mobile: "/assets/images/lottery-banners/eurojackpot.jpg";*/
    $banner-bg: "/assets/images/lottery-banners/eurojackpot-zero.jpg";
    $banner-mobile: "/assets/images/lottery-banners/eurojackpot-mobile-zero.jpg";
    @include banner(transparent, transparent, $banner-bg, $banner-mobile, false,1);
}
/*USPB Play Banner*/
#banner-uspb {
    $gradient-left: #6461cc;
    $gradient-right: #6461cc;
    /*        $banner-bg: "/assets/images/lottery-banners/us-powerball.jpg";
        $banner-mobile: "/assets/images/lottery-banners/us-powerball.jpg";*/
    $banner-bg: "/assets/images/lottery-banners/us-powerball-zero.jpg?v=3";
    $banner-mobile: "/assets/images/lottery-banners/us-powerball-mobile-zero.jpg?v=3";
    @include banner(transparent, transparent, $banner-bg, $banner-mobile, false,1);
}
/*USMM Play Banner*/
#banner-usmm {
    $gradient-left: #e7304e;
    $gradient-right: #e7304e;
    /* $banner-bg: "/assets/images/lottery-banners/us-megamillions.jpg";
        $banner-mobile: "/assets/images/lottery-banners/us-megamillions.jpg";*/
    $banner-bg: "/assets/images/lottery-banners/megamillions-zero.jpg";
    $banner-mobile: "/assets/images/lottery-banners/megamillions-mobile-zero.jpg";
    @include banner(transparent, transparent, $banner-bg, $banner-mobile,false, 1);
}
/*IREL Play Banner*/
#banner-irel {
    $gradient-left: #02ca5d;
    $gradient-right: #02ca5d;
    /* $banner-bg: "/assets/images/lottery-banners/irish-lotto.jpg";
        $banner-mobile: "/assets/images/lottery-banners/irish-lotto.jpg";*/
    $banner-bg: "/assets/images/lottery-banners/irish-zero.jpg";
    $banner-mobile: "/assets/images/lottery-banners/irish-mobile-zero.jpg";
    @include banner(transparent, transparent, $banner-bg, $banner-mobile,false, 1);
}
/*UKLO Play Banner*/
#banner-uklo {
    $gradient-left: #2460d8;
    $gradient-right: #97011e;
    /*$banner-bg: "/assets/images/lottery-banners/uk-banner-new.jpg";
        $banner-mobile: "/assets/images/lottery-banners/uk-banner-new.jpg";*/
    $banner-bg: "/assets/images/lottery-banners/uk-zero.jpg";
    $banner-mobile: "/assets/images/lottery-banners/uk-mobile-zero.jpg";
    @include banner(transparent, transparent, $banner-bg, $banner-mobile, false,1);

    .container {
        padding: 1rem;
    }

    .banner-label {
        padding: 10px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: #e52a30;
        color: $white;
        max-width: 200px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-family: $proximaSoft;
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        z-index: 9;
    }


    #lottery-game--banner-info {
        margin: 2rem auto;
        padding: 2rem 30px 1rem;
        position: relative;
        overflow: visible;

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 4rem;
            top: -2rem;
            left: 0;
            background-image: url("/assets/icons/new.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .center {
            flex-direction: column;
            align-items: center;
        }

        p {
            font-size: 0.875em;
            color: $blue;
            line-height: 1.7;
            text-align: left;
        }

        h2 {
            font-size: 40px;
            color: #2c68a5;
        }
    }

    .banner-draw--cart {
        span {
            padding-right: 1.5625rem;
        }

        .icon-cart {
            top: 3px;
            right: 10px;
        }
    }

    .popular {
        p {
            margin-left: 0;
            font-size: 1rem;
        }

        h3 {
            color: $blue;
            font-size: 1rem;
            margin: 2.5px 0;
        }
    }
}
/*PRIM Play Banner*/
#banner-prim {
    $gradient-left: #f94045;
    $gradient-right: #f94045;
    /*  $banner-bg: "/assets/images/lottery-banners/primitiva-play-banner.jpg";
        $banner-mobile: "/assets/images/lottery-banners/primitiva-play-banner.jpg";*/
    $banner-bg: "/assets/images/lottery-banners/primitiva-zero.jpg";
    $banner-mobile: "/assets/images/lottery-banners/primitiva-mobile-zero.jpg";
    @include banner(transparent, transparent, $banner-bg, $banner-mobile,false, 1);
}
/* Superenalotto Play Banner*/
#banner-superenalotto {
    $gradient-left: #ee0071;
    $gradient-right: #ee0071;
    /*        $banner-bg: "/assets/images/lottery-banners/sena.jpg";
        $banner-mobile: "/assets/images/lottery-banners/sena.jpg";*/
    $banner-bg: "/assets/images/lottery-banners/sena-zero.jpg";
    $banner-mobile: "/assets/images/lottery-banners/sena-mobile-zero.jpg";
    @include banner(transparent, transparent, $banner-bg, $banner-mobile, false,1);

    #lottery-game--banner-info {
        margin: 2rem auto;
        padding: 2rem 30px 1rem;
        position: relative;
        overflow: visible;

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 4rem;
            top: -2rem;
            left: 0;
            background-image: url("/assets/icons/new.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .center {
            flex-direction: column;
            align-items: center;
        }

        p {
            font-size: 0.875em;
            color: $blue;
            line-height: 1.7;
            text-align: left;
        }

        h2 {
            font-size: 40px;
            color: #2c68a5;
        }
    }

    .banner-draw--cart {
        span {
            padding-right: 1.5625rem;
        }

        .icon-cart {
            top: 2px;
            right: 10px;
        }
    }

    .popular {
        p {
            margin-left: 0;
            font-size: 1rem;
        }

        h3 {
            color: $blue;
            font-size: 1rem;
            margin: 2.5px 0;
        }
    }
}

#banner-about {
    $gradient: transparent;
    $bgUrl: '/assets/images/about-top.jpg';
    $bgmobileUrl: '/assets/images/about-top.jpg';

    @include banner-generic($gradient, $bgUrl, $bgmobileUrl);

    .container {
        background-size: cover !important;
        background-position: center center !important;
    }

    h1 {
        max-width: 45rem;
        text-align: left;
        line-height: 1;
        z-index: 1;
        position: relative;
    }
}

#about-betting-banner {
    $gradient: transparent;
    $bgUrl: '/assets/images/landing/about-betting-banner-desktop.jpg';
    $bgmobileUrl: '/assets/images/landing/about-betting-banner-mobile.jpg';

    @include banner-generic($gradient, $bgUrl, $bgmobileUrl);

    .container {
        background-size: cover !important;
        background-position: center center !important;
    }

    h1 {
        font-size: 2rem;
        text-align: left;
        margin: auto;
        line-height: 1;
        z-index: 1;
        position: relative;
    }

    .banner-right {
        max-width: unset !important;
    }

    .banner-content--container {
        position: initial;
    }

    @media screen and (max-width: 1100px) {

        .banner-left {
            display: flex !important;
        }

        .banner-right {
            display: none;
        }
    }
}

.banner-combo {
    padding: 0 !important;

    .container {
        border-radius: unset;
        /*        background: url("/assets/images/lottery-banners/combos-mobile.jpg") center/cover no-repeat;
*/
        background: url("/assets/images/lottery-banners/combos-mobile-zero.jpg?v=2") center/cover no-repeat;
    }

    @media screen and (min-width: 768px) {
        .container {
            min-height: 272px;
            /*            background: linear-gradient(to right, transparent 0%, transparent 35%, transparent 70%), url("/assets/images/lottery-banners/combo-banner.jpg") center right/auto 110% no-repeat !important;
*/
            background: linear-gradient(to right, transparent 0%, transparent 35%, transparent 70%), url("/assets/images/lottery-banners/combos-zero.jpg?v=2") center right/auto 110% no-repeat !important;

            h1 {
                font-size: 3rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .container {
            min-height: 150px;
            padding-bottom: 2rem;
        }
    }

    @media screen and (min-width: 1180px) {
        .container {
            .headers {
                margin-left: 4rem;
            }
        }
    }
}

/*New Syndicates Banner*/
.banner-syndicates {
    padding: 0 !important;

    .container {
        border-radius: unset;
        /*        background: url("/assets/images/lottery-banners/combos-mobile.jpg") center/cover no-repeat;
*/
        background: url("/assets/images/lottery-banners/syndicates-mobile-zero.jpg") center/cover no-repeat;
    }

    @media screen and (min-width: 768px) {
        .container {
            min-height: 272px;
            /*            background: linear-gradient(to right, transparent 0%, transparent 35%, transparent 70%), url("/assets/images/lottery-banners/combo-banner.jpg") center right/auto 110% no-repeat !important;
*/
            background: url("/assets/images/lottery-banners/syndicates-zero.jpg") center no-repeat !important;

            h1 {
                font-size: 3rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .container {
            min-height: 150px;
            padding-bottom: 2rem;
        }
    }

    @media screen and (min-width: 1180px) {
        .container {
            .headers {
                margin-left: 4rem;
            }
        }
    }
}

/*New Lotto Systems Banner*/
.banner-systems {
    padding: 0 !important;

    .container {
        border-radius: unset;
        background: url("/assets/images/lottery-banners/system-mobile-zero.jpg") center/cover no-repeat;
    }

    @media screen and (min-width: 768px) {
        .container {
            min-height: 272px;
            background: url("/assets/images/lottery-banners/system-zero.jpg") center center no-repeat !important;

            h1 {
                font-size: 3rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .container {
            min-height: 150px;
            padding-bottom: 2rem;
        }
    }

    @media screen and (min-width: 1180px) {
        .container {
            .headers {
                margin-left: 4rem;
            }
        }
    }
}


#banner-rules {
    $gradient: transparent;
    $bgUrl: '/assets/images/game-rules-cover.png';
    $bgmobileUrl: '/assets/images/game-rules-cover.png';

    @include banner-generic($gradient, $bgUrl, $bgmobileUrl);

    .container {
        min-height: rem(200) !important;
        background-size: cover !important;
        background-position: center center !important;

        @media screen and (max-width: 600px) {
            min-height: 150px !important;
        }
    }
}

.banner-draws {
    text-align: left;
    z-index: 9;
    padding-left: 40px;
    background: transparent;
    padding-right: 3rem;

    @media screen and (max-width: 1000px) {
        background: transparent;
    }

    > h2 {
        color: white;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        max-width: 360px;
    }

    .banner-draw {
        width: 100%;
        cursor: pointer;
        padding: 15px 30px;
        margin-bottom: 20px;
        max-width: 360px;
        display: block;
        margin-left: 0;
        transition: all ease-in-out 0.2s;

        .banner-draw--cart {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1;
            color: $blue;
            position: relative;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                position: static;
            }

            span {
                padding-right: rem(25);
            }

            svg {
                margin-left: 0.5rem;
                width: 19px;
                height: 15px;

                path {
                    &.st0 {
                        fill: $orange;
                        stroke-width: 0;
                    }
                }
            }
        }

        > div {
            display: flex;
            align-items: flex-start;

            > * {
                font-size: 16px !important;
                margin: 2.5px 0;
            }

            svg {
                height: 15px;
                width: auto;
                position: absolute;
                margin: auto;

                &.icon-cart {
                    transform: scale(0) translate(0,0);
                    animation: scaleUp 0.2s ease-in-out 0.05s;
                    animation-fill-mode: forwards;
                    transform-style: preserve-3d;


                    &.new-svg {
                        height: 38px;
                        width: 38px;
                        margin-top: 15px;
                        padding-left: 5px;

                        g {
                            path {
                                fill: $orange;
                                stroke: $orange;
                            }
                        }

                        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            left: 70%;
                            top: 0;
                        }
                    }
                }

                &.icon-check-circle {
                    stroke: $green;
                    stroke-dasharray: 100;
                    stroke-dashoffset: 100;
                    transform-style: preserve-3d;
                    transform: scale(0);

                    &.new-svg {
                        height: 38px;
                        width: 38px;
                        margin-top: 25px;
                        margin-left: 25px;
                        padding-left: 5px;
                    }
                }
            }
        }

        > p {
            margin: 2.5px 0;
        }

        h3 {
            color: $blue;
            line-height: 1.6;
        }

        &:hover {
            opacity: 1;
        }

        &.active {
            animation: scaleCard 0.75s ease-in-out 0s;

            .icon-cart {
                animation: cartAnimation 0.75s ease-in-out 0.05s;
                animation-fill-mode: forwards;

                #cart-arrow {
                    transform: scaleY(1.5);
                    transition: all ease-in-out 0.3s;
                }
            }

            .icon-check-circle {
                animation-name: dash;
                animation-duration: 0.4s;
                animation-delay: 0.4s;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                transform: scale(1);
            }

            @supports (-ms-ime-align: auto) {
                .icon-cart {
                    transform: scale(0);
                }

                .icon-check-circle {
                    stroke-dasharray: 100;
                    stroke-dashoffset: 200;
                    transition: all ease-in-out 0.4s 0.4s;
                    transform: scale(1);
                }
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                .icon-cart {
                    transform: scale(0);
                    animation: none;
                    transition: none;
                    display: none;
                }

                .icon-check-circle {
                    stroke-dasharray: 0;
                    stroke-dashoffset: 0;
                    transition: none;
                    transform: scale(1);
                    animation: none;
                }
            }
        }

        &:hover {
            background-color: rgba(255,255,255,0.85);
        }
    }

    @media only screen and (max-width: 1000px) {
        position: static;
        width: 100%;
        height: auto;
        padding: 20px;

        h2 {
            margin-top: 0;
            padding-top: 1rem;
            margin: 0 auto 2rem;
        }

        .banner-draw {
            max-width: 320px;
            display: block;
            margin: 0 auto 1rem;
        }
    }
}

@media screen and (max-width: 1000px) {
    .banner-left .jackpot .jackpot-name h1 {
        text-align: center;
    }

    .banner {
        background: none;
    }

    .banner-left {
        display: block;
        margin: auto;

        img {
            position: static;
            margin: auto;
            width: 5rem;
            height: 5rem;
        }

        .jackpot-name {
            transform: translate(0rem, .5rem);

            h1 {
                margin: auto;
                transform: translate(0, -0.5rem);
            }
        }
    }

    .banner-right {
        padding: 20px 0 0;
        margin: auto;
    }
}

@media screen and (max-width: 600px) {
    #banner-home-superdraw {
        .tax-free {
            display: none;
        }
    }

    #banner-draws {
        display: none;
    }

    #banner-uklo {
        h1 {
            transform: translate(0rem, 0rem);
        }
    }
}
