#banner-customer {
    $gradient: #1a3d9b;
    $bgUrl: '/assets/images/landing/support/customer-banner.png';
    $bgmobileUrl: '/assets/images/landing/faq-banner-mobile.jpg';
    @include banner-generic($gradient, $bgUrl, $bgmobileUrl);

    .wrapper {
        span {
            padding-left: 3rem;
            line-height: 2;
        }
    }

    @media screen and (max-width: 600px) {
        .container {
            h1, span {
                padding-left: 0rem !important;
                margin: 0.5rem 0 0;
                line-height: 1.2;
            }
        }
    }
}

#banner-customer {
    padding-bottom: 0 !important;

    .container {
        border-radius: 0 !important;
    }

    span.h3 {
        font-size: 1.75rem;
    }

    h1 {
        font-size: 2.875rem !important;
    }

    span.h4 {
        font-size: 21px;
    }
}

.contact-us-main {
    .button {
        width: 158px !important;
        height: 45px !important;
        padding: .75rem 1rem .875rem !important;
    }
}
.left-container {
    width: 682px;
    padding: 0;

    .section-contact-call {
        background: #EAF3FC;
        height: 180px;

        .call-container {
            padding: 2rem;
            text-align: left;

            h2 {
                text-align: left;
                margin-top: 0;
            }
            /*    p {
                font-weight: bold;
            }*/
        }
    }

    .section-contact-form {
        background: #EEEEEE;

        .contact-form-container {
            padding: 2rem;
            text-align: left;
            width: 100%;

            h2 {
                text-align: left;
                margin-top: 0;
            }

            p {
                // font-weight: bold;
            }

            #contact-support--form {
                margin-top: 1rem;

                .form-flex {
                    gap: 0 1rem;
                }

                input {
                    width: 100%;

                    &::placeholder {
                        //color: #38A4D0;
                    }
                }

                .select-left {
                    margin-right: 0 !important;
                }

                .contact-form-options {
                    margin: 1rem 0 !important;
                    font-weight: bold;
                }
            }
        }
    }

    .phone-cta {
        text-decoration: underline;
        cursor: pointer;
    }

    #callback-inputs {
        // display:none;
    }

    .svg-logo {
        flex: none;
    }

    .left-content {
        max-width: 480px;
        margin-left: 2rem;
    }
}

.right-container {
    display: flex;
    padding: 2rem 0 2rem 2rem;
    margin-left: 4rem;

    .contact-cta-container {
        max-width: 417px;
        width: 100%;
        margin-bottom: 2rem;

        h2 {
            text-align: left;
            margin-top: 0;
        }

        .mleft-1 {
            margin-left: 1rem;
        }

        svg {
            width: 80px;
            height: 80px;
        }

        p {
            max-width: 300px;
           // font-weight: bold;
        }
    }
}

@media screen and (max-width: 1024px) {
    .contact-us-main {
        flex-wrap: wrap;

        .left-container {
            width: 100%;
        }

        .left-content {
            max-width: unset
        }

        .right-container {
            margin: auto 0;
            width: 100%;

            .contact-cta-container {
                max-width: unset;

                p {
                    max-width: 100%;
                }
            }
        }
    }
}


@media screen and (max-width: 600px) {
    #banner-customer {
        padding: 0 !important;
        margin: 0 !important;
        /*   .container {
         //will need a new image
            min-height: 175px !important;
        }*/

        .wrapper {
            width: 100%;

            span.h3 {
                font-size: 21px;
                text-align: center !important;
            }

            h1 {
                font-size: 28px !important;
                text-align: center !important;
            }

            span.h4 {
                font-size: 1rem;
                text-align: center !important;
            }
        }
    }

    .contact-us-main {
        width: 100%;
    }

    .left-container {
        .section-contact-call {
            .call-container {
                padding: 1rem;

                h2 {
                    //margin-top:10px !important;
                    line-height: 120%;

                    .phone-cta {
                        display: block;
                    }
                }
            }
        }

        .section-contact-form {
            .contact-form-container {
                padding: 1rem;

                #contact-support--form {
                    margin-top: 0;
                }
            }
        }

        .left-content {
            margin-left: 1rem;
        }
    }

    .right-container {
        margin: 0;
        padding: 1rem;
    }

    h2 {
        max-width: 100% !important;
        font-size: 21px;
        margin: 0;
    }

    .svg-logo {
        width: 60px;
        height: 60px;
        flex: none;
    }

    .mobile-flex-col {
        flex-direction: column;
    }   
}
