#cart {
    padding: rem(25) 0;
    background-image: linear-gradient( to bottom, #2da0f1 0%, #289bee 44%, #178be4 68%, #0076d8 100% );
}

.nav-cart {
    padding: 0 !important;
}

.cart-items {
    padding: 0 rem(20) rem(20);

    .empty-cart {
        padding: rem(10) rem(10) rem(10) 0;
        color: $grey;
        text-align: right;
        display: block;
        font-size: 0.85rem;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: $red;
        }
    }

    .cart-item {
        display: flex;
        align-items: center;
        align-self: center;
        padding-bottom: rem(20);
        margin-bottom: rem(20);
        border-bottom: 1px solid $lightGrey;

        &:last-of-type {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .remove-cart-item {
            cursor: pointer;

            svg {
                width: rem(20) !important;
                height: rem(20) !important;
                max-width: 100% !important;
                margin-left: rem(10);
                max-height: rem(20);

                path {
                    transition: all ease-in-out 0.2s;
                }

                &:hover {
                    * {
                        stroke: $red;
                        opacity: 1;
                    }
                }
            }
        }

        .lottery-flag {
            margin: 0;
            width: rem(40);
            height: auto;
            margin-right: rem(15);
        }

        .cart-item--main {
            text-align: left;
            margin-right: auto;

            h4 {
                margin: 0 0 rem(3);
                font-weight: 600;
                font-size: em(14);
            }

            span {
                &.ticket {
                    background: $orange;
                }
            }

            p {
                font-size: em(10);
                font-weight: 600;
                opacity: 0.6;
                color: $grey;
                margin: 0;
            }
        }

        p {
            margin: 0;
            color: $darkGrey;
            font-weight: 700;
            opacity: 0.8;
        }

        i {
            margin-left: rem(10);
            font-size: em(22);
            transition: all ease-in-out 0.2s;
        }
    }
}

#header-cart-mobile {
    .no-sides {
        .cart-items {
            .empty-cart {
                padding: 0 0 rem(10) 0;
            }
        }
    }
}

.card-container {
    display: block;
}

.cart-footer {
    border-top: 1px solid $blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(20);

    p {
        margin-bottom: 0 !important;
        color: $darkGrey !important;
        font-weight: 700;
        font-size: em(16);
        opacity: 0.8;
    }

    input {
        margin-bottom: 0;
    }

    a.button {
        margin-top: 0;
    }
}

#checkout {
    .container {
        // max-width: 55rem;
        padding: 1.25rem 0;
    }

    .flex {
        display: flex;
    }

    .has-duplicate {
        background: rgba(216, 38, 45, .2);
    }

    p,
    h3,
    h4 {
        margin: 0;
        padding: 0;
        color: #2c68a5;
    }

    h4 {
        &.checkout-lottery-name {
            line-height: 1.2;
        }
    }

    span {
        &.checkout-syndicate-tag {
            color: $grey;
            font-weight: 600;
            opacity: .6;
        }
    }

    .org,
    .orange-text {
        color: #e25f2c !important;
    }

    .combined-jackpot-title {
        color: #E25F2C;
        font-size: 1em;
        font-family: $openSans;
    }

    .taleft {
        text-align: left;
    }

    .taright {
        text-align: right;
    }

    .half {
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
            width: 48%;
        }
    }

    .trigger {
        padding-right: 2rem;
        display: inline;
        position: relative;
        color: #2c68a5;
        line-height: 2;

        &:before,
        &:after {
            transition: 0.5s ease all;
        }

        &:before {
            content: 'Show draw dates';
        }

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            width: 0.9rem;
            height: 0.9rem;
            top: 0.2rem;
            background: url('/assets/icons/icon-angle-down.svg');
            background-repeat: no-repeat;
            background-size: 50%;
            background-position: center;
            background-repeat: no-repeat;
            background-color: #2c68a5;
            border-radius: 50%;
            height: .9375rem;
            width: .9375rem;
            margin-left: .625rem;
            margin-top: .15625rem;
        }

        &.syndicates-trigger {
            &:before {
                content: 'Show numbers';
            }
        }
    }

    .active {
        .trigger {
            &:before {
                content: 'Hide draw dates';
            }

            &:after {
                transform: rotate(-180deg);
            }
        }
    }

    .cart-header {
        padding: 1rem 0;
        position: relative;

        .empty-cart {
            display: block;
            text-align: right;
            width: 100%;
            color: #ffffff;
            text-decoration: underline;
            padding: 5px 10px 0;
            cursor: pointer;
            font-size: 0.85rem;
        }

        > h1 {
            color: white;
            font-size: rem(30);
            text-align: left;
            font-weight: 600;
            width: 92.5%;
            max-width: rem(1180);
            margin: 0 auto;
            padding: 0 0 rem(25);

            &:after {
                content: '';
                width: 100%;
                height: 1px;
                background: white;
                display: block;
                position: absolute;
                left: 0;
                margin-top: rem(15);
            }
        }

        .cart-empty {
            padding: 2rem;

            svg {
                width: 6rem;
                height: 6rem;
            }

            .cart-empty-title {
                font-size: em(22);
                line-height: 1.3;
            }

            p {
                font-size: em(16);
            }
        }
    }

    .lottery-card {
        padding: 2rem;
        margin-bottom: 1rem;

        &.checkout-card {
            max-width: 100%;

            .top {
                position: relative;
                align-items: center;
                padding-bottom: 1rem;
                border-bottom: 1px solid #f1f1f1;

                .logo {
                    width: 3rem;
                    height: 3rem;
                    margin-right: 1rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                p {
                    font-size: 0.9rem;
                    margin-bottom: 0.5rem;
                }

                .remove {
                    position: absolute;
                    top: 0;
                    right: 0;

                    svg {
                        g {
                            transition: 0.5s ease all;
                        }
                    }

                    &:hover {
                        cursor: pointer;

                        svg {
                            g {
                                stroke: red;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .details {
                padding: 1rem 0 0 !important;
                margin: 0 !important;

                h1, p.lottery-price--jackpot-pending {
                    font-size: em(38);
                    line-height: 1.3;
                    color: #e25f2c;
                    margin: 0;
                    font-weight: bold;
                }

                #dates,
                #dates2 {
                    .trigger {
                        &:before {
                            content: 'Show draw dates';
                        }
                    }

                    &.active {
                        .trigger {
                            &:before {
                                content: 'Hide draw dates';
                            }
                        }
                    }
                }

                .draw-dates {
                    flex-wrap: wrap;
                    border: 0;
                    margin: 1rem 0;
                    max-height: 0;
                    overflow: hidden;

                    span {
                        display: inline-block;
                        font-size: 0.8rem;
                        opacity: 0.7;
                        color: #2c68a5;
                        margin: 0.2rem;
                    }
                }

                .active {
                    .draw-dates {
                        max-height: 1800px;
                        transition: all ease 0.5s;
                    }
                }

                .number-list {
                    .single {
                        margin: 1rem 0;

                        h4 {
                            margin-left: 0.5rem;
                            font-weight: 400;
                        }

                        .numbers {
                            position: relative;
                            margin-top: 0.5rem;
                            width: 100%;
                            padding: 0.5rem;
                            background: #f3f9fe;

                            span {
                                color: #2c68a5;
                                padding-right: 0.75rem;
                                font-size: 1.2rem;
                                font-weight: 100;
                            }

                            .edit {
                                position: absolute;
                                right: 0.5rem;
                                top: 0.5rem;

                                svg {
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }

                #multiplier {
                    &.number-list {
                        .single {
                            .flex {
                                svg {
                                    g {
                                        opacity: 1;
                                    }

                                    path {
                                        fill: #41b304 !important;
                                    }

                                    circle {
                                        stroke: #41b304 !important;
                                    }
                                }
                            }
                        }
                    }

                    .trigger {
                        &:before {
                            content: 'Show all games' !important;
                        }
                    }

                    &.active {
                        .trigger {
                            &:before {
                                content: 'Hide all games' !important;
                            }
                        }

                        .all-games {
                            max-height: 1800px;
                            transition: all ease 0.5s;
                        }
                    }

                    .multiplier {
                        align-items: center;
                        justify-content: flex-end;
                        font-size: 1.2rem;
                        font-weight: 200;
                        color: #41b304;
                        margin: 0;

                        svg {
                            margin-left: 0.5rem;
                        }
                    }

                    .all-games {
                        max-height: 0;
                        overflow: hidden;
                    }
                }

                .card-total {
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 0rem;
                    padding-top: 0rem;

                    .button {
                        padding: 0.55rem 0.8rem 0.65rem 0.6rem !important;
                        font-weight: 200;
                        font-size: 0.9rem;

                        &.disabled {
                            pointer-events: none;
                            opacity: 0.5;
                        }

                        &.remove-multi, &.remove-joker {
                            color: $blue;
                            padding: 0 0.8rem 0.65rem 0 !important;
                            border: none !important;

                            svg {
                                width: 100%;
                                margin-right: .5rem;

                                path {
                                    stroke: #41b304;
                                }

                                polyline {
                                    color: #41b304;
                                }
                            }

                            img {
                                &.multi-close {
                                    display: inline-block;
                                    width: 16px;
                                    height: 16px;
                                    margin-left: 0.5rem;
                                }
                            }
                        }
                    }

                    h3 {
                        font-size: 1.6rem;
                        font-weight: 100;

                        span {
                            font-weight: 400;
                        }
                    }
                }

                .total,
                .mob-total {
                    width: 100%;

                    h3 {
                        font-size: 1.6rem;
                        font-weight: 100;
                        margin-left: 1rem;
                        min-width: 100px;

                        span {
                            font-size: 1.2rem;
                            color: $orange;
                        }
                    }
                }

                .total {
                    width: 100%;
                    justify-content: flex-end;

                    .three-col {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: center;
                        margin-right: 3rem;
                        position: relative;
                        top: -10px;
                        /*Edge*/
                        @supports (-ms-ime-align:auto) {
                            max-width: 150px;
                        }

                        .col {
                            width: 33% !important;
                        }

                        &.stepper {
                            .add-syndicate,
                            .minus-syndicate {
                                width: 2rem !important;
                                height: 2rem !important;
                                background: $orange;
                                border-radius: 100%;
                                color: white;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 1.6rem;
                                font-weight: 500;
                                position: relative;
                                cursor: pointer;
                                user-select: none;
                            }

                            .minmaxsyndicate {
                                pointer-events: none;
                                opacity: 0.5;
                            }

                            .minus {
                                span {
                                    position: absolute;
                                    top: 10%;
                                }
                            }

                            .num {
                                background: $orange;
                                width: 4rem !important;
                                height: 4rem !important;
                                border-radius: 0.5rem;
                                margin: 0 0.5rem;
                                display: flex;
                                /*flex-wrap: wrap;*/
                                align-items: center;
                                justify-content: center;
                                text-align: center;

                                h3 {
                                    border: none;
                                    border-radius: unset;
                                    margin: 0;
                                    padding: 0;
                                    font-size: 2rem !important;
                                    font-weight: 600;
                                    font-family: inherit;
                                    text-align: center;
                                    height: auto;
                                    color: #ffffff;
                                    line-height: 1.1;
                                    /*IE<11*/
                                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                        max-width: 60px !important;
                                    }
                                }

                                span {
                                    display: block;
                                    width: 100%;
                                    color: white;
                                    font-weight: normal;

                                    &:first-child {
                                        font-size: 2rem;
                                    }

                                    &:last-child {
                                        font-size: 0.7rem;
                                        text-transform: uppercase;
                                    }
                                }

                                h5 {
                                    margin: 0;
                                    color: white;
                                }
                            }
                        }
                    }
                }

                .mob-total {
                    display: none;
                    padding-top: 1rem;
                    margin-top: 1.5rem;
                    border-top: 1px solid #f1f1f1;

                    h3 {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .lottery-card-account {
        max-width: 40rem !important;
        box-shadow: none;

        h3 {
            color: $orange;
        }
    }

    .lottery-deck--quickplay {
        display: flex;

        .lottery-card {
            margin-bottom: rem(15);
            position: relative;
            padding: rem(25) rem(20) rem(15) rem(20);
            min-width: 0;

            .orange {
                font-weight: 600;
                font-size: em(18);
                margin: 0;
            }

            p {
                font-size: em(14);
                margin: rem(5) 0 rem(10);
                text-align: left;
            }

            img {
                width: rem(35);
                height: auto;
                position: absolute;
                top: rem(25);
                right: rem(25);
            }

            .add-quickplay {
                color: $orange;
                font-size: em(12);
                display: block;
                text-decoration: underline;
            }
        }
    }

    .checkout-footer {
        padding: 2rem 0 0 0;

        .cart-total {
            padding: 3.125rem 0;
            max-width: 320px;
            margin: auto;

            &, .flex {
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
            }

            .funds {
                display: block;
                width: 100%;
                max-width: 80rem;

                p {
                    margin: .9375rem 0 0;
                    font-weight: 400;
                    font-size: 1em;
                    color: #e25f2c;
                    font-weight: 600;
                }
            }

            span.total-cost {
                font-size: 2em;
                font-weight: 400;
                line-height: 1;
                padding: 1.09375rem 1.5625rem;
                margin: 0;
            }

            p {
                margin: .9375rem 0 0;
            }

            h3 {
                margin: 0 0 .9375rem;
                font-size: 1.375em;
                color: #e25f2c;
            }
        }
    }

    .cart-purchase {
        p {
            color: $orange !important;
            font-weight: 600;
            font-size: em(16);
        }

        button {
            margin-bottom: rem(5);
            width: 13.75rem;
            font-size: 1em;
            line-height: 1;
            padding: .9375rem 0;
        }
    }

    .checkout-payment-container {
        display: none;

        &.added {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            background: #fff;
            border: 1px solid #ccc;
            padding: .5rem 1rem;
            max-width: 98%;
            margin: auto;
            border-radius: 5px;
            background: #eee;
        }

        p {
            font-size: 1rem;
            line-height: 1.6;
            text-align: center;
            display: flex;
            flex-direction: column;
        }

        #checkout-payment-mindeposit, #checkout-payment-mindeposit-amount {
            padding: 0;
        }

        span {
            color: $orange;
            padding: 5px;
        }
    }

    .flex-column-single {
        #g-explainer {
            display: flex;
            align-items: center;
        }

        #g-symbol {
            width: 100%;
            height: 100%;
            margin: auto 1%;
            padding: 1% 0;
            max-width: 28px;
            min-width: 28px;
            max-height: 28px;
            min-height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            color: #FFFFFF;
            background-color: #38A4D0;
        }
    }
}

.lottery-card {
    &.cart-empty {
        padding: rem(25);
        margin-bottom: rem(5);

        .cart-empty-title {
            color: $orange;
            font-size: em(16);
            font-weight: 400;
            letter-spacing: 0;
            margin: rem(20) 0 rem(10);

            span {
                font-weight: 600;
                font-size: 100%;
                vertical-align: baseline;
            }
        }

        p {
            color: #7a7b78;
            font-size: em(11);
            font-weight: 400;
        }

        .button {
            margin-top: rem(15);
        }
    }

    &.lottery-card-results {
        .game-container {
            //max-width: rem(360);
            margin: 0 auto;
            margin: 0 0 0 auto !important;
            width: 50%;

            &.multiplier-container {
                .game-number-line {
                    svg {
                        * {
                            opacity: 1;
                        }

                        circle {
                            stroke: #41b304;
                        }

                        path {
                            fill: #41b304;
                        }
                    }
                }
            }

            > h3 {
                text-align: left;
            }


            .play-now--game {
                .game-number-lines {
                    margin-top: 1rem;

                    .flex {
                        svg {
                            display: none;
                        }

                        p {
                            &.game-count {
                                padding: 0 0 0 0.625rem !important;
                                font-size: rem(16) !important;
                                font-weight: 400;
                                text-align: left;
                                margin: 0 !important;
                            }
                        }
                    }

                    .game-number-line {
                        border-bottom: 1px solid $lightGrey;

                        .number-line--numbers {
                            .lottery-numbers-container {
                                margin: 0;

                                .lottery-numbers {
                                    .lottery-number {
                                        background-color: none;
                                        background: none;
                                        border-radius: unset;
                                        color: $orange;
                                        margin: 0;

                                        &.number {
                                            background-color: none;

                                            span {
                                                //font-size: 1.2rem;
                                                font-weight: 400;
                                            }
                                        }

                                        &.lucky {
                                            span {
                                                // font-size: 1.2rem;
                                                font-weight: 400;
                                                color: $lightBlue;
                                            }
                                        }

                                        &.primitiva-lucky {
                                            display: block;
                                        }
                                    }
                                }
                            }

                            .lottery-line-actions {
                                display: block;
                                right: 10px;

                                img {
                                    display: inline-block;
                                    width: 15px;
                                    height: 15px;
                                    cursor: pointer;
                                }
                            }
                        }

                        .primitiva-custom-numbers {
                            display: block;
                        }

                        .custom-numbers--action {
                            .button {
                                margin: 1rem 0 !important;
                            }
                        }
                    }
                }

                .number-lines-expand {
                    justify-content: flex-start !important;
                    text-decoration: none;
                    font-weight: 500;
                    margin-left: 1%;
                }
            }

            &.USPB, &.USMM {
                .play-now--game {
                    .game-number-lines {
                        .flex {
                            svg {
                                position: relative;
                                display: block;
                                top: 3px;
                            }
                        }
                    }
                }
            }

            .card-total {
                h4 {
                    &.multiplier {
                        color: #41b304 !important;
                        font-size: 1.2rem;
                        font-weight: 200;

                        svg {
                            margin-left: 0.5em;
                        }
                    }
                }
            }
        }
    }
}


/*New Payments Layout*/
/*.checkout-footer--bottom {
    .card-container{

    display:flex;
    }
}*/
#account-payment-methods {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;

    > li {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding: 0 1rem;
        border-radius: 5px;
        margin: 10px 5px;
        box-shadow: 0 0 2px 1px rgba(0,0,0,0.2);
        cursor: pointer;

        &.active {
            box-shadow: 0 0 4px 2px rgba(44,104,165,1);
        }

        &.disabled {
            opacity: 0.8;
            pointer-events: none;
            filter: blur(3px);
            -webkit-filter: blur(3px);
            -moz-filter: blur(3px);
            -o-filter: blur(3px);
            -ms-filter: blur(3px);
            filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
        }

        &.Visa {
            background: linear-gradient(to bottom, #2F1191, #2F1191);

            a {
                color: #ffffff !important;
            }

            .payment-content {
                .expiry-message {
                    color: #ffffff !important
                }
            }
        }

        &.Mastercard {
            background: linear-gradient(to bottom, #972429, #63191D);

            a {
                color: #fff !important;
            }

            .cc-name, .cc-expiry {
                color: #1A1F71 !important;
            }

            .payment-content {
                .expiry-message {
                    color: #ffffff !important
                }
            }
        }
    }

    .payment-expand {
        display: flex;
        position: relative;
        line-height: 1;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none !important;
        margin: auto;
        height: 3rem;

        &.active {
            opacity: 1 !important;

            + .payment-content {
                margin-top: 0.5rem;
                margin-bottom: 1rem;
                display: block;

                &.ccard {
                    max-height: 450px !important;
                }
            }
        }
    }

    .payment-content {
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: all ease-in-out 0.3s;

        #bt-form, #bt-form-manage {
            &.disabled {
                opacity: 0.8;
                pointer-events: none;
                filter: blur(3px);
            }
        }

        button[type="submit"] {
            margin: 1rem auto;
            display: flex !important;

            &.update-button {
                margin: 0 !important;
            }
        }
    }

    .credit-card-layout {
        max-width: 80%;
        margin: auto;
        background: #fff;
        border: 1px solid #ccc;
        flex: 1;
        padding: 1rem;
        border-radius: 5px;

        &.updateCard {
            margin-top: 5px;
        }

        .cc-number {
            font-size: 2rem;
            font-weight: 600;
            color: #1A1F71;
            display: none;
        }

        .cc-meta {
            display: flex;
            justify-content: space-between;
            max-width: 80%;
        }

        .cc-name-box {
            display: flex;
            flex-direction: column;
        }

        .cc-name {
            color: #1A1F71;
            font-size: 1.1rem;
        }


        .title {
            text-transform: uppercase;
            color: #1A1F71;
            font-weight: 600;
            font-size: .75rem;
            opacity: .6;
        }

        .cc-date-box {
            display: flex;
            flex-direction: column;
        }

        .cc-expiry {
            display: flex;
            flex-direction: row;
            justify-content: center;
            color: #1A1F71;
            font-size: 1.1rem;
        }
    }

    .instructions {
        color: #fff;
    }

    a {
        display: block;
        width: 100%;
        color: #333;
        font-weight: bold;
    }

    img {
        max-width: 3rem;
        margin-right: 1rem;
        opacity: 1;
        vertical-align: middle;

        &.payment-visa {
            margin-right: .5rem;
        }
    }

    #paypal-button, #paypal-button-save {
        width: 320px;
        margin: auto;
    }
}

#account-withdrawl-methods {
    #paypal-button-withdraw {
        width: 320px;
        margin: auto;
    }

    > li {
        &.disabled {
            opacity: 0.8;
            pointer-events: none;
            filter: blur(3px);
            -webkit-filter: blur(3px);
            -moz-filter: blur(3px);
            -o-filter: blur(3px);
            -ms-filter: blur(3px);
            filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
        }
    }
}


@media screen and (max-width: 768px) {
    #checkout {
        .lottery-card {
            &.checkout-card {
                .details {
                    .total {
                        .three-col {
                            position: initial;
                            justify-content: center;
                            margin: 0.5rem auto;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #checkout {
        h4 {
            line-height: 1;
        }

        .dates-container {
            //  display:none;
        }

        .cart-header {
            padding: 1rem 0;

            h1 {
                color: #fff;
                font-size: 2em;
                text-align: center;
                padding: 0;
                margin: 0 !important;
            }
        }

        .bg-blue-gradient {
            .half {
                .item {
                    text-align: center;
                    width: 100%;

                    .total {
                        display: block;
                    }
                }
            }

            .lottery-card {
                max-width: 100% !important;
                max-width: 50rem !important;

                .game-container {
                    width: 100%;
                    margin: 1rem auto auto !important;

                    .lottery-numbers {
                        flex: 0;
                    }

                    .number-lines-expand {
                        justify-content: center !important;
                    }
                }

                &.checkout-card {
                    .details {
                        .total,
                        .mob-total {
                            h3 {
                                margin: 0 auto;
                            }
                        }

                        .draw-dates {
                            margin: 0;
                        }
                    }

                    .top {
                        padding-bottom: 1rem;
                    }
                }
            }
        }

        .checkout-footer {
            padding: 0;

            .cart-total {
                padding: 0;
            }

            .checkout-footer--bottom {

                .cart-payment-details {
                    .container {
                        padding: 0 !important;

                        .lottery-card {
                            padding: 0 0 1rem !important;
                            width: 100%;
                            overflow: hidden;
                            max-width: 100% !important;
                            margin-bottom: 0;
                            min-width: 18.75rem;

                            &.lottery-card-account {
                                h3 {
                                    font-size: 1.375em;
                                    font-family: $proximaSoft;
                                    margin: 0 auto !important;
                                }
                            }
                        }
                    }

                    + .cart-purchase {
                        padding: 1.875rem 0;
                    }
                }

                .container-small {
                    padding: 0 !important;

                    .regulation-digits {
                        margin: 1.25rem 0;
                    }
                }

                .checkout-add-games {
                    background: linear-gradient(to bottom,#2da0f1 0%,#289bee 44%,#178be4 68%,#0076d8 100%);
                    padding: 1.875rem 0 1.875rem;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #checkout {
        .checkout-card {
            padding: 1rem;

            .number-list {
                h3,
                .single {
                    text-align: left;
                }
            }

            .card-total {
                text-align: center;

                .button {
                    font-size: 0.9rem;
                    margin-bottom: 0 !important;
                }

                h3,
                h4 {
                    margin: 1.5rem auto 0;
                    text-align: center;
                }

                .multiplier {
                    justify-content: center !important;
                }
            }

            &.syndicates {
                .details {
                    .total {
                        //display: none;
                    }

                    .mob-total {
                        display: block;
                    }

                    h3.org {
                        margin-bottom: 1rem;
                    }

                    h3.combined-jackpot-title {
                        margin-bottom: 0rem;
                    }
                }
            }
        }

        #account-payment-methods {
            .credit-card-layout {
                max-width: 100% !important;

                .cc-meta {
                    max-width: 100% !important;
                }
            }
        }

        .checkout-payment-container {
            &.added {
                max-width: 100%;
                padding: 0.5rem;
            }

            p {
                font-size: 0.85rem;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #checkout {
        .lottery-card {
            &.checkout-card {
                .top {
                    .taleft {
                        display: block;
                        text-align: center;

                        .logo {
                            margin: auto;
                        }
                    }

                    h4 {
                        &.checkout-lottery-name {
                            line-height: 1.5;
                        }
                    }

                    span {
                        &.is-label {
                            margin: 1px auto auto;
                        }
                    }
                }

                .details {
                    h1 {
                        font-size: em(30);
                        margin: 0;
                    }

                    .draw-dates {
                        justify-content: center;

                        span {
                            width: 40%;
                            font-size: rem(14);
                        }
                    }
                }

                .expand {
                    .tables {
                        .title {
                            span {
                                display: block;
                            }
                        }
                    }
                }

                .card-total {
                    flex-direction: column;
                    justify-content: center !important;
                }
            }
        }
    }
}

@media screen and (max-width: 340px) {
    .game-number-lines {
        .game-number-line {
            .number-line--numbers {
                padding-right: 0 !important;

                .lottery-line-actions {
                    right: 0 !important;
                }
            }
        }
    }
}

@media only screen and (min-width: 860px) {
    .cart-total {
        padding: 0 !important;
    }

    .checkout-footer--bottom {
        background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
        flex: 0 0 100%;
        padding: 1rem 0 3rem 0;
        margin: 2rem auto auto auto;

        .card-container {
            box-shadow: 0 0 5px 2px rgba(0,0,0,0.2);
            max-width: rem(860);
            width: 92.5%;
            justify-content: center;
            align-items: center;
            margin: rem(50) auto;
            border-radius: 10px;
            background: white;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;


            .container-small {
                max-width: 73.75rem;
                padding: 0;
            }

            .regulation-digits {
                margin: auto;
            }
        }
    }

    .cart-payment-details {
        background: white;
        padding: 1rem 0;

        .container {
            width: 100%;
            max-width: 100%;
            padding: 1rem 0;

            .lottery-card {
                margin-left: auto;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
                overflow: visible;
                padding: 0 !important;

                h3 {
                    text-align: left;
                    margin: 0 0 rem(10) !important;
                }

                .select-dropdown {
                    flex: 0 0 100%;
                }

                .tab-content {
                    &.active {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        overflow: visible;

                        .input-row {
                            width: 100%;
                        }

                        .terms-conditions-check {
                            flex: 0 0 auto;
                        }

                        input {
                            font-size: em(12);
                        }
                    }
                }
            }
        }
    }

    .cart-payment-details + .cart-purchase {
        margin-top: rem(32);
        border-left: 1px solid #2c68a5;
        margin-left: rem(25);
        padding-left: rem(25);
    }

    .cart-purchase {
        flex: 0 0 auto;
        padding-top: rem(30);
    }

    #checkout-voucher {
        &.active {
            margin-top: rem(30);
        }
    }

    .checkout-add-games {
        background: none;
        padding-top: 0;
    }
}
