.lottery-confirmations {

	.lottery-cta--image {
		max-height: 75vw;
		background-size: cover;
	}

	.lottery-card {
		padding: rem(30) rem(20);
		max-width: rem(560);
		width: auto;

		a {
			text-decoration: none;
		}

		h1 {
			color: $orange;
			font-size: em(30);
			margin: 0 0 rem(15);
			font-weight: 600;
			line-height: 1.3;
		}

		p {
			font-size: em(18);
			margin-bottom: rem(20);
			font-family: $openSans;
		}

		.buttons-container {
			margin-top: rem(20);
		}

		.button {
			width: rem(230);
			justify-content: center;
			margin: rem(10) auto 0;
			padding-left: rem(10);
			padding-right: rem(10);
			display: block;
			flex: 1;
		}

		.print {
			width: auto;
			padding-left: rem(30);
			padding-right: rem(30);
			margin: 0 auto rem(10) !important;
			max-width: rem(200);
		}
	}

	.lottery-confirmations-entries {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;

		td {
			font-size: em(18);
			color: $blue;
			text-align: left;
			border: 1px solid $grey;
			padding: rem(5) rem(10) rem(5) rem(10);
		}

		td:last-child {
			white-space: nowrap;
		}
	}
}

.bpay-container {
	text-align: left;
	/*border: 1.5px solid #183168;*/
	padding: rem(5);
	margin: rem(20) 0 rem(10);

	p {
		color: #183168;
		font-size: em(11) !important;
		font-weight: 600;

		span {
			font-weight: 700;
			display: inline-block;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.bpay-header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: rem(15);

		img {
			width: auto;
			margin-right: rem(10);
			width: rem(40);
			height: auto;
		}

		.bpay-details {
			border: 1.5px solid #183168;
			flex: 1;
			padding: rem(5) 0;
			align-self: stretch;
			display: flex;
			justify-content: center;
			flex-direction: column;

			p {
				margin: 0;
				font-size: em(14) !important;
				font-weight: 700;
				line-height: 1.8;

				>:first-child {
					float: left;
				}

				>:last-child {
					float: right;
				}
			}

			.bpay-ref {
				color: $orange;
			}

			.copy {
				color: $grey;
				cursor: pointer;
				border: 1px solid $grey;
				font-size: 0.6rem;
				margin-left: 5px;
				width: 60px;
				text-align: center;
				vertical-align: text-bottom;

				&.copied {
					color: $darkGrey;
					border-color: $lightGrey;
					background-color: $lightGrey;
				}
			}
		}
	}
}

.payid-container {
    p.instructions {
        color: #183168;
        font-size: .85em !important;
        font-weight: 600;
        margin: 1rem auto;
        max-width:95%;
        padding: 1rem;
        background: $lightGrey;
    }

    .payid-details {
        border: 1.5px solid #183168;
        flex: 1;
        padding: rem(5) 0;
        align-self: stretch;
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 95%;
        margin: auto;

        .hr-text {
            line-height: 1em;
            position: relative;
            outline: 0;
            border: 0;
            color: black;
            text-align: center;
            height: 1.5em;
            width: 100%;

            &:before {
                content: '';
                background: linear-gradient(to right, transparent, $blue, transparent);
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                height: 1px;
            }

            &:after {
                content: attr(data-content);
                position: relative;
                display: inline-block;
                color: black;
                padding: 0 .5em;
                line-height: 1.5em;
                color: $blue;
                background-color: #fcfcfa;
            }
        }

        p {
            margin: 0;
            font-size: em(14) !important;
            font-weight: 700;
            line-height: 1.8;

            > :first-child {
                float: left;
            }

            > :last-child {
                float: right;
            }
        }


        .copy {
            color: $grey;
            cursor: pointer;
            border: 1px solid $grey;
            font-size: 0.85rem;
            margin-left: 10px;
            padding: 0 2px;
            width: 60px;
            text-align: center;
            vertical-align: middle;

            &.copied {
                color: $darkGrey;
                border-color: $lightGrey;
                background-color: $lightGrey;
            }
        }
    }

    .email-payment-details {
        max-width: 320px;
        width: auto;
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        margin: 1rem auto !important;

        svg {
            margin-right: .75rem !important;
        }
    }
}


@media only screen and (min-width: 860px) {
	.lottery-confirmations {
		.lottery-card {
			text-align: left;
			padding: rem(40) rem(50);
			min-width: rem(360);


			.buttons-container {
				display: flex;
				width: 100%;

				.button {
					display: flex;
					margin-right: rem(10);

					&:last-child {
						margin-right: 0;
					}
				}
			}

			&.bpay-card {
				max-width: rem(640);
				width: 100%;


				.buttons-container {
					float: right;
					width: calc(100% - #{rem(340)});
					flex-direction: column;
					display: flex;
					align-items: flex-start;

					.print {
						margin-top: 0;
						width: auto !important;
						transition: none;


						&:hover {
							background-color: white;
							color: $orange;
						}
					}

					.button {
						margin-bottom: rem(10);
						width: 100%;
					}
				}
			}

			&.payment-success {
				min-width: rem(500);
			}
		}
	}

	.bpay-container {
		max-width: rem(400);
		width: 100%;
		float: left;	 
	}
}

@media only screen and (min-width: 980px) {
	.lottery-confirmations {
		padding: 8vw 0;

		.lottery-card {
			.buttons-container {
				display: flex;
				width: 100%;

				.button {
					margin-right: rem(15);

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 450px) {
	.lottery-confirmations {
	.lottery-card {
			padding: rem(30) rem(10);
		}
		.lottery-confirmations-entries {
			td {
				font-size: em(16);
				padding: rem(5) rem(5) rem(5) rem(5);
			}
		}
	}
}

@media only screen and (max-width: 340px) {
	.lottery-confirmations {
		.lottery-card {
			padding: rem(30) rem(5);
		}
		.lottery-confirmations-entries {
			td {
				font-size: em(14);
			}
		}
	}
}
