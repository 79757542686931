.lottery-tabs {
    background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
    padding: rem(25) 0 rem(25);
    //margin-top: 2rem;
    h1 {
        color: #ffffff;
        text-align: center;
        padding: 0 1rem;
    }

    > .tab-nav {
        margin-bottom: rem(20);
        width: 100%;
    }

    .tab-nav {
        padding: 0;

        > li {
            font-size: em(13) !important;
            font-weight: 700 !important;
        }
    }

    .lottery-deck {
        padding: 0 1.5%;
    }

    .lotery-tab--nav {
        display: flex;
        align-items: center;
        margin-top: rem(20);
        margin-bottom: rem(20);

        .tab-slide-toggle {
            max-width: 55%;
        }

        .tab-toggle.tab-slide-toggle span.active {
            background: #2c68a5;
            color: #fff;
        }

        p {
            text-align: left;
            color: white;
            line-height: 1.3;
            margin: 0;
            letter-spacing: rem(1);
            display: inline-block;
            flex: 0 0 20%;
            position: relative;
            top: -3px;
            font-weight: 800;
            margin-right: rem(10);
        }
    }

    .mobile-slide {
        width: 100%;
        .lottery-card {
            display: block;
            width: 100% !important;
            margin: 0 auto rem(10) !important;
            border-left: 5px solid #2C68A5;

            .is-label {
                letter-spacing: 1px;
                font-size: .6rem;      
                right:-20px;
            }

            .lottery-card-front {
                display: flex;
                text-align: left;
                position: relative;

                img {
                    margin: 0 rem(15) 0 0;

                    &.cta {
                        display:none !important;
                        margin: 0;
                        background-color: $blue;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        right: 0.5rem;
                        width: 1.5rem;
                        height: 1.5rem;
                        padding: 0.25rem;
                        align-self: center;
                    }
                }

                .lottery-card--content {
                    margin: 0;

                    h2 {
                        font-size: em(16);
                        color: $darkGrey;
                    }

                    p {
                        text-align: left;
                        display: block;
                        margin: 0;

                        &.timer {
                            margin-top: rem(5);

                            span {
                                font-size: em(14);
                            }
                        }
                    }
                }

                p.lottery-card--amount {
                    font-size: em(30) !important;
                    color: $blue;

                    &.extra-tag {
                        font-size: em(22) !important;
                        max-width: 85%;
                    }
                }

                p.lottery-card--amount-pending {
                    padding-top: 0;
                    font-size: em(25) !important;
                }
            }
            
            &.USPB {
                border-left: 5px solid $colorUSPB;

                 .is-label {
                    background: $colorUSPB;
                }
            }

            &.USMM {
                border-left: 5px solid $colorUSMM;

                 .is-label {
                    background: $colorUSMM;
                }
            }

            &.IREL {
                border-left: 5px solid $colorIREL;

                 .is-label {
                    background: $colorIREL;
                }
            }

            &.PRIM {
                border-left: 5px solid $colorPRIM;

                 .is-label {
                    background: $colorPRIM;
                }
            }

            &.EURJ {
                border-left: 5px solid $colorEURJ;

                 .is-label {
                    background: $colorEURJ;
                }
            }

            &.EURO {
                border-left: 5px solid $colorEURO;

                 .is-label {
                    background: $colorEURO;
                }
            }

            &.UKLO {
                border-left: 5px solid $colorUKLO;

                 .is-label {
                    background: $colorUKLO;
                }
            }

            &.SENA {
                border-left: 5px solid $colorSENA;

                 .is-label {
                    background: $colorSENA;
                }
            }

            &.COMBOS {
                border-left: 5px solid $colorCombos;

                 .is-label {
                    background: $colorCombos;
                }
            }

            &.SYNDICATES {
                border-left: 5px solid $colorSyndicates;

                 .is-label {
                    background: $colorSyndicates;
                }
            }
        }
    }

    &.no-superdraw {
        padding: 4rem;

        @media screen and (max-width: 600px) {
            padding: 2rem 1rem;
        }

        .container {
            .white-box--shadow {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: column;
                padding: 2rem !important;
                margin-bottom: 2rem !important;
                margin-top: 0 !important;
                background-color: white;
                border-radius: 10px;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                position: relative;
                text-align: left;

                p {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    flex-direction: column;
                    font-weight: 600;
                    text-align: center;
                    color: $blue;

                    img {
                        max-width: 3rem;
                    }

                    a {
                        text-decoration: none;
                    }

                    span {
                        color: $orange;
                        font-weight: 600;
                        font-size: 1.2rem;
                        line-height: 2;
                    }
                }
            }
        }
    }

    .desktop-slider {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        grid-gap: 1rem 2rem;
        grid-auto-flow: dense;
        place-items: center;

        .lottery-card {
            min-width: unset;
            max-width: 17rem;
            padding: 0;
            overflow: unset;
            margin: unset;
            margin-top: 2rem;
            border-top: 5px solid #2C68A5;

            .is-label {
                letter-spacing: 1px;
                font-size: .6rem;
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                overflow: visible;
            }

            .lottery-card-front {
                padding: 3rem 0 0;
                display: flex;
                flex-flow: column nowrap;

                .lottery-infor {
                    min-height: 130px;

                    img {
                        width: 4rem;
                        height: auto;
                        margin: 0 auto;
                        transform: translate(-50%,-50%);
                        filter: blur(0) !important;
                        position: absolute;
                        top: 0%;
                        left: 50%;
                    }

                    h2 {
                        color: $darkGrey;
                    }
                }

                p {
                    &.lottery-card--amount {
                        color: $blue;
                    }

                    &.lottery-card--amount-pending {
                        padding-top: 0;
                    }

                    &.timer {
                        flex-direction: row;
                        padding: .5rem 0.5rem 1rem;
                        font-weight: 600;

                        .timer-countdown {
                            font-size: 1em;
                            font-weight: 600;
                        }
                    }
                }
            }

            .button {
                margin-top: .5rem;
            }

            &.USPB {
                border-top: 5px solid $colorUSPB;

                > .is-label {
                    background: $colorUSPB;
                }
            }

            &.USMM {
                border-top: 5px solid $colorUSMM;

                > .is-label {
                    background: $colorUSMM;
                }
            }

            &.IREL {
                border-top: 5px solid $colorIREL;

                > .is-label {
                    background: $colorIREL;
                }
            }

            &.PRIM {
                border-top: 5px solid $colorPRIM;

                > .is-label {
                    background: $colorPRIM;
                }
            }

            &.EURJ {
                border-top: 5px solid $colorEURJ;

                > .is-label {
                    background: $colorEURJ;
                }
            }

            &.EURO {
                border-top: 5px solid $colorEURO;

                > .is-label {
                    background: $colorEURO;
                }
            }

            &.UKLO {
                border-top: 5px solid $colorUKLO;

                > .is-label {
                    background: $colorUKLO;
                }
            }

            &.SENA {
                border-top: 5px solid $colorSENA;

                > .is-label {
                    background: $colorSENA;
                }
            }

            &.COMBOS {
                border-top: 5px solid $colorCombos;

                > .is-label {
                    background: $colorCombos;
                }
            }

            &.SYNDICATES {
                border-top: 5px solid $colorSyndicates;

                > .is-label {
                    background: $colorSyndicates;
                }
            }
        }
    }

    .lottery-card {
        &.extra-lottery {
            display: none;
        }

        &.fade-out {
            opacity: 0;
            display: none;
            transition: opacity 5s linear;
        }
    }

    .more-lotteries {
        display: block;
        width: 100%;
        color: $white;
        font-size: 1.2rem;
        padding: 1rem 0 0;
        margin: 2rem auto auto;
        text-align: center;
        transition: all ease-in-out 0.2s;

        svg {
            vertical-align: middle;
            transition: all ease-in-out 0.2s;
        }

        &.active {
            svg {
                animation: rotate-svg .5s forwards;
            }
        }

        @keyframes rotate-svg {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(-180deg);
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .lottery-tabs {
        .desktop-slider {
            display: grid;
            align-items: center;
            grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
            grid-gap: .5rem;
            grid-auto-flow: dense;
            place-items: center;

            .lottery-card {
                min-width: auto !important;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .lotery-tab--nav {
        .tab-slide-toggle {
            max-width: 80% !important;
        }
    }
}


@media only screen and (max-width: 620px) {
    .lottery-tabs {
        border-radius:unset;
        h1 {
            font-size: 1.1rem;
            max-width: 85%;
            margin: auto;
        }

        .lotery-tab--nav {
            .tab-slide-toggle {
                max-width: 90% !important;

                span {
                    line-height: 1 !important;
                    padding: 6px !important;
                }
            }
        }

        .desktop-slider {
            display: none;
        }
    }
    }


@media only screen and (min-width: 620px) {
    .lottery-tabs {
        .mobile-slider {
            display: none !important;
        }
    }
}

@media only screen and (min-width: 860px) {
    .lottery-tabs .lottery-deck {
        justify-content: space-between;
        padding: 0 !important;

        .lottery-deck--card {
            width: 23% !important;
            flex: 0 0 23%;
        }
    }
}

@media only screen and (min-width: 760px) {
    .lottery-tabs, .lottery-play--nav > .container {
        .lotery-tab--nav > .tab-nav, > .tab-nav {
            width: 100%;
            font-size: em(20);
            justify-content: center;

            > li {
                opacity: 0.7;
                font-size: em(16) !important;

                br {
                    display: none;
                }

                &.active, &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
