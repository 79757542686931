/*	Fonts	*/
$openSans: "open-sans";
$proximaSoft: "proxima-soft";
/*	Colors	*/

$brown: #302C29;
$darkGrey: #4C4D4A;
$grey: #7A7B78;
$lightGrey: #E3E3E3;
$blue: #2C68A5;
$lightBlue: #38A4D0;
$orange: #E25F2C;
$orangeLight: #F48B30;
$green: #209D4D;
$androidGreen: #84BF45;
$red: #D8262D;
$white: #FFFFFF;

$colorUSPB:#530b9f;
$colorUSMM:#d8262d;
$colorEURO: #2c68a5;
$colorEURJ: #e0b304;
$colorSENA: #209d4d;
$colorPRIM: #f48b30;
$colorUKLO: #d8262d;
$colorIREL: #84bf45;
$colorCombos: #41286c;
$colorSyndicates: #e25f2c;
$colorSuperdraw: #f48b30;
$colorFutureball: #89298e;

$displayProps: ("block", "inline-block", "inline", "flex", "none");

@each $disp in $displayProps {
    .d-#{$disp} {
        display: #{$disp} !important;
    }
}

.v-hidden {
    visibility: hidden;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}

$alignments: ("center", "space-between", "space-around", "space-evenly", "flex-start", "flex-end");

@each $align in $alignments {
    .justify-#{$align} {
        justify-content: #{$align};
    }

    .items-#{$align} {
        align-items: #{$align};
    }

    .justify-items-#{$align} {
        justify-items: #{$align};
    }

    .content-#{$align} {
        align-content: #{$align};
    }
}

.max-width-100{
    max-width:100% !important;
}
.m-0auto {
    margin: 0 auto;
}

.m-auto {
    margin: auto !important;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.font-bold{
    font-weight:bold !important;
}

$textAlignments: ("left", "right", "center");

@each $textAlign in $textAlignments {
    .text-#{$textAlign} {
        text-align: #{$textAlign} !important;
    }
}


.hr-text-or {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    width: 100%;

    &:before {
        content: '';
        background: linear-gradient(to right, transparent, #fff, transparent);
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
    }

    &:after {
        content: attr(data-content);
        position: relative;
        display: inline-block;
        color: black;
        padding: 0 .5em;
        line-height: 1.5em;
        color: #fff;
        background-color: #047ADA;
    }
}