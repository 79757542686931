footer {
    position: relative;

    .footer-top {
        padding: 2rem 0;
        background-color: #7A7B78;

        .container {
            max-width: rem(600);

            img {
                width: rem(150);
                height: 53px;
                margin: 0 0 rem(10);
            }

            .secondary-logo {
                width: 80px;
                height: 80px;
                margin-left: rem(20);
            }

            p {
                color: white;
                font-size: em(14);
                font-family: $openSans;
                line-height: 1.8;
            }
        }
    }

    .footer-bottom {
        background-color: #302C29;
        padding: rem(25) 0;

        .footer-nav {
            ul {
                list-style: none;
                max-width: rem(280);

                li {
                    margin-bottom: rem(5);
                    margin-right: rem(15);
                    display: inline-block;
                    text-align: center;

                    a {
                        color: white;
                        text-decoration: underline;
                        font-family: $openSans;
                        font-weight: 400;
                        font-size: em(14);
                    }
                }
            }

            + .copy {
                margin: rem(15) auto 0;
            }
        }

        .copy {
            font-size: 12px;
            color: #ffffff;
            font-family: $openSans;
            max-width: 100%;
        }
    }

    .download-buttons {
        padding-top: 2rem;
    }

    @media only screen and (max-width: 600px) {
        .footer-top{
            padding:1rem 0;
        }
    }
}

@media only screen and (min-width: 780px) {
    footer {
        .footer-bottom {
            .footer-nav {
                ul {
                    max-width: 100%;

                    li {
                        &:nth-of-type(3n + 3) {
                            margin-right: rem(15);
                        }
                    }
                }
            }
        }
    }
}

