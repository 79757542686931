/*	Tabs	*/

.tab-nav {
    margin: 0;
    list-style: none;
    align-items: center;
    align-items: stretch;
    padding: 0 6%;
    display: inline-block;
    white-space: nowrap;
    align-self: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: space-around !important;
    user-select: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    li {
        list-style: none;
        color: white;
        font-size: em(15);
        letter-spacing: 0.5px !important;
        line-height: 1.3;
        padding: rem(10) rem(10) rem(12.5);
        border-bottom: 3px solid transparent;
        transition: all ease-in-out 0.2s;
        cursor: pointer;
        font-weight: 600;
        align-self: stretch;
        align-items: center;
        display: flex;
        max-width: 25%;
        font-size: em(14);

        svg {
            margin: 0 auto rem(10);
            display: block;
            flex: 0 0 100%;
            height: rem(45);
            width: auto;
        }


        &.active {
            border-color: white;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &.tab-nav-orange {
        border-bottom: 1px solid $orange;

        li {
            color: $orange;

            &.active {
                border-color: $orange;
            }
        }
    }

    &.tab-nav-noborder {
        border-bottom: none;
    }

    &.tab-nav-nooverflow {
        overflow: hidden;
        justify-content: space-around;
        align-items: flex-end;

        li {
            max-width: 100%;
            width: auto;
            padding: rem(10) rem(5);
            font-size: em(18);
            font-weight: 700;
            flex: 0 0 auto;
            display: block;
            letter-spacing: 0;
            margin: 0;
        }
    }

    &.tab-nav-small {
        li {
            font-weight: 400;
        }
    }
}

.tab-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    user-select: none;
    max-width: rem(1080);
    background-attachment: white;
    border-radius: 10px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: rem(15) 0;
    max-width: 95vw;

    span {
        color: $blue;
        cursor: pointer;
        opacity: 0.6;
        line-height: 1.3;
        font-weight: 600;
        font-size: em(15);
        transition: all ease-in-out 0.2s;

        &:hover {
            opacity: 1;
        }
    }

    label {
        margin: 0 rem(25);

        input[type="checkbox"] {
            display: none;

            + span {
                display: block;
                width: rem(45);
                height: rem(15);
                background: $orange;
                position: relative;
                border-radius: rem(50);
                opacity: 1;

                &:before {
                    content: '';
                    border-radius: 50%;
                    width: rem(15);
                    height: rem(15);
                    background: white;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    transform: translateX(-96%);
                    transition: all ease-in-out 0.2s;
                    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    span.active {
        opacity: 1;

        + label {
            span {
                &:before {
                    left: 0;
                    transform: none;
                }
            }
        }
    }

    &.no-border {
        box-shadow: none;
        border: none;
        margin: 0;
    }

    &.tab-slide-toggle {
        background: transparent;
        border: 2px solid #ffffff;
        /*justify-content: space-between;*/
        justify-content: center;
        padding: 0;
        border-radius: 25px;
        border: 2px solid #ffffff;
        width:90%;
        span {
            padding: rem(10) rem(35);
            border-radius: 25px;
            color: #ffffff;
            width: 50%;
            opacity:1;
            &.active {
                background: #ffffff;
                color: $blue;
            }

            &:first-child {
                &.active {
                    margin-left: -1px;
                }
            }

            &:last-child {
                &.active {
                    margin-right: -1px;
                }
            }
        }

        @media only screen and (max-width: 620px) {
            width:95%;

            span {
                padding: 10px;
                font-size:.8rem;
            }
        }
    }

    &.tab-slide-play {
        border: 0;
        box-shadow: none;
        margin-bottom: 0;
        max-width: rem(860);

        span {
            line-height: 1.6;
            opacity: 1;

            &.active {
                background: #2C68A5;
                color: #ffffff;
                border-radius: 15px;
                border-bottom-left-radius: unset;
                border-bottom-right-radius: unset;
                border-bottom: none;
            }
        }
    }

    &.tab-toggle-standardsystems {
        max-width: 50%;

        @media only screen and (max-width: 780px) {
            max-width: 75%;
        }

        @media only screen and (max-width: 600px) {
            max-width: 75%;

            span {
                width: 50%;
            }
        }
    }
}

.tab-content {
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
    z-index: -99;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    display: block;

    &.active {
        display: block;
        opacity: 1;
        z-index: 1;
        visibility: visible;
        height: auto;
        overflow: visible;

        &.division-results {
            display: flex;
        }
    }

    &.tab-content-slide {
        max-height: 0;
        opacity: 1;
        visibility: visible;
        height: auto;
        transition: all 0 !important;


        &.active {
            max-height: 5000px;
        }
    }

    &.tab-content-overflow {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    &.tab-content-play {
        background: #2C68A5;

        &.active {
            padding: 1rem 0;
            // margin-left: -1px;
        }
    }

    .play-game {
        max-width: rem(860);
        margin: auto;
    }
}

@media only screen and (min-width: 760px) {
    .tab-nav {
        li {
            font-size: em(16);
        }

        &#lottery-game--play-menu {
            justify-content: center !important;

            li {
                margin: auto 2rem;
            }
        }
    }

    .tab-toggle {
        span {
            font-size: em(19);

            br {
                display: none;
            }
        }
    }
}
