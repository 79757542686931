.lottery-general {
	padding: rem(30) 0 rem(10);
	font-size: 18px;

	.container-small {
		max-width: rem(760);
	}

	h1 {
		color: $orange;
		font-size: em(30);
		padding-bottom: rem(20);
	}

	p, li {
		font-size: em(14);
		color: #4C4D4A;
		font-family: $openSans;
		line-height: 1.45;

		&:first-child {
			margin-top: 0;
		}
	}
    &.no-padding-top{
        padding-top:0 !important;
    }
	&.align-left {
		text-align: left;

		h1 {
			text-align: center;
			margin: 0;
		}

		h3 {
			margin-bottom: 0;
			margin-top: rem(20);
		}

		h3, p, li {
			color: $grey;
			font-size: em(12);
		}
	}

	table {
		width: 100%;
		border: 1px solid $grey;
		font-size: em(12);

		td {
			border: 1px solid $grey;
			padding: rem(12);
		}
	}
}

@media only screen and (min-width: 768px) {
	.lottery-general {
		padding-bottom: rem(25);

		h1 {
			text-align: left !important;
			font-size: em(36);

			&:first-child {
				margin-bottom: rem(25) !important;
			}
		}

		h3 {
			font-size: em(18) !important;
		}

		p, li {
			font-size: em(16) !important;
		}
	}

	.bg-blue-gradient {
		.lottery-card {
			max-width: rem(1080);
		}		
	}

	table {
		font-size: em(16) !important;
	}
}

