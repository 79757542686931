
.syndicates {
    &.banner {
        .container {
            /*            background: url("/assets/images/syndicates/syndicates-banner.jpg") left no-repeat !important;
*/
            background: url("/assets/images/lottery-banners/syndicates-zero.jpg") left no-repeat !important;
            background-size: cover !important;
        }

        .headers {
            flex: 70%;
            max-width: 45rem;

            h1 {
                span {
                    font-size: 2.2rem;
                    font-weight: 800;
                }

                font-weight: 600;
                max-width: 45rem;
                font-size: 4.5rem;
                line-height: 1;
                z-index: 1;
                position: relative;
            }

            .tag-line {
                font-size: 1.5rem;
                font-weight: 600;
                max-width: 45rem;
            }
        }

        .banner-draws {
            background: transparent;
            padding-right: 3rem;

            .banner-draw {
                max-width: 300px;
                margin-bottom: 20px !important;
                padding: 15px 25px;

                & > div {
                    justify-content: space-between;

                    & > * {
                        flex: auto !important;
                    }
                }

                div {
                    &.syndicate-quick-play {
                        display: block;

                        p {
                            &.jackpot {
                                font-weight: 600;
                            }
                        }
                    }
                }

                p {
                    color: #2c68a5 !important;
                }

                .orange {
                    color: #e25f2c !important;
                }

                &.info-card {
                    padding-top: 2rem;
                    margin-top: 3rem;
                    position: relative;
                    overflow: visible;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 4rem;
                        top: -2rem;
                        left: 0;
                        background-image: url('/assets/images/new_img_icon.png');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                    }

                    .center {
                        display: block;
                        margin: auto;

                        h2,
                        p {
                            text-align: center !important;
                        }

                        h2 {
                            font-size: 40px !important;
                            color: #2c68a5 !important;
                        }
                    }
                }
            }
        }

        &.no-syndicates {
            .headers {
                max-width: 100%;
            }
        }
    }

    .steps {
        align-items: stretch;

        .col {
            width: 18%;
            padding: 2rem 1rem;
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                right: -2rem;
                top: 0;
                bottom: 0;
                left: 0;
                background: url('/assets/images/syndicates/step-divide.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            span {
                display: block;
                width: 2rem;
                height: 2rem;
                border: 1px solid #f66c1c;
                text-align: center;
                color: #f66c1c;
                line-height: 1.8;
                font-weight: 400;
                border-radius: 3rem;
                margin: auto;
            }

            img {
                height: 3rem;
                width: auto;
                margin-top: 1rem;
                display: inline-block;
                object-fit: contain;
            }

            p {
                color: #2c68a5;
                font-size: 1rem;
                margin-bottom: 0;
            }
        }
    }

    .bg-blue-gradient {
        padding: 5rem 0 2rem !important;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 6rem;
            height: 6rem;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
            top: -3rem;
            background-image: url(/assets/images/lottery-logos/lottery-syndicates.png);
            background-repeat: no-repeat;
            background-position: center center;
            pointer-events: none;
            touch-action: none;
            border-radius: 50%;
            border: 8px solid #2DA0F1;
            background-size: cover;
        }


        @media only screen and (max-width: 600px) {
            padding: 4rem 0 1rem !important;

            &:before {
                width: 4.25rem;
                height: 4.25rem;
                top: -2rem;
                border: 6px solid #2DA0F1;
            }
        }

        .plays-container {
            .white-box--shadow {
                &.no-syndicates {

                    p {
                        font-weight: 600;

                        img {
                            max-width: 3rem;
                        }

                        a {
                            text-decoration: none;
                        }

                        span {
                            color: $orange;
                            font-weight: 600;
                            font-size: 1.2rem;
                            line-height: 2;
                        }
                    }
                }

                padding: 2rem !important;
                margin-bottom: 2rem !important;
                margin-top: 0 !important;
                background-color: white;
                border-radius: 10px;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                position: relative;
                text-align: left;

                @media screen and (max-width: 600px) {
                    padding: 2rem .5rem !important;
                }

                &:last-child {
                    margin-bottom: 0 !important;
                }

                .two-col {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;

                    .col {
                        width: 50%;
                    }

                    .title {
                        width: 50%;

                        img {
                            width: 8rem;
                            height: 8rem;
                        }
                    }
                }


                h2 {
                    font-size: em(38);
                    line-height: 1.3;
                    margin: 0;
                }

                h4 {
                    font-size: 1.4rem;
                    margin: 0 auto;

                    &.orange-text {
                        margin: 0;

                        &.combined-jackpot-title {
                            margin: .5rem 0 0;
                        }
                    }
                }

                p {
                    margin: 1rem 0 0;
                }

                p,
                h4,
                h5,
                .trigger {
                    color: #2c68a5;
                }


                .combined-jackpot-title {
                    margin: rem(10) 0 0;
                    color: #E25F2C;
                    font-size: 1em;
                    font-family: $openSans;
                }

                .three-col {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    /*Edge*/
                    @supports (-ms-ime-align:auto) {
                        max-width: 150px;
                    }

                    .col {
                        width: 33% !important;
                    }
                }


                .title {
                    img {
                        object-fit: contain;
                        margin-right: 1rem;
                    }
                }

                .shares {
                    h5 {
                        font-size: 1.2rem;
                        font-weight: 600;
                        margin: 0 0 0.5rem;
                        min-width: 150px;
                    }

                    p {
                        margin: 0;
                        font-weight: 100;
                    }
                }

                .top,
                .bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .col {
                        width: auto;
                    }
                }

                .top {
                    border-bottom: 1px solid rgba(#979797, 0.2);
                    padding-bottom: 1rem;
                }

                .bottom {
                    padding-top: 1rem;

                    .trigger {
                        padding-right: 1.2rem;
                        display: inline;
                        position: relative;

                        &:before,
                        &:after {
                            transition: 0.5s ease all;
                        }

                        &:before {
                            content: 'Show numbers';
                        }

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 0.9rem;
                            height: 0.9rem;
                            right: 0;
                            top: 0.4rem;
                            background: url('/assets/images/syndicates/arrow.svg') center center;
                            background-repeat: no-repeat;
                        }
                    }

                    .button {
                        max-width: 16rem;
                    }
                }

                .stepper {
                    .add,
                    .minus {
                        width: 2rem !important;
                        height: 2rem !important;
                        background: #2c68a5;
                        border-radius: 100%;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.6rem;
                        font-weight: 500;
                        position: relative;
                        cursor: pointer;
                        user-select: none;
                    }

                    .minus {
                        span {
                            position: absolute;
                            top: 10%;
                        }
                    }

                    .num {
                        background: #2c68a5;
                        width: 4rem !important;
                        height: 4rem !important;
                        border-radius: 0.5rem;
                        margin: 0 0.5rem;

                        input {
                            &.qty {
                                border: none;
                                border-radius: unset;
                                font-size: 2rem !important;
                                font-weight: 600;
                                font-family: inherit;
                                height: auto;
                                max-height: 35px;
                                padding: 0;
                                margin: auto;
                                /*IE<11*/
                                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                    max-width: 60px !important;
                                }
                            }
                        }

                        span {
                            width: 100%;

                            &:first-child {
                                font-size: 2rem;
                            }

                            &:last-child {
                                font-size: 0.7rem;
                                text-transform: uppercase;
                            }
                        }

                        h5 {
                            margin: 0;
                            color: white;
                        }
                    }
                }

                h5 {
                    &.total-syndicate-cost {
                        min-width: 150px;

                        &:before {
                            content: 'Total: $'
                        }
                    }
                }
            }
        }
    }

    .active {
        .trigger {
            &:before {
                content: 'Hide numbers' !important;
            }

            &:after {
                transform: rotate(-180deg);
            }
        }

        .expand {
            max-height: 100%;
            transition: all ease 1s;
        }
    }

    .expand {
        max-height: 0;
        overflow: hidden;

        .tables {
            &:first-of-type {
                margin-top: 2rem;
                border-top: 1px solid rgba(#979797, 0.2);
            }
        }
    }

    .tables {
        width: 100%;
        padding-top: 1rem;

        .title {

            h5 {
                text-align: left;
                font-weight: 400;
                color: #f66c1c !important;
                font-size: 1rem !important;
                margin: 0 !important;

                span {
                    color: #2c68a5;

                    &.multiplier {
                        display: flex !important;
                        line-height: 2;
                        align-items: center;

                        svg {
                            width: 18px;
                            position: relative;
                            top: 2px;
                            left: 5px;
                        }

                        &.checkout-page {
                            line-height: 0;
                            margin-top: -2px;
                        }
                    }

                    &.lottery-date {
                        display: block;
                        line-height: 1.5;
                    }
                }
            }

            img {
                width: 3rem;
                height: 3rem;
                margin-right: 1rem;
                vertical-align: middle;
            }
        }

        .four-col {
            display: flex;
            justify-content: space-between;

            .col {
                min-width: calc(25%);

                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                    min-width: calc(24.5%);
                }

                width: auto;

                ul,
                li {
                    margin: 0;
                    list-style: none;
                }

                ul {
                    padding: 0 1rem;
                    border-right: 1px solid #fb8e0c !important;
                }

                &.one-col {
                    ul {
                        border-right: 0 !important;
                        padding-right: 0;
                    }
                }

                &:last-child {
                    ul {
                        border-right: 0 !important;
                        padding-right: 0;
                    }
                }

                &:first-child {
                    ul {
                        padding-left: 0;

                        li {
                            &:first-child {
                                border: 0;
                            }
                        }
                    }
                }

                @media screen and (-webkit-min-device-pixel-ratio:0) {
                    ul:has(> li.rowcount-7) {
                        padding: 0;

                        li {
                            padding: 0.25rem;
                        }
                    }
                }

                li {
                    padding: 0.25rem 0.5rem;
                    display: flex;
                    justify-content: space-around;
                    color: #1b90e8;
                    font-size: 0.9rem;
                    border-top: 1px solid $lightGrey;

                    @media screen and (min-width: 1200px) {
                        &:first-child {
                            border: 0;
                        }
                    }

                    span {
                        width: 100%;
                        height: 100%;
                        margin: auto 1%;
                        padding: 1% 0;
                        max-width: 28px;
                        min-width: 28px;
                        max-height: 28px;
                        min-height: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: normal;
                        color: $orange;

                        &.game-number {
                            color: $grey;
                            font-weight: normal;
                        }

                        &.blue-text {
                            color: $lightBlue;
                        }
                    }

                    &.rowcount-8 {
                        span {
                            margin: auto .5% !important;
                        }
                    }

                    &.rowcount-9 {
                        flex-wrap: wrap;
                        justify-content: flex-start;
                    }

                    &.rowcount-10 {
                        flex-wrap: wrap;
                        justify-content: flex-start;
                    }

                    &.rowcount-11 {
                        flex-wrap: wrap;
                        justify-content: flex-start;
                    }

                    &.rowcount-12 {
                        flex-wrap: wrap;
                        justify-content: flex-start;
                    }
                }

                &.bonus-container {
                    ul {
                        li {
                            &:first-child {
                                border: 0;
                            }
                        }
                    }
                }
            }

            &.flex-column-syndicates {
                flex-direction: column;
                flex-wrap: wrap;

                .col {
                    width: 100%;

                    ul {
                        padding: 0 1rem;

                        &.standard {
                            border-right: 1px solid #fb8e0c !important;

                            @media screen and (max-width: 1200px) {
                                border: 0 !important;
                            }
                        }

                        &.ul-sys {
                            border: 0 !important;
                            padding: 0;
                            display: flex;
                            justify-content: center;

                            li {
                                border: 0 !important;
                            }
                        }
                    }

                    .results-expand-sysentry {
                        display: flex;
                        cursor: pointer;
                        justify-content: center;
                        text-decoration: underline !important;
                        padding-bottom: 1rem;
                        margin: auto;
                        text-align: center;

                        &:after {
                            margin-left: .325rem;
                        }

                        span {
                            display: flex;
                            justify-content: center;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .syndicate-information {
        padding: 1rem 0;

        ul {
            list-style: none;
            padding: 2rem 0;
            margin: 0;

            > li {
                display: flex;
                text-align: left;
                padding: 1rem 0;

                > img {
                    max-width: 10rem;
                    margin-right: 3rem;
                    align-self: flex-start;
                }

                div {
                    padding-left: 1rem;
                    position: relative;

                    h1 {
                        margin: 0;
                        font-size: 1.375em;
                        font-weight: 700;
                        color: $blue;
                    }

                    p {
                        /*                        &:first-of-type {
                            &:before {
                                position: absolute;
                                height: 50px;
                                width: 3px;
                                left: 0;
                                background: #E25F2C;
                                display: block;
                                content: "";
                            }
                        }*/

                        line-height: 1.5;
                        max-width: 90%;
                    }

                    table {
                        border: 1px solid $darkGrey;

                        tr {
                            th {
                                &:first-child {
                                    background: $blue;
                                }

                                color: #ffffff;
                                background: $lightBlue;
                                text-align: center;
                            }

                            td {
                                text-align: left;
                                padding: 5px 10px;
                                border: 1px solid
                            }
                        }
                    }

                    ul {
                        padding: 1rem;
                        list-style: disc;

                        li {
                            display: list-item;
                            padding: initial;
                        }
                    }

                    ol {
                        padding: 1rem;

                        li {
                            display: list-item;
                        }
                    }
                }
            }
        }
    }

    .system-scroll {
        height: 320px;
        overflow-y: scroll;
        background: #eee;
        margin-bottom: .5rem;
        cursor: n-resize;
        flex-wrap: wrap;

        .scroll-msg {
            display: flex;
            justify-content: center;
            padding: 1rem 0;
        }


        .tables {
            .four-col {
                justify-content: center;

                .col {
                    width: auto;
                }
            }


            &:first-of-type {
                margin-top: 0 !important;
                border: 0 !important;
            }
        }
    }

    #syndicate-custom-text {
        display: block;
        max-width: 90%;
        padding-left: .5rem;
        border-left: 3px solid $orange;
        color: $grey;
    }

    .flex-column-syndicates {
        > .scroll-msg {
            display: none !important;
        }

        .tables {
            li {
                .guaranteed-label {
                    position: relative;
                    background: #e6e6e6;

                    &:before {
                        position: absolute;
                        top: -15px;
                        left: 0px;
                        font-size: .6rem;
                    }
                }
            }

            @media screen and (min-width: 1200px) {
                li {
                    &:first-child {
                        .guaranteed-label.USPB {
                            &:before {
                                content: 'Power';
                            }
                        }

                        .guaranteed-label.USMM {
                            &:before {
                                content: 'Mega';
                            }
                        }

                        .guaranteed-label.EURO {
                            &:before {
                                content: 'Star';
                                left: 5px;
                            }
                        }

                        .guaranteed-label.EURJ {
                            &:before {
                                content: 'Euro';
                                left: 5px;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 1200px) {
                .col {
                    &:first-of-type {
                        li {
                            &:first-child {
                                > .guaranteed-label.USPB {
                                    &:before {
                                        content: 'Power';
                                    }
                                }

                                > .guaranteed-label.USMM {
                                    &:before {
                                        content: 'Mega';
                                    }
                                }

                                > .guaranteed-label.EURO {
                                    &:before {
                                        content: 'Star';
                                    }
                                }

                                > .guaranteed-label.EURJ {
                                    &:before {
                                        content: 'Euro';
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }



        #g-explainer {
            display: flex;
            align-items: center;
        }

        #g-symbol {
            width: 100%;
            height: 100%;
            margin: auto 1%;
            padding: 1% 0;
            max-width: 28px;
            min-width: 28px;
            max-height: 28px;
            min-height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            color: #FFFFFF;
            background-color: #38A4D0;
        }
    }
    //loading shimmer css
    .shimmer-line {
        height: 10px;
        margin-top: 20px;
        background: #777;
    }

    .shimmer-line-br {
        border-radius: 8px;
    }

    .shimmer-line-full {
        width: 100%;
    }

    .shimmer-line-80 {
        width: 80%;
    }

    .shimmer-line-60 {
        width: 60%;
        margin-left: 5%;
    }

    .shimmer-circle {
        background: #777;
        border-radius: 50%;
    }

    .shimmer-circle-md {
        height: 65px;
        width: 65px;
    }

    .shimmer-wrapper {
        width: 100%;
        height: 100%;
        animation: shimmer-full-view 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940);
        margin-top: 2rem;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .ml-10 {
        margin-left: 10px;
    }

    @keyframes shimmer-full-view {
        0% {
            background-position: -1000px 0;
        }

        100% {
            background-position: 1000px 0;
        }
    }

    @keyframes shimmer-animation {
        0% {
            background-position: -1000px 0;
        }

        100% {
            background-position: 1000px 0;
        }
    }

    .shimmer-animate {
        animation: shimmer-animation 2s infinite linear;
        background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
        background-size: 1000px 100%;
    }
}

    @media screen and (max-width: 1200px) {
        .syndicates {

            .steps {
                display: none !important;
            }

            .bg-blue-gradient {
                padding: 2rem 0;

                .plays-container {
                    .white-box--shadow {
                        .two-col {
                            .col, .title, .shares {
                                width: 100% !important;
                            }

                            .stepper {
                                margin: 1rem auto;

                                .add,
                                .minus {
                                    width: 3rem !important;
                                    height: 3rem !important;

                                    span {
                                        font-size: 2rem;
                                    }
                                }

                                .num {
                                    margin: 0 1rem;
                                    width: 5rem !important;
                                    height: 5rem !important;

                                    span {
                                        &:first-child {
                                            font-size: 2.5rem;
                                        }

                                        &:last-child {
                                            font-size: 0.9rem;
                                        }
                                    }
                                }
                            }
                        }

                        .top,
                        .bottom {
                            margin-top: 2rem;
                        }
                    }
                }
            }

            .expand {
                .tables {
                    .title {
                        &.flex {
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .four-col {
                        display: block;
                        flex-wrap: wrap;
                        justify-content: center;

                        .col {
                            width: 100%;
                            padding: 0;

                            ul {
                                border-right: none !important;
                                padding: 0;
                                max-width: 280px;
                                margin: auto;
                            }

                            &:first-child {
                                ul {
                                    &:first-child {
                                        li {
                                            &:first-child {
                                                border: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @supports (-ms-ime-align:auto) {
                .three-col {
                    max-width: 250px !important;
                }
            }
        }
    }

    @media screen and (max-width: 1080px) {
        .syndicates {
            .plays-container {
                .white-box--shadow {
                    .col {
                        margin: 0;
                    }

                    h1 {
                        font-size: 50px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .syndicates {
            &.banner {
                .container {
                    justify-content: center;
                    min-height: 400px;
                    /*                background: url("/assets/images/syndicates/syndicates-banner.jpg") top no-repeat !important;
*/ background-size: cover !important;
                    width: 100% !important;
                    flex-direction: column;

                    .headers {
                        padding: 10% 0 5%;
                        max-width: 100%;

                        h1 {
                            text-align: center;
                            font-size: 4rem;
                        }

                        span {
                            &.tag-line {
                                text-align: center;
                                font-size: 1rem;
                            }
                        }
                    }

                    .banner-draws {
                        width: 100%;
                        padding: 10% 0;

                        .banner-draw {
                            margin: auto;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .syndicates {
            .section.thick {
                padding: 2rem 0;
            }

            .bg-blue-gradient {
                padding: 1rem 0;

                .plays-container {
                    .white-box--shadow {
                        padding: 1.5rem;

                        .two-col {
                            .col {
                                width: 100% !important;
                            }

                            .title,
                            .top,
                            .bottom {
                                flex-direction: column;
                                text-align: center;
                            }

                            .top,
                            .bottom {
                                margin: 0;
                                padding: 1rem 0 0;
                                border: 0;

                                p {
                                    font-size: 18px;
                                    font-weight: 500;

                                    &.cost-per-share {
                                        font-size: 0.875em;
                                    }
                                }
                            }

                            .title {
                                width: 100% !important;

                                h1 {
                                    line-height: 1;
                                }

                                img {
                                    width: 6rem !important;
                                    height: 6rem !important;
                                    margin: 0 auto 1rem;
                                }
                            }

                            .bottom {
                                flex-direction: column-reverse;
                            }

                            .button {
                                margin-bottom: 1rem;
                            }
                        }

                        .is-label {
                            justify-content: center;
                            margin: 5px auto;
                            color: #ffffff;
                        }
                    }
                }
            }

            .white-box--shadow {
                &.active {
                    .expand {
                        max-height: 100%;
                        transition: all ease 1s;
                    }
                }
            }

            .expand {
                .tables {
                    .four-col {
                        display: block;

                        .col {
                            width: 100%;
                        }
                    }
                }
            }

            &.no-syndicates {
                .headers {
                    padding: 20% 0 0 !important;
                }
            }
        }
    }

    @media screen and (max-width:600px) {
        .syndicates {
            .expand {
                .tables {
                    &:first-of-type {
                        margin-top: 1rem;
                    }

                    .title {
                        h5 {
                            margin: 1rem 0 !important;
                        }
                    }

                    .four-col {
                        .col {
                            li {
                                &.rowcount-8 {
                                    span {
                                        margin: auto .5% !important;
                                    }
                                }

                                &.rowcount-9 {
                                    flex-wrap: wrap;
                                    justify-content: flex-start;
                                }

                                &.rowcount-10 {
                                    flex-wrap: wrap;
                                    justify-content: flex-start;
                                }

                                &.rowcount-11 {
                                    flex-wrap: wrap;
                                    justify-content: flex-start;
                                }

                                &.rowcount-12 {
                                    flex-wrap: wrap;
                                    justify-content: flex-start;
                                }
                            }
                        }
                    }
                }
            }

            #banner-draws {
                display: block !important;
            }


            &.banner {
                .container {
                    background: url("/assets/images/lottery-banners/syndicates-mobile-zero.jpg") left no-repeat !important;
                    min-height: 300px;

                    .headers {
                        h1 {
                            font-size: 3rem;
                        }

                        span {
                            &.tag-line {
                                text-align: center;
                                font-size: 1rem;
                            }
                        }

                        p {
                            font-size: 1rem;
                        }
                    }
                }
            }

            &.no-syndicates {
                .headers {
                    padding: 25% 0 0 !important;
                }
            }
        }
    }
