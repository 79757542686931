.popup {
    width: 100vw;
    position: fixed;
    top: 40%;
    transform: translateY(-50%);
    left: 0;
    z-index: 9999;
    // height: 75vh;
    margin: 10vh 0;
    display: none;
    -webkit-overflow-scrolling: touch;
    overflow: visible !important;

    &.active {
        display: block;
    }

    .popup-container {
        height: 75vh;
        overflow: auto !important;
        width: 100% !important;
    }

    .popup-container > .container {
        padding: rem(20) 5%;
        max-width: rem(560);
        background: linear-gradient(to top left, #047ADA 0%, #39ABF7 100%);
        border-radius: 10px;
        margin: auto;

        &.container-large {
            padding: 0;
            width: 95%;
            text-align: center;
        }
    }

    .lottery-card {
        text-align: center;
    }

    .popup-close {
        position: absolute;
        right: rem(20);
        top: rem(20);
        color: white;
        width: rem(35);
        height: rem(35);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid white;
        border-radius: 50%;
        font-size: em(12);
        z-index: 9999;
        top: 0;
        right: calc(50% - #{rem(280)});
        transform: translate(40%, -40%);
        background: white;
        box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);

        svg {
            * {
                fill: $blue;
            }
        }
    }

    .popup-inner {
        text-align: center;
        margin: rem(25) auto rem(25);
        overflow: hidden;
        max-width: rem(560);
        width: 95%;

        @media only screen and (max-width: 600px) {
            margin: 0 auto;
        }

        h3 {
            text-align: center !important;
        }

        .popup-icon {
            width: rem(85);
            height: rem(85);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 2px solid white;
            margin: 0 auto rem(40);

            &.icon-spinner {
                width: rem(175);
                height: rem(175);

                svg {
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            svg {
                height: 55% !important;
                width: auto !important;
                overflow: visible;

                * {
                    stroke-width: 0.5px;
                    fill: white;
                }
            }
        }

        h3 {
            color: white;
            font-size: em(18);
            font-weight: 600;
            margin: 0 0 rem(25);
        }

        #global--notification-popup-title {
            margin: 0 0 rem(15);
        }

        p {
            color: white;
            font-size: em(14);
            font-family: $openSans;
            line-height: 1.5;
            margin-top: 0;
        }

        .buttons-container {
            margin-top: rem(30);
            display: flex;
            flex-direction: column;

            p {
                font-size: em(14) !important;
                text-align: left;
                width: 95%;
                margin: rem(10) auto;

                a {
                    text-decoration: underline;
                    color: inherit;
                }
            }
        }

        .button {
            display: block;
            padding: rem(14);
            margin-top: rem(10);
            font-size: em(14);
            font-weight: 600;
            font-family: $openSans;
            min-width: 150px;

            &.continue {
                text-decoration: none;
                font-size: 1em;
                margin-top: 1rem;
                font-family: $proximaSoft;
            }
        }
    }

    &.popup-waiting {
        .container {
            padding-bottom: rem(50);
            padding-right: 0;
            padding-left: 0;
        }

        h3 {
            font-size: em(24);
        }

        p {
            text-align: center;
        }

        .lottery-card {
            width: 95%;
        }
    }

    h4 {
        color: white;
        margin-bottom: rem(10);
        font-weight: 400;
    }

    .popup-card {
        border-top: 1px solid white;
        width: 95%;
        margin: 0 auto;
        padding-top: rem(30);


        .lottery-card {
            padding: rem(25) rem(30);

            &.lottery-card-results {
                padding-right: 5%;
                padding-left: 5%;
            }

            p {
                text-align: right;
            }

            .lottery-date {
                text-align: left;
                font-size: em(10) !important;
                margin-left: 0 !important;
            }

            .draw-card--header {
                text-align: left;
                font-size: 23px;
                max-width: 100%;


                * {
                    text-align: left;
                }

                .lottery-flag {
                    width: rem(40);
                    height: rem(40);
                }
            }

            .lottery-numbers-container {
                p {
                    text-align: center;
                }
            }
        }
    }

    &.express-checkout {
        padding-bottom: 0;

        .container {
            max-width: rem(560);
            text-align: left;
            padding: rem(50) 0 0;
        }

        .popup-inner {
            padding-bottom: rem(25);

            h3 {
                font-size: em(20);
                text-align: left;
            }

            p {
                max-width: rem(360);
                text-align: center;
                margin: 0 auto;
            }
        }

        .button {
            margin: 0 rem(7.5) rem(15);

            span {
                width: rem(25);
                height: rem(25);
                flex: 0 0 rem(25);
            }
        }

        .checkout-actions {
            padding-bottom: rem(25);

            p {
                margin-right: auto !important;
                margin-left: auto !important;
                max-width: rem(380);
            }
        }
    }

    &.voucher-details {
        .container {
            padding: 0;
            max-width: rem(350);
            width: 85%;
        }

        .popup-inner {
            h3 {
                text-align: center;
                max-width: 100%;
                font-weight: 400;
            }
        }

        .checkout-actions {
            padding: rem(40) rem(15) !important;

            .container {
                padding: 0;
            }

            p {
                font-weight: 400;
                margin: 0 0 rem(15) !important;
            }
        }

        form {
            display: flex;

            input[type="text"] {
                border: rem(2) solid $blue;
                color: $lightBlue;
                width: 100%;
                text-align: center;
                margin-right: rem(5);
            }

            input::-webkit-input-placeholder {
                color: $lightBlue;
            }

            input:-moz-placeholder { /* Firefox 18- */
                color: $lightBlue;
            }

            input::-moz-placeholder { /* Firefox 19+ */
                color: $lightBlue;
            }

            input:-ms-input-placeholder {
                color: $lightBlue;
            }

            input[type="submit"], button {
                border: none;
                width: rem(200);
                max-width: 40%;
                background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
                color: white;
            }
        }
    }

    &.voucher-added {
        padding-top: rem(20);

        .container {
            padding: 0;
            width: 85%;
        }

        .popup-inner {
            h3 {
                text-align: center;
                font-weight: 400;
            }

            p {
                text-align: center;
                max-width: 100%;
                margin-bottom: 0;
            }
        }

        .checkout-actions {
            padding: rem(40) rem(15) !important;
            text-align: left;

            .container {
                width: 85%;
                padding: 0;
                max-width: rem(450);
            }

            p {
                font-weight: 400;
                margin: 0 0 rem(10);
            }

            .button-container {
                position: relative;
                margin: 0 auto rem(40);
                border-radius: 100px;
                width: rem(360);
                border: rem(2) solid $blue;

                .button-title {
                    color: $lightBlue;
                    font-size: em(14);
                    flex: 1;
                    margin: 0;
                    border-radius: 100px;
                    padding: rem(10) rem(150) rem(10) rem(15);
                }

                .button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 101%;
                    min-height: 0;
                    margin: 0;
                    width: auto;
                    transform: none !important;
                    font-weight: 400;
                    padding: 0 rem(25);
                    font-size: em(14);
                }
            }
        }
    }

    &.popup-maintenance {
        .container {
            background: white;
            border-radius: 10px;
        }

        .popup-icon {
            svg {
                height: 90% !important;

                * {
                    stroke-width: 2px !important;
                }
            }
        }

        .popup-close {
            border-color: $blue;

            svg {
                * {
                    fill: $blue;
                }
            }
        }

        h3, p {
            color: $blue;
        }
    }

    &.signup-popup {
        padding: 0;
        background-image: none;
        background: transparent;

        .account-exists {
            padding: 8px;
            border-radius: 8px;
            margin-top: 1rem;
            background: $green;
            color: #fff;
            margin: 0 auto 1rem auto;
            display: none;

            &.active {
                display: block;
            }
        }

        .tab-toggle {
            background: transparent;
            padding: 0;
        }

        .popup-container {
            height: 90vh;
        }

        .popup-container > .container {
            padding: 0 !important;
            width: 92.5% !important;
            background: white;
            border-radius: 10px;
            overflow: hidden;
        }

        .container-small {
            padding: 0 rem(15);
            width: 100%;
            max-width: rem(435);
        }


        .label-for {
            position: relative;
        }


        .input-row {
            label {
                button {
                    margin-top: 0;
                }
            }
        }

        .terms-conditions-check {
            .checkbox {
                top: rem(11);
            }
        }

        label {
            position: relative;

            .checkbox {
                top: rem(7.5);
            }

            input[type="submit"], button {
                /*padding: rem(15) rem(75);*/
                margin-top: rem(20);
                min-width: 150px;
            }
        }

        form {
            .input-row {
                .select-dropdown {
                    .select-rendered:after {
                        right: rem(5);
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }

        #sign-up.nav-tab.tab-content {
            height: 100%;
            opacity: 1;
            visibility: visible;
            z-index: 1;
            position: static;

            .sign-in {
                .container {
                    width: 100%;
                    padding: 0;
                }

                .sign-in--header {
                    background: linear-gradient(to top left, #0B80DE 0%, #44B5FD 100%);
                    padding: rem(25) 0 rem(30);

                    .h2title {
                        margin: 0 auto;
                        font-size: em(22);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        line-height: 1.2;
                        text-align: center;
                        font-weight: 700 !important;
                        color: white;

                        .signup-icon {
                            margin-right: rem(15);
                            border: 2px solid white;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 6.5px;

                            svg {
                                width: rem(15);
                                height: rem(15);
                                box-sizing: content-box;

                                * {
                                    stroke: white;
                                }
                            }
                        }

                        i {
                            font-size: em(34);
                            margin-right: rem(15);
                        }
                    }

                    p {
                        margin-top: 0;
                        font-size: em(14);
                        font-weight: 400;
                        font-family: $openSans;
                        color: white;

                        &:first-of-type {
                            margin-top: rem(20);
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .button {
                    margin-bottom: rem(20);
                    position: relative;
                    font-weight: 700;
                    padding-left: rem(10);
                    padding-right: 0;

                    i {
                        position: absolute;
                        left: rem(18);
                        font-size: em(18);
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .sign-in--main {
                    padding: rem(35) 0 0;

                    .container {
                        width: 90%;

                        > p {
                            color: $blue;
                        }
                    }
                }

                .sign-in-action {
                    margin-top: rem(10);
                    margin-bottom: rem(35);

                    p {
                        color: $blue;
                        display: flex;
                        justify-content: space-between;
                        margin: 0;
                        line-height: 1;
                        font-size: em(14);
                        font-family: $openSans;
                        padding: 0 rem(10);

                        span {
                            color: $orange;
                            margin-left: rem(10);
                            font-weight: 700;
                            text-decoration: underline;
                        }
                    }
                }

                form {
                    h3 {
                        color: $orange;
                        font-weight: 600;
                        font-size: em(16);
                        margin: rem(15) 0 rem(20);
                        display: inline-block;
                    }
                }

                &#sign-up {
                    input[type="submit"] {
                        margin-bottom: rem(25);

                        + .sign-in-action {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &.popup-status {
        .sign-in--main {
            .radio-container {
                label {
                    input {
                        margin-right: rem(35) !important;
                    }
                }
            }
        }
    }

    &.popup-bpay {
        .popup-icon {
            width: rem(85);
            height: rem(85);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 2px solid white;
            margin: 0 auto;

            svg {
                width: 50%;
                height: 50%;

                * {
                    fill: white;
                }
            }
        }

        h2 {
            margin-top: 0;
            color: $orange;
        }

        p {
            margin-bottom: rem(30);
        }

        .bpay-container {
            float: none;
            margin: 0 auto 1rem;
            /*max-width: 17.75rem;*/
        }

        .buttons-container {
            display: block;

            .button {
                max-width: rem(140);
                width: auto;
                padding-left: rem(30);
                padding-right: rem(30);
                margin: 0 auto;

                svg {
                    flex: 0 0 rem(30);

                    #Rectangle-Copy {
                        display: none;
                    }
                }

                &:hover {
                    background-color: transparent;
                    color: $orange;
                }
            }
        }
    }
}

.popup-page {
    background: linear-gradient(to top left, #047ADA 0%, #39ABF7 100%);
    padding: rem(80) 0 rem(40);

    &.page-error {
        p {
            text-align: center !important;
        }

        .button {
            margin-top: rem(20);
        }
    }

    .popup-inner {
        padding: rem(40) rem(20);
        background-color: white;
        position: relative;
        border-radius: 10px;
        margin: auto;
        position: relative;
        box-shadow: 0 0 rem(30) 0 rgba(0,0,0,0.1);

        .h2title {
            color: $blue;
            margin: 1rem auto;

            span {
                border-color: $blue;

                svg {
                    margin: 0;

                    * {
                        fill: $blue;
                    }
                }
            }
        }

        h3, p {
            color: $blue;
        }

        p {
            text-align: left;
            font-family: $openSans;
            margin-top: 0;
        }

        a {
            font-weight: 600;
            text-decoration: none;
        }

        .sign-in--main {
            margin-top: rem(40);
        }

        label {
            .checkbox {
                top: rem(7.5);
            }

            .label-content > span:first-child {
                font-size: 1rem;
                font-weight: 600;
                margin-bottom: 0.1rem;
            }
        }

        .password-strength--validation {
            p {
                font-size: rem(14);
            }
        }
    }
}

#account--spending-edit {
    margin-top: rem(30);
}

@media only screen and (min-width: 420px) {
    .popup-page {
        padding: rem(100) 0;
    }
}

@media only screen and (min-width: 580px) {
    .popup {
        &.voucher-details {
            .container {
                max-width: rem(550);
                padding: 0;
            }
        }
    }

    .popup-page {
        padding: rem(140) 0 rem(120);

        .popup-inner {
            max-width: rem(660);
            padding: rem(60) rem(75) rem(60);

            svg {
                height: 100%;
            }

            h3 {
                font-size: em(22);
            }
        }
    }
}

@media only screen and (min-width: 760px) {
    .popup {
        border-radius: rem(10);

        .popup-inner {
            text-align: left;

            h3 {
                text-align: center;
            }
        }

        .buttons-container {
            display: flex;
            flex-wrap: wrap;
            flex-direction: initial !important;
            text-align: center;

            .button {
                flex: 1;
                margin-right: 3%;
                line-height: 1.3;

                + .button {
                    margin-right: 0;
                }

                &.button-orange-solid {
                    flex: 0 0 100%;
                    margin-top: rem(15);
                }
            }
        }

        &.voucher-details {
            border-radius: rem(10) rem(10) 0 0;

            .container {
                width: 70%;
            }

            .checkout-actions {
                padding: rem(40) rem(80) !important;
            }
        }

        &.voucher-added {
            border-radius: rem(10) rem(10) 0 0;

            .container {
                width: 80%;
            }

            .checkout-actions {
                .container {
                    width: 70%;
                }

                .button-container {
                    .button-title {
                        padding: rem(10) rem(150) rem(10) rem(30);
                    }

                    .button {
                        padding: 0 rem(30);
                    }
                }
            }
        }

        &.signup-popup {
            #sign-up.nav-tab.tab-content {
                .sign-in {
                    .sign-in--header {
                        background-image: none;
                        padding: 0;

                        .h2title {
                            justify-content: flex-start;
                            color: $blue;

                            .signup-icon {
                                border-color: $blue;

                                svg {
                                    * {
                                        stroke: $blue;
                                    }
                                }
                            }
                        }

                        p {
                            text-align: left;
                            color: $grey;
                        }
                    }
                }
            }

            &.play-again-popup {
                .button {
                    margin: 0 0 1rem !important;
                }
            }
        }
    }
}


@media only screen and (max-width: 620px) {
    .popup {
        .popup-close {
            right: 5%;
        }
    }
}

@media only screen and (min-width: 980px) {
    .popup {
        .popup-close {
            cursor: pointer;
            transition: all ease-in-out 0.15s;

            svg {
                path {
                    transition: fill ease-in-out 0.15s;
                }
            }

            &:hover {
                background: $blue;
                border-color: $blue;

                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }

        &.popup-maintenance {
            .popup-close {
                &:hover {
                    background: $blue;
                    border-color: $blue;

                    svg {
                        path {
                            fill: white;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    &.play-again-popup {
        .button {
            margin: 0 0 1rem !important;
        }
    }
}

.icon-oops {
    height: 100% !important;
    min-width: rem(90);

    * {
        fill: $blue !important;
    }
}

.hostedpayment-popup {
    display: none;
    position: fixed;
    top: 2.5%;
    left: 0;
    height: 95%;
    width: 100%;
    z-index: 9999;

    &.active {
        display: block;
    }

    .hostedpayment-container {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        max-width: 35rem;
        margin: auto;
        padding: 1%;
        background: linear-gradient(to top left, #047ADA 0%, #39ABF7 100%);
        border-radius: 10px;
        overflow: auto;
    }

    .hostedpayment-msg {
        color: white;
        font-size: em(14);
        font-family: $openSans;
        line-height: 1.5;
        margin: 0;
        padding: 0;
    }

    .hostedpayment-header {
        margin: 0;
        padding: 0 0 3% 0;
        text-align: left;
        border-radius: inherit;
    }

    .hostedpayment-content {
        position: relative;
        height: 100%;
        width: 100%;
        border-radius: inherit;
    }

    .hostedpayment-loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border-radius: inherit;
    }

    .hostedpayment-payment {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
        border-radius: inherit;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .hostedpayment-frame {
        height: 100%;
        width: 100%;
        border-radius: inherit;
        margin-bottom: -1em; //Stop the double scrollbars from appearing.
        &.hostedpayment-frame-started {
            background: white;
        }
    }
    // paysafecard requires a size of 600x840
    &.paysafecard {
        top: 0;
        height: 100%;
        overflow-y: auto;

        .hostedpayment-container {
            height: initial;
            max-width: 620px;
            padding: 10px;

            .hostedpayment-content {
                height: 840px;
            }
        }
    }
}

#checkout--duplicate-numbers-warning-popup {
    h3 {
        margin: auto auto .5rem;
    }

    .popup-container > .container {
        padding: 1.25rem 3%;
    }

    .buttons-container {
        margin-top: 1rem;
    }

    .popup-icon {
        width: 3rem;
        height: 3rem;
        margin: 0 auto 1rem;
    }

    .popup-duplicate-games {
        background: #fff;
        margin: 0 auto;
        padding: 1rem 0 1.5rem;

        .game-number {
            padding: .5rem 0 0;
        }

        .dupe-list {
            width: 90%;
            display: flex;
            justify-content: center;
            margin: .25rem auto;
            background: rgba(216,38,45,0.2);
        }

        .lottery-numbers {
            flex: unset;

            .lottery-number {
                background: none;
                border-radius: unset;
                color: $orange;
                margin: 0;

                &.number {
                    background-color: none;

                    span {
                        font-weight: 400;
                    }
                }

                &.lucky {
                    span {
                        font-weight: 400;
                        color: $lightBlue;
                    }
                }

                &.primitiva-lucky {
                    display: block;
                }
            }
        }
    }
}
