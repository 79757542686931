.lottery-regulations {
    background: #f8f8f8;
    .lottery-regulations--regulation {
        padding: rem(35) 0;
        h3 {
            color: $blue;
            font-weight: 700;
            margin: rem(15) 0 rem(30);
        }

        &:last-of-type {
            border-bottom: 0;
        }

        @media only screen and (max-width: 600px) {
            padding: 1rem 0;

            h3 {
                margin: .5rem 0;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.regulation-digits {
    max-width: rem(500);
 
}

@media only screen and (max-width: 600px) {
    .lottery-regulations--regulation {

        &:first-child {
            border-bottom: 1px solid #ccc;
        }

        &:last-child {
            border-bottom: 1px solid #ccc;
        }
    }
    .regulation-digits {
 
        img {
            min-width: 400px !important;
        }
    }
}
@media only screen and (max-width: 400px) {
    .regulation-digits {

        img {
            min-width: auto !important;
        }
    }
}
    @media only screen and (min-width: 760px) {
        .lottery-regulations {
            padding: 0 calc(7.5% / 2);

            .regulation-digits {
                img {
                    min-width: auto !important;
                }
            }

            .lottery-regulations--regulation {
                flex: 1;
                margin: 0;
                border-bottom: none;

                &:first-child {
                    border-right: 1px solid #ccc;
                    padding-right: 5%;
                }

                &:last-child {
                    padding-left: 5%;
                }
            }
        }
    }

    @media only screen and (min-width: 1080px) {
        .lottery-regulations {
            padding: 0 calc((100% - #{rem(1080)}) / 2);

            .lottery-regulations--regulation {
                .container {
                    h3 {
                        margin: 0 0 0 rem(20);
                    }

                    .regulation-digits {
                        flex: 0 0 100%;
                        // margin-top: rem(25);
                        img {
                            min-width: 400px !important;
                        }
                    }
                }
            }
        }
    }
