﻿.landing-page {
    text-align: left;

    .container {
        display: flex;
        padding: rem(75) 0;

        h1 {
            margin-top: 0;
            color: black;
        }

        p {
            color: black;
        }

        .landing-left {
            .landing-lotteries {
                margin-top: rem(10);
                display: flex;
                flex-wrap: nowrap;

                .landing-lottery {
                    flex-grow: 1;

                    &:first-of-type {
                        margin-right: rem(5);
                    }

                    &:last-of-type {
                        margin-left: rem(5);
                    }
                }
            }

            &.official-mega-millions {
                p {
                    a {
                        display: block;
                    }
                }

                ol {
                    li {
                        color: #000000;
                    }
                }

                img {
                    &.us-lotto-img {
                        max-width: 760px;
                    }
                }

                table {
                    border: 1px solid #ccc;
                    border-collapse: collapse;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    table-layout: fixed;
                    border-spacing: 2px;

                    tr {
                        border: 1px solid #ddd;
                        padding: .35em;
                    }

                    th, td {
                        padding: .625em;
                        text-align: center;
                        color: #000;
                    }

                    th {
                        font-size: .85em;
                        letter-spacing: .1em;
                        text-transform: uppercase;
                        background: #333 !important;
                        color: #ffffff;
                    }

                    tr {
                        background: #fff;

                        &:nth-child(2n) {
                            background: #eee;
                            color: #000;
                        }
                    }

                    &.division {
                        border: 1px solid #aaa;
                        background-color: #aaa;

                        tr {


                            &:nth-child(2) {
                                background: gold;
                                padding: 10px !important;
                                color: #000;
                            }
                        }
                    }

                    &.megaplier {
                        border: 1px solid #aaa;
                        background-color: #eee;

                        tr {
                            &:nth-child(1) {
                                background: #000;

                                td {
                                    color: #fff;
                                    padding: 10px;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }

                @media only screen and (max-width: 600px) {
                    .table-responsive-container {
                        overflow-x: auto;

                        table {
                            table-layout: auto;
                        }
                    }
                }

                .megaplier td:nth-child(2) {
                    background-color: #cccc99;
                }

                .megaplier td:nth-child(3) {
                    background-color: #99cc99;
                }

                .megaplier td:nth-child(4) {
                    background-color: #66cc99;
                }

                .megaplier td:nth-child(5) {
                    background-color: #33cc66;
                }

                .megaplier td:nth-child(6) {
                    background-color: #009933;
                }

                .lottery-numbers {
                    display: flex;
                    align-items: flex-start;

                    @media only screen and (max-width: 600px) {
                        justify-content: center;
                    }

                    .lottery-number {
                        width: 2.6rem;
                        height: 2.6rem;
                        border-radius: 100%;
                        color: white;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        margin: 0 0.3rem;

                        @media only screen and (max-width: 900px) {
                            width: 2rem;
                            height: 2rem;
                            font-size: 14px;
                            margin: 0 0.2rem;
                        }

                        &.orange {
                            background-color: #f66c1c;
                        }

                        &.blue {
                            background-color: #1b90e8;
                        }
                    }
                }
            }
        }

        .landing-right {
            flex: 0 0 rem(360);
            width: rem(360);
            margin-left: 10%;

            &.official-mega-millions {
                margin-left: 5%;

                @media only screen and (max-width: 600px) {
                    margin-left: 0;
                }

                .landing-lottery {

                    span.jackpot {
                        color: #e25f2c;
                        font-size: 1rem;
                        display: block;
                        font-weight: 700;
                    }
                }
            }
        }

        .landing-side {
            margin-bottom: rem(50)
        }

        .landing-lottery {
            display: flex;
            align-items: center;
            padding: rem(10) rem(15);
            background-color: #f3f1f2;
            margin-bottom: rem(10);
            transition: all ease-in-out 0.2s;

            * {
                cursor: pointer;
            }

            &:hover {
                opacity: 0.7;
            }

            p {
                font-weight: 600;
            }

            img {
                margin-right: rem(10);
                width: rem(55);
            }
        }
    }

    &.game-rules {
        .container {
            padding: 2rem 0;

            section {
                &.banner-cta {   
                    padding: 0rem 6rem;
                    border-radius: 1rem; 
                    &.EURJ {
                        background: linear-gradient(to right,#6f50bd 25%, #1b5cbf 100%);
                    }

                    &.EURO {
                        background: linear-gradient(to right,#005fe2 25%, #1b5cbf 100%);
                    }

                    &.USPB, &.USMM {
                        background: linear-gradient(to right,#ae002d 25%, #1b5cbf 100%);
                    }

                    &.SENA {
                        background: linear-gradient(to right,#209d4d 25%, #1b5cbf 100%);
                    }

                    &.UKLO {
                        background: linear-gradient(to right,#e52a30 25%, #1b5cbf 100%);
                    }

                    &.IREL {
                        background: linear-gradient(to right,#07865b 25%, #1b5cbf 100%);
                    }

                    &.PRIM {
                        background: linear-gradient(to right,#f94045 25%, #1b5cbf 100%);
                    }

                    .lottery-info-container {
                        .lottery-info {
                            h2 {
                                color: #ffffff;
                                line-height: 1.2;

                                &.lottery-jackpot {
                                    font-size: 1.8rem;
                                }
                            }

                            p {
                                color: #ffffff;
                                margin: 0;
                            }
                        }
                    }

                    .lottery-time {
                        color: #fff;
                        font-size: 1rem;
                        font-weight: 600;
                        text-align: center;

                        .timer-countdown {
                            font-size: 1.4rem;
                            display: block;
                        }
                    }

                    .play-now {
                         a {
                            &.button {
                                font-size: 1.2em;
                                min-width: 220px;
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1024px) {
                section {
                    &.banner-cta {
                        padding: 1rem 3rem !important;
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                section {
                    &.banner-cta {
                        flex-direction: column;
                        padding: 1rem;
                        align-items: center;
                        max-width: 100%;
                        .lottery-info-container {
                            flex-direction: column;

                            img {
                                max-width: 6rem;
                            }

                            .lottery-info {
                                //  padding-left: 1rem;
                                .lottery-name {
                                    font-size: 1.2rem !important;
                                    margin: auto;
                                }

                                .lottery-jackpot {
                                    font-size: 1.7rem !important;
                                    margin: auto;
                                }

                                p {
                                    line-height: 1;
                                    font-size: .85rem;
                                    margin: auto;
                                }
                            }
                        }

                        .lottery-time {
                            margin: .5rem auto;

                            .timer-countdown {
                                font-size: 1.4rem !important;
                            }
                        }

                        .play-now a.button {
                            margin-top: 1rem;
                            font-size: 1em;
                        }
                    }
                }
            }

            h2 {
                color: $blue;
            }

            a {
                &.download-pdf {
                    color: $blue;
                    font-weight: 600;
                }
            }

            table {
                border: 1px solid $darkGrey;
                width: 100%;
                .circle {
                    display: inline-block;
                    height: 19px;
                    width: 19px;
                    margin: auto;
                    border-radius: 50%;
                    vertical-align: middle;

                    &.cyan {
                        background: #6cccdc;
                    }

                    &.yellow {
                        background: #f4ec1f;
                    }
                }

                tr {
                    th {
                        background: $lightBlue;
                        padding: 10px 0;
                        color: #ffffff;
                        text-align: center;

                        &.special {
                            background: $blue;
                        }
                    }

                    td {
                        text-align: center;
                        padding: 10px 0px;
                        font-weight: 600;
                        border: 1px solid;

                        &.border-right-none {
                            border-right: 1px solid $white;
                        }
                    }
                }
            }

            ol {
                li {
                    color: #333 !important;
                    padding: 5px 0;
                }
            }
        }
    }
}

    @media only screen and (max-width: 860px) {
        .landing-page {
            .container {
                display: block;
                padding: rem(50) 0 0 0;

                .landing-left {
                    .landing-lotteries {
                        flex-wrap: wrap;
                        flex-direction: column;

                        .landing-lottery {
                            &:first-of-type {
                                margin-right: 0;
                            }

                            &:last-of-type {
                                margin-left: 0;
                            }
                        }
                    }
                }

                .landing-right {
                    margin: rem(50) 0 0;
                    width: 100%;
                    flex: 0 0 100%;

                    .landing-lottery {
                        display: none;
                    }
                }
            }
        }
    }

$desktop-image: 'titans.png';
$mobile-image: 'titans-mobile.png';
$image-path: '/assets/images/landing/titans/';

.landing-page-template {

    h1 {
        color: #fff;
        font-weight: 400;
        padding: 0;
        margin: 0;
        text-align: left;
        line-height: 1.2;
        font-size: 6vw;


        @media (max-width: 767px) {
            text-align: center;
            font-size: 8vw;
        }

        @media (max-width: 600px) {
            font-size: 6vw;
        }
    }

    h2 {
        color: #ffc82e;
        width: 100%;
        font-size: 42px;

        @media (max-width: 600px) {
            font-size: 4vw;
            max-width: 100% !important;
            margin: 1rem auto;
            line-height: 1.5;
        }
    }

    h3 {
        color: #fff;
        font-size: 28px;
        max-width: 90%;
        text-align: center;
        margin: 2rem auto;

        @media (max-width: 767px) {
            max-width: 100%;
            line-height: 1.2;
            margin: 2rem auto;
        }

        @media (max-width: 600px) {
            font-size: 4vw;
            max-width: 100% !important;
            margin: 1rem auto;
            line-height: 1.5;
        }
    }

    .landing-banner {
        padding: 2rem 0;
        max-width: 80rem;
        position: relative;
        width: 100%;
        background-size: cover;
        background-position: center;

        .container {
            padding: 0 2rem;
            max-width: 80rem;
            position: relative;
            width: 100%;
        }

        @media (min-width: 767px) {
            padding-top: 56.25%;
            background-image: var(--desktop-image);
        }

        @media (max-width: 767px) {
            padding-top: 100%;
            background-image: var(--mobile-image);
        }
    }

    .content-left {
        flex: 1 1 100%;
        box-sizing: border-box;

        @media (min-width: 767px) {
            max-width: 45%;
        }
    }

    #banner-content {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, 0%);
        text-align: center;
        z-index: 10;
        padding-top: 3rem;

        @media (max-width: 767px) {
            padding: 1rem;
        }
    }

    .logos {
        width: 100%;
        max-width: 250px;
        padding-bottom: 1rem;
        display: flex;

        @media (max-width: 767px) {
            max-width: 150px;
            margin: auto auto 1rem;
            padding: 0;
        }
    }

    .button-cta {
        background: #ffc82e;
        border-radius: 100px;
        padding: 1rem 3rem;
        color: #333333;
        font-weight: bold;
        width: fit-content;
        font-size: 2rem;

        @media (max-width: 767px) {
            position: absolute;
            font-size: 1.5rem;
            left: 50%;
            top: 85%;
            transform: translate(-50%, -50%);
        }

        @media (max-width: 462px) {
            font-size: 1rem;
            padding: 1rem 2rem;
        }
    }

    .section-content {
        padding-top: 1rem;

        @media (max-width: 767px) {
            padding-top: 0rem;
        }
    }

    .vip {
        width: 100%;
        max-width: fit-content;
        margin: 1rem auto;

        @media (max-width: 767px) {
            margin: 1rem auto auto;
        }
    }


    .w-full {
        width: 100%;
    }

    .image-section {
        padding-top: 3rem;

        @media (max-width: 767px) {
            padding-top: 2rem;
        }

        @media (max-width: 600px) {
            padding-top: 1rem;
        }
    }

    .lottery-logos {
        max-width: fit-content;
    }

    .tlo-images {
        gap: 3rem;
        padding: 2rem 0;

        @media (max-width: 767px) {
            gap: 1rem;
            padding: 1rem 0;
            flex-direction: column;

            svg, .lottery-logos {
                width: 75% !important;
                margin: auto;
            }
        }
    }

    .terms {
        font-size: 14px;
        display: block;
        color: #fff;

        @media (max-width: 600px) {
            font-size: 12px;
        }
    }

    .desktop-only {
        display: block;

        @media (max-width: 767px) {
            display: none !important;
        }
    }

    .mobile-only {
        display: none !important;

        @media (max-width: 767px) {
            display: block !important;
        }
    }
}

