.lottery-card {
    background-color: white;
    padding: rem(25) rem(10);
    border-radius: rem(10);
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.2);
    overflow: hidden;
    position: relative;
    max-width: rem(360);
    margin: 0 auto;
    flex: 0 0 auto;
    width: 100%;

    &.card-gradient {
        background-image: linear-gradient(to bottom right, #F5993C 0%, #F76B1C 100%);
        text-align: center;
        padding: rem(50) rem(30);

        p {
            color: white;
            font-size: em(18);
        }

        h2 {
            font-size: em(30);
            line-height: 1.3;
        }
    }

    .card-banner {
        width: 170px;
        background: $red;
        position: absolute;
        top: 21px;
        left: -40px;
        text-align: center;
        font-size: 12px;
        letter-spacing: 1px;
        padding: rem(5) 0;
        transform: rotate(-30deg);
        z-index: 99;
        color: white !important;

        &.joker {
            background: $androidGreen;
        }
    }

    .card-trash {
        position: absolute;
        right: rem(15);
        top: rem(15);
        width: rem(23);
        height: rem(23);
        cursor: pointer;
        z-index: 99;
    }


    .title {
        color: $orange;
        font-size: em(42);
        font-weight: 400;
        margin-bottom: rem(20);
        letter-spacing: 0;
    }

    .icon-return {
        &:before {
            content: url('../icons/icon-return.svg');
            position: absolute;
            top: rem(15);
            right: rem(15);
            cursor: pointer;
        }
    }

    .lottery-card--header {
        display: flex;
        max-width: 90%;
        margin: 0 auto;
        text-align: left;
        align-items: center;
        margin-bottom: rem(30);

        img {
            width: rem(41);
            height: rem(41);
            margin: 0 rem(15) 0 0;
        }

        h2 {
            margin: 0 0 rem(5);
        }

        p {
            font-size: em(16);
            font-weight: 700;
            margin: 0;
        }
    }

    .lottery-card--content {
        max-width: 95%;

        @supports (-ms-ime-align:auto) {
            max-width: 75%;
        }

        margin: 0 auto;
    }

    .lottery-flag {
        margin: 0 auto rem(20);
        display: block;
    }

    h2 {
        font-size: em(20);
        color: $blue;
        font-weight: 700;
        letter-spacing: rem(0.5);
        margin: 0 0 rem(5);
    }

    h3 {
        font-size: 1.3rem;
        color: $blue;
        line-height: 2;
        margin: 0 auto;
    }

    p {
        color: $blue;
        font-size: em(18);
        margin: 0 0 rem(10);
        font-family: $openSans;

        a {
            color: inherit;
            text-decoration: underline;
            display: inline;
        }

        &.orange {
            color: $orange;
            font-size: em(13);
        }
    }

    .card-icon {

        svg {
            margin: 0 auto rem(25);
            display: block;
            height: rem(50);
            width: auto;
        }
    }

    .lottery-card--amount {
        color: $orange;
        font-size: em(38);
        margin: 0 0 rem(10);
        position: relative;
        display: inline-block;
        letter-spacing: 0;
        font-weight: 900;
        line-height: 1;
        font-family: $proximaSoft;

        .lottery-card--currency {
            position: relative;
            top: rem(-10);
            right: 0 !important;
        }

        &.extra-tag {
            max-width: 95%;
            font-size: 1.5rem !important;
        }
    }

    .lottery-card--amount-pending {
        font-size: em(28) !important;
        padding-top: rem(10);
    }

    .lottery-card--amount-thin {
        color: $orange;
        font-size: em(42);
        display: flex;
        justify-content: center;
        line-height: 1;
        margin: rem(20) 0;

        span {
            font-size: 45%;
            margin-right: rem(5);
        }
    }

    .lottery-card--currency {
        color: inherit;
        font-size: 50%;
        position: absolute;
        right: 100%;
        margin-right: rem(5);
        line-height: 1.3;
        font-weight: 700;
    }

    .button {
        margin-top: rem(16);
    }

    .read-more {
        margin-top: rem(15);
        color: $blue;
        font-weight: 500;
        display: inline-flex;
        justify-content: center;
    }

    &.lottery-card-offset {
        margin-bottom: rem(-75);
        position: relative;
        top: rem(-75);
    }

    &.lottery-card-transparent {
        background: transparent;

        .flipper {
            background: transparent;
            border: 2px solid white;
        }
    }

    &.lottery-card-left {
        text-align: left;
    }

    &.no-sides {
        padding: rem(25) 0 !important;

        .card-container {
            padding: 0 rem(10);
        }
    }

    &.lottery-card-contact {
        padding: rem(40) rem(20);

        img {
            max-width: rem(200);
        }

        h3 {
            font-family: $openSans;
            font-size: em(22);
            font-weight: 600;
            margin: rem(30) 0 rem(10);
        }

        p {
            font-size: em(14);
            font-weight: 400;
            font-family: $openSans;
            color: $blue;
            line-height: 1.5;

            a {
                color: inherit;
                font-size: inherit;
                text-decoration: none;
            }

            span {
                font-weight: 600;
            }
        }

        > a {
            font-size: em(14);
            font-weight: 600;
            font-family: $openSans;
            color: $blue;
            line-height: 1.3;
            text-decoration: underline;
            max-width: rem(220);
        }
    }

    &.lottery-card-privacy {
        text-align: left;
        padding: rem(20) rem(15);

        h3, p {
            font-family: $openSans;
            font-size: em(14);
            font-weight: 600;
            color: #4C4D4A;
        }

        h3 {
            margin: 0 0 rem(5);
        }

        p {
            font-weight: 400;
            margin-bottom: rem(20);

            &.bold {
                font-weight: 600;
            }

            a {
                color: inherit;
                line-height: 1;
                text-decoration: underline;
            }
        }
    }

    &.lottery-card--basic {
        padding: rem(25) rem(10) !important;

        * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.lottery-card-pending {
        background-color: $lightGrey;
    }

    &.lottery-card--grey {
        text-align: left;

        .radio-container {
            margin: rem(25) 0 0 !important;

            &.over-limit {
                opacity: .5;
                cursor: not-allowed;

                &:active {
                    pointer-events: none;
                }
            }

            &.draw-reintegro {
                margin: auto auto auto !important;
                justify-content: flex-start;

                label {
                    flex: 0 0 25%;
                }
            }
        }

        p {
            color: $grey;
            margin: 0;
            font-size: em(16);
            font-weight: 600;

            &.orange {
                color: $orange;
                font-size: em(18);
                font-weight: 600;
                margin-bottom: rem(15);
            }
        }

        &.flip {
            .card-flip:before, .card-flip:hover {
                background-color: transparent !important;
            }
        }

        .flipper {
            box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);

            .card-flip {
                top: rem(27.5);
            }

            .lottery-card-front, .lottery-card-back {
                padding: rem(25) rem(85) rem(30) rem(30);
            }

            .lottery-card-back {
                background-color: rgba(0,0,0,0.05);
            }
        }
    }

    &.lottery-card--works {  
        background-color: #fff;        
        svg, img {
            margin: 0 auto 1rem;
     /*       height: rem(50);
            min-width: rem(50);*/
        }

        img {
            height: rem(40);
            margin-bottom: rem(30)
        }

        p {
            color: $grey;
            font-size: 1rem;
            margin: 0 auto;
            text-align:left;
            border-top:1px solid $grey;
            padding-top:1rem;
            max-width:90%;
            line-height:1.2;
        }
    }

    &.lottery-card-flip-reintegro {
        margin-top: 1rem;
        margin-bottom: 1rem;

        .lottery-card-front {
            padding: 1.5625rem !important;
        }

        &.hidden {
            display: none;
        }

        &.shown {
            display: block;
        }

        p.orange {
            max-width: 95%;
        }
    }
}

.lottery-card--combo {
    margin-bottom: rem(10);

    p {
        font-weight: 600;
    }

    h2 {
        margin-bottom: 0;
        display: flex;
        justify-content: center;
    }

    .col {
        &:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            img {
                max-width: 7rem;

                @media only screen and (max-width: 600px) {
                    max-width: 5rem;
                }
            }
        }
    }

    .lottery-combo--jackpot-title {
        margin: rem(10) 0 0;
        color: #E25F2C;
        font-size: 1em;
    }

    .lottery-card--amount {
        padding: 0;
        margin: 0 0 rem(10) 0;
    }

    .close-time {
        margin: rem(16) 0 0;
        font-size: 1rem;
        font-family: $proximaSoft;
        font-weight: 400;
    }

    .info-icon {
        margin-left: rem(10);
    }

    .info-data {
        max-height: 0;
        overflow: hidden;
        transition: max-height ease-in-out .2s;

        p {
            margin: 0;
            font-weight: 400;
            font-size: em(14);
        }
    }
}

    @media only screen and (min-width: 860px) {
        .lottery-card--combo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2rem;
            margin-bottom: 2rem;

            h2 {
                justify-content: flex-start;
            }

            .col {
                text-align: left;

                &:first-child {
                    width: 10%;
                    justify-content: space-around;
                    margin-bottom: 0;
                }

                &:nth-child(2) {
                    width: 40%;
                }

                &:nth-child(3) {
                    p:first-child {
                        margin: 0;
                        padding-bottom: rem(10);
                    }

                    p:last-child {
                        margin: 0;
                        padding-top: rem(10);
                    }
                }

                .button {
                    margin: 0;
                }
            }

            .lottery-card--amount {
                margin: 0;
            }
        }
    }

    .lottery-card--row {
        border-top: 1px solid white;
        margin-top: rem(40);
        padding-top: rem(20);

        .container {
            > h3 {
                flex: 0 0 100%;
                width: 100%;
                color: white;
                font-size: em(22);
                letter-spacing: 0.5px;
                font-weight: 400;
                margin-bottom: rem(30);
            }

            .lottery-card {
                margin-bottom: rem(15);


                p.lottery-card--amount {
                    font-size: 2em;
                }
            }
        }
    }

    @media only screen and (min-width: 415px) {
        .lottery-card {
            min-width: rem(300);

            .devices-only {
                display: none !important;
            }
        }
    }

    @media only screen and (min-width: 560px) {
        .lottery-card {
            &.lottery-card-offset {
                margin-bottom: -20vw;
                top: -20vw;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        .lottery-card--row {
            .container {
                display: flex;
                flex-wrap: wrap;
                max-width: rem(630);

                .lottery-card {
                    flex: 0 0 40%;
                }
            }
        }
    }

    @media only screen and (min-width: 980px) {
        a.lottery-card {
            &:hover {
                opacity: 1;

                .button {

                    &.button-orange {
                        background-color: $orange;
                        color: white;
                    }
                }


                .dropdown-link {
                    opacity: 0.6;
                }
            }
        }
    }

    @media only screen and (min-width: 1280px) {
        .lottery-card--row {
            .container {
                max-width: 100%;
                justify-content: space-between;

                .lottery-card {
                    max-width: rem(280);
                    flex: 0 0 rem(280);
                    min-width: 0;
                    margin: 0;
                }
            }
        }
    }

    @keyframes flipOpacity {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @media only screen and (max-width: 980px) {
        .account-draws {
            .sort-container {
                width: 50% !important;
                grid-template-columns: auto !important;

                .draws-sort {
                    margin: 10px auto auto !important;
                }
            }
        }

        .lottery-card {
            min-width: rem(300) !important;
            max-width: 100%;

            .desktop-only {
                display: none !important;
            }

            .devices-only {
                display: block !important;
            }

            &.lottery-card-results {
                .draw-card--header {
                    display: grid !important;
                    margin: 0 auto !important;

                    img {
                        &.lottery-flag {
                            margin: 0 auto 1rem auto;
                        }
                    }

                    .draw-details {
                        text-align: center;
                        margin: auto !important;

                        .devices-only {
                            display: block;
                            margin-top: 10px;

                            .draw-card--winnings > div {
                                min-width: 17rem !important;
                            }

                            &.button {
                                margin: 10px auto;
                            }

                            a {
                                text-align: center;
                                display: block;
                                line-height: 1.5;
                            }
                        }

                        p {
                            text-align: center;
                            font-size: 1rem;

                            &.lottery-price {
                                font-size: 2rem;
                            }
                        }
                    }
                }

                .results-content {
                    &.active {
                        margin: 1rem auto !important;
                    }

                    .lottery-card-flip, .majorwin {
                        display: none;
                    }
                }
            }

            &.lottery-card--combo {
                .close-time {
                    margin: rem(6) 0 rem(16);
                }
            }
        }
    }
