section {
    &.video-section {
        padding: rem(30) 0;
        background: #f8f8f8 !important;

        .container {
            > h2 {
                color: $blue;
                font-size: 24px;
                margin: 0 auto;

                svg {
                    max-width: 2.5rem;
                    position: relative;
                    margin-right: 15px;
                    top: 10px;
                }
            }

            h3 {
                color: $blue;
                font-weight: 400;
                font-size: 18px;
                margin: .5rem auto 1rem;
            }

            .video-frame {
                padding: 1rem 0 0;

                iframe {
                    width: 600px;
                    height: 335px;
                }
            }

            @media only screen and (max-width: 600px) {
                width:100%;
            }
        }
    }
}
