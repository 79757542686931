.lottery-signup {
	background: linear-gradient(to top left, #097EDD 0%, #2194EA 50%, #36A8F6 100%);
	padding: rem(40) 0 rem(30);

	* {
		color: white;
	}

	h3 {
		margin-top: rem(15);
		font-size: em(24);
	}

	form {
		margin: rem(30) auto 0;
		max-width: rem(360);

		.input-row {
			justify-content: center;
			max-width: 100%;
			display: block;

			input {
				max-width: rem(230);
				width: 100%;
			}

			button {
				background-color: white;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				width: rem(15);
				height: rem(15);
				padding: 0;

				svg {
					height: rem(14);
					width: auto;
					margin: 0 auto;
				}
			}
		}
	}
}

#lottery-signup-banner {
    color: white;
    background-image: linear-gradient(to right, #F5993C, #F76B1C);
    padding: rem(20) 0;
    border-radius: 8px;
    margin-bottom: 1rem;

    svg {
        margin-right: rem(20);
    }

    .lottery-signup-banner-row {
        display: flex;
        justify-content: center;
    }

    .lottery-signup-banner-mobile {
        display: none;
    }

    h3 {
        color: white;
        font-size: em(28);
        font-weight: 700;
        margin: rem(7) rem(40) 0 0;
    }

    @media only screen and (max-width: 600px) {
        margin:1rem;
    }
}

#lottery-signup-banner {
	&.signup-banner-reverse {
		background: white;

		h3 {
			color: $orange;
		}
	}
}

@media only screen and (max-width: 760px) {
	#lottery-signup-banner {
		.lottery-signup-banner-desktop {
			display: none;
		}

		.lottery-signup-banner-mobile {
			display: block;
		}

		.button {
			padding: rem(12) rem(15) rem(14);
		}
	}
}
