.lottery-deck-tab {
    .select-dropdown {
        margin: 0 0 rem(20);
    }
}

.lottery-deck {
    display: flex;
    flex-wrap: wrap;
    overflow: visible;
    width: 100%;

    &.lottery-slider--slider {
        display: block;
        padding: 0 !important;
    }

    .lottery-card.lottery-deck--card {
        flex: 0 0 48.5%;
        margin-right: 3%;
        margin-bottom: 3%;
        display: flex;
        flex-direction: column;
        padding: rem(20);
        min-width: 0;
        max-width: 100%;

        .lottery-flag {
            width: rem(38);
            height: rem(38);
            margin-bottom: rem(10);
        }

        h2 {
            font-size: em(18);
            line-height: 1.2;
            margin: 0 0 rem(15);
        }

        .lottery-card--amount {
            font-size: em(22);

            span {
                right: 75%;
            }
        }

        .add-quickplay {
            margin-top: auto;
        }

        .cart-qp {
            color: $orange;
            font-size: em(22);
            font-weight: 900;
            font-family: $proximaSoft;
            margin: 0 0 rem(10);
            line-height: 1;
        }

        .read-more {
            margin-top: auto;
        }

        .timer {
            font-size: em(12) !important;
            margin-top: auto;

            + .button {
                margin-top: 0;
            }

            svg {
                flex: 0 0 rem(15);
                margin-right: rem(10);
            }
        }

        &:nth-of-type(even) {
            margin-right: 0;
        }

        .flipper {
            padding: 0 !important;
            display: flex;
            justify-content: center;

            .card-flip {
                transition: opacity ease-in 0s !important;
            }

            h3 {
                color: white;
                font-size: em(13);
                font-weight: 400;
                line-height: 1.3;
                margin-bottom: rem(15);
            }


            .flip-trigger {
                color: white;
                text-decoration: underline;
                font-size: em(14);
                font-weight: 400;
                margin-top: auto;
                top: rem(10);
                right: rem(10);
            }

            .lottery-card-front {
                padding: rem(15) rem(10);
                display: flex;
                flex-direction: column;
            }

            .lottery-card-back {
                margin-top: rem(40);
                background: white;
                padding: rem(0) rem(10) rem(15);
                font-size: initial;
                width: 100%;
                border-radius: rem(10);

                p {
                    color: $orange;
                    font-weight: 400;
                    font-size: em(13);
                }

                .lottery-numbers-container {
                    margin-bottom: rem(10);

                    p {
                        font-size: em(12) !important;
                        margin-bottom: rem(5);
                    }

                    .lottery-numbers {
                        margin-bottom: rem(2.5);

                        .lottery-number {
                            margin: 0 rem(1) 0 0;
                            width: calc((100% - (6 * #{rem(1)})) / 7);

                            span {
                                font-size: em(10);
                            }
                        }
                    }
                }
            }
        }

        &.flip {
            .card-flip {
                left: rem(10) !important;
                top: rem(10) !important;
            }
        }

        .timer-countdown {
            font-size: 10px;
        }
    }

    &.lottery-deck--quickplay {
        justify-content: center !important;

        &.my-numbers {
            .lottery-card {
                .play-game--draws {
                    .draw-multipliers {
                        margin: 0 !important;
                    }

                    .radio-container {
                        padding: 0;
                    }
                }
            }
        }
    }
}

#play-now--quick-play {
    .container {
        .play-game--quickplay {
            > h2 {
                color: white;
                margin-bottom: rem(30);
                font-weight: 400;
                font-size: em(22);
                letter-spacing: 0.5px;
            }

            > p {
                color: white;
                margin: rem(20) 0 rem(25);
            }
        }
    }
}

.is-superdraw {
    #play-now--quick-play {
        .play-game {
            .button {
                margin-bottom: 1.5rem;
            }
        }
    }
}

.lottery-card--quickplay {
    margin-bottom: rem(20);
    text-align: left;
    position: relative;
    padding: rem(20) rem(30);
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    min-width: 0;
    margin: 0 0 rem(20);
    flex: 0 0 100%;
    transform-style: preserve-3d;

    &.popular {
        overflow: visible;

        &:before {
            content: 'POPULAR';
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(10%, -60%);
            background-color: #F8E71C;
            box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
            color: #E25F2C;
            font-size: 14px;
            border-radius: 5px;
            padding: 7.5px 15px;
            letter-spacing: 1px;
        }
    }

    p {
        font-weight: 600;
        margin: 0;
        font-family: $proximaSoft;

        &.orange {
            font-size: em(22);
            letter-spacing: 0;
        }
    }

    &.multiplier {
        p {
            padding-left: rem(35);
        }
    }

    .card-banner {
        top: rem(13);
        left: rem(-47);
    }

    .icon-cart, img {
        position: absolute;
        right: rem(10);
        top: rem(10);
        width: rem(50);
        height: auto;
        max-height: rem(50);
        transform: scale(0);
        animation: scaleUp 0.2s ease-in-out 0.05s;
        animation-fill-mode: forwards;
        transform-style: preserve-3d;

        ellipse {
            display: none;
        }

        #cart-arrow {
            position: relative;
            transform-style: preserve-3d;
            transform: scaleY(1);
        }
    }

    img {
        width: rem(35);
        top: rem(18.5);
        right: rem(18.5);
    }

    .icon-check-circle {
        stroke: $green;
        position: absolute;
        right: rem(22.5);
        top: rem(24.5);
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        transform-style: preserve-3d;
        transform: scale(0);
    }

    .add-quickplay {
        color: $orange;
        text-align: center;
        font-size: em(11);
        margin: rem(15) auto 0;
        text-decoration: underline;
        display: block;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .icon-cart, img {
            transform: scale(1);
        }
    }

    &.active {
        animation: scaleCard 0.75s ease-in-out 0s;

        .icon-cart, img {
            animation: cartAnimation 0.75s ease-in-out 0.05s;
            animation-fill-mode: forwards;

            #cart-arrow {
                transform: scaleY(1.5);
                transition: all ease-in-out 0.3s;
            }
        }

        .icon-check-circle {
            animation-name: dash;
            animation-duration: 0.4s;
            animation-delay: 0.4s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
            transform: scale(1);
        }

        @supports (-ms-ime-align: auto) {
            .icon-cart, img {
                transform: scale(0);
            }

            .icon-check-circle {
                stroke-dasharray: 100;
                stroke-dashoffset: 200;
                transition: all ease-in-out 0.4s 0.4s;
                transform: scale(1);
                /*animation: none;*/
            }
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .icon-cart, img {
                transform: scale(0);
                animation: none;
                transition: none;
                display: none;
            }

            .icon-check-circle {
                stroke-dasharray: 0;
                stroke-dashoffset: 0;
                transition: none;
                transform: scale(1);
                animation: none;
            }
        }
    }

    &.over-limit {
        opacity: .5;
        cursor: not-allowed;

        &:active {
            pointer-events: none;
        }
    }

    &.is-systementry {
        flex: auto !important;
        max-width: 840px;
        margin: auto auto 2rem;
    }

    .systementry-box {
        flex: auto !important;
        max-width: 860px;
        margin: auto .8rem 1rem;
        display: flex;
        justify-content: space-evenly;

        @supports (-ms-ime-align:auto) {
            justify-content: space-around;
        }

        @media only screen and (max-width: 980px) {
            max-width: 625px;
        }

        @media only screen and (max-width: 700px) {
            display: block;
            max-width: 100%;
            margin: 1rem auto 1rem -1rem;

            .icon-cart {
                top: 1.625rem !important;
            }
        }
    }

    #play-custom-text {
        display: block;
        max-width: 100%;
        padding-left: .5rem;
        border-left: 3px solid $orange;
        color: $grey;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;

        @media only screen and (max-width: 700px) {
            margin-left: -1rem;
        }
    }

    .is-label {
        padding: 5px;
        color: $white;
        text-align: left;
        text-transform: capitalize;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
        display: flex;
        max-width: 150px;
        font-size: .9rem;
        letter-spacing: 1px;
        position: absolute;
        min-width: 100px;
        text-align: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;

        &.sysentry {
            background: $orange;
        }
    }
}

    .popular {
        overflow: visible;

        &:before {
            content: 'POPULAR';
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(10%, -60%);
            background-color: #F8E71C;
            box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
            color: #E25F2C;
            font-size: 14px;
            border-radius: 5px;
            padding: 7.5px 15px;
            letter-spacing: 1px;
        }
    }

    @media only screen and (min-width: 420px) {
        .lottery-deck {
            .lottery-card {
                &.lottery-deck--card {
                    .timer-countdown {
                        font-size: em(17);
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 540px) {
        .button {
            &.add-quickplay {
                font-size: em(12);
                padding: rem(10) rem(5);
            }
        }
    }

    @media only screen and (min-width: 680px) and (max-width: 979px) {
        .lottery-card--quickplay {
            flex: 0 0 rem(270);
            max-width: rem(270);
            margin-right: rem(20);

            &:nth-of-type(2n + 2) {
                margin-right: 0;
            }
        }
    }

    @media only screen and (min-width: 980px) {
        #play-now--quick-play {
            .container {
                max-width: 100%;
                width: 100%;
            }
        }

        .lottery-card--quickplay {
            flex: 0 0 rem(265);
            max-width: rem(265);
            margin-right: rem(20);
            cursor: pointer;

            &:nth-of-type(3n+3) {
                margin-right: 0;
            }

            &:hover {
                background-color: rgba(255,255,255,0.85);

                .icon-cart, img {
                    transform: scale(1);
                }
            }

            &.active {
                background-color: white;

                &:hover {
                    transform: none !important;
                }
            }
        }
    }

    @keyframes cartAnimation {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(0);
        }
    }

    @keyframes scaleCard {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.05);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 200;
        }
    }

    @keyframes scaleUp {
        to {
            transform: scale(1);
        }
    }
