.faqs-information {
    padding: 1rem 0;

    a {
        color: $blue;
        font-weight: 600;
    }

    .single-game-img {
        max-width: 320px;
        display: block;
        margin: auto;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        > li {
            display: flex;
            text-align: left;
            padding: 1rem 0;

            > img {
                max-width: 10rem;
                margin-right: 3rem;
                align-self: flex-start;
            }

            div {
                //  padding-left: 1rem;

                &.accordion {
                    padding-left: 0rem;

                    div {
                        padding-left: 0rem;
                    }

                    .results-content {
                        p {
                            &:before {
                                display: none;
                            }
                        }
                    }
                }

                position: relative;

                h2 {
                    margin: 0;
                    font-size: 1.375em;
                    font-weight: 700;
                    color: $blue;

                    @media screen and (max-width: 600px) {
                        margin: auto;
                    }
                }

                p {
                    /*                    &:first-of-type {
                        &:before {
                            position: absolute;
                            height: 50px;
                            width: 3px;
                            left: 0;
                            background: #E25F2C;
                            display: block;
                            content: "";
                        }
                    }*/

                    line-height: 1.5;
                    max-width: 90%;
                }

                table {
                    border: 1px solid $darkGrey;
                    width: 100%;

                    tr {
                        th {
                            &:nth-child(even) {
                                background: $lightBlue;
                            }

                            color: #ffffff;
                            background: $blue;
                            text-align: center;
                        }

                        td {
                            text-align: left;
                            padding: 5px 10px;
                            border: 1px solid $grey;
                        }
                    }

                    &.joker-awards {
                        tr {
                            th {
                                vertical-align: middle;
                            }

                            &:nth-child(2) {
                                background-color: $orangeLight;

                                td {
                                    color: #ffffff;
                                }
                            }

                            td {
                                text-align: center;
                            }
                        }
                    }

                    &.mega-multiplier-table {
                        td {
                            text-align: center !important;
                        }
                    }
                }

                ul {
                    padding: 1rem;
                    list-style: disc;

                    li {
                        display: list-item;
                        padding: initial;
                    }
                }

                ol {
                    padding: 1rem;

                    li {
                        display: list-item;
                    }
                }
            }

            .instant-win-img {
                max-width: 100%;
                margin: auto auto 1rem auto;
                width: auto;
            }
        }
    }

    .accordion {

        h3 {
            text-align: left;
            color: $blue;
        }

        p {
            text-align: left;
        }

        .qa {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            .results-content {
                margin: 0;
            }

            .results-expand {
                display: block;
                cursor: pointer;

                &:after {
                    display: none;
                }

                h3 {
                    margin: 0;
                }
            }

            .results-expand.active + .results-content {
                max-height: 100% !important;
            }
        }
    }

    &.syndicates-information {
        .container {
            padding: 2rem 0;
            margin: 0 auto;
             width: 92.5%;
            max-width: rem(1180);

            > div {
                display: flex;
                text-align: left;
                padding: 1rem 0;

                > img {
                    max-width: 10rem;
                    margin-right: 3rem;
                    align-self: flex-start;
                }

                div {
                    // padding-left: 1rem;

                    &.accordion {
                        padding-left: 0rem;

                        div {
                            padding-left: 0rem;
                        }

                        .results-content {
                            p {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }

                    position: relative;

                    h2 {
                        margin: 0;
                        font-size: 1.375em;
                        font-weight: 700;
                        color: $blue;
                    }

                    p {
                        /*                        &:first-of-type {
                            &:before {
                                position: absolute;
                                height: 50px;
                                width: 3px;
                                left: 0;
                                background: #E25F2C;
                                display: block;
                                content: "";
                            }
                        }*/

                        line-height: 1.5;
                        max-width: 90%;
                    }

                    table {
                        border: 1px solid $darkGrey;
                        width: 100%;

                        tr {
                            th {
                                &:nth-child(even) {
                                    background: $lightBlue;
                                }

                                color: #ffffff;
                                background: $blue;
                                text-align: center;
                                line-height: 1.5;
                            }

                            td {
                                text-align: left;
                                padding: 5px 10px;
                                border: 1px solid
                            }
                        }
                    }

                    ul {
                        padding: 1rem;
                        list-style: disc;

                        li {
                            display: list-item;
                            padding: initial;
                        }
                    }

                    ol {
                        padding: 1rem;

                        li {
                            display: list-item;
                        }
                    }
                }
            }
        }
    }
}

    @media screen and (max-width: 1080px) {
        .faqs-information {
            padding: 0;

            .two-col {
                .col {
                    width: 100%;
                    margin: 1rem 0 0;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .faqs-information {

            ul {
                padding: 1rem 0;

                > li {
                    display: block;
                    text-align: center;

                    > img {
                        margin-right: 0;
                        max-width: 6rem;
                    }

                    div {
                        &.faq {
                            padding: 0;

                            h3 {
                                margin: auto;
                                text-align: center;
                            }

                            p {
/*                                &:first-of-type {
                                    &:before {
                                        display: none;
                                    }
                                }*/

                                max-width: 100%;
                                text-align: center;
                            }

                            ul, ol {
                                li {
                                    text-align: left;
                                }
                            }

                            .table-scroll {
                                overflow: scroll;
                            }
                        }
                    }
                }
            }

            &.syndicates-information {
                padding: 0 1rem;

                .container {
                    padding: 1rem 0;
                    width: 100%;

                    div {
                        display: block;
                        text-align: center;

                        @media screen and (max-width: 600px) {
                            h2{
                                margin:auto !important;
                            }
                        }
                            > img {
                                margin-right: 0;
                                max-width: 6rem;
                            }

                            div {
                                padding: 0;

                                h3 {
                                    margin: auto;
                                    text-align: center;
                                }

                                p {
                                    &:first-of-type {
                                        &:before {
                                            display: none;
                                        }
                                    }

                                    max-width: 100%;
                                    text-align: center;
                                }

                                ul, ol {
                                    li {
                                        text-align: left;
                                    }
                                }

                                .table-scroll {
                                    overflow: scroll;
                                }

                                &.faq {
                                    padding: 0 1rem;

                                    > div {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

@media screen and (max-width: 600px) {

    .mobile-table-scroll {
        padding:0 !important;
        overflow-x: scroll;
    }
}