/*	Notification 	*/

.notifications-container {
	position: fixed;
	z-index: 999;
	left: rem(10);
	bottom: rem(10);
	
	.notification {
		width: rem(150);
		// background: linear-gradient(to top left, #047ADA 0%, #39ABF7 100%);
		background: linear-gradient(to top left, #F5993C 0%, #F76B1C 100%);
		padding: rem(10);
		border-radius: rem(5);
		box-shadow: 0 0 1px 1px rgba(0,0,0,0.1);
		font-size: em(12);
		font-weight: 600;
		opacity: 1;
		text-align: left;
		position: relative;
		margin-bottom: rem(5);
		color: white;
		user-select: none;
		line-height: 1.2;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

@media only screen and (min-width: 760px) {
	.notifications-container {
		.notification {
			font-size: em(16);
			padding: rem(15);
			width: rem(260);
		}
	}
}