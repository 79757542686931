#banner-how-it-works {
	$gradient: #1a3d9b;
	$bgUrl: '/assets/images/how-it-works/banner-how-it-works.jpg';
	$bgmobileUrl: '/assets/images/how-it-works/banner-how-it-works-mobile.jpg';
	@include banner-generic($gradient, $bgUrl, $bgmobileUrl);

	.wrapper {
		span {
			padding-left: 3rem;
			line-height: 2;
		}
	}

	padding: 0 !important;

	.container {
		border-radius: 0 !important;
		max-height: 250px !important;
		min-height: 250px !important;
	}

	span.h3 {
		font-size: 1.75rem;
	}

	h1 {
		font-size: 2.875rem !important;
	}

	h2 {
		font-size: 21px;
		padding-left: 3rem;
		line-height: 2;
	}

	@media screen and (max-width: 600px) {
		padding: 0 !important;
		margin: 0 !important;

		.container {
			max-height: 150px !important;
			min-height: 150px !important;

			h1, span {
				padding-left: 0rem !important;
				margin: 0.5rem 0 0;
				line-height: 1;
			}

			padding: 0;
			margin: 0;
		}

		.wrapper {
			width: 100%;

			span.h3 {
				font-size: 21px;
				text-align: center !important;
			}

			h1 {
				font-size: 28px !important;
				text-align: center !important;
			}

			span.h4 {
				font-size: 1rem;
				text-align: center !important;
			}
		}

		h2 {
			text-align: center !important;
			line-height: 1.2;
			padding: 0;
			margin: 0.5rem 0 0;
			font-size: 1rem;
		}
	}
}

.how-it-works {
	padding-bottom: rem(20);
	box-shadow: none;
	border-radius: 0;
	margin: -3px 0 0 0;
	//padding: 0;

	h1 {
		color: white;
		font-weight: 400;
		margin: auto auto rem(25);
	}

	h2 {
		color: white;
		font-weight: 600;
		margin: 0 0 rem(25);
	}

	.lottery-deck {
		margin-top: rem(30);

		.lottery-card {
			&.lottery-card--works {
				width: 100%;
				flex: 0 0 100%;
			}
		}
	}
}

/*@media only screen and (min-width: 560px) {
	.how-it-works {
		.lottery-deck {
			.lottery-card {
				&.lottery-card--works {
					width: 48%;
					flex: 0 0 48%;
				}
			}
		}
	}
}*/

@media only screen and (min-width: 860px) {
	.how-it-works {
		.lottery-deck {
			justify-content:space-around !important;
			.lottery-card {
				&.lottery-card--works {
					max-width: 32%;
					flex: 0 2 32%;
					margin-right: 0;
					margin-left: 0;
					margin-bottom: rem(15);
				}
			}
		}
	}
}

@media only screen and (min-width: 980px) {
	.how-it-works {
		.lottery-deck {
			position: static !important;
			padding: 0;
			flex: 0 0 100%;

			&:first-of-type {
				margin-bottom: rem(10);
			}
		}
	}
}