.lottery-card-flip {
    perspective: 1000px;
    padding: 3px;
    box-shadow: none;
    align-items: stretch;
    background: transparent;

    .card-trash {
        right: rem(10);
        top: rem(15);
        opacity: 1;
        transition: all ease-in-out 0.2s 0.2s;
        z-index: 999;
    }

    .card-flip {
        position: absolute;
        top: rem(20);
        right: rem(20);
        color: $blue;
        width: rem(23);
        height: rem(23);
        font-size: em(20);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $blue;
        border-radius: 50%;
        z-index: 99999;
        transition: opacity ease-in 0.3s;
        cursor: pointer;

        &:before {
            content: 'i';
            font-weight: 700;
            font-size: 13px;
            color: $blue;
            font-family: $openSans;
        }
    }

    .flipper {
        transition: 0.6s;
        transform-style: preserve-3d;
        position: relative;
        background: white;
        border-radius: rem(10);
        box-shadow: 0 0 5px 2px rgba(0,0,0,0.2);
        height: 100%;


        &.no-padding {
            padding: 0;
            box-shadow: none;
            width: auto;

            .card-flip {
                border-color: white;
                background-color: transparent !important;

                &:before, &:after {
                    background-color: transparent !important;
                    color: white;
                }
            }
        }

        &.draw-card--winnings {
            padding: rem(10) 0;
            margin: rem(10) 0;

            > div {
                padding: 1rem;
                min-width: 20rem;
            }

            p {
                padding: 0;
            }
        }
    }


    .lottery-card-front, .lottery-card-back {
        backface-visibility: hidden;
        transition: transform ease-in-out 0.3s;
        padding: rem(30) 5% rem(25);
        overflow: hidden;
        border-radius: rem(10);
    }

    .lottery-card-front {
        transform: rotateY(0deg);
        z-index: 2;
        height: auto;

        .card-flip {
            position: static !important;
            margin-left: rem(10);
        }

        .lottery-date {
            display: flex;
            align-items: center;
        }

        &.superdraw {
            background-image: url('/assets/images/confetti-mobile-new.png');

            @media only screen and (min-width: 620px) {
                background-image: url('/assets/images/confetti-new.png');
            }

            > .lottery-flag {
                width: 4.5rem;
                height: 4.5rem;
                margin: 0 auto .5rem;
            }
        }
    }

    .lottery-card-back {
        -webkit-transform: translateZ(-1px);
        transform: rotateY(-180deg);
        height: 0;
        display: none;
        pointer-events: none;

        .subtotal {
            text-align: center !important;
        }

        &.lottery-card-back--spacing {
            padding-top: rem(50);
        }
    }

    &.flip {
        .card-flip {
            left: rem(20);
            border: none;
            animation: flipOpacity ease-in-out 0.2s;


            &:before {
                content: '';
                background-image: url('../icons/icon-return.svg');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                display: block;
                width: rem(25);
                height: rem(25);
                transform: rotateY(180deg);
                background-color: white !important;
            }
        }

        .card-trash {
            right: auto;
            left: rem(10);
            opacity: 0;
            transition: all ease-in-out 0s;
            visibility: hidden;
        }

        .draw-card--winnings {
            .card-flip {
                &:before {
                    background-image: url('../icons/icon-return-white.svg');
                }
            }
        }

        .flipper {
            transform: rotateY(180deg);
            background:transparent !important;
            .lottery-card-front {
                height: 0;
                display: none;
            }

            .lottery-card-back {
                height: auto;
                display: block;
                position: relative;
                z-index: 9999;
            }
        }
    }
}


@media only screen and (min-width: 980px) {
	.lottery-card-front {
		.flip-trigger {
			transition: background-color ease-in-out 0.2s;

			&:hover {
				background-color: $blue;

				&:before {
					color: white !important;

				}
			}
		}
	}
	

	.card-trash:hover {
		* {
			stroke: $red;
		}
	}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {	

	.lottery-card-flip {

		.flipper {
			overflow: hidden;

			.card-flip {
				z-index: 9999;
			}
			
			.lottery-card-back, .lottery-card-front {
				transform: none;
				height: auto;
				z-index: 999;
			}

			.lottery-card-front {
				transition: all ease-in-out 1s 1s !important;
				-webkit-transform: translate3d(0, 0, 0);
				-ms-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0 );
			}

			.lottery-card-back {
				opacity: 0;
			}
		}

		&.flip {
			.card-flip {
				left: rem(20);
				z-index: 9999;
			}

			.lottery-card-front {
				opacity: 0;
			}

			.lottery-card-back {
				transform: rotateY(180deg) !important;
				backface-visibility: visible !important;
				animation-name: flipOpacity;
				animation-duration: 0.3s;
				animation-delay: 0.3s;
				animation-fill-mode: forwards;
			}
		}

		&.flip-front {
			.lottery-card-front {
				opacity: 0;
				backface-visibility: visible !important;
				animation-name: flipOpacity;
				animation-duration: 0.3s;
				animation-delay: 0.3s;
				animation-fill-mode: forwards;
			}
		}
	}
}

@keyframes flipOpacity {
	to {
		opacity: 1;
	}
}