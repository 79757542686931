.results-type {
	font-weight: 700;
	color: $blue !important;

	span {
		color: $orange;
	}
}

.lottery-card {
    &.lottery-card--results {
        max-width: rem(600);

        .lottery-numbers-container {
            margin-bottom: rem(10);

            .lottery-numbers {
                .lottery-number {
                    max-width: 10vw;
                }
            }
        }
    }

    &.lottery-card-results {
        margin: 1rem auto 2rem auto;
        text-align: center;
        @keyframes snow {
            from {
                background-position: 0;
            }

            to {
                background-position: 0px -50px;
            }
        }

        .flipper > .flip-trigger {
            display: none;
        }

        &.flip {
            .flip-trigger {
                right: auto !important;
                left: rem(10) !important;
                display: block;
            }

            .game-type {
                display: none !important;
            }
        }

        p {
            font-size: em(16);
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .lottery-date {
            color: inherit;
            display: block;
            line-height: 1.5;
        }

        .draw-card--header {
            display: flex;
            text-align: left;
            align-items: center;
            margin: 0 auto rem(16);
            max-width: 100%;
            padding: 0 2rem;

            &.win {
                background-image: url("/assets/images/confetti-bg.png");
                animation: snow 10s linear infinite alternate;

                @keyframes snow {
                    from {
                        background-position: 0;
                    }

                    to {
                        background-position: 0px 400px;
                    }
                }
            }

            .lottery-flag {
                width: 5rem;
                height: 5rem;
                object-fit: contain;
                margin-right: 1rem;
                margin-top: 2rem;
            }

            .draw-details {
                flex: 1;
                margin-right: rem(10);

                h3 {
                    margin: 0;
                    font-size: 1.4rem;
                    font-weight: 700;
                    color: $grey;
                    margin: 0 0 rem(1);
                    line-height: 1;
                }

                .lottery-price {
                    margin: 0;
                    font-size: em(38);
                    font-weight: 800;
                    color: $orange;
                    line-height: 1.2;
                    font-family: $proximaSoft;

                    .syndicate-shares {
                        font-size: 1.2rem;
                        display: inline-block;
                        vertical-align: middle;
                        color: $blue;
                        font-weight: 500;
                    }
                }

                .syndicate-tag {
                    color: $grey;
                    font-weight: 600;
                    opacity: .6;
                }

                span {
                    &.free-ticket {
                        position: relative;
                        margin-top: 1px;
                        border: 2px solid $green;
                        background: $white;
                        color: $green;
                        text-transform: uppercase;
                        text-align: center;
                        display: inline-block;
                        padding: .5em 1em;
                        font-size: .75rem;
                        font-weight: 600;
                        border-radius: 2px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 4px;
                            height: 1em;
                            width: 0.5em;
                            border: 2px solid $green;
                            left: -2px;
                            border-radius: 0 1em 1em 0;
                            border-left-color: $green;
                            background-color: $green;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 4px;
                            height: 1em;
                            width: 0.5em;
                            border: 2px solid $green;
                            right: -2px;
                            border-radius: 1em 0 0 1em;
                            border-right-color: $white;
                        }
                    }
                }

                .lottery-price--jackpot-pending {
                    font-size: em(20);
                    padding-bottom: em(2);
                }
            }

            .ticket-details {
                display: grid;
                justify-content: flex-end;
                //.draw-cost, .draw-result {
                text-align: right;

                .draw-result {
                    font-weight: 700;
                    margin: auto 0 0;
                    font-size: 1.2rem;

                    &.win {
                        color: $orange;
                    }

                    &.no-win {
                        color: $grey;
                    }

                    &.pending {
                        color: $grey;
                    }

                    span {
                        display: block;
                    }
                }

                .button {
                    justify-self: end;
                }

                span {
                    line-height: 1.5;
                }
            }

            .draw-result {
                margin-top: 0;
            }
        }

        .lottery-numbers-container {
            margin-bottom: rem(10);

            .lottery-numbers {
                .lottery-number {
                    font-size: initial;

                    > span {
                        font-size: initial;

                        > span {
                            font-size: em(14);
                        }
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .draw-card--winnings {
            background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
            display: inline-flex;
            justify-content: center;
            border-radius: rem(10);

            h2 {
                color: $white !important;
            }

            p {
                text-align: center;
                display: flex;
                flex-direction: column;
                color: white;
                margin: 0;
                font-size: em(14);
                font-weight: 700;
                padding: rem(15) rem(60);
                line-height: 1;

                > span {
                    font-size: 137.5%;
                    margin-top: rem(10);
                    font-weight: 800;
                    letter-spacing: 0.5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .currency {
                        font-size: 75%;
                        margin-right: rem(2);
                    }
                }

                &.winnings-details {
                    text-align: left;
                    display: block;
                    margin: rem(10) 0;
                    font-weight: 400;
                    font-size: em(17);

                    span {
                        font-size: inherit;
                        display: inline;
                        font-weight: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }

        .draw-card--footer {
            margin: rem(25) 0;

            h4 {
                color: $orange;
                font-size: em(22);
                margin: 0 0 rem(5);
            }

            h5 {
                color: $orange;
            }

            p {
                color: $blue;
                font-size: em(14);
                font-weight: 400;
            }
        }

        .results-expand {
            justify-content: flex-start;
            margin-left: rem(0);
            margin-top: rem(-10);
            line-height: 1;
            justify-content: center;
            cursor: pointer;

            &:after {
                content: '';
                transform: rotate(90deg);
                margin-left: rem(5);
                margin-right: 0;
                transition: all ease-in-out 0.2s;
                top: 3px;
                width: rem(13);
                height: rem(13);
                background-color: $blue;
                background-image: url(../icons/icon-angle-right.svg);
                background-size: 45%;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 100%;
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                &:after {
                    top: 1px;
                    background-size: 10px;
                    width: rem(15);
                    height: rem(15);
                }
            }

            &.active {
                &:after {
                    transform: rotate(-90deg);
                }

                span {
                    color: $blue;
                    font-weight: bold;
                }
            }

            &.results-expand-sysentry {
                text-decoration: underline !important;
                padding-bottom: 1rem;
            }
        }

        .results-content {
            text-align: center;
            max-width: 95%;
            margin: 1rem auto auto;

            > p {
                font-size: em(16) !important;
                font-weight: 500;
                margin: 0 0 rem(15);
                line-height: 1;

                &.results-type {
                    font-weight: 700;
                    line-height: 2;

                    &.results-type-joker {
                        margin-top: 2rem;

                        span {
                            padding: 0 5px;
                            background-color: $androidGreen;
                            color: #ffffff;
                        }
                    }
                }
            }

            .results-expand {
                margin-top: 0;
                display: inline-flex !important;

                &.active, &:after {
                    display: flex;
                }
            }

            .joker-winnings-container {
                display: grid;
                grid-row-gap: 5px;
                justify-content: center;
                grid-template-columns: 30px auto;
                margin: 2rem auto auto;

                .joker-icon {
                    max-width: 25px;
                }

                .joker-winnings {
                    justify-self: start;
                    display: flex;
                    text-align: left;

                    p {
                        margin: 0 auto;
                        font-size: em(14);
                        font-weight: 600;

                        &:first-child {
                            margin: 0 auto;
                        }

                        margin: 0 5px;

                        .joker-number {
                            padding: 1px 5px;
                            letter-spacing: 1px;
                            color: $orange;

                            &.selected {
                                background-color: $orange;
                                color: white;
                            }

                            mark {
                                background-color: $androidGreen;
                                color: white;
                                padding: 0 0 0 2px;

                                &:last-child {
                                    padding: 0 2px 0 2px;
                                }
                            }
                        }

                        span {
                            &.instant-win-numbers {
                                background: $androidGreen;
                                padding: 5px;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }

            .purchase-meta {
                max-width: 95%;
                margin: 1rem auto;
                display: grid;
                justify-items: end;
                grid-row-gap: 0px;

                span {
                    line-height: 1.2;
                    display: block;

                    &.free-ticket {
                        position: relative;
                        margin-top: 1px;
                        border: 2px solid $green;
                        background: $white;
                        color: $green;
                        text-transform: uppercase;
                        text-align: center;
                        display: inline-block;
                        padding: .5em 1em;
                        font-size: .68rem;
                        font-weight: 600;
                        border-radius: 2px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 4px;
                            height: 1em;
                            width: 0.5em;
                            border: 2px solid $green;
                            left: -2px;
                            border-radius: 0 1em 1em 0;
                            border-left-color: $green;
                            background-color: $green;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 4px;
                            height: 1em;
                            width: 0.5em;
                            border: 2px solid $green;
                            right: -2px;
                            border-radius: 1em 0 0 1em;
                            border-right-color: $white;
                        }
                    }
                }
            }

            &.syndicates {
                .tables {
                    padding: 1rem 0 0;

                    .flex {
                        display: flex;

                        h5 {
                            margin: 1rem 0 !important;
                        }

                        &.title {
                            justify-content: center;
                        }
                    }

                    .four-col {
                        justify-content: center;
                        flex-wrap: wrap;

                        .col {
                            li {
                                display: flex;

                                .lottery-number {
                                    padding: 0;
                                    width: 100%;
                                    height: 100%;
                                    margin: auto 1%;
                                    padding: 1% 0;
                                    // padding:2.35%;
                                    border-radius: 50%;
                                    max-width: 28px;
                                    min-width: 28px;
                                    max-height: 28px;
                                    min-height: 28px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-weight: bold;

                                    &.number {
                                        color: $orangeLight;
                                        background: transparent;

                                        &.selected {
                                            background-color: $orangeLight;
                                            color: white;

                                            &.instant {
                                                background-color: $orangeLight;
                                                background-image: -webkit-linear-gradient(135deg,$androidGreen 50%, $orangeLight 50%);
                                                text-shadow: 0px 0px 1px #ffffff;
                                            }

                                            &.reintegro {
                                                background-color: $lightBlue;
                                                color: white;
                                            }

                                            &.bonus {
                                                background-color: $darkGrey;
                                            }

                                            &.jolly {
                                                background-color: $darkGrey;
                                            }
                                        }
                                    }

                                    &.lucky {
                                        background-color: transparent;
                                        color: $lightBlue;

                                        &.selected {
                                            background-color: $lightBlue;
                                            color: white;
                                            border: none;

                                            &:before {
                                                color: $lightBlue;
                                            }
                                        }
                                    }

                                    &.instant {
                                        background-color: $androidGreen;
                                        color: white;
                                    }

                                    &.superstar {
                                        background-color: $lightBlue;
                                        color: white;

                                        &.instant {
                                            background-color: $lightBlue;
                                            background-image: -webkit-linear-gradient(135deg,$androidGreen 50%, $lightBlue 50%);
                                            text-shadow: 0px 0px 1px #ffffff;
                                        }
                                    }

                                    &.bonus {
                                        background-color: $darkGrey;
                                        color: white;
                                    }

                                    &.reintegro {
                                        color: $lightBlue;
                                    }

                                    &.jolly {
                                        background-color: $darkGrey;
                                        color: white;

                                        &.instant {
                                            background-color: $darkGrey;
                                            background-image: -webkit-linear-gradient(135deg,$androidGreen 50%, $darkGrey 50%);
                                            text-shadow: 0px 0px 1px #ffffff;
                                        }
                                    }

                                    &.game-number {
                                        color: $grey;
                                        font-weight: normal;
                                    }

                                    &.IREL, &.UKLO {
                                        &.lucky {
                                            background-color: $darkGrey !important;
                                            color: white;
                                        }
                                    }
                                }
                            }

                            &:nth-child(n+5) {
                                padding-top: rem(32);
                            }

                            .instant-win {
                                justify-content: center;
                                align-content: center;
                                line-height: 2;
                                color: $grey;

                                span {
                                    &:first-child {
                                        margin-right: 5px;
                                    }
                                }
                            }

                            .info-data {
                                max-height: 0;
                                overflow: hidden;
                                transition: max-height ease-in-out .2s;
                                margin: 0 0 10px;
                                font-weight: 400;
                                font-size: em(14);
                            }

                            .bonus-grid {
                                display: flex;
                                justify-content: center;
                                background: #ffffff;
                                margin-bottom: 20px;

                                p {
                                    color: #ffffff;
                                    background: $androidGreen;
                                    margin: 0;
                                    font-weight: 600;
                                    font-size: em(14) !important;
                                    min-width: 30px;
                                    max-width: 30px;
                                    min-height: 30px;
                                    max-height: 30px;
                                    padding: 5px;
                                    text-align: center;

                                    &:first-child {
                                        padding: 5px 5px 5px 10px;
                                    }

                                    &:last-child {
                                        padding: 5px 10px 5px 5px;
                                    }
                                }
                            }

                            p {
                                margin: 0;

                                &.divisionwin {
                                    font-weight: bold;
                                    width: 100%;
                                    font-size: em(14) !important;
                                }
                            }
                        }
                    }
                }

                .selected-numbers-container {
                    &.title-selected-container {
                        justify-content: left;
                        max-width: 100%;
                        margin: 0;
                    }
                }



                @media screen and (max-width: 1000px) {
                    .four-col {
                        flex-wrap: wrap;

                        .col {
                            width: 100%;
                            padding: 0;

                            &.bonus-container {
                                &:nth-child(n+2) {
                                    padding-top: rem(16);
                                }

                                ul {

                                    li {
                                        &:last-child {
                                            border-bottom: 2px solid $lightGrey !important;
                                        }
                                    }
                                }
                            }

                            ul {
                                border-right: none !important;
                                padding: 0 !important;
                                max-width: 280px;
                                margin: auto;

                                li:last-child {
                                    border-bottom: none !important;
                                }
                            }
                        }
                    }
                }

                @media screen and (max-width: 768px) {
                    .tables {
                        .four-col {
                            display: block;

                            .col {
                                width: 100%;

                                &.bonus-container {
                                    &:nth-child(n+2) {
                                        padding-top: 2rem;
                                    }
                                }

                                ul {
                                    padding: 0;
                                }

                                .bonus-grid {
                                    margin-bottom: 1rem;
                                }
                            }
                        }
                    }
                }
            }

            &.SYN {
                .lottery-numbers-container {
                    &.selected-numbers-container {
                        &.title-selected-container {
                            margin: 0 auto !important
                        }
                    }
                }
            }

            &.SENA {
                h3 {
                    color: $blue;
                    margin: 0;
                    text-align: left;

                    + p {
                        text-align: left;
                    }
                }
            }

            .scroll-msg {
                font-style: italic;
                line-height: 2;
            }
        }

        .draw-dates {
            border-top: 1px solid #E5E5E5;

            h4 {
                color: $blue !important;
                font-size: em(16) !important;
                font-weight: 600 !important;
                margin: rem(10) 0 rem(15) !important;
            }

            .draw-date {
                margin: 0;
                font-size: em(16);
                font-family: $proximaSoft;
                font-weight: 400;
            }
        }

        .draw-date-single {
            margin: 0;
            font-size: em(16);
            font-family: $proximaSoft;
            font-weight: 400;
        }
    }

    .draw-date-single {
        margin: 0;
        font-size: em(16);
        font-family: $proximaSoft;
        font-weight: 400;
    }

    .number-lines-expand {
        width: 100%;
        justify-content: center;
    }

    .subtotal {
        color: $orange !important;
        font-size: em(22) !important;
        font-weight: 400;
        font-family: $proximaSoft !important;
        text-align: center;
        margin: rem(20) 0 0;
    }

    .currency-exchange {
        color: $darkGrey !important;
        font-size: em(14);
        text-align: left;
        margin: 0 auto;
        font-weight: 700;
        max-width: 480px;
        margin-top: rem(10);

        &.small{
            text-align:justify;
            font-size:0.75rem;
            font-weight:500;
        }
    }

    .order-number {
        margin-top: rem(20) !important;
        font-weight: 400 !important;
    }
}

.results-actions {
	padding: rem(10);
	margin: rem(20) auto auto;
	background-color: white;
	width: 95%;
	max-width: rem(795);
	border-radius: rem(10);
	justify-content: flex-start;
	box-shadow: 0 0 5px 2px rgba(0,0,0,0.2);
	flex-wrap: wrap;

	.disclaimer {
		flex: 0 0 100%;
		margin: rem(5) 0 !important;
	}

	p {
		color: $grey !important;
		font-size: em(12);
		display: flex;
		align-items: center;
		text-align: left;
		margin: rem(10) 1% !important;

		svg {
			width: rem(25);
			flex: 0 0 rem(25);
			height: rem(25);
			margin-right: rem(10);
		}

		&.active {
			color: $orange !important;

			svg {
				stroke: $orange;

				&.icon-check-circle {
					path {
						fill: inherit;
					}
				}

				ellipse {
					stroke: $orange;
				}

				path {
					fill: $orange;
					stroke: $orange;
				}
			}
		}
	}
}

.results-expand {
	position: relative;
	line-height: 1;
	display: flex-inline;
	align-items: center;
	justify-content: center;
	text-decoration: none !important;
	margin-top: rem(20);

	span {
		text-decoration: underline;
	}

	&:after {
		content: '';
		background-image: url(../icons/icon-angle-down.svg);
		background-size: 50%;
		background-position: center;
		background-repeat: no-repeat;
		background-color: $blue;
		border-radius: 50%;
		height: rem(15);
		width: rem(15);
		left: calc(100% + #{rem(20)});
		text-decoration: none;
		transition: all ease-in-out 0.2s;
		margin-left: rem(20);
		margin-top: 1px;
	}

	&.active {
		&:after {
			transform: rotate(-180deg);
		}

		+ .results-content {
			margin-top: rem(25);
			display: block;

			&.no-slide {
				max-height: 100% !important;
				max-width: 80%;
				margin: 1rem auto;
			}
		}
	}
}

.results-content {
	max-height: 0;
	overflow: hidden;
	transition: all ease-in-out 0.3s;

	&.no-slide {
		display: none;
	}

	.game-types {
		display: flex;
		align-items: center;
		justify-content: space-around;
		max-width: rem(300);
		margin: 0 auto;
		flex-wrap: wrap;
	}

	.game-type {
		display: flex;
		font-weight: 400 !important;
		justify-content: center;
		align-items: center;

		svg {
			margin-left: rem(5);

			* {
				stroke: $orange;
			}
		}
	}
}

.division-results {
	display: flex;
	text-align: left !important;

	div {
		margin-right: rem(15);
		flex: 1;
		display: flex;
		flex-direction: column;

		span {
			display: block;
			margin-top: rem(10);
			font-size: em(14);
			color: #312C29;
			font-family: $openSans;
			font-weight: 400;

			&.division-title {
				color: $blue;
				margin-bottom: auto;
				flex: 1 0 auto;
				font-weight: 700;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}

	> * {
		flex: 1;
		margin: 0 1%;
	}

	.flex-2 {
		flex: 1.5;
	}
}

@media only screen and (min-width: 860px) {
	.lottery-card {
		&.lottery-card-results {
			.lottery-card-back {
				padding-top: 0;
			}
		}
	}
}

@media only screen and (min-width: 980px) {
	.lottery-card {
		&.lottery-card--results {
			> .lottery-numbers-container {
				display: flex;
				max-width: rem(460);
				margin: 0 auto;
				justify-content: center;

				.lottery-numbers {
					width: auto;
					margin-right: rem(3);

					+ .lottery-numbers {
						margin-right: 0;
					}

					.lottery-number {
						width: rem(35);
						height: rem(35);
						padding: 0;
					}
				}
			}
		}
	}

	.division-results {
		max-width: rem(480);
		margin: 0 auto;
		position: relative;
	}

	.results-actions {
		display: flex;
		justify-content: space-around;

		&.results-actions--column {
			display: block;
			margin-top: rem(10);

			p {
				margin-bottom: rem(5) !important
			}
		}
	}
}

@media only screen and (max-width: 980px) {
	.lottery-card {
		&.lottery-card-results {
			.draw-card--header {
				.draw-details {
					.lottery-price {
						.syndicate-shares {
							display: block;
						}
					}

					span {
						&.is-label {
							margin: 1px auto auto;
						}
					}
				}
			}

			.ticket-details {
				span {
					display: block;
					text-align: center;
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.lottery-card {
		&.lottery-card-results {
			.draw-card--header {
				padding: 0;
			}
		}

		.division-results {
			div {
				span {
					font-size: em(12) !important;
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.lottery-card {
		&.lottery-card--results {
			#lottery-results--dynamic-content {
				> .lottery-numbers-container {
					.lottery-numbers {
						justify-content: center;
					}
				}
			}
		}
	}

	.results-expand {
		&.active {
			+ .results-content {
				&.no-slide {
					max-width: 100%;
				}
			}
		}
	}
}
