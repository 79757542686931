header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background: white;
    border-bottom: 2px solid rgba(0,0,0,0.1);
    user-select: none;

    &.noborder {
        border-bottom: none;
    }


    #header-strip {
        text-align: center;
        background-image: linear-gradient(to top, #F5993C 0%, #F76B1C 100%);
        padding: rem(5) 0;

        p {
            color: white;
            font-size: em(17);
            margin: 0;
            letter-spacing: 0.5px;
        }

        .desktop-only {
            display: block !important;
        }

        .devices-only {
            display: none !important;
        }

        @media only screen and (max-width: 600px) {
            .desktop-only {
                display: none !important;
            }

            .devices-only {
                display: block !important;
            }
        }
    }

    .nav-cart-container {
        position: relative;
        margin-left: rem(5);

        .nav-cart-qty {
            position: absolute;
            top: 0;
            right: 0;
            background-color: $orange;
            border-radius: 50%;
            font-size: 8px;
            width: rem(15);
            height: rem(15);
            text-decoration: none !important;
            transform: translate(40%, -40%);
            font-weight: 400;
        }

        svg {
            margin-left: 0 !important;
        }
    }

    #header-top {
        background-color: $brown;

        .container {
            #header-logo {
                width: 40vw;
                max-width: 350px;
            }

            #header-top-nav {
                list-style: none;
                transition: all ease-in-out 0.2s;

                > li {
                    padding: em(15) em(7.5);

                    &#header--sign-up, &#header--sign-in {
                        padding: 0;
                        margin: 5px;
                        color: white;

                        > .active {
                            padding: .25rem .75rem;
                            background-image: linear-gradient(to right, #F5993C, #F76B1C);
                            min-height: 2rem;
                            letter-spacing: normal;
                            font-weight: normal;
                            border: 0;
                            border-radius: 100px;

                            &:hover {
                                opacity: 1;
                            }
                        }

                        svg {
                            border: 0 !important;
                            flex: auto;
                            width: 25px;
                            height: 25px;
                            margin: 0 0 0 .25rem;
                            padding: .25rem;
                        }
                    }

                    &.not-verified-li {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }

                > * {
                    color: $lightGrey;
                    display: flex;
                    align-items: center;
                    font-size: em(14);
                    line-height: 1;
                    cursor: pointer;
                    position: relative;

                    svg {
                        width: rem(12);
                        height: rem(12);
                        flex: 0 0 rem(12);
                        border: 2px solid white;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 2.5px;
                        box-sizing: content-box;
                        margin-left: rem(5);
                        transition: all ease-in-out 0.2s;
                    }

                    img {
                        width: rem(26);
                        height: rem(26);
                        border-radius: 50%;
                        margin-right: rem(10);
                        border: 1px solid white;
                    }

                    &.nav-active {
                        color: $orange;
                        text-decoration: underline;

                        .nav-cart-container {
                            > svg {
                                border-color: $orange;

                                * {
                                    stroke: $orange !important;
                                }

                                path {
                                    fill: $orange;
                                }
                            }
                        }
                    }

                    .nav-title {
                        color: inherit;
                        margin: 0;
                    }
                }

                .top-nav--desktop-item {
                    position: absolute;
                    top: 70%;
                    right: rem(-10);
                    max-width: none;
                    z-index: -1;
                    visibility: hidden;
                    opacity: 0;
                    padding-top: rem(15);
                    transition: opacity ease-in-out 0.2s;
                    overflow: hidden;
                    height: 0;
                    max-width: 97.5vw;

                    .tab-content.active {
                        overflow: hidden;
                    }

                    .lottery-card {
                        box-shadow: none;
                        border: 1px solid $blue;
                        width: rem(400);
                        padding: rem(20) 0 !important;
                        max-height: calc(100vh - 100px);
                        overflow: auto;

                        .lottery-card-account--header {
                            padding-bottom: rem(15);
                        }

                        .button {
                            font-size: em(13);
                        }

                        input[type="submit"] {
                            padding: rem(10) rem(30);
                        }

                        .account-funds {
                            padding: 0 rem(20) rem(15);

                            .choose-plays {
                                padding-top: rem(15);
                            }
                        }

                        .account-nav-funds--footer {
                            padding: rem(10) rem(20) 0;
                            margin-top: rem(10);

                            h3 {
                                span {
                                    border: none;
                                    margin: 0 0 0 rem(25);
                                    font-size: em(16);
                                    color: $orange;
                                }

                                &:hover {
                                    color: $darkGrey !important;
                                }
                            }

                            .button-orange-solid, .button-blue-solid {
                                align-self: center;
                                padding: rem(10);
                                min-width: rem(175);

                                &:hover {
                                    background-position: right center;
                                }
                            }
                        }

                        .logout {
                            margin: rem(10) 0 0;
                            font-size: em(14);

                            a {
                                color: $orange;
                                font-weight: 600;
                                margin-left: rem(5);
                            }
                        }

                        .orange {
                            font-size: em(20);
                            font-weight: 600;
                            margin: rem(20) auto rem(10);
                            display: inline-block;
                        }
                    }

                    &:before, &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        bottom: calc(100% - 1px - #{rem(15)});
                        right: rem(25);
                        transform: translateX(50%);
                        border-width: 10px;
                    }

                    &:before {
                        border-width: 11px;
                        border-color: transparent transparent $blue transparent;
                    }

                    &:after {
                        border-color: transparent transparent white transparent;
                    }

                    .cart-empty {
                        svg {
                            width: rem(75);
                            height: auto;
                            flex: 0 0 rem(75);
                            margin: 0 auto;
                            max-height: rem(75);
                        }
                    }

                    .nav-cart {
                        padding: 0 !important;
                        font-size: 18px;

                        .cart-footer {
                            p {
                                color: $darkGrey;
                                font-size: em(15);
                                margin: 0;
                            }

                            input[type="submit"] {
                                font-size: em(16);
                            }
                        }
                    }
                }
            }
        }

        &.not-found {
            padding: 15px 0;
        }
    }

    #user-edit {
        background-color: $brown;

        .container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: rem(20) 0 rem(10);

            a {
                flex: 0 0 49%;
                font-size: em(14);
                margin-bottom: rem(10);
                padding: rem(10);
                font-weight: 700;
            }
        }
    }

    #header-bottom {
        padding: rem(10) 0;
        transition: all ease-in-out 0.2s 0.1s;

        .container {

            > * {
                display: flex;
                align-items: center;
                font-size: em(16);
                color: $blue;
                text-decoration: underline;
                margin-right: rem(15);

                &:last-child {
                    margin-right: 0;
                    margin-left: auto;
                }

                svg {
                    height: rem(25);
                    max-width: rem(25);
                    margin-right: rem(5);
                }

                &.nav-grey {
                    color: $blue;
                    cursor: pointer;
                    user-select: none;
                    text-decoration: none;

                    &:after {
                        content: '';
                        margin-left: rem(5);
                        margin-right: 0;
                        position: relative;
                        top: 1px;
                        width: 1.2rem;
                        height: 1.2rem;
                        background-image: url(../icons/icon-menu.svg);
                        background-size: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        &:after {
                            top: 1px;
                            background-size: 10px;
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }

                &.active {
                    text-decoration: underline;

                    &:after {
                        background-image: url(../icons/icon-menu-active.svg);

                        
                    }
                }
            }
        }

        .nav-desktop {
            display: none;
        }
    }

    #header-nav {
        max-width: rem(500);
        right: -105%;
        visibility: visible;
        opacity: 1;
        z-index: 1;
        display: block !important;

        &.active {
            right: 0;
            position: absolute;
        }

        .header-cta {
            text-align: left;
            padding: rem(25) 5% rem(30);
            background-image: linear-gradient(to top left, #0A7FDD 0%, #35A7F5 100%);

            a {
                text-decoration: underline;
                color: white;
                font-size: em(18);
                font-weight: 700;
                letter-spacing: 0.5px;
            }

            p {
                color: white;
                font-weight: 400;
                font-size: em(14);
                font-family: $openSans;
                max-width: 80%;
                line-height: 1.5;
                margin: 0;
            }
        }

        ul {
            padding-bottom: rem(100) !important;

            li {
                list-style: none;
                display: inline-block;
                width: 100%;
                border-bottom: 1px solid $blue;
                line-height: 1;

                a {
                    display: inline-block;
                    font-size: em(18);
                    color: $blue;
                    font-weight: 600;
                    padding: rem(15) rem(30) rem(15) 5%;
                    display: flex;
                    justify-content: space-between;

                    &:hover {
                        opacity: 1 !important;
                    }

                    &:after {
                        content: '';
                        background-image: url(../icons/icon-angle-right.svg);
                        background-size: 45%;
                        background-position: center;
                        background-repeat: no-repeat;
                        width: rem(15);
                        height: rem(15);
                        background-color: $blue;
                        border-radius: 50%;
                    }

                    span {
                        text-decoration: underline;
                    }
                }

                &.support-link {
                    background: $orange;
                    border-bottom: none;

                    a {
                        color: $white;

                        &:after {
                            content: '';
                            background-image: url(../icons/icon-angle-right-orange.svg);
                            background-size: 45%;
                            background-position: center;
                            background-repeat: no-repeat;
                            width: rem(15);
                            height: rem(15);
                            background-color: $white;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }

    #absolute-header {
        position: fixed;
        width: 100%;
        height: auto;
        left: 0;
        height: 0;
        overflow-y: scroll;
        background: white;

        .absolute-header-container {
            position: relative;
        }
    }

    .nav-tab {
        &.active {
            height: 100vh;
        }
    }

    #header-top-nav, .lottery-card-account--header {
        > li {
            transition: all ease-in-out 0.2s !important;

            > svg, .nav-cart-container > svg {
                path, polygon {
                    transition: all ease-in-out 0.2s;
                }
            }
        }
    }
}

    .sign-in {
        padding-bottom: rem(35);

        .sign-in--header {
            background: linear-gradient(to top left, #0B80DE 0%, #44B5FD 100%);
            padding: rem(50) 0 rem(30);

            * {
                color: white;
            }

            .h2title {
                margin: 0 auto;
                font-size: em(22);
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1.2;
                text-align: center;
                font-weight: 700 !important;


                i {
                    font-size: em(34);
                    margin-right: rem(15);
                }
            }

            p {
                margin-top: 0;
                font-size: em(14);
                font-weight: 400;
                font-family: $openSans;

                &:first-of-type {
                    margin-top: rem(20);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .sign-in--error {
            margin-bottom: rem(20);
            color: $orange;
            font-weight: 600;
            font-size: 1em;
        }

        .button-icon-inline {
            margin-bottom: rem(20);
            position: relative;
            font-weight: 700;
            padding-left: rem(10);
            padding-right: 0;

            &.button-icon-inline {
                width: rem(200);
            }

            svg {
                * {
                    stroke: $orange;
                    fill: $orange;
                }

                polygon {
                    fill: transparent !important;
                }
            }

            &.button-orange-solid, &.button-blue-solid {
                svg {
                    position: absolute;
                    left: rem(16);
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .radio-container {
            p {
                padding: 0;
                margin: 0 0 rem(20);
            }
        }

        p {
            color: $blue;
            margin: 0 0 rem(15);
            font-size: em(14);
            font-family: $openSans;
            padding: 0 rem(10);
        }

        .button-orange-solid, .button-blue-solid {
            margin-bottom: rem(10);
            font-size: em(15);
            /*max-width: rem(325);*/
            svg {
                position: absolute;
                left: rem(20);
                overflow: visible;
            }

            &.has-border {
                svg {
                    border: 2px solid white;
                    border-radius: 50%;
                    width: 1.75rem;
                    height: 1.75rem;
                    padding: 0.3rem;
                }
            }
        }

        .sign-in--main {
            padding: rem(35) rem(10) 0;

            .radio-container {
                label {
                    input {
                        margin-right: rem(30);
                    }
                }
            }
        }

        #global--signin-form, #global--signup-form {
            button[type="submit"] {
                margin-top: rem(25) !important;
                margin-bottom: rem(25) !important;
            }
        }

        #global--forgot-password-form {
            button[type="submit"] {
                margin-top: rem(10) !important;
                margin-bottom: rem(25) !important;
            }
        }

        .sign-in--return {
            text-align: left;
            margin: 0;

            > * {
                display: flex;
                align-items: center;
                color: $blue;
                text-decoration: underline;
                cursor: pointer;
                font-weight: 600;

                svg {
                    margin-right: rem(10);
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .sign-in-action {
            margin-top: rem(10);

            p {
                color: $blue;
                display: flex;
                justify-content: center;
                margin: 0;
                line-height: 1;
                font-size: em(14);
                font-family: $openSans;
                padding: 0 !important;
                margin-bottom: rem(10);

                span {
                    color: $orange;
                    margin-left: rem(10);
                    font-weight: 700;
                    text-decoration: underline;
                }

                a {
                    margin-left: rem(10);
                }
            }
        }

        form {
            h3 {
                display: inline-block;
            }
        }

        &#sign-up {
            input[type="submit"] {
                margin-bottom: rem(25);

                + .sign-in-action {
                    margin-bottom: 0;
                }
            }
        }
    }

    #forgot-password {
        p {
            color: $grey;
            font-size: em(14);
            text-align: left;

            span, a {
                font-weight: 700;
            }
        }

        .sign-in-action {

            p {
                text-align: center;
                justify-content: center;
                margin-bottom: rem(10);

                span, a {
                    margin-left: rem(5);
                    color: inherit;
                }
            }
        }
    }

    .nav-tab {
        display: none;
        background: white;
        position: fixed;
        overflow: auto !important;
        width: 100%;
        margin-top: 2px;
        transition: none !important;
        -webkit-overflow-scrolling: touch;


        .container {
            max-width: rem(360);
        }


        &.active {
            display: block;
            z-index: 999;
            background: white;
            padding: rem(25) 0 rem(100) !important;
        }

        &#header-nav {
            padding: 0 !important;
        }
    }

    body {
        &.nav-small {
            header {
                #header-top {
                    .container {
                        #header-logo {
                            margin: 0;
                        }

                        #header-top-nav {
                            > li {
                                padding-top: em(10);
                                padding-bottom: em(10);
                            }

                            a.not-verified {
                                background: red;
                                padding: 5px;
                                color: #fff;
                                font-weight: bold;
                                margin-left: 5px;
                            }
                        }
                    }
                }

                #header-bottom {
                    padding: 1rem 0;
                }
            }
        }
    }

    .sign-in--card {
        h3 {
            span {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    @media only screen and (min-width: 760px) {
        header {
            #header-strip {
                padding: rem(15) 0;
            }

            .nav-cart-container {
                margin-left: rem(10);

                .nav-cart-qty {
                    font-size: 12px;
                    width: rem(20);
                    height: rem(20);
                }
            }

            #header-top {
                .container {   
                    #header-top-nav {
                        > li {
                            font-size: em(17);
                            padding-left: em(12.5);
                            padding-right: em(12.5);

                            svg {
                                width: rem(15);
                                flex: 0 0 rem(15);
                                height: rem(15);
                                padding: rem(5);
                                margin-left: rem(10);
                                display: block;
                            }
                        }

                        span.underline {
                            margin-right: rem(5)
                        }
                    }
                }
            }

            #header-bottom {
                padding: rem(10) 0;

                .container {
                    > * {
                        margin-right: rem(30);
                    }

                    .nav-desktop {
                        display: flex;
                    }
                }
            }

            #header-nav {
                .header-nav-desktop {
                    display: none;
                }
            }
        }
        
        header {
            .nav-tab {
                -webkit-overflow-scrolling: touch;
                background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%) !important;

                &#header-nav {
                    background: white;
                    background-image: none !important;
                }
            }

            .sign-in {
                background: transparent;
                max-width: rem(580);
                margin: rem(50) auto;

                .sign-in--card {
                    padding: rem(25) 0;
                    border-radius: rem(10);
                    overflow: hidden;
                    box-shadow: 0 0 5px 2px rgba(0,0,0,0.2);
                    background: white;
                    margin-bottom: rem(30);
                }

                .sign-in--header {
                    background: white;
                    text-align: left;
                    padding-bottom: 0;
                    text-align: left;

                    * {
                        color: $blue;
                        text-align: left;
                    }

                    p {
                        color: $grey;
                        padding: 0;
                    }

                    .h2title {
                        margin: 0;
                        text-align: left;
                        justify-content: flex-start;

                        .icon {
                            border-color: $blue;

                            svg {
                                * {
                                    fill: $blue;
                                }
                            }
                        }

                        .signup-icon {
                            border-color: $blue;

                            svg {
                                * {
                                    fill: transparent;
                                    stroke: $blue;
                                }
                            }
                        }
                    }
                }
            }

            #user-edit {
                .container {
                    a {
                        flex: 0 0 24%;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 980px) {
        header #header-top .container #header-top-nav > * {
            .nav-title {
                transition: all ease-in-out 0.2s;
            }

            &:hover {
                color: $orange;
                text-decoration: underline;
            }
        }

        header {           
            &.cart-active {
                #header-cart {
                    .top-nav--desktop-item {
                        opacity: 1 !important;
                        visibility: visible !important;
                        z-index: 999 !important;
                        height: auto !important;
                    }
                }
            }

            #header-top-nav, .lottery-card-account--header {
                > li {
                    transition: all ease-in-out 0.2s !important;

                    > svg, .nav-cart-container > svg {
                        path, polygon {
                            transition: all ease-in-out 0.2s;
                        }
                    }

                    &.has-hover:hover, &.nav-active {

                        > a {
                            opacity: 1;
                        }


                        > svg, .nav-cart-container > svg {
                            border-color: $orange !important;

                            path {
                                fill: $orange;
                                stroke: $orange;
                            }

                            polygon {
                                stroke: $orange;
                            }
                        }


                        .top-nav--desktop-item {
                            opacity: 1 !important;
                            visibility: visible !important;
                            z-index: 9999 !important;
                            height: auto !important;

                            li {
                                a {
                                    &:hover {
                                        color: $orange;
                                    }
                                }
                            }
                        }
                    }

                    &.nav-active {
                        opacity: 1;
                    }
                }
            }

            #header-bottom {
                .nav-grey {
                    transition: all ease-in-out 0.2s;

                    svg {
                        * {
                            transition: all ease-in-out 0.2s;
                        }
                    }
 
                }
            }

            .sign-in-action {
                span {
                    transition: all ease-in-out 0.2s;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            #user-edit {
                .container {
                    width: 65%;

                    a {
                        flex: 0 0 22%;
                    }
                }
            }
        }        
    }

@media only screen and (max-width: 600px) {
    #header-top {
        .container {
            #header-top-nav {

                > li {
                    &#header--sign-up, &#header--sign-in {
                        svg {
                            display:none;
                        }
                    }
                }
            }
        }
    }
}
    @media only screen and (max-width: 426px) {
        body.nav-small {
            header {
                #header-top {
                    .container {
                        #header-logo {
                            width: auto;

                            #logo-large {
                                display: none;
                            }

                            #logo-small {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 426px) {
        body.nav-small {
            header {
                #header-top {
                    .container {
                        #header-logo {
                            #logo-small {
                                display: none;
                            }

                            #logo-large {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }