.combo-bg-blue-gradient {
    padding: 5rem 0 2rem !important;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 6rem;
        height: 6rem;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        top: -3rem;
        background-image: url(/assets/images/lottery-logos/lottery-combos.png);
        background-repeat: no-repeat;
        background-position: center center;
        pointer-events: none;
        touch-action: none;
        border-radius: 50%;
        border: 8px solid #2DA0F1;
        background-size: cover;
    }
}


@media only screen and (max-width: 600px) {
    .combo-bg-blue-gradient {
        padding: 4rem 0 1rem !important;

        &:before {
            width: 4.25rem;
            height: 4.25rem;
            top: -2rem;
            border: 6px solid #2DA0F1;
        }
    }
}


#combo--container {
    .lottery-card--combo {
        border-radius: unset;
        box-shadow: unset;
    }

    .combo-info {
        gap: 0 3rem;

        img {
            width: 7rem;
            height: 7rem;

            &.system-logo {
                width: 5rem;
                height: 5rem;
            }
        }
    }

    .combo-pricing {
        text-align: right;
    }

    .pricing-pricing-info {
        flex-direction: column;
    }

    @media only screen and (max-width: 860px) {
        .lottery-card--combo {
            flex-direction: row;
            justify-content: space-between;
            padding: 1.5rem !important;

            h2 {
                justify-content: start;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .systems-pricing {
            .button {
                width: max-content;
                margin: auto;
            }
        }

        h2 {
            padding-left: 0;
        }

        .lottery-card--combo {
            flex-direction: column;
            justify-content: center;
        }

        .combo-info {
            gap: 0 2rem;

            @media only screen and (max-width: 600px) {
                gap: 0 1rem;
            }

            text-align: left;

            h2 {
                margin-left: 0;
                width: 100%;
                max-width: 100%;
                text-align: left;
            }

            img {
                width: 5rem;
                height: 5rem;
            }

            .lottery-card--amount {
                margin: 0;
                font-size: 2rem;
            }
        }

        .pricing-pricing-info {
            justify-content: space-evenly;

            @media only screen and (max-width: 600px) {
                justify-content: center;
                flex-direction: column;

                p {
                    margin: 0;

                    &:first-child {
                        order: 2;
                        font-size: 1rem;
                    }
                }
            }

            flex-direction: row;
        }

        .combo-pricing {
            text-align: center;
        }
    }

    .lottery-card--combo {
        .info-icon {
            width: 1.125rem;
            height: 1.125rem;
        }
    }

    .is-label {
        top: 0;
        left: 0;
        border-top-left-radius: 0;
    }


       
    }
    /*TEMP*/

    @media only screen and (max-width: 600px) {
        .banner-combo {
            .container {
                .headers {
                    h1 {
                        font-size: 2.375rem;
                    }

                    span {
                        font-size: 1.75rem;
                    }
                }
            }
        }
    }

 