.lottery-game {
    position: relative;
    padding-bottom: 0;

    .tab-nav {
        justify-content: space-around;

        li {
            font-size: em(15);
        }
    }

    .tab-toggle {
        margin-bottom: rem(20);
    }

    .lottery-date-search {
        margin-top: 0;
        margin-bottom: rem(25);

        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
}

.play-game--right {
    margin: rem(30) auto 0;
}

.game-numbers--container {
    max-width: rem(350);
    margin: 0 auto;
}

.lottery-number {
    font-family: $proximaSoft !important;
}

.add-game-favourites {
    margin-top: rem(20);

    &.disabled {
        display: none;
    }
}

.lottery-game--play-now {
    max-width: rem(860);
    margin: 0 auto;

    &.play-page {
        max-width: 100%;
    }

    .play-now--header {
        margin-top: rem(25);

        h3 {
            color: white;
            font-size: em(18);
            margin: 0 0 rem(25);
        }
    }

    .play-now--game {
        .container {
            .play-game--numbers {
                max-width: rem(400);
                margin: 0 auto;
            }
        }
    }

    h5 {
        color: $orange;
        font-size: em(18);
        font-weight: 700;
        margin: rem(10) 0 0;
        letter-spacing: 0;
        line-height: 1.3;

        &.qplay {
            color: $white;
            margin: 1rem auto 1.5rem;
        }

        &.lotto-systems-h5 {
            > a.info-icon {
                margin-left: 5px;
            }
        }
    }

    #play-now--my-favourites {
        padding: 1.5rem 1rem;

        .favourites-container {
            margin-bottom: 1.5rem;

            form {
                margin-top: 1.5rem;

                .favourite-lottery {
                    box-shadow: none;
                    border-radius: unset;
                }
            }
        }

        .select-dropdown {
            background: $white;
            max-width: 20rem;
            margin: .5rem auto;
            border: 2px solid $white;

            .select-rendered, .select-option {
                color: $blue !important;
                justify-content: center;
                text-align: center;

                &.default {
                    color: $orange;
                }

                &.hide {
                    display: none;
                }

                &.show {
                    display: block;
                }
            }
        }
    }
}


.lottery-card {
    .play-now--game {
        input[type="submit"] {
            margin: rem(15) auto 0;
            font-weight: 700;
        }

        &:before {
            display: none;
        }
    }
}

.play-now--game {
    background: white;

    .play-game {
        padding: 0 rem(10) rem(40);

        > .container > h2 {
            color: white;
        }

        .play-game--numbers {
            padding: rem(20) 0 0;

            .select-dropdown {
                max-width: 20rem;
                margin: 1rem auto 1.5rem;

                .select-rendered, .select-option {
                    justify-content: center;
                }
            }

            .tab-lotto-systems {
                display: none;

                &.show-options {
                    display: block;
                }
            }

            .standard-systems-select {
                margin: 1rem auto 1rem;

                ul {
                    li {
                        background: $white;
                        border-bottom: 1px solid $lightGrey;

                        &:first-child {
                            display: none;
                        }

                        &:nth-child(2) {
                            border-top: 1px solid $lightGrey;
                        }

                        &:focus {
                            background: $lightGrey !important;
                            color: $blue !important;
                        }

                        &:hover {
                            background-color: #2C68A5;
                            color: #fff;
                        }
                    }
                }
            }

            .infor-lotto-systems {
                margin-bottom: 1rem;
                display: none;

                &.show-infor {
                    display: block;
                }

                > table {
                    background: #eeeeee;
                    border-spacing: 1px;
                    border-collapse: separate;
                    width: 90%;
                    margin: auto;
                    text-align: center;
                    line-height: 1.5;
                    border-radius: 10px;
                    overflow: hidden;
                    border: 0;

                    tr {

                        &:first-child {
                            background: #aaaaaa;
                            color: $white;
                        }
                    }

                    th {
                        color: #fff;
                    }

                    td {
                        color: #7A7B78;
                        font-weight: 600;
                        line-height: 2;
                        border: 0;
                    }
                }
            }
        }
    }
}

.play-qp--game {
    .play-game {
        .play-game--quickplay {
            padding: rem(10) 0;

            .select-dropdown {
                background: $white;
                max-width: 20rem;
                margin: 1rem auto 1.5rem;
                border: 2px solid $white;

                .select-rendered, .select-option {
                    justify-content: center;
                }
            }
        }
    }
}

.game-number-lines {
    margin-top: rem(20);
    overflow: hidden;

    p {
        font-weight: 700;
        margin: 0 0 rem(10) !important;
        font-size: em(14) !important;

        &.game-count {
            font-size: 1.25rem !important;
            margin: 0 auto !important;
            color: $blue;
            text-align: center !important;
            margin-right: auto !important;
            margin-left: auto !important;
        }
    }

    .game-number-line {
        opacity: 0;
        position: relative;
        left: -100%;

        .number-line--numbers {
            position: relative;
            display: inline-block;
            padding-right: rem(40);

            .lottery-line-actions {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                flex-direction: column;

                > span {
                    border: 2px solid $red;
                    border-radius: 50%;
                    margin-bottom: rem(7.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: rem(20);
                    height: rem(20);
                    color: $red;
                    font-weight: 700;
                    font-size: em(10);
                    cursor: pointer;

                    svg {
                        width: rem(9);

                        * {
                            fill: $blue;
                        }
                    }

                    &.lottery-favourite {
                        border-color: #79B409;
                        color: #79B409;

                        svg {
                            * {
                                stroke: #79B409;
                                fill: transparent;
                                stroke-width: 3.5px;
                            }
                        }

                        &.active {
                            background-color: $green;

                            svg {
                                * {
                                    stroke: white;
                                    fill: white;
                                }
                            }
                        }
                    }

                    &.lottery-edit {
                        border-color: $blue;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                .lottery-close {
                    svg {
                        * {
                            fill: $red;
                            transition: all ease-in-out .2s;
                        }
                    }
                }

                img {
                    cursor: pointer;
                    margin: 1px auto
                }
            }
        }

        .lottery-numbers {
            justify-content: flex-start;
            align-items: center;

            &.edit-line {
                flex-wrap: wrap;
                flex: auto !important;
            }

            .lottery-number {
                font-size: em(15);

                &.primitiva-lucky {
                    display: none;
                }
            }
        }

        &.added {
            overflow: visible;
            opacity: 1;
            margin-bottom: rem(30);
            left: 0;
        }

        &.remove {
            max-height: 0;
            right: 500px;
        }
    }

    &.no-favourite {
        .lottery-favourite {
            display: none !important;
        }

        .custom-numbers--title {
            padding: 0 rem(15);
        }

        .game-number-line {
            margin-bottom: rem(10);
        }
    }

    &.number-lines-minimised {
        .game-number-line {
            &:nth-of-type(n + 4) {
                display: none;
            }
        }

        + .number-lines-expand {
            &::after {
                transform: rotate(0);
            }
        }
    }
}

.number-lines-expand {
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    line-height: 2;
    text-decoration: underline;

    &:after {
        content: '';
        background-image: url(../icons/icon-angle-down.svg);
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $blue;
        border-radius: 50%;
        height: rem(15);
        width: rem(15);
        display: block;
        text-decoration: none;
        transition: all ease-in-out 0.2s;
        margin-left: rem(10);
        margin-top: rem(2.5);
        transform: rotate(180deg);
    }

    &:hover {
        opacity: 0.7;
    }
}

.choose-numbers {
    min-width: 75%;
    padding: rem(12.5) rem(30);
    letter-spacing: 0;
    font-weight: 700;
}

.draw-multipliers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: rem(20) rem(10) rem(20) rem(25) !important;

    label {
        flex: 0 0 33.33%;
        display: flex;
        align-items: center;
        margin: rem(15) 0;

        &:last-of-type {
            margin: rem(15) 0;
        }

        &.over-limit {
            opacity: .5;
            cursor: not-allowed;

            &:active {
                pointer-events: none;
            }
        }
    }
}

.instruction-text {
    text-align: center !important;
}

.custom-numbers {
    .custom-numbers--title {
        position: relative;
        text-align: center !important;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem !important;

        &.reintegro-notice {
            background-color: $grey;
            padding: 10px !important;
            border-radius: 5px;
            color: #ffffff !important;
        }
    }

    .quick-select, .remove-line, .lottery-reset {
        position: relative;
        width: rem(25);
        height: rem(25);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid purple;
        color: purple;
        border-radius: 50%;
        font-size: em(12);
        cursor: pointer;
        transition: all ease-in-out 0.2s;

        svg {
            width: rem(10);

            path {
                transition: all ease-in-out 0.2s;
            }
        }

        * {
            stroke: purple;
            fill: purple;
        }

        &:after {
            content: 'Random pick';
            display: block;
            position: absolute;
            text-align: left;
            left: rem(28);
            width: 90px;
            text-decoration: underline;
            font-size: .85rem;
        }
    }

    .remove-line {
        border-color: $red;
        color: $red;
        left: -20%;

        svg {
            width: rem(7);
        }

        * {
            stroke: $red !important;
            fill: $red !important;
        }

        &:after {
            content: 'Delete';
        }
    }

    .lottery-reset {
        border: 0;
        color: #1CA3CC;
        left: -5%;

        &:hover {
            svg {
                transform: rotate(360deg);
                transition: all ease-in-out 0.2s;
            }
        }

        svg {
            width: 1.25rem;
            height: 1.25rem;
        }

        * {
            stroke: #1CA3CC;
            fill: #1CA3CC;
        }

        &:after {
            content: 'Reset';
        }
    }

    .lottery-number {
        color: white;
        font-size: em(16);
        font-style: italic;
        text-indent: -2px;
        font-weight: 800;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .custom-numbers--selected {
        display: flex;
        margin: rem(15) 0;
        flex-wrap: wrap;
        background-color:#eee;
        padding:10px;
        border-radius:5px;

        .custom-selected {
            border-radius: 50%;
            width: calc((100% - (2*6%)) / 7) !important;
            padding-bottom: calc((100% - (2*6%)) / 7) !important;
            height: 0;
            min-height: rem(35);
            position: relative;
            margin: 0 rem(5) 0 0 !important;
            /*&.custom-selected-8 {
                width: calc((100% - (2*7%)) / 8) !important;
                padding-bottom: calc((100% - (2*7%)) / 8) !important;
                min-height: 2rem;
            }

            &.custom-selected-9 {
                width: calc((100% - (2*8%)) / 9) !important;
                padding-bottom: calc((100% - (2*8%)) / 9) !important;
                min-height: 2rem;
            }*/
            &.custom-selected-8, &.custom-selected-9, &.custom-selected-10 {
                margin-top: 2px !important;
            }

            .lottery-number {
                border: 3px solid $orange;
                position: absolute !important;
                width: 100%;
                height: 100%;
                transition: all ease-in-out 0.1s;
                font-size: 0;
                background: transparent;

                &.selected {
                    background: $orange;
                }
            }

            &.selected {
                background: $orange;

                .lottery-number {
                    opacity: 1;
                    font-size: em(16);
                }
            }

            &:last-of-type {
                margin-right: 0 !important;
            }
        }
    }

    .custom-numbers--selection {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: rem(15);
        user-select: none;

        .custom-selection {
            width: calc((100% - (2*7%)) / 8) !important;
            padding-bottom: calc((100% - (2*7%)) / 8) !important;
            position: relative;
            min-height: rem(30);
            margin: 0 rem(5) rem(5) 0 !important;
            border: none !important;
            outline: none;

            .lottery-number {
                position: absolute;
                background: #CBCBCB;
                width: 100%;
                height: 100%;
            }

            &.selected {
                .lottery-number {
                    background: $orange;
                }
            }

            &:nth-of-type(8n + 8) {
                margin-right: 0 !important;
            }
        }
    }

    .custom-numbers--action {
        display: flex;
        justify-content: space-between;
        margin: rem(30) 0 rem(20);


        .button {
            font-size: em(14);
            padding: rem(14.5) rem(20);
            line-height: 1;
            letter-spacing: 0;
            font-weight: 700;
            flex: 0 0 60%;
            margin: 0 auto;
        }
    }

    &.custom-numbers--lucky {
        &.primitiva-custom-numbers {
            display: none;
        }

        .custom-numbers--selected {
            .custom-selected {
                .lottery-number {
                    border-color: $lightBlue;
                }

                &.selected {
                    background: $lightBlue;
                }
            }

            &.hidden {
                display: none;
            }
        }

        .custom-numbers--selection {
            &.hidden {
                display: none;
            }

            .custom-selection {
                &.selected {
                    .lottery-number {
                        background: $lightBlue;
                    }
                }
            }
        }
    }

    &.hidden {
        display: none;
    }
}

.add-game {
    width: rem(225);
    margin: rem(20) auto 0 !important;
    font-weight: 700;
    letter-spacing: 0;
    padding: rem(12) rem(20) rem(14);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    line-height: 1;

    svg {
        position: absolute;
        left: rem(10);
        top: 50%;
        transform: translateY(-50%);

        circle {
            stroke: $blue;
        }
    }

    &.disabled {
        opacity: 0.5;
    }

    &.over-limit {
        display: none;
    }
}

.play-game--draws {
    max-width: rem(250);
    margin: rem(30) auto;

    p {
        letter-spacing: 0;
        margin-top: rem(10);
    }

    .input-range {
        margin-top: rem(50);
    }
}

.play-game--footer {
    .game-footer--title {
        color: $orange;
        font-size: em(22);
        font-weight: 700;
        margin: 0 0 rem(15);
    }

    .play-game--dates {
        color: $orange;
        font-weight: 700;
        margin: 0 0 rem(25);
        font-size: em(16);
    }

    input[type="submit"], button {
        width: 80%;
        border-radius: rem(20);
        padding: rem(15);
        margin: 0 auto;
        font-weight: 700;
        font-family: $proximaSoft;
        letter-spacing: 0;
        color: white;
        background-image: linear-gradient(to right, #F5993C 0%, #F76B1C 51%, #F5993C 100%);
        border: none;
        background-size: 150% auto;
        text-align: center;
        display: block;

        &:hover {
            background-position: right center;
        }
    }

    .play-checkout-btn {
        max-width: 225px;
    }

    #checkout-cart-svg {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;
    }
}

.lottery-game--lottery-results {
    max-width: rem(420);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .results {
        flex: 0 0 75% !important;

        .results-nav-container {
            padding: 0 1rem 1rem;
            max-width: 50rem;
            margin: auto;

            a {
                color: #fff;
                cursor: pointer;
                font-weight: bold;

                &:hover {
                    text-decoration: underline;
                    color: $orange;
                    opacity: 1;
                }
            }
        }
    }

    .lottery-results--nav {
        margin-bottom: rem(30);

        * {
            color: white;
        }

        p {
            margin: 0 0 rem(15);
            font-weight: 700;
        }

        .results-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 72.5%;
            margin: 0 auto;
            font-size: 14px;

            p {
                padding: 0 rem(20);
                margin-bottom: 0;
                flex: 1 1 100%;
            }

            a {
                line-height: 1.3;
                text-align: center;
                display: block;
                position: relative;
                flex: 1 0 auto;

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background-size: 50%;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: rem(15);
                    height: rem(15);
                    background-color: white;
                    border-radius: 50%;
                    line-height: 1;
                    font-weight: 900;
                    font-size: em(12);
                }

                &.result-prev {
                    &:before {
                        background-image: url(../icons/icon-angle-left-blue.svg);
                        right: calc(100% + #{rem(15)});
                    }
                }

                &.result-next {
                    &:before {
                        background-image: url(../icons/icon-angle-right-blue.svg);
                        left: calc(100% + #{rem(15)});
                    }
                }

                &.disabled {
                    opacity: 0.3 !important;
                    cursor: default;
                }
            }
        }
    }

    .lottery-card--results {
        padding: rem(20) rem(30);
        max-width: 100% !important;

        .lottery-numbers-container {
            margin-bottom: rem(20);
        }

        h3 {
            color: $blue;
            margin: 0 0 rem(15);
            font-size: em(16);
        }

        .results-expand {
            + .results-content {
                opacity: 0;
                visibility: hidden;
                transition: opacity ease-in-out 0.2s;
            }

            &.active {
                + .results-content {
                    opacity: 1;
                    visibility: visible;
                    overflow: visible !important;
                }
            }
        }
    }
}

.divisions-breakdown {
    .lottery-numbers {
        margin: 0 auto;
    }

    .lottery-number {
        margin-left: 0 !important;
    }
}

.lottery-game--how-to-play {
    &:before {
        display: none;
    }

    .how-to-play--information {
        padding: rem(20) 0;

        .lottery-card {
            padding: rem(25) rem(20);

            h2 {
                margin-bottom: rem(30);
                font-size: em(18);
                line-height: 1.5;
                font-weight: 600;
                font-family: $openSans;
                color: $orange;
            }

            p {
                text-align: left;
                font-size: em(14);
                margin: 0 0 rem(5);
                font-family: $openSans;
                display: flex;
                justify-content: space-between;

                span {
                    font-weight: 700;
                    flex: 0 0 42.5%;
                    margin-left: 2.5%;
                }
            }
        }
    }

    .how-to-play--divisions {
        background: white;
        padding: rem(40) 0;

        .container {
            width: 80%;
        }

        img {
            &.instant-win-img {
                max-width: 450px;
                width: auto;
                margin: auto auto 1rem auto;
            }
        }

        .lottery-flag {
            width: rem(45);
            height: auto;
            margin-bottom: rem(15);
        }

        h2 {
            color: $orange;
            font-size: em(18);
            font-weight: 700;
            font-family: $openSans;
            margin: rem(25) 0 rem(15);
        }

        h3 {
            color: $orange;
            font-size: em(18);
            font-weight: 400;
            line-height: 1.3;
            font-family: $openSans;
            margin: 0 0 rem(7.5);
        }

        p {
            font-size: em(14);
            font-family: $openSans;
            color: #7A7B78;
            text-align: left;
            margin: 0.5rem 0;
            color: $blue;
        }

        .divisions-breakdown {
            .lottery-number {
                margin-left: 0 !important;
            }
        }
    }

    .how-to-play--descriptions {
        background: white;

        .container {
            width: 80%;
        }

        .how-to-description {
            border-top: 1px solid #E6E6E6;
            padding: rem(32.5) 0 rem(20);

            h2 {
                margin: 0 0 rem(30);
                color: $orange;
                font-size: em(16);
                font-family: $openSans;
                font-weight: 600;
                letter-spacing: 0.3px;
            }

            p {
                text-align: left;
                color: $blue;
                font-size: em(14);
                font-weight: 400;
                letter-spacing: 0.3px;
            }
        }
    }

    .how-to-play--footer {
        padding: rem(20) 0 0;
    }
}

.choose-plays {
    padding: rem(25) 0 0;

    .button {
        transform: none !important;
        transition: none !important;
    }

    h4 {
        font-size: em(16);
        letter-spacing: 0;
        line-height: 1.3;
        margin: 0;
    }

    .choose-plays-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 1rem;

        label {
            flex: 0 0 24.25%;
            max-width: 24.5%;
            cursor: pointer;
            padding: rem(13) rem(15);
            letter-spacing: rem(0.5);
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            margin-bottom: rem(8);
            overflow: hidden;
            font-weight: 700;

            &.input-full {
                padding: 0;
                display: none;
                flex: 0 0 100%;

                &.active {
                    display: block;
                }
            }
        }

        .input-blue {
            flex: 0 0 100% !important;
            max-width: 100%;
            padding: 0;

            input {
                margin: 0;
                font-weight: 700;
                text-align: center;
                width: 100%;
            }
        }

        input[type="radio"] {
            display: none;

            &:checked {
                + label {
                    color: white;
                    background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
                    border-color: transparent;
                }
            }

            @media only screen and (min-width: 760px) {
                + label:hover {
                    color: white;
                    background-image: linear-gradient(to left, #F76B1C 0%, #F76C1C 50%, #F89436 100%);
                    border-color: transparent;
                }
            }
        }

        &.items-center {
            justify-content: center;

            label {
                flex: 0 0 40% !important;
                margin-right: 1rem !important;
                max-width:initial;
            }
        }
    }

    &.border-bottom {
        &:after {
            content: '';
            width: 100%;
            height: rem(10);
            background: linear-gradient(to top, #36A8F5 0%, #36A8F6 100%);
            display: block;
            margin-top: rem(22);
        }
    }
}

.divisions-breakdown {
    max-width: rem(320);
    margin: 0 auto;

    &.active {
        overflow: visible;
    }

    .divisions-container {
        text-align: center;
        margin-bottom: rem(75) !important;

        h4 {
            display: block;
            width: 100%;
            margin: 0 0 rem(15);
            color: $blue;
            font-size: 0.875rem;
            font-family: $openSans;
            font-weight: 700;
            line-height: 2;
        }

        p {
            font-size: 0.875rem;
            color: #7A7B78 !important;
            font-family: $openSans;
            font-weight: 400;
            margin-bottom: rem(15) !important;
        }
    }
}

.total-cost {
    display: inline-block;
    color: $orange;
    font-weight: 700;
    font-size: em(30);
    padding: rem(20);
    border-radius: rem(10);
    line-height: 1;
    margin-bottom: rem(30);
    border: rem(2) solid $orange;
    user-select: none;
}

@media only screen and (min-width: 760px) {
    .play-now--game {
        max-width: rem(860);
        margin: 0 auto;

        &:before {
            display: none;
        }

        .choose-plays {
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding-bottom: rem(30);
            margin: 0 auto;
            text-align: center;

            .choose-plays-container {
                max-width: rem(460);
                margin: rem(25) auto 0;
            }
        }

        .play-game {
            padding: rem(25) 0;

            .container {
                align-items: flex-start;
                width: 100%;
                max-width: rem(400);

                > * {
                    flex: 1;
                    padding-top: 0;
                    margin-top: 0;
                    width: 100%;
                }

                .play-game--numbers {
                    top: rem(110);
                    left: 0px;
                    margin: 0 auto;
                    max-width: 100%;
                }

                .play-game--right {
                    top: rem(110);
                    right: 0px;
                    padding: 0 5%;
                    margin: rem(30) auto 0;
                }
            }
        }
    }

    .lottery-game--lottery-results {
        width: 92.5%;
        max-width: rem(1180);
        margin: 0 auto;

        &.active {
            display: flex !important;
            flex-wrap: wrap;
        }

        .lottery-results--nav {
            margin: rem(30) 0 rem(15);
            width: 92.5%;

            .container {
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;

                p {
                    margin: 0 auto;
                }

                .results-nav {
                    justify-content: flex-end;
                    align-items: center;
                    flex: 0 0 100%;
                    margin: 0;
                    padding: 0 rem(30) 0;

                    a {
                        opacity: 0.8;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    br {
                        display: none;
                    }
                }
            }
        }

        .results {
            flex: 0 0 75% !important;

            .container {
                width: 100%;
            }
        }

        > .container {
            flex: 1;
            min-width: 45%;
            width: 100% !important;
            max-width: 100% !important;

            .lottery-card {
                width: 100%;
                max-width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        .lottery-date-search {
            flex: 1 0 100%;
            width: 100%;
            max-width: rem(360);
        }

        .lottery-card--results {
            max-width: 90% !important;
        }
    }
}

@media only screen and (min-width: 860px) {
    .lottery-game {
        .play-now--game {
            border-radius: rem(10);
            box-shadow: 0 0 5px 2px rgba(0,0,0,0.2);
        }
    }
}

@media only screen and (min-width: 980px) {
    .add-game {
        svg {
            path {
                transition: all ease-in-out 0.2s;
            }

            circle {
                transition: all ease-in-out 0.2s;
            }
        }

        &:hover {
            svg {
                path {
                    fill: white;
                }

                circle {
                    stroke: white;
                }
            }
        }
    }

    .game-number-lines {
        .game-number-line {
            .number-line--numbers {
                .lottery-line-actions {
                    > span {
                        transition: all ease-in-out 0.2s;

                        &:hover {
                            color: white;

                            path {
                                color: white;
                                stroke: white;
                            }
                        }

                        &.lottery-edit {
                            * {
                                transition: all ease-in-out 0.2s;
                            }

                            &:hover {
                                background-color: $blue;
                            }
                        }

                        &.lottery-favourite {
                            &:hover, &.active {
                                background-color: $green;

                                svg {
                                    * {
                                        stroke: white;
                                        fill: white;
                                    }
                                }
                            }
                        }

                        &.lottery-close {
                            &:hover {
                                background-color: $red;

                                path {
                                    fill: white;
                                    stroke: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-numbers {
        .quick-select {
            transition: all ease-in-out 0.15s;
            cursor: pointer;

            &:hover {
                background-color: purple;

                * {
                    stroke: white;
                    fill: white;
                }
            }
        }

        .remove-line {
            &:hover {
                background-color: $red;

                * {
                    stroke: white !important;
                    fill: white !important;
                }
            }
        }
    }

    .lottery-game--how-to-play {
        .how-to-play--main {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 92.5%;
            max-width: rem(1180);
            margin: 0 auto;
            padding: rem(15) 0 rem(40);

            .how-to-play--information, .how-to-play--divisions {
                display: flex;
                width: 50rem;

                > .container, .lottery-card {
                    width: 100%;
                    flex: 1;
                    max-width: 100% !important;
                }

                > .container {
                    display: flex;
                    flex-direction: column;
                }

                .lottery-card {
                    height: 100%;
                    padding: 1.25rem 3rem 2rem;

                    h3 {
                        font-size: em(18);
                        font-weight: 700;
                    }
                }
            }



            .how-to-play--divisions {
                border-radius: rem(10);
                padding: rem(15) rem(25) rem(40);

                .how-to-play--divisions-title {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: rem(15);

                    h2 {
                        margin: 0;
                    }

                    img {
                        flex: 0 0 auto;
                        margin: 0 0 0 rem(15);
                    }
                }


                h3 {
                    margin-bottom: 0;
                }
            }
        }

        .how-to-play--descriptions {
            .container-desktop {
                display: flex;
                justify-content: space-between;
                text-align: left;
            }

            .how-to-description {
                flex: 0 0 45%;

                .container {
                    max-width: 100%;
                    width: 100%;
                }

                h2 {
                    margin-bottom: rem(10);
                    font-size: em(18);
                }
            }
        }

        .how-to-play--footer {
            padding-top: rem(50);
        }
    }

    .divisions-breakdown {
        display: flex !important;
        align-items: flex-end;
        width: 95%;
        max-width: 100%;
        justify-content: space-between;

        .divisions-container {
            //flex: 0 0 47.5%;
            //min-width: 47.5%;
            margin-bottom: 0 !important;
            display: block;
            width: 100%;

            .isJoker {
                margin: 1rem 0 0;
                padding: 1rem 0 0;
                border-top: 1px solid $lightGrey;

                h2 {
                    text-align: left;
                    color: $grey;
                    margin: 0;
                    padding: 1rem 0;
                }
            }

            .how-to-win {
                display: flex;

                &.primitiva-how-to-win {
                    margin: 1rem auto;
                }

                .division {
                    flex: 0 0 50%;
                    text-align: left;

                    h4 {
                        margin: 0;
                    }
                }

                .lottery-numbers-row {
                    flex: 0 0 50%;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-top: 0 !important;

                    .flex-container {
                        display: flex;
                        flex-direction: column;

                        .flex-col {
                            display: flex;
                            flex-direction: row;
                        }
                    }

                    img {
                        &.divisions {
                            max-width: 250px;
                        }
                    }
                }

                .lottery-number {
                    max-width: 2rem;

                    &.joker {
                        border-radius: 5px !important;

                        &.half {
                            height: 15px;
                            border: 1px solid $orange;
                        }

                        &.empty {
                            background: none;
                            border: 1px solid $lightGrey;
                        }
                    }
                }
            }
        }

        .input-range {
            width: 40%;
            display: flex;
            margin-bottom: rem(5);
        }
    }

    .custom-selection, .custom-selected.selected {
        cursor: pointer;
        transition: all ease-in-out 0.15s;

        &:hover {
            opacity: 0.5;
        }
    }

    .lottery-game--lottery-results {
        .lottery-results--nav {
            .container {
                .results-nav {
                    font-size: 16px;

                    a {
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .lottery-game--lottery-results .results {
        display: block;
        flex: auto !important;
        margin: auto;
    }

    .lottery-card .read-more {
        display: flex;
    }

    .divisions-breakdown {
        .divisions-container {
            display: block;
            margin-bottom: 0 !important;

            .isJoker {
                margin: 1rem 0 0;
                padding: 1rem 0 0;
                border-top: 1px solid $lightGrey;

                h2 {
                    text-align: center;
                    color: $grey;
                    margin: 0;
                    padding: 1rem 0;
                }
            }

            .how-to-win {
                margin: 1rem auto;

                h4 {
                    font-size: 0.875rem !important;
                    margin: 0 !important;
                }

                .division {
                    p {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .lottery-game--lottery-results {
        .results {
            flex: auto !important;
        }
    }

    .lottery-game--how-to-play {

        .how-to-play--divisions {
            img {
                &.instant-win-img {
                    max-width: 100%;
                    margin: auto auto 1rem auto;
                }
            }
        }
    }

    .infor-lotto-systems {
        > table {
            width: 100% !important;
            font-size: .9em;
        }
    }
}
