.charity-page {
    h2 {
        max-width: 100% !important;
        margin: 1rem auto;
    }

    .charity-banner {
        background: url(/assets/images/charity/charity-banner-mobile.jpg) top center no-repeat;
        min-height: 260px;
        padding: 1rem;

        .charity-logo {
            width: 200px;
            max-width: 100%;
        }

        .charity-cheque {
            width: 300px;
        }

        h1 {
            font-size: 1.2rem;
        }

        span {
            font-size: 1rem;
        }
    }

    .charity-donations {
        padding: 2rem 1rem;
        background: #eee;

        h2 {
            text-align: left !important;
        }

        h3 {
            font-size: 1.5rem;
        }

        ul {
            list-style: none;
            max-width: 1080px;
            padding: 1rem 0;

            li {
                border-radius: 5px;
                border-left: 5px solid $blue;
                background: #ffffff;
                padding: 1rem;
                margin: 1rem 0;
                //min-width: 250px;
                width: 320px;

                > a {
                    width: 290px;
                }

                &:nth-child(n+4) {
                    //  display: none !important;
                }

                &.hiddenStyle {
                    display: none !important
                }

                > div {
                    padding-left: 10px;
                }
            }

            .charity-box {
                width: 225px;
            }

            .charity-desc {
                line-height: 1.2;
                font-weight: 600;
            }

            .italics {
                font-size: 90%;
                line-height: 2;
            }

            img {
                width: 50px;
                height: 50px;
                display: block;
                margin-right: 10px !important;
                margin-left: 0 !important;
            }
        }
    }

    .charity-promo {
        padding: 2rem;

        h2 {
            max-width: 100%;
            text-align: center;
            margin: auto;
            font-weight: normal;
            font-size: 1.5rem;
            color: $darkGrey;
        }
    }

    .charity-grid {
        margin: 2rem auto 0;
        padding:0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Responsive columns */
        grid-gap: 20px; /* Optional: space between the images */
        width: fit-content; /* Set the container width */
         a,img {
            width: 280px;
            height: 280px;
        }
    }
    /* Media queries for different screen sizes */
    @media (max-width: 1200px) {
        .container {
            grid-template-columns: repeat(3, 1fr); /* 3 images per row */
        }
    }

    @media (max-width: 900px) {
        .container {
            grid-template-columns: repeat(2, 1fr); /* 2 images per row */
        }
    }

    @media (max-width: 600px) {
        .container {
            grid-template-columns: 1fr; /* 1 image per row */
        }
    }

    .charity-funding {
        min-height: 300px;
        background: url(/assets/images/charity/funding-banner-mobile.jpg) top center no-repeat;
        padding: 1rem;

        h2 {
            font-size: 1.5rem;
        }

        > div {
            padding: 1rem;
            background: #ffffff;
            border-radius: 5px;
            margin: auto;

            p {
                text-align: center;
                max-width: 100%;
                margin: auto auto 1rem;
                font-size: 1rem;
            }
        }
    }

    .shimmer {
        margin-top: 6px;
        background: #f6f7f8;
        animation: shim infinite 1.2s linear;
    }
      
    @keyframes shim {
        0% {
            background-position: -468px 0;
        }

        100% {
            background-position: 468px 0;
        }
    }

    .shimmer_circle {
        width: 65px;
        height: 65px;
        border-radius: 65px;
    }

    .shimmer_circle_heading .shimmer_heading {
        width: 90%;
        margin-left: 3%
    }

    .shimmer_circle_heading {
        display: flex;
        align-items: center;
    }

    .shimmer_heading {
        width: 50%;
        height: 20px;
        margin-top: 6px;
    }

    .shimmer_small_para {
        height: 24px;
    }

    @media screen and (min-width: 600px) {

        .charity-banner {
            min-height: 272px !important;
            max-width: 100%;
            background: url(/assets/images/charity/charity-banner.jpg) top center no-repeat;
            padding: 1rem 0;

            .charity-logo {
                width: 320px;
                height: 100px;
            }

            .charity-cheque {
                width: 550px;
                height: 200px;
            }

            h1 {
                font-size: 2rem;
            }

            span {
                font-size: 1.2rem;
            }
        }

        .charity-donations {
            padding: 2rem;

            h3 {
                font-size: 2rem;
            }

            ul {
                max-width: 1080px;
                padding: 1rem 0;

                li {
                    margin: 1rem 0;

                    &:nth-child(n+4) {
                        display: flex !important;
                    }

                    &.hiddenStyle {
                        display: none !important
                    }
                }

                img {
                    width: 65px;
                    height: 65px;
                    display: block;
                    margin-left: auto !important;
                }
            }
        }

        .charity-promo {
   
            h2 {
                max-width: 60%;
                font-size: 2rem;
            }
        }

        .charity-funding {
            min-height: 400px !important;
            background: url(/assets/images/charity/funding-banner.jpg) top center no-repeat;
            padding: 1rem 2rem;

            h2 {
                font-size: 2rem;
            }

            > div {
               // max-width: 1080px;
                padding: 2rem;
                margin-top: 2rem;

                p {
                    max-width: 75%;
                    font-size: 1.5rem;
                    margin: auto auto 2rem;
                }
            }
        }
    }
}
