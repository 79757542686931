.footer-accordions-section {
    margin-top: 2rem;
    padding: 0;
    background: $lightGrey;

    > h2 {
        padding: 1.2rem 5rem;
        margin: 0;
        color: $blue;
    }

    > p {
        padding: 0 5rem;
        line-height: 1;
        margin: 0;
    }

    ul {
        list-style: none;
    }

    li[itemtype="http://schema.org/Question"] {
        list-style: none;
        cursor: pointer;
        padding: 0 5rem;
        background: $lightGrey;
        border-bottom: 1px solid #ccc;

        a {
            justify-content: space-between;
            padding: 1.2rem 0;
            margin: 0;

            &:hover {
                opacity: 1;
            }

            &:after {
                display: none;
            }
        }

        h4 {
            font-size: 1.3125rem;
            color: $blue;
            display: flex;
            margin: 0;
            text-align: left;
        }

        p {
            font-weight: 500;
            text-decoration: none;
            font-size: 1.5rem;
            color: $darkGrey;
            margin: 0;

            a {
                padding: 0 !important;
            }
        }

        .view-more {
            font-size: 1.175rem;
        }

        img {
            position: relative;
            width: 18px;
            height: 18px;
            margin-left: 1.5rem;
            margin-top: 8px;
        }

        .active {
            > h4 {
                color: $blue;
            }

            .view-more {
                display: none;
            }

            img {
                transform: rotate( -180deg);
            }
        }

        .results-expand {
            position: relative;

            &.active {
                &:after {
                    transform: rotate(-180deg);
                }

                + .results-content {
                    margin-top: rem(25);
                    display: block;

                    &.no-slide {
                        max-height: 100% !important;
                        max-width: 80%;
                        margin: 1rem auto;
                    }
                }
            }
        }

        .results-content {
            overflow: hidden;
            transition: all ease-in-out 0.3s;
            margin-top: 0 !important;
            max-height: 0;

            p {
                font-size: 1.2rem;
                text-align: left;
                display: block;
                margin-bottom: 1rem;

                &.small {
                    font-size: 1rem;
                }

                &:last-child {
                    padding-bottom: 1rem;
                }
            }

            ul {
                padding: 0 0 1rem;

                li {
                    text-align: left;
                    font-size: 1.2rem;
                }
            }
        }
    }

    .download-buttons {
        padding-top: 2rem;
    }

    @media only screen and (max-width: 1000px) {
        li {
            padding: 1rem;
        }
    }

    @media only screen and (max-width: 768px) {
        li {
            p {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        padding: 1rem 0 0;
        margin-top: 1rem;

        > h2, > p {
            padding: 0 .75rem;
            line-height: 1.2;
        }

        h2 {
            font-size: 1.3125rem;
        }

        li {
            padding: .5rem !important;

            a {
                padding: 0.25rem !important;
            }

            h4 {
                font-size: 1.2rem;
                text-align: left;
                max-width: 80%;
                text-align: left;
                line-height: 1;
            }

            img {
                width: 25px;
                height: 25px;
            }

            .results-content {

                p {
                    font-size: 1rem !important;
                    padding:.5rem .5rem 1rem;
                    &:last-child {
                        padding-bottom: 0rem;
                    }
                }
            }

            .results-expand.active + .results-content {
                margin-top: 1rem !important;
            }
        }
    }
}
