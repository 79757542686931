.lottery-numbers {
    overflow: visible;
}

.lottery-numbers-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &.numbers-container-column {
        .lottery-numbers {
            flex: 0 0 95%;
            margin-bottom: rem(5);

            .lottery-number {
                width: calc((100% - (6 * #{rem(3)})) / 7);
                max-width: 100%;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    > p {
        flex: 100%;
        font-size: em(16) !important;
        font-weight: 700;
        margin: 0 0 rem(10);
        line-height: 1;
    }

    .lottery-numbers {
        display: flex;
        align-items: center;
        flex: 0 0 100%;

        &.center {
            justify-content: center;
        }

        .lottery-number {
            border-radius: 50%;
            color: white;
            font-weight: 700;
            font-size: em(17);
            position: relative;
            margin: rem(5) rem(3) 0 0;
            background: $orange;
            /*width: calc((100% - (6 * #{rem(3)})) / 7);*/
            width: rem(32.5);
            min-width: rem(30);
            max-width: 8.5vw;
            text-align: center;
            user-select: none;

            > span {
                height: 0;
                display: block;
                padding-top: 100%;
                position: relative;

                > span {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    margin: 0 auto;
                    width: 100%;
                }
            }

            &.number {
                background-color: $orange;
            }

            &.lucky {
                background-color: $lightBlue;

                &.ireluk {
                    background-color: $darkGrey !important;
                }
            }

            &.instant {
                background: $androidGreen;
            }

            &.superstar {
                background-color: $lightBlue;
            }

            &.reintegro {
                background-color: $lightBlue;
                order: 2;
            }

            &.bonus {
                background-color: $darkGrey;
                order: 1;
            }
        }

        &.lottery-numbers-row {
            margin-bottom: 0;
            flex: 0 0 100%;
            justify-content: center;

            .flex-container {
                display: flex;
                flex-direction: column;

                .flex-col {
                    display: flex;
                    flex-direction: row;
                }
            }

            .lottery-number {
                width: calc((100% - (8 * #{rem(3)})) / 9);
                font-size: initial;

                > span {
                    font-size: initial;

                    > span {
                        font-size: em(14);
                    }
                }

                &.joker {
                    border-radius: 5px !important;

                    &.half {
                        height: 15px;
                        border: 1px solid $orange;
                    }

                    &.empty {
                        background: none;
                        border: 1px solid $lightGrey;
                    }
                }
            }
        }

        &.lottery-numbers-results {
            .lottery-number {
                &.number {
                    color: $orangeLight;
                    background: transparent;

                    &.selected {
                        background-color: $orangeLight;
                        color: white;
                    }
                }

                &.blue {
                    background-color: $lightBlue;
                }

                &.grey {
                    background-color: $darkGrey;
                }
            }
        }
    }

    &.selected-numbers-container {
        display: grid;
        margin: 0 auto !important;

        &:nth-child(odd) {
            background: rgba(#1b90e8, 0.1);
        }

        &:nth-child(even) {
            background: rgba(#1b90e8, 0.2);
        }

        &.title-selected-container, &:first-child {
            margin: 1rem auto !important;
            background: none;
        }


        p {
            margin: 0;
            text-align: left;
            padding: 5px 0 0 5px;

            &.title-selected {
                font-weight: normal;
                min-width: 230px;
            }
        }

        .lottery-numbers {
            .lottery-numbers-row {
                min-width: 231px;
                justify-content: flex-start;
            }

            .lottery-number {
                border: none;
                border-radius: 0;

                &.lucky {
                    border: none;
                }

                span > span {
                    font-size: 1.2rem !important;
                    font-weight: 400;
                }
            }
        }
    }


    .results-type-joker {
        margin: 1rem 0 0;
        font-size: 1em !important;
        font-family: $proximaSoft;
    }
}


@media only screen and (min-width: 768px) {
    .lottery-numbers-container {
        .lottery-numbers {
            flex: 0 1 auto;
        }
    }
}
