.archive-faqs {
    padding: rem(20) 0 rem(60);

    h1 {
        flex: 0 0 100%;
        color: $orange;
        font-size: em(32);
        margin: 0 0 rem(30);
    }


    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        > * {
            flex: 0 0 100%;
            text-align: left;
            margin-top: 0;
        }

        > p {
            font-weight: 600;
            font-size: em(20);

            &:last-of-type {
                margin-bottom: rem(30);
            }
        }
    }

    .archive-faq {
        display: flex;
        align-items: center;
        padding: rem(15) rem(20);
        min-width: auto !important;
        overflow: visible;
        flex-wrap: wrap;
        max-width: 100% !important;
        flex: 0 0 48%;
        max-width: 48%;
        margin: 0 4% rem(20) 0;
        flex-wrap: wrap;
        justify-content: center;
        transition: all ease-in-out 0.2s;

        &:nth-of-type(2n+2) {
            margin-right: 0
        }

        svg, img {
            max-height: rem(40);
            height: auto;
            width: rem(40);
            overflow: visible;
            align-self: center;
            margin-bottom: rem(10);
        }

        .faq-content {
            flex: 0 0 100%;
            cursor: pointer;
            text-align: center;


            h3 {
                font-weight: 600;
                margin: 0 0 rem(5);
                color: #A2A2A2;
                font-size: em(18);
                line-height: 1;
                transition: all ease-in-out .2s;
            }

            p {
                color: #AAAAAA;
                font-size: em(16);
                margin: 0;
            }

            .pdf {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: rem(5);
                font-size: em(14);
                opacity: 0.7;

                svg {
                    width: rem(15);
                    margin-right: rem(5);
                    margin-bottom: 0;
                }
            }
        }

        &:hover {
            opacity: 1;
            transform: scale(1.02);

            h3 {
                color: $orange;
            }
        }


        .st0 {
            fill: none;
            stroke: #7A7B78 !important;
        }

        .st1 {
            fill: none;
            stroke: #7A7B78;
        }

        .st2 {
            fill: none;
            stroke: #7A7B78;
        }
    }
}

@media only screen and (min-width: 780px) {
    .archive-faqs {
        h1 {
            text-align: left;
            font-size: em(42);
            margin: rem(30) 0 rem(50);
        }
    }
}

@media only screen and (min-width: 900px) {
    .archive-faqs {
        .archive-faq {
            flex: 0 0 calc(96% / 3);
            max-width: calc(96% / 3) !important;
            margin: 0 2% rem(25) 0 !important;
            flex-wrap: nowrap;
            align-items: center;
            padding: rem(25) rem(20);

            svg, img {
                width: rem(40);
                max-height: rem(40);
                margin-bottom: 0;
            }

            img {
                width: rem(60);
                max-height: rem(60);
            }

            .faq-content {
                text-align: left;
                flex: 1;
                margin-left: rem(25);

                h3 {
                    font-size: em(25);
                }

                .pdf {
                    justify-content: flex-start;
                }
            }

            &:nth-of-type(3n + 3) {
                margin-right: 0 !important;
            }
        }
    }
}


/*New Faqs*/

#banner-faq {
    $gradient: #1a3d9b;
    $bgUrl: '/assets/images/landing/faq-banner.jpg';
    $bgmobileUrl: '/assets/images/landing/faq-banner-mobile.jpg';
    @include banner-generic($gradient, $bgUrl, $bgmobileUrl);

  
}

.faq-page {
    h2 {
        color: $orange;
        text-align: left;
    }

    .select-dropdown {
        display: none;
    }

    main {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 3rem 0 1rem;

        nav {
            flex: 3;

            h2 {
                margin: 0;
            }

            ul {
                &.faq-nav {
                    display: block;
                }

                &.faq-nav-mobile {
                    display: none;
                }

                padding: 0;
                margin: 0;
                list-style: none;
                position: sticky;
                top: 8rem;

                @media only screen and (max-width: 1280px) {
                    overflow-y: auto;
                    max-height: 354px;
                }

                li {
                    margin: 1rem 0;

                    a {
                        display: flex;
                        text-align: left;
                        padding: 0 0 0 .5rem;
                        color: $grey;
                        opacity: 1;
                        border-left: 3px solid transparent;
                        font-weight: 600;

                        &:hover, &.active {
                            color: $blue;
                            border-left: 3px solid $orange;
                        }
                    }
                }
            }
        }

        .container {
            flex: 7;

            > div {
                //jump scroll offset fix
                &:before {
                    content: "";
                    display: block;
                    height: 120px;
                    margin: -120px 0 0;
                }
            }

            section {
                max-width: initial;
                padding: 2rem !important;
                margin: 0 !important;
                background-color: white;
                border-radius: 0.625rem;
                box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
                width: 100%;

                &:nth-child(n+1) {
                    margin: 2rem 0 !important;
                }

                h3 {
                    color: $blue;
                    margin: 0;
                    text-align: left;
                }

                ul {
                    list-style: none;
                    padding: 1rem 0;
                    margin: 0;

                    li {
                        margin: 0;
                        border-bottom: 1px solid $lightGrey;

                        a {
                            display: flex;
                            text-align: left;
                            /*padding-left: .5rem;*/
                            margin: 0;
                            color: $grey;
                            cursor: pointer;
                            justify-content: flex-start;
                            font-weight: 500;


                            &.results-expand {
                                &:after {
                                    display: none;
                                }

                                &:before {
                                    content: '';
                                    background-image: url(../icons/faq-plus.svg);
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    height: 1.2rem;
                                    width: 1.2rem;
                                    text-decoration: none;
                                    -webkit-transition: all ease-in-out 0.2s;
                                    transition: all ease-in-out 0.2s;
                                    margin-right: 1.25rem;
                                    width: 5%;
                                }
                            }


                            &.active {
                                h4 {
                                    color: $blue;
                                }

                                &:before {
                                    background-image: url(../icons/faq-minus.svg);
                                }
                            }
                        }

                        h4 {
                            text-align: left;
                            margin: 1rem 0;
                            padding: .5rem 0;
                            line-height: 1.2;
                            font-weight: 500;
                            width: fit-content;
                        }

                        .results-content {
                            background: #eee;
                            text-align: left;
                            margin: 0;

                            ol {
                                margin-bottom: .5rem;

                                li {
                                    color: $grey;
                                    border: 0;

                                    a {
                                        display: initial;
                                        color: $orange;
                                        padding-left: 0;
                                    }
                                }
                            }

                            ul {
                                padding-inline-start: 40px;
                                list-style: disc;

                                li {
                                    color: $grey;
                                    border: 0;
                                }
                            }

                            h4 {
                                color: $blue;
                                margin: 0;
                                padding: .5rem 1rem 0;
                            }

                            h5 {
                                margin: 0;
                                padding: .5rem 1rem 0;
                            }

                            p {
                                padding: 0rem 1rem;
                                font-size: 1rem;
                                font-family: $proximaSoft;

                                a {
                                    display: initial;
                                    color: $orange;
                                    padding-left: 0;
                                }

                                img {
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section {
        &.intro {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            p {
                text-align: left;
                margin: 0;

                &.h2 {
                    color: $orange;
                    text-align: left;
                    font-size: 1.5em;
                    font-weight: 600;
                    margin: 1rem 0;
                }
            }

            > div {
                display: flex;
                flex-direction: column;

                > .button {
                    margin-top: 4rem;
                    z-index: 99;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
 

    .faq-page {
        .select-dropdown {
            display: block;
        }

        main {
            flex-direction: column;
            padding: 1rem 0;

            nav {
                display: block;

                h2 {
                    margin: 1rem auto;
                }

                ul {
                    &.faq-nav {
                        display: none;
                    }

                    &.faq-nav-mobile {
                        display: block;
                    }

                    &.select-options {
                        top: 0;
                        margin: auto;

                        li {
                            margin: auto;
                        }
                    }
                }
            }

            .container {
                width: 100%;
                max-width: 100%;

                section {
                    padding: 1rem !important;

                    .results-expand {
                        &:before {
                            display: inline-table;
                        }
                    }
                }

                > div {
                    &:before {
                        content: "";
                        display: block;
                        height: 210px;
                        margin: -210px 0 0;
                    }
                }
            }
        }

        section {
            &.intro {
                flex-direction: column;

                > div {
                    > .button {
                        max-width: max-content;
                        margin: 1rem auto auto;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .faq-page main .container section ul li a.results-expand:before{ width: 10%;}   
} 