.lottery-card {

	&.lottery-card-document-upload {
		padding: 0;
		margin-top: rem(35);

		.button {
			width: auto;
			margin: 0 auto rem(17.5);
			padding: rem(15) rem(40);
			min-width: rem(155);
			display: inline-block;
		}

		.terms {
			text-align: left;
			padding: rem(20) rem(25);
			border-top: 1px solid #E3E3E3;

			p {
				font-size: em(11);
				color: #7A7B78;
				margin: 0;
			}
		}

		.more {
			margin-bottom: em(20);
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.file-upload {
	display: flex;
	flex-wrap: wrap;
	text-align: left;
	padding: rem(25) rem(15) rem(20);
	margin: rem(15) rem(10);

	.upload-image {
		margin: auto 0;
		width: 30%;
		flex: 0 0 30%;
		margin-right: rem(25);
		height: auto;
		align-self: flex-start;
	}



	.file-upload-container {
		font-size: em(16);
		font-family: $openSans;
		line-height: 1.2;
		flex: 1;

		.file-upload-title {
			display: block;
			font-weight: 600;
			padding: rem(10);
			margin-bottom: rem(10);
			margin-left: -5px;
		}

		span {
			line-height: 1.3;
		}

		.error {
			color: red;
		}
	}

	input[type="file"] {
		display: none;
	}
}

@media only screen and(max-width: 390px) {
	.lottery-card.lottery-card-document-upload .button {
		min-width: 0;
		margin-right: 0;
	}
}