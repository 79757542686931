.input-other {
    align-items: center;

    &.active {
        display: flex !important;
    }

    span {
        color: $blue;
        margin-right: rem(5);
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input, select, textarea {
    user-select: text !important;

    &:disabled {
        border-color: rgba(0,0,0,0.3) !important;
        color: rgba(0,0,0,0.3) !important;

        &::placeholder {
            color: rgba(0,0,0,0.3) !important;
        }
    }
}

.password-strength {
    .password-strength--meter {
        height: rem(15);
        width: 95%;
        border-radius: rem(25);
        margin: 0 2.5% rem(15);
    }

    .password-strength--validation {
        p {
            text-align: left;
            margin: 0 0 rem(5) !important;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;

            svg {
                height: rem(17.5);
                margin-right: rem(7.5);
                max-width: rem(25);
            }
        }

        p:last-child {
            margin-bottom: rem(15) !important;
        }

        .password-strength--title {
            color: $orange;
            font-weight: 700;
            margin: 0 0 rem(10) !important;
        }
    }
}

.toggle-password {
    position: absolute;
    right: rem(15);
    top: rem(14);
    width: rem(20);
    height: rem(20);
    transition: all ease-in-out 0.19s;

    * {
        fill: $blue;
        transition: all ease-in-out 0.1s;
    }

    &.active {
        * {
            fill: $orange;
        }
    }
}

/* disable password toggle for IE/Edge */
_:-ms-input-placeholder, :root .toggle-password {
    display: none;
}

@supports (-ms-ime-align:auto) {
    .toggle-password {
        display: none;
    }
}

.has-placeholder {
    position: relative;
    display: flex;
    flex-direction: column;
}

form {
    h3 {
        color: $orange;
        font-weight: 600;
        font-size: em(16);
    }

    p {
        color: $blue;
        font-family: $openSans;
        font-weight: 400;
        font-size: em(14);
    }

    .form-section {
        margin-top: rem(35);

        input {
            &[type="submit"] {
                margin-top: rem(15);
            }
        }
    }

    .input-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 100%;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: rem(12);
        flex-wrap: wrap;

        .input-error {
            margin-top: rem(7.5);
        }

        * {
            margin-bottom: 0 !important;
        }

        > * {
            margin-right: rem(5);
            flex: 1 1 auto !important;
            width: 0;

            &:last-child {
                margin-right: 0;
            }
        }

        label {
            input, button {
                width: 100%;
            }
        }

        button {
            flex: 0 0 auto;
            width: auto;
            align-self: center;
            cursor: pointer;
            font-weight: 600;
            font-size: em(14);
            letter-spacing: 0;
            display: inline-block;

            svg {
                width: rem(17.5);
                height: rem(17.5);
            }
        }

        input {
            &[type="submit"] {
                margin-top: 0 !important;
                width: 100%;
                -webkit-appearance: none;

                &:active {
                    background-color: inherit;
                    box-shadow: none;
                }
            }
        }
    }

    .input-error {
        text-align: left;
        display: block;
        color: #D0021B;
        font-size: 14px;
        font-weight: 600;
        padding: rem(5) rem(22.5);
        margin-bottom: rem(10);
        flex: 0 0 100% !important;
    }

    input, textarea, select, .select-dropdown {
        margin-bottom: rem(12.5);
    }

    label {
        cursor: pointer;
        position: relative;

        &.input-underline {
            input, textarea {
                border: none;
                border-bottom: 2px solid $blue;
                border-radius: 0;
                width: 75%;
                font-size: em(16);

                &::placeholder {
                    font-weight: 700;
                }
            }
        }

        &.input-white {
            input, textarea {
                border-color: white;
                background-color: transparent;
                color: white;
            }
        }

        &.input-blue {
            input, textarea {
                border-color: $blue;
                background-color: transparent;
                color: $blue;

                &::placeholder {
                    color: $blue;
                }
            }
        }

        &.input-square {
            input, textarea {
                border-radius: rem(20) !important;
            }
        }

        &.input-orange-solid {
            input, textarea, button {
                color: white;
                background-image: linear-gradient(to right, #F5993C 0%, #F76B1C 51%, #F5993C 100%) !important;
                background-size: 150% auto !important;
                border: none;
            }
        }

        &.input-green-solid {
            input, button {
                border: none;
                background-image: linear-gradient(to right, #90bc40 0%, #429321 51%, #90bc40 100%);
                background-size: 150% auto;
                color: white;
            }
        }

        &.input-full {
            width: 100%;

            input, textarea, button {
                width: 100%;
            }
        }

        &.input-wide {
            input, button {
                padding-right: rem(75);
                padding-left: rem(75);
            }
        }

        &.has-error {
            color: #D0021B;

            input, textarea {
                border-color: #D0021B;
                margin-bottom: rem(7.5);

                &::placeholder {
                    color: #D0021B;
                }

                &:before {
                    border-color: #D0021B;
                }
            }
        }
    }

    textarea {
        background-color: transparent;
        border: 2px solid white;
        font-size: em(14);
        font-family: $openSans;
        color: white;
        border-radius: rem(20);
        padding: rem(10) rem(20) rem(12);
        outline: none;
        height: rem(125);
        resize: none;
        font-weight: 600;

        &::placeholder {
            color: white;
            font-size: inherit;
            font-weight: 600;
            opacity: 0.64 !important;
        }
    }

    button {
        display: inline-block !important;
        text-align: center;
    }

    input, button {
        outline: none;
        line-height: 1;

        &[type="text"], &[type="number"], &[type="password"], &[type="email"], &[type="date"] {
            background-color: transparent;
            border: 2px solid white;
            font-size: 14px !important;
            color: white;
            border-radius: rem(50);
            padding: rem(10) rem(20) rem(12);
            -webkit-appearance: none;
            position: relative;
            overflow: visible;
            font-weight: 600;
            height: 45px;
            line-height: 1;
            font-family: $openSans;
            -moz-appearance: textfield;
            appearance: textfield;
            margin-bottom: .5rem;

            &::placeholder {
                color: inherit;
                line-height: 20px;
                font-size: inherit !important;
                opacity: 0.64;
            }

            &:-ms-input-placeholder {
                font-size: 14px !important;
            }
        }

        &[type="submit"] {
            border: 2px solid $orange;
            background: none;
            box-shadow: none;
            border-radius: rem(50);
            color: $orange;
            padding: rem(13.5) rem(30);
            font-size: em(16);
            font-weight: 600;
            font-family: $proximaSoft;
            align-self: flex-start;
            min-height: rem(45);
            cursor: pointer;
            transition: all ease-in-out 0.2s;

            &:hover {
                background-color: $orange;
                color: white;
            }
        }

        &[type="image"] {
            margin-left: rem(20);
        }

        &[type="radio"], &[type="checkbox"] {
            width: 0;
            position: relative;
            margin: 0;
            cursor: pointer;
            opacity: 0;
            visibility: hidden;

            &:checked {
                + .checkbox {
                    &:before {
                        background-color: $blue;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        background: white;
                        width: rem(5);
                        height: rem(5);
                        border-radius: 50%;
                        left: rem(8.5);
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }

            + .checkbox {
                position: absolute;
                width: auto;
                top: 50%;
                transform: translateY(-50%);
                left: 0;

                &:before {
                    content: '';
                    width: rem(22);
                    height: rem(22);
                    border-radius: 50%;
                    background-color: white;
                    border: 2px solid $blue;
                    box-sizing: border-box;
                    display: block;
                }
            }
        }
    }

    #credit-card-number {
        font-size: 2rem;
    }

    /** Paypal Hosted Fields */

    .hosted-field {
        background-color: transparent;
        border: 2px solid white;
        font-size: 14px !important;
        border-radius: rem(50);
        padding: rem(10) rem(20) rem(12);
        -webkit-appearance: none;
        position: relative;
        overflow: visible;
        font-weight: 600;
        height: 45px;
        line-height: 1;
        font-family: $openSans;
        -moz-appearance: textfield;
        appearance: textfield;
        border-color: $blue;
        color: $blue;

        &::placeholder {
            color: $blue;
            line-height: 20px;
            font-size: inherit !important;
            opacity: 0.64;
        }

        &:-ms-input-placeholder {
            font-size: 14px !important;
        }
    }

    .hosted-fields--label {
        text-transform: uppercase;
        color: #1A1F71;
        font-weight: 600;
        font-size: .75rem;
        opacity: .75;
        line-height: 2;
        padding-left: .5rem;
    }

    .braintree-hosted-fields-invalid, .hosted-field.is-invalid {
        border: 2px solid #ed574a !important;
    }

    .invalid-feedback {
        display: none;
        width: 100%;
        margin: 0.25rem auto 0.5rem;
        color: #ed574a;
        padding-left: 1rem;
        font-weight: bold;
    }

    .invalid-feedback.show {
        display: block;
    }

    #submit-1001[disabled], #new-credit-card[disabled], .paypal-update-button[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.lottery-date-search {
    p {
        color: white;
        margin-bottom: rem(15);
        font-weight: 700;
    }

    input, select-dropdown {
        margin: 0 !important;
        font-family: $proximaSoft !important;
        font-weight: 500 !important;
    }
}

.radio-container {
    padding: 0;
    margin: rem(10) auto rem(15) !important;
    text-align: left;
    width: 90%;

    textarea {
        width: 100%;
        margin-top: rem(15);
    }

    &.has-error {
        label, .label-for {
            color: #D0021B;

            input {
                &:before {
                    border-color: #D0021B;
                }
            }
        }
    }

    .input-error {
        margin-top: rem(10);
    }

    label, .label-for {
        text-align: left;
        display: flex !important;
        margin-bottom: rem(15);
        color: $blue;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        &.disabled {
            color: rgb(180,180,180);

            .checkbox:before {
                border: 2px solid rgb(180,180,180);
            }

            input[type="radio"]:checked {
                + .checkbox:before {
                    background-color: rgb(180,180,180);
                }
            }
        }

        &:last-of-type {
            margin-bottom: 0;
            margin-right: 0;
        }


        &.over-limit {
            opacity: .5;
            cursor: not-allowed;

            &:active {
                pointer-events: none;
            }
        }


        .terms-conditions-check {
            position: relative;

            input[type="checkbox"] {
                pointer-events: none;

                &:before {
                    top: -5px;
                }

                &:after {
                    top: 5px !important;
                }
            }
        }

        input {
            margin-right: rem(30);
            width: 0 !important;

            &:checked {
                ~ .label-content {
                    .radio-container {
                        display: inline-flex !important;
                    }
                }
            }
        }

        .label-content {
            font-size: em(14);
            margin-top: rem(-3.5);

            span {
                color: $blue;
                font-weight: 400;
                font-size: inherit;
                font-family: $openSans;
                margin-bottom: rem(10);
                line-height: 1.4;
                display: block;
            }

            .label-for {
                margin-top: rem(10);
                margin-bottom: rem(10);

                > span {
                    margin-bottom: 0;
                    margin-top: rem(-3.5);
                }
            }

            .input-error {
                color: $red;
                padding: 0;
                font-weight: 600;
            }
        }
    }

    .radio-container {
        display: none !important;
        flex-direction: column;
    }
}

.input-range {
    position: relative;
    display: inline-block;
    width: 100%;

    .input-range-container {
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        line-height: 1;
        height: rem(12);
        width: 100%;
    }

    .output-range-container {
        position: absolute;
        left: 0;
        bottom: calc(100% + #{rem(15)});
        width: rem(33);
        height: rem(26);
        display: flex;
        align-items: center;
        justify-content: center;
        background: $orange;
        transform: translateX(-50%);
        color: white;
        border-radius: 2.5px;

        output {
            color: white;
            font-size: em(12);
            font-weight: 600;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: rem(2);
            border-color: $orange transparent transparent transparent;
        }
    }



    input[type=range] {
        width: 100%;
        -webkit-appearance: none;
        border-radius: 50px;
        height: rem(8);
        overflow: visible;
        margin: 0;
        background-color: #EFEFEF;
        width: 100%;
        line-height: 1;
        outline: none;


        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            background: white;
            box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
            width: rem(20);
            height: rem(20);
            border-radius: 50%;
        }

        &::-moz-range-progress {
            background-color: $orange;
        }

        &::-moz-range-track {
            background-color: #E6E6E6;
        }

        &::-ms-fill-lower {
            background-color: $orange;
        }

        &::-ms-fill-upper {
            background-color: #E6E6E6;
        }

        &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            border-radius: 50px;
        }
    }
}

@supports (-ms-ime-align:auto) {
    .toggle-password {
        display: none !important;
    }

    input, textarea, select {
        &::placeholder {
            line-height: 1 !important;
        }
    }

    .input-range {
        .input-range-container {
            overflow: hidden;
        }

        input[type=range] {
            height: auto;
            width: 100%;

            &::-ms-thumb {
                border: none;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background: white;
                box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
            }

            &::-ms-track {
                background: transparent;
                border-color: transparent;
                border-width: 10px 0;
                color: transparent;
            }
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .toggle-password {
        display: none !important;
    }

    input, textarea, select {
        &::placeholder {
            line-height: 1 !important;
        }
    }

    .input-range {
        .input-range-container {
            overflow: hidden;
        }

        input[type=range] {
            height: auto;
            width: 100%;
            margin-top: 12.5px;

            &::-ms-thumb {
                border: none;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background: white;
                box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
            }

            &::-ms-track {
                background: transparent;
                border-color: transparent;
                border-width: 10px 0;
                color: transparent;
            }

            &::-ms-tooltip {
                display: none;
            }
        }
    }
}

.terms-conditions-check {
    text-align: left;
    color: $blue;
    display: flex;
    justify-content: space-between;
    margin: rem(10) 0 rem(25);
    position: relative;

    .input-error {
        color: #D0021B;
        padding: 0;
        margin-top: rem(15);
    }

    input[type="checkbox"] {
        margin-right: rem(35) !important;

        &:checked {
            + .checkbox {
                &:before {
                    background-position: center;
                    background-color: transparent;
                    background-image: url(../icons/icon-check.svg);
                    background-size: 50%;
                    background-repeat: no-repeat;
                    transition: none;
                }

                &:after {
                    display: none;
                }
            }
        }

        + .checkbox {
            &:before {
                border-radius: 0;
                top: 0;
                transform: none;
                width: rem(24);
                height: rem(24);
            }
        }
    }

    > span {
        line-height: 1.3;
        width: 100%;
        display: block;
        text-align: left;
    }

    a {
        color: inherit;
        text-decoration: underline;
        display: inline;
        font-weight: 700;
    }
}

.checkbox-offset {
    padding: 0 rem(5) 0 rem(10);
    margin-bottom: rem(10);
}
#checkSavePayPal {
    &.disabled {
        opacity: .5;
        cursor: not-allowed;
    }
}
.input-tooltip {
    position: relative;
    overflow: visible;

    input {
        margin-bottom: 0;
    }

    .tooltip-action {
        width: rem(20);
        height: rem(20);
        display: block;
        position: absolute;
        right: rem(15);
        top: rem(12.5);
        border-radius: 50%;
        border: 2px solid $blue;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: visible;

        &:before {
            content: 'i';
            font-weight: 700;
            color: $blue;
            font-size: em(12);
        }

        &.active {
            + .tooltip-content {
                display: block;

                &.tooltip-content-ccv {
                }
            }
        }
    }
}


.tooltip-content {
    display: none;
    position: absolute;
    bottom: calc(100% + #{rem(10)});
    left: 0;

    &.tooltip-content-ccv {
        width: 100% !important;
        flex: auto !important;
        align-items: center;
        text-align: left;
        border: 1px solid $blue;
        background: white;
        border-radius: rem(10);
        overflow: hidden;
        padding: rem(10);
        justify-content: space-between;
        box-shadow: 0 0 3px 1px rgba(0,0,0,0.2);


        &:before {
            font-size: em(13);
            content: 'CVV is an anti-fraud security feature. The 3 CVV numbers are located on the back of your card.';
            flex: 0 0 55%;
            margin-right: 5%;
            line-height: 1.2;
            font-weight: 700;
        }

        &:after {
            content: '';
            flex: 1;
            text-align: center;
            line-height: 1.5;
            background-image: url('../icons/ICON-CCV.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            display: block;
            flex: 0 0 40%;
            align-self: stretch;
            min-height: rem(75);
        }
    }

    &.active {
        display: flex;
    }
}


@media only screen and (min-width: 980px) {
    input[type="submit"].button-orange {
        &:hover {
            background: $orange;
            color: white;
        }
    }

    label {
        &.input-blue {
            input[type="submit"], button {
                &:hover {
                    background: $blue;
                }
            }
        }

        &.input-white {
            input[type="submit"], button {
                &:hover {
                    background: white;
                    color: $orange;
                }
            }
        }

        &.input-orange-solid, &.input-green-solid {
            input, button {
                &:hover {
                    background-position: right center;
                }
            }
        }
    }
}



.has-float-label {
    display: block;

    label, > span.labeltext {
        position: absolute;
        left: 20px;
        /*top: -5px;*/
        cursor: text;
        font-size: 90%;
        opacity: 1;
        -webkit-transition: all .2s;
        transition: all .2s;
        color: #E25F2C;
        font-weight: 500;
        transform: translateY(-5px);

        @supports (-ms-ime-align:auto) {
            top: 0;
        }
    }

    input, user-select, textarea {
        // margin-top: 15px;
        &:placeholder {
            opacity: 1;
            transition: all .2s
        }

        &:placeholder-shown:not(:focus) + * {
            font-size: 100%;
            opacity: 0;
            transition: all .4s
        }

        &:focus {
            margin: 12px 0 !important;
            transition: all .2s;

            ~ .toggle-password {
                top: 1.875rem !important;
            }
        }

        &:not(:focus) + * {
            opacity: 0;
            transition: all .2s;
        }
    }
}


@media only screen and (max-width: 600px) {
    #dateInput {
        margin: 14px 0 !important;
    }

    #dateInput + span {
        opacity: 1;
        transition: all .2s
    }
}