
.lottery-app-container {
    .lottery-app {
        font-size: em(16);
         padding: 10px;
         border-radius: 30px;
       // border: 2px solid #fff;
        margin: 0 1rem;

        span {
            line-height: 1.2;
            margin-left: rem(15);
            font-family: $openSans;
            margin-top: 0;
            font-weight: 600;
        }

        svg {
            width: rem(30);
            height: rem(30);
            overflow: visible;


            * {
                fill: #fff;
            }

            circle + path {
                fill: white;
            }

            &#linkedin {
                .outer {
                    fill: $blue;
                }

                .inner {
                    fill: #ffffff;
                }
            }
        }
    }
}

.lottery-card--social {
    display: flex;
    justify-content: center;

    a {
        color: $blue;
        font-size: em(34);
        margin-right: rem(25);
        padding: rem(10);

        svg {
            transition: all ease-in-out 0.2s;

            path {
                transition: all ease-in-out 0.2s;
            }
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

@media only screen and (min-width: 680px) {
    .lottery-footer-cards {
        > .container {
            display: flex;
            flex-wrap: wrap;

            .lottery-card {
                width: 100%;
                max-width: 100%;
            }

            > * {
                flex: 1;
                margin-right: 1.5%;
                margin-bottom: 0;

                &:last-child {
                    margin-right: 0;

                    .lottery-card {
                        height: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 960px) {
    .lottery-footer-cards {
        .footer-cards--left {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: rem(-20);

            .lottery-card {
                flex: 0 0 48.25%;
                min-width: 0;
                width: 100%;
                max-width: 48.25%;
                margin: 0 0 rem(20);

                &:first-child {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                .download-buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    max-width: 460px;
                    margin: auto;

                    img {
                        max-width: 100%;
                        width: 220px;
                        height: auto;

                        &:first-child {
                            margin-right: 20px;
                        }
                    }
                }

                .button {
                    margin-top: 1rem;
                }

                .syndicate-button {
                    max-width: 80%;
                    cursor: pointer;
                }
            }

            .lottery-app-container {
                margin-top: rem(40);

                .lottery-app {
                    flex: 0 0 24%;
                    max-width: 24%;
                    display: flex;
                    flex-direction: column;
                    padding: rem(10) rem(5);
                    margin: 0;
                    text-align: center;
                    transition: all ease-in-out 0.2s;

                    &:nth-of-type(3n + 3) {
                        margin-right: 0;
                    }

                    &:nth-of-type(1n + 4) {
                        margin-bottom: 0;
                    }

                    svg {
                        margin-bottom: 0;
                        margin-right: 0;

                        path, polygon {
                            transition: fill ease-in-out 0.2s;
                        }
                    }

                    span {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:hover {
                        background: $blue;
                        color: white;
                        opacity: 1;

                        svg {
                            * {
                                fill: white;
                            }

                            circle + path {
                                fill: $blue;
                            }

                            &#linkedin {
                                .outer {
                                    fill: #fff;
                                }

                                .inner {
                                    fill: $blue;
                                }
                            }
                        }
                    }
                }
            }

            .lottery-card--social {
                a {
                    &:hover {
                        opacity: 1;

                        svg {
                            transform: scale(1.1);

                            * {
                                fill: $orange;
                            }
                        }
                    }
                }
            }
        }

        .footer-cards--right {
            display: flex;
            align-items: stretch;
            position: relative;
            flex-direction: column;
            flex-wrap: wrap;

            .lottery-card {
                flex: 1;
            }

            .tab-nav {
                justify-content: flex-start;
                border-bottom: none;
                padding: 0 rem(40);

                li {
                    flex: 0 0 49%;
                    text-align: left;
                    justify-content: flex-start;
                    display: flex;
                    padding: 0;
                    border-bottom: none;
                    pointer-events: none;

                    svg {
                        margin: 0 rem(15) 0 0;
                        max-width: rem(50);
                        flex: 0 0 auto;
                    }
                }
            }

            .lottery-card--content {
                h3 {
                    br {
                        display: none;
                    }
                }
            }

            .tabs-container {
                display: flex;
                justify-content: space-between;
                padding: 0 rem(40);
                width: 100%;
                max-width: 100%;

                .tab-content {
                    flex: 0 0 45%;
                    opacity: 1;
                    visibility: visible;
                    overflow: visible;
                    height: auto;
                    display: block;
                    z-index: 0;
                    flex: 1;

                    ul {
                        margin: rem(25) 0 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .lottery-app-container {
        justify-content: space-between;

        .lottery-app {
            margin-bottom: 0 !important;
        }
    }

    .lottery-footer-cards {
        .footer-cards--left {
            .lottery-card {
                .syndicate-button {
                    max-width: 220px;
                    cursor: pointer;
                }

                .download-buttons {
                    display: block;

                    img {
                        max-width: 100%;
                        width: 220px;
                        margin: 0 auto 20px;

                        &.download-android {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .lottery-app {
        margin: auto !important;
    }

    h3.fingertips {
        br {
            display: none;
        }
    }
}
