// Sass Mixins
@import "partials/mixins";

// Sass Variables
@import "partials/variables";

// CSS Reset
@import "partials/reset";

/* Misc. */

html {
    left: 0;
    top: 0;
    width: 100%;
}

body {
    text-align: center;
    position: relative;
    overflow-x: hidden !important;


    &:before {
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.5);
        opacity: 0;
        position: fixed;
        z-index: -1;
        visibility: hidden;
        transition: all ease-in-out 0.3s;
    }

    &.scroll-hidden {
        overflow: hidden;

        &:before {
            opacity: 1;
            visibility: visible;
            z-index: 999;
        }
    }

    &.popup-active {
        &:before {
            z-index: 1000;
        }
    }
}

main {
    transition: all ease-in-out 0.2s;
    position: relative;

    &#main {
        //top: 138px;
    }
}

//Blueshyft popup styles

.popup .popup-inner {
    p.blueshyft-amount {
        font-size: 2rem;
        margin: auto;
        text-align: center;
        font-weight: 600;
    }

    p.blueshyft-text {
        font-size: 1rem;
        text-align: center;
    }

    p.blueshyft-code {
        font-size: 2rem;
        background: #fff;
        border-radius: 5px;
        color: $blue;
        text-align: center;
    }

    #success-gif {
        transform: scale(0.01);
        background: $green;
        border-radius: 100%;
        display: block;
        margin: auto;
        width: 75%;
        height: 75%;

        .checkmark {
            stroke-width: 4;
            stroke: #fff;
            stroke-miterlimit: 10;
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
        }

        &.active {
            animation: circle .6s ease .2s forwards;

            .checkmark {
                animation: stroke .8s ease-out .7s forwards;
            }
        }
    }

    @keyframes circle {
        to {
            transform: scale(1)
        }
    }

    @keyframes stroke {
        to {
            stroke-dashoffset: 0
        }
    }
}

#deposit-D--payment-method-12, #deposit-L--payment-method-12 {
    p {
        margin: auto;
        font-size: 1rem;
        text-align: left;
        font-weight: 600;
    }

    ul {
        margin: auto;
        padding: 1rem 1.5rem;
        text-align: left;

        li {
            color: $blue;
            list-style: disc;
            padding: 5px 0;
        }
    }
}


* {
    box-sizing: border-box;
    color: $grey;
    max-width: 100%;
}

.site-container {
    max-width: 1280px;
    margin: 0 auto;
}

.print-container {
    display: none;
}

.container {
    margin: 0 auto;
    width: 92.5%;
    max-width: rem(1180);

    &.container-large {
        width: 95%;
    }

    &.container-small {
        width: 80%;
    }

    &.container-desktop-small {
        max-width: rem(980);
    }
}

div, h1, h2, h3, h4, h5, h6, p, li, a {
    font-family: Arial, sans-serif;
}

.wf-active {
    div, h1, h2, h3, h4, h5, h6, p, li, a {
        font-family: $proximaSoft;
    }
}

/*div, h1, h2, h3, h4, h5, h6, p, li, a {
	font-family: $proximaSoft;
}*/

img {
    width: 100%;
    height: auto;
    user-select: none;
}

h1 {
    font-size: em(28);
}

h2 {
    font-size: em(24);
}

h3 {
    font-size: em(22);
}

h4 {
    font-size: em(18);
}

h5 {
    font-size: em(16);
}

h6 {
    font-size: em(16);
}

p, li {
    font-size: em(16);
    line-height: 1.4;
    font-weight: 400;
}

p {
    a {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        text-decoration: underline;
    }
}

a {
    display: inline-block;
    transition: color ease-in-out 0.2s;
    user-select: none;
}

span, i, b, strong, sup {
    font-weight: inherit;
    color: inherit;
    font-family: inherit;
}

b, strong {
    font-weight: 700;
}

section {
    &.general-section {
        padding: rem(45) 0;
    }

    &.bg-blue-gradient {
        padding: rem(25) 0 rem(35);
    }
}

.bg-blue-gradient {
    background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
}

.local-hash {
    padding-top: rem(140);
    margin-top: rem(-156);
    display: block;
}

.el-desktop {
    display: none !important;
}

.bold {
    font-weight: 700;
}

.orange {
    color: $orange;
}

.underline {
    text-decoration: underline;
}

.pointer {
    cursor: pointer;
}

.wrap-word {
    word-break: break-word;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.bold.underline:hover {
    opacity: 0.8;
    cursor: pointer;
}

.blue {
    color: $blue;
}

.white {
    color: white;
}

.border-bottom {
    border-bottom: 1px solid white;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    padding: 0 5% 1rem;

    @media only screen and (max-width: 680px) {
        font-size: 1.1em;
    }
}

.side-borders {
    display: flex;
    align-items: center;

    &:before,
    &:after {
        content: "";
        flex: 1;
        height: 1px;
        background-color: $blue;
    }

    &:before {
        margin-right: rem(10);
    }

    &:after {
        margin-left: rem(10);
    }
}

.social-full {
    width: 100%;
    flex: 0 0 100%;
    position: relative;
    height: rem(45);
    border-radius: 40px;
    margin-bottom: rem(10);

    span {
        border-radius: 50%;
        width: rem(43);
        height: rem(43);
        background: white;
        position: absolute;
        left: 1px;
        top: 1px;

        svg {
            width: 30%;
        }
    }

    &.facebook {
        background-color: #425893;

        span {
            svg {
                * {
                    fill: #425893;
                }
            }
        }
    }

    &.google {
        background-color: #cd5642;

        span {
            svg {
                * {
                    fill: #cd5642;
                }
            }
        }
    }

    &.apple {
        background-color: #58595B;

        span {
            svg {
                width: 1.8rem;
            }
        }
    }
}

/* Flex Cols */

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

/* Misc. */

button {
    background: transparent;
    box-shadow: none;
    border: none;
    outline: none;
}

.section-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

button.button {
    font-size: em(16);
    font-weight: 800;
    font-family: inherit;
}

.button {
    border: 2px solid transparent;
    padding: rem(12) rem(40) rem(14);
    border-radius: 100px;
    font-size: em(16);
    font-weight: 800;
    letter-spacing: rem(1);
    background: transparent;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    min-height: rem(45);
    user-select: none;
    transition: all ease-in-out 0.2s !important;
    text-align: center;
    position: relative;
    cursor: pointer;

    &.print {
        svg {
            max-height: rem(25);
            width: auto;
            margin: rem(-5) rem(15) rem(-5) rem(-5);

            * {
                stroke-width: 3px;
            }
        }
    }

    &.button-center {
        justify-content: flex-start;
        text-align: left;
    }

    &.button-full {
        width: 100%;
    }

    &.button-white {
        color: white;
        border-color: white;

        &.active {
            background-color: white;
            color: $orange;
        }
    }

    &.button-orange {
        color: $orange;
        border-color: $orange;
    }

    &.button-orange-solid {
        color: white;
        background-image: linear-gradient(to right, #F5993C 0%, #F76B1C 51%, #F5993C 100%);
        border: none;
        background-size: 150% auto;
    }

    &.button-blue-solid {
        color: white;
        background-image: linear-gradient(to right, #2DA0F1 0%, #0076D8 51%, #2DA0F1 100%);
        border: none;
        background-size: 150% auto;
    }

    &.button-green-solid {
        background-image: linear-gradient(to right, #90bc40 0%, #429321 51%, #90bc40 100%);
        background-size: 150% auto;
        color: white;
        border: transparent;
    }

    &.button-blue {
        border-color: $blue;
        color: $blue;
    }

    &.button-red {
        border-color: $red;
        color: $red;

        &:hover {
            background: $red;
            color: $white;
        }
    }

    &.button-icon-inline {
        position: relative;
        overflow: visible;

        .button-icon {
            border: 2px solid $blue;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: rem(5);
            position: absolute;
            left: rem(10);
            top: 50%;
            transform: translateY(-50%);
            transition: all ease-in-out 0.2s;
            overflow: visible;

            svg {
                width: rem(12.5);
                height: auto;
                max-height: rem(12.5);
            }
        }
    }

    &.button-icon {
        display: flex;
        font-size: em(13);
        font-weight: 600;
        font-family: $openSans;
        letter-spacing: 0;
        justify-content: center;
        align-items: center;
        padding: rem(12) rem(10);
        border: none;

        svg {
            display: block;
            width: rem(23);
            flex: 0 0 rem(23);
            margin-right: rem(10);
        }
    }

    &.button-square {
        border-radius: rem(20) !important;
    }

    &.add-quickplay {
        font-size: em(10);
        font-weight: 700;
        padding: rem(7.5) rem(20);
        line-height: 1.3;
    }

    &.results-expand {
        &:after {
            display: none;
        }
    }

    &.button-follow {
        text-align: center;
        width: 100%;
        box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
        border-radius: 5px;
        color: $orange;
        font-weight: 600;
        font-size: rem(22);
        display: flex;

        svg {
            margin-left: rem(20);
            height: rem(32);
            width: auto;

            * {
                fill: $blue;
                transition: all ease-in-out 0.15s;
            }
        }
    }
}

.tm {
    font-size: 50%;
    vertical-align: top;
    display: inline !important;
    font-weight: 600;
}

.rt {
    display: inline !important;
}

.remove {
    &:before {
        content: '';
    }
}

.button, button {
    &.loading {
        position: relative;
        overflow: hidden;
        background: none;

        &:before {
            content: '';
            background: white;
            background-image: url('../icons/spinner.gif');
            background-size: rem(30);
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.default-list {
    padding: 0;
    margin: rem(20) 0 rem(20) rem(10);
    list-style: none;
    text-align: left;

    li {
        color: $blue;
        font-size: em(18);
        font-weight: 600;
        margin-bottom: rem(7.5);

        a {
            color: $blue;
            font-weight: 600;
            font-size: inherit;
            text-decoration: underline;
            display: inline-block;
        }
    }
}

.icon-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400 !important;
    font-size: em(22);

    .icon {
        width: rem(32);
        height: rem(32);
        flex: 0 0 rem(32) !important;
        border-radius: 50%;
        border: 2px solid white;
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-right: rem(15);
        flex: 0 0 auto;

        svg {
            max-width: rem(14);
        }
    }

    &.blue {
        color: $blue;

        .icon {
            border-color: $blue;

            svg {
                * {
                    fill: $blue;
                }
            }
        }
    }
}

.icon-text {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        border: 2px solid $blue;
        border-radius: 50%;
        padding: rem(6);
        width: rem(32);
        height: rem(32);
        overflow: visible;
        margin-left: rem(10);
    }

    &.icon-blue {
        border-color: $blue;

        svg {
            * {
                fill: $blue;
            }
        }
    }
}

/* Forms */
@import "components/forms";
@import "components/style-select";

/* Header - Footer */
@import "components/header";
@import "components/footer";

/* Banner */
@import "components/banners";

/* Misc. Parts */
@import "components/futureball";
@import "components/lottery-numbers";
@import "components/matched-ticket";

/* Cart */
@import "components/cart";
/*@import "components/checkout";*/
@import "components/express-checkout";


/* Lottery Cards */
@import "components/lottery-card";
@import "components/lottery-deck";
@import "components/lottery-card-results";
@import "components/lottery-card-favourite";
@import "components/lottery-card-flip";
@import "components/lottery-card-account";

/* Homepage Lottery Tabs */
@import "components/lottery-tabs";

/* Lottery CTA */
@import "components/lottery-cta";

/* Lottery General Sections */
@import "components/lottery-general";
@import "components/lottery-blurb";

/* Lottery Game */
@import "components/lottery-game";

/* My Account */
@import "components/account";
@import "components/my-draws";
@import "components/my-history";

/* Secondary Pages */
@import "components/how-it-works";
@import "components/contact";
@import "components/site-map";

/* Confirmations */
@import "components/confirmations";

/* Popups */
@import "components/popups";

/* Document Upload */
@import "components/document-upload";

/* Lottery Search */
@import "components/lottery-search";

/* Lottery Signup */
@import "components/lottery-signup";
@import "components/signup";

/* Information Faqs */
@import "components/_lottery-information-faqs.scss";

/* Lottery Regulations */
@import "components/lottery-regulations";

/* Footer Sponsors */
@import "components/_lottery-footer-sponsors.scss";

/* Lottery Benefits */
/*@import "components/lottery-benefits";*/

/* Lottery Footer Cards */
@import "components/lottery-footer-cards";

/* Tabs */
@import "components/tabs";

/* Timer */
@import "components/timer";

/* Notifications */
@import "components/notifications";

/* Result Listing */
@import "components/lottery-result-listing";
@import "components/lottery-play-listing";

/* Faqs */
@import "components/faqs";

/* Landing */
@import "components/about-betting";
@import "components/landing";
@import "components/charity";
@import "components/video-embed";

/* syndicates */
@import "components/syndicates";

/* combo */
@import "components/combo";

/* all lottery page */
@import "components/all-lotteries";

/* support page */
@import "components/support";

/*app	*/
@import "components/app";

/* Footer Accordions	*/
@import "components/footer-accordions";

.lottery-flag {
    width: rem(60);
    height: rem(60);
}

.lottery-flag-small {
    width: rem(40);
    height: rem(40);
}

.cta-highlight {
    color: $orangeLight;
}

.read-more {
    text-decoration: underline;
    font-size: em(16);
    line-height: 1.4;
    cursor: pointer;

    &.red {
        color: $red;
    }
}

.select-options {
    li {
        img {
            width: 30px;
        }
    }
}

.info-icon {
    cursor: pointer;
    border: 2px solid $blue;
    border-radius: 50%;
    width: rem(23);
    height: rem(23);
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: 'i';
        font-weight: 700;
        font-size: 13px;
        color: $blue;
        font-family: $openSans;
    }
}

.signup-error {
    color: $red;
    padding-bottom: em(15);
    display: none;
}

.is-label {
    padding: 5px;
    color: $white;
    text-transform: uppercase;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    max-width: 150px;
    font-size: .7rem;
    letter-spacing: 1px;
    position: absolute;
    min-width: 100px;
    text-align: center;
    justify-content: center;     

    &.expired {
        right: 1.2rem;
        padding: 3px;
        min-width: 90px;
        border-radius: unset;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background: red;
        color: #fff;
        font-weight: bold;

        &.is-checkout {
            right: 1.7rem;
        }

        @media only screen and (max-width: 600px) {
            font-size: .6rem;
            right: .9rem;
            padding: 2px;
            min-width: 75px;

            &.is-checkout {
                right: 0.3rem;
            }
        }
    }
}

.mobile-slide {
    .is-label {
        transform: translate(-10px, -25px);
        max-width: 130px;
    }
}

.syndicates, .cart-items, .checkout-card, .account-draws {
    .is-label {
        margin-top: 1px;
        position: relative;
        color: #ffffff !important;

        &.syndicate {
            background: $colorSyndicates;
        }

        &.superdraw {
            background: $colorSuperdraw;
        }
    }
}

#account-draws {
    .is-label {
        position: relative;
        color: #ffffff;
        font-size: .6rem;
        letter-spacing: 1px;
    }
}

#guaranteed-power-main {
    // display: flex;
    max-width: 28px;
    max-height: 28px;
    color: $white;
    background-color: $orange;
    margin-left: 1px;
    font-size: .875rem;
    max-width: none;
    max-height: none;
    border-radius: unset;

    span {
        color: $white;
        top: 48%;
        font-weight: normal;
    }
}


#guaranteed-power-g {
    // display: flex;
    max-width: 28px;
    max-height: 28px;
    color: $white;
    background-color: #38A4D0;
    margin-left: 1px;
    font-size: .875rem;
    max-width: none;
    max-height: none;
    border-radius: unset;

    span {
        color: $white;
        top: 48%;
        font-weight: normal;
    }
}

#play-now--custom-form {
    #guaranteed-power-g {
        max-width: none;
        max-height: none;
        border-radius: unset;

        span {
            font-weight: bold;
            top: 50%;
        }
    }

    #guaranteed-power-main {
        max-width: none;
        max-height: none;
        border-radius: unset;
    }
}

.g-explainer {
    display: flex;
    align-items: center;
    line-height: 1;
    margin: 0 !important;
    line-height: 2;

    .g-symbol {
        width: 100%;
        height: 100%;
        margin: auto 1%;
        padding: 1% 0;
        max-width: 28px;
        min-width: 28px;
        max-height: 28px;
        min-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: #FFFFFF;
    }

    #g-symbol-main {
        background-color: $orange;
    }

    #g-symbol-supp {
        background-color: #38A4D0;
    }
}

@media only screen and (max-width: 600px) {
    .sm-display-none {
        display: none !important;
    }
}

@media only screen and (min-width: 980px) {
    .container-desktop {
        max-width: rem(1080);
        margin: 0 auto;
        width: 95%;
    }

    .el-desktop {
        display: block !important;
    }

    .el-mobile {
        display: none;
    }

    a {
        transition: opacity ease-in-out 0.2s;

        &:hover {
            opacity: 0.6;
        }
    }

    .button {
        &:hover {
            opacity: 1;
        }

        &.button-white {
            &:hover {
                background: white;
                color: $orange;
            }
        }

        &.button-orange {
            &:hover {
                background: $orange;
                color: white;
            }
        }

        &.button-orange-solid, &.button-green-solid, &.button-blue-solid {
            &:hover {
                background-position: right center;
            }
        }

        &.button-blue {
            &:hover {
                background: $blue;
                color: white;
            }
        }

        &.button-icon-inline {
            &:hover {
                background: $orange;
                color: white;
                border-color: $orange;

                .button-icon {
                    border-color: white !important;
                }

                svg {

                    * {
                        fill: white;
                        stroke: white;
                    }

                    circle {
                        fill: transparent;
                    }
                }
            }
        }

        &.button-follow:hover {
            background-color: $blue;
            color: white;

            svg {
                * {
                    fill: white;
                }
            }
        }
    }

    .read-more {
        transition: all ease-in-out 0.2s;

        &:hover {
            opacity: 0.7;
        }
    }
}

@media print {
    body:before {
        display: none;
    }

    header, footer, .banner, .tab-nav, .lottery-cta, .lottery-regulations, .lottery-footer-cards, .lottery-blurb, .buttons-container, .footer-bottom, .footer-logo.secondary-logo {
        display: none !important;
    }

    .container, .site-container {
        width: 100% !important;
        max-width: 100% !important;
    }

    footer:before {
        display: none;
    }

    * {
        -webkit-print-color-adjust: exact !important;
        -webkit-filter: opacity(1);
    }

    main {
        top: 0 !important;
    }

    .history-print-title {
        display: block !important;
        background-image: linear-gradient(to right, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);
        padding: 20px 0;

        p {
            color: white;
            font-size: 28px;
            margin: 0;
            font-weight: 600;
        }
    }

    .history-row.history-row-titles {
        font-size: 24px !important;
        border-color: #7A7B78;
    }

    .history-row {
        justify-content: flex-start !important;
        font-size: 18px !important;
        padding: 0 30px !important;

        .date {
            width: 100px !important;
            flex: 0 0 100px !important;
            margin-right: 20px !important;
        }

        .lottery {
            width: 200px !important;
            flex: 0 0 200px !important;
            margin-right: auto !important;
        }

        .cost, .balance {
            width: 100px !important;
            flex: 0 0 100px !important;
            font-size: 18px;
        }
    }


    .print-container {
        width: 85% !important;
        margin: 0 auto;
        display: block;
    }

    .account-history {
        margin: 0 !important;

        .tabs-container {
            margin: 50px auto;
            border-radius: 10px;
            overflow: visible;
            box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.1);
            width: 85% !important;

            .history-row {
                padding: 0;
            }
        }
    }

    .print-logo {
        img {
            width: 180px;
        }
    }

    footer {
        page-break-inside: avoid;
    }
}
