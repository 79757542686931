.lottery-card {
    &.lottery-card-favourite {
        .favourite-lottery {
            margin-bottom: rem(50);

            &:last-of-type {
                margin-bottom: 0;
            }

            .game-number-lines {
                .game-number-line {
                    .flex {
                        svg {
                            display: none;
                        }
                    }
                }
            }
        }

        .favourite-header {
            display: flex;
            align-items: center;
            justify-content: center;


            img {
                margin: 0;
                width: rem(30);
                height: rem(30);
                margin-right: rem(10);
            }

            h4 {
                margin: 0;
            }
        }


        .custom-numbers--action {
            .button {
                margin: rem(10) 0 0;
            }
        }

        .lottery-favourite {
            display: none !important;
        }
    }
}

@media only screen and (max-width: 768px) {
    .lottery-card {
        &.lottery-card-favourite {
            .favourite-lottery {
                .game-number-lines {
                    .game-number-line {
                        .lottery-numbers{
                            flex:auto;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 340px) {
    .lottery-card {
        &.lottery-card-favourite {
            .favourite-lottery {
                .game-number-lines {
                    .game-number-line {
                        .lottery-numbers {
                            flex: 0 0 100%;
                        }
                    }
                }
            }
        }
    }
}
