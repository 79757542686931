.ticket-link {
    text-decoration: underline;
    color: $grey;

    &.pending {
        text-decoration: none;
        cursor: default;
        color: inherit;
    }
}

.flex {
    display: flex;
}

.banner {

    &.banner-account {
        max-width: 80rem;
        border-radius: 8px;
        margin: 1rem auto;
        position: relative;
        padding: 0;
        background-color: $lightGrey !important;

        .button {
            transform: none !important;
        }

        > .container {
            max-width: rem(480);
            padding: rem(15) 0 rem(15);
            min-height: 325px;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-image: url(../icons/tlo-logo-transparent-dark.svg);
            background-size: 115% auto;
            background-repeat: no-repeat;
            background-position: center right;
            pointer-events: none;
            touch-action: none;
            background-size: cover;
        }

        * {
            z-index: 1;
            position: relative;
            color: $blue;
        }

        img {
            border-radius: 50%;
            width: rem(80);
            height: rem(80);
            border: 3px solid white;
            margin-bottom: rem(15);
        }

        h1 {
            font-weight: 700;
            margin-top: rem(5);
            margin-bottom: rem(5);
            display: inline-flex;
            line-height: 1;

            span {
                margin-left: rem(15);
            }

            &.verified {
                &:after {
                    font-size: 40%;
                    background-color: white;
                    border-color: white;
                }
            }
        }

        .account-no {
            margin-bottom: 1rem;
        }

        p {
            font-family: $proximaSoft;
            font-size: em(18);
            font-weight: 700;
            letter-spacing: 0.3px;
            color: $blue;
        }

        .account-unverified {
            border-radius: 5px;
            text-decoration: underline;
            display: block;
            cursor: pointer;
            background: red;
            color: #ffffff;
            font-weight: bold;
            padding: 1rem;
            font-size: 1.2rem;
        }

        .account-pending-review {
            background: $orange;
        }

        .account-suspended {
            background: $red;
            text-decoration:none !important;
        }

        .icon-round {
            border-radius: 50%;
            border: 2px solid white;
            width: rem(32);
            flex: 0 0 rem(32);
            height: rem(32);
            position: relative;
            background: #fff;

            svg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: auto;
                overflow: visible;
            }

            &.active {
                background: white;

                svg {
                    * {
                        fill: $orange;
                    }
                }
            }
        }

        #account-notifications {
            position: absolute;
            top: rem(17);
            right: 0;

            svg {
                width: rem(18);
            }
        }

        #account-edit {
            svg {
                width: rem(15);
            }
        }

        .balance {
            line-height: 1;
            margin: 1rem auto auto;

            > span {
                font-size: 145%;
                margin-top: rem(15);

                .icon-round {
                    margin-left: rem(15);
                    width: rem(26);
                    height: rem(26);
                    flex: 0 0 rem(26);

                    svg {
                        height: rem(12.5);
                    }
                }
            }
        }

        .account-balance {
            display: flex;
            max-height: 0;
            transition: all ease-in-out 0.5s;
            visibility: visible;
            opacity: 1;
            height: auto;

            > p {
                text-align: center;
                flex: 1;
                margin: 0;
                font-size: em(18);
                position: relative;
                opacity: 0;
                transition: all ease-in-out 0.5s 0.2s;

                &:first-of-type {
                    right: 150px;
                }

                &:last-of-type {
                    left: 150px;
                }

                span {
                    display: block;
                    font-size: 125%;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }

            &.active {
                max-height: 500px;


                > p {
                    opacity: 1;

                    &:first-of-type {
                        right: 0;
                    }

                    &:last-of-type {
                        left: 0;
                    }
                }
            }
        }

        .account-actions {
            > a {
                flex: 1;
                width: 0;
                margin-right: rem(12.5);
                font-size: em(16);
                padding: rem(12.5) 0 rem(13.5);
                border-color: $blue;

                &:last-of-type {
                    margin-right: 0;
                }

                &.active {
                    + p {
                        display: block;
                    }
                }
            }

            .button {
                margin-top: rem(25);
                min-width: 0;
            }

            > p {
                flex: 0 0 100%;
                margin-bottom: 0;
                display: none;

                span {
                    display: block;
                    font-size: 125%;
                }
            }
        }
    }
}

.account--action-tab {
    .container {
        max-width: rem(860);
    }

    .lottery-card-account {
        padding: rem(25) rem(20) !important;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &.active {
        padding: rem(20) 0;
    }
}

.lottery-card {
    &.lottery-card-account {
        padding: rem(25) rem(20);
        margin-bottom: rem(15);

        h3 {
            font-size: em(16);
            font-weight: 600;
            font-family: $openSans;
            margin: 0 0 rem(25);
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                display: flex;
                align-items: center;
            }

            svg {
                margin-left: rem(15);
                cursor: pointer;

                * {
                    fill: $orange;
                }
            }
        }

        h4 {
            font-size: em(20);
            color: $blue;
            margin: 0 0 rem(10);
        }

        p {
            font-size: em(14);
            text-align: left;
            margin: 0 0 rem(5);
        }

        form {
            h3 {
                margin: rem(30) 0 rem(25);
                display: inline-flex;
                position: relative;
            }

            input[type="submit"] {
                margin: rem(10) 0 0;
            }
        }

        > .payment-detail {
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.account-favourites {
    h5 {
        color: $white;
        font-size: 1rem;
        margin: auto auto 1rem;
    }

    .favourites-container {
        max-width: rem(780);
        margin: 0 auto;
        padding-top: rem(20);

        .lottery-card-favourite {
            overflow: visible;
            max-height: none;

            .play-now--game {
                max-width: rem(400);
                width: 100%;
                margin: 0 auto;
            }
        }
    }

    .results-content {
        .select-dropdown {
            ul {
                overflow: auto;
            }
        }
    }
}

.select-add-lottery {
    max-width: rem(360);
    margin: 0 auto;
}

/*	Payment Details */

.payment-detail {
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: em(16) !important;
    font-weight: 400;
    margin: 0 auto;
    position: relative;
    overflow: visible;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    padding-right: 0 !important;

    img {
        width: rem(30);
        height: auto;
    }

    .remove-payment {
        width: rem(20);
        height: rem(20);
        border: 2px solid $red;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.15s;
        cursor: pointer;

        svg {
            width: 75%;
            height: 75%;
            fill: $red;
            transition: all ease-in-out 0.15s;
        }

        &:hover {
            background-color: $red;

            svg {
                fill: white;
            }
        }
    }
}

.payment-details {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    h3 {
        color: $blue;
        margin: 1rem 0 .5rem;
    }

    .payment-detail {
        margin: 0;
        width: 95%;
        padding: rem(5) 5%;
        border-top: 1px solid $blue;
        justify-content: space-between;

        &:first-of-type {
            border-top: none;
            padding-top: 0;
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

/*	Set Limit 	*/

#account--spending-edit.active {
    margin-top: rem(25);
}

#account--notifications {
    .lottery-card {
        max-width: 100%;
        padding: rem(25) rem(40);

        p {
            font-size: em(16);
        }
    }
}

/*	Account Funds 	*/

.account-funds {
    background: white;

    &.active {
        margin-bottom: rem(-50);
    }

    h4 {
        font-weight: 600;
    }

    .container {
        max-width: rem(860);
    }

    &#account--withdraw, &#account--add-funds {
        .hr-text {
            &:before {
                background: linear-gradient(to right, transparent, $blue, transparent);
            }

            &:after {
                color: $blue;
                background-color: #fff;
            }
        }
    }

    .account-funds--top {
        &.manage-cc-bank {
            @media only screen and (max-width: 600px) {
                display: none;
            }
        }

        padding: rem(25) 0 0;

        h2 {
            margin-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;

            svg {
                margin-right: rem(15);
            }
        }

        .account-amount {
            display: block;
            line-height: 1.5;
            font-size: em(20);
            color: $blue;
            margin: rem(15) 0;

            span {
                display: block;
                font-size: 90%;
            }
        }

        p {
            color: $darkGrey;
            margin: rem(15) 0;
            font-weight: 600;
            font-size: 1rem;
        }

        .container {
            max-width: rem(480);
        }

        .choose-plays {
            padding-top: 0;
        }
    }

    .account-funds--bottom {
        padding: 1rem 0 3rem;
        border-bottom: 1px solid rgba(0,0,0,0.1);

        .container {
            background-color: white;

            .select-dropdown {
                .select-options {
                    max-height: rem(192);
                }
            }

            &.saved-withdrawals {
                margin-bottom: 1rem;

                .meta-container {
                    display: flex;
                    justify-content: flex-start;
                    margin: 0.5rem auto;

                    > img {
                        max-width: 2.5rem;
                        margin-right: 1rem;
                    }

                    .meta {
                        display: flex;
                        flex-direction: column;
                        align-self: center;

                        h4 {
                            text-align: left;
                            color: $blue;
                            margin: 0;
                            line-height: 1.5;
                        }

                        span {
                            color: $grey;
                            font-size: .8rem;
                            text-align: left;
                        }
                    }
                }
            }
        }

        h3 {
            margin: 0 0 rem(25);
            text-align: center;
            color: #e25f2c;
            font-weight: 600;
        }

        #account-payment-methods, #account-withdrawl-methods {
            list-style: none;
            padding: 0;
            margin: 0 0 1rem;
            text-align: left;

            > li {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                padding: 0 1rem;
                border-radius: 5px;
                margin: 10px auto;
                box-shadow: 0 0 2px 1px rgba(0,0,0,0.2);
                cursor: pointer;

                form {
                    width: 100%;
                }
                /*    &.active {
                    box-shadow: 0 0 4px 2px rgba(44,104,165,1);
                }*/

                &.saved {
                    background: #eee;
                }

                &.BPay {
                    background: #183168;

                    a {
                        color: #fff;
                    }
                }

                &.ZeptoPayID {
                    background: #7A7B78;

                    a {
                        color: #fff;
                    }
                }

                &.Visa {
                    background: linear-gradient(to bottom, #2F1191, #2F1191);

                    a {
                        color: #ffffff !important;
                    }
                }

                &.Mastercard {
                    background: linear-gradient(to bottom, #972429, #63191D);

                    a {
                        color: #ffffff !important;
                    }

                    .cc-name, .cc-expiry {
                        color: #1A1F71 !important;
                    }
                }
            }

            .payment-expand {
                display: flex;
                position: relative;
                line-height: 1;
                align-items: center;
                justify-content: flex-start;
                text-decoration: none !important;
                margin: auto;
                height: 3rem;

                &.withdraw-expand {
                    // height: 3.5rem;

                    h3 {
                        font-size: 1rem;
                        margin: auto 0;
                        text-align: left;
                        color: #2C68A5;
                    }
                }

                &.active {
                    + .payment-content {
                        margin-top: 0.5rem;
                        margin-bottom: 1rem;
                        display: block;

                        &.ccard {
                            max-height: 450px !important;
                        }
 
                    }
                }
            }

            .payment-content {
                width: 100%;
                max-height: 0;
                overflow: hidden;
                transition: all ease-in-out 0.3s;

                &.maxheight75 {
                    max-height: 75px;
                }
            }

            .credit-card-layout {
                max-width: 80%;
                margin: auto;
                background: #fff;
                border: 1px solid #ccc;
                flex: 1;
                padding: 1rem;
                border-radius: 5px;

                &.updateCard {
                    margin-top: 5px;
                }

                .cc-number {
                    font-size: 2rem;
                    font-weight: 600;
                    color: #1A1F71;
                    display: none;
                }

                .cc-meta {
                    display: flex;
                    justify-content: space-around;
                    max-width: 80%;
                }

                .cc-name-box {
                    display: flex;
                    flex-direction: column;
                }

                .cc-name {
                    color: #1A1F71;
                    font-size: 1.1rem;

                    &.block {
                        display: block;
                        min-width: 100px;
                    }
                }

                .title {
                    text-transform: uppercase;
                    color: #1A1F71;
                    font-weight: 600;
                    font-size: .75rem;
                    opacity: .6;
                }

                .cc-date-box {
                    display: flex;
                    flex-direction: column;
                }

                .cc-expiry {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    color: #1A1F71;
                    font-size: 1.1rem;

                    &.block {
                        display: block;
                        min-width: 100px;
                    }


                    &.cc-expired {
                        color: #fff !important;
                    }
                }

                .cc-expired {
                    background: red;
                    color: #fff !important;
                    font-weight: bold;
                }
            }

            .bank-card-layout {
                padding: 0;
                background: none;
                max-width: 100%;
                border: none;

                .cc-meta {
                    /*                    justify-content: space-around;

*/ justify-content: flex-start;
                    max-width: 95%;
                    margin-left: 1rem;
                }

                .cc-name, .cc-expiry {
                    line-height: 1.5;
                }
            }

            .payid-layout {
                background: #fff;
                border: 1px solid #ccc;
                flex: 1;
                padding: 1rem;
                align-self: stretch;
                display: flex;
                justify-content: center;
                flex-direction: column;
                max-width: 80%;
                margin: auto;
                border-radius: 5px;

                .hr-text {
                    line-height: 1em;
                    position: relative;
                    outline: 0;
                    border: 0;
                    color: black;
                    text-align: center;
                    height: 1.5em;
                    width: 100%;

                    &:before {
                        content: '';
                        background: linear-gradient(to right, transparent, $blue, transparent);
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 100%;
                        height: 1px;
                    }

                    &:after {
                        content: attr(data-content);
                        position: relative;
                        display: inline-block;
                        color: black;
                        padding: 0 .5em;
                        line-height: 1.5em;
                        color: $blue;
                        background-color: #fff;
                    }
                }

                p {
                    margin: 0;
                    font-size: 1rem !important;
                    font-weight: 600;
                    line-height: 1.8;

                    > :first-child {
                        float: left;
                    }

                    > :last-child {
                        float: right;
                    }
                }

                .bpayref {
                    color: $blue !important;

                    a {
                        text-decoration: none;
                        color: $blue !important;
                    }
                }

                .copy {
                    color: #333;
                    cursor: pointer;
                    border: 1px solid #ccc;
                    font-size: 1rem;
                    margin-left: 10px;
                    padding: 0 5px;
                    width: 60px;
                    text-align: center;
                    vertical-align: middle;

                    &.copied {
                        color: #fff;
                        width: 70px;
                        border-color: $lightGrey;
                        background-color: $blue;
                    }
                }
            }

            .instructions {
                color: #fff;
            }

            .remove-payment {
                width: 1rem;
                height: 1rem;

                svg {
                    margin-top: -3px;

                    path {
                        fill: red;
                    }
                }
            }

            .email-payment-details-sent.active {
                color: $blue;
                font-weight: bold;
                padding: 5px 10px;
                background: #fff;
                max-width: auto;
            }

            .email-payment-details, .print-bpay {
                width: auto;
                margin: 0 0 1rem;
                text-decoration: underline;
            }
        }

        a {
            display: block;
            width: 100%;
            color: #333;
            font-weight: bold;

            &.orange {
                color: #E25F2C;
                text-decoration: underline;
            }
        }

        img {
            max-width: 3rem;
            margin-right: 1rem;
            opacity: 1;
            vertical-align: middle;

            &.bank {
                max-width: 2rem;
                margin-right: 0;
            }

            &.paypal {
                max-width: 3rem;
                margin-right: 0;
            }
        }

        .paypal-name {
            color: $blue;
            overflow: hidden;
            margin-left: 1rem;

            @media only screen and (max-width: 600px) {
                font-size: .75rem;
            }
        }

        .manage-withdrawl {
            .account-funds--withdraw-container {
                padding-bottom: 2rem;

                button {
                    margin: auto !important;
                }

                .tab-content.active {
                    max-width: 75%;
                }
            }

            #account-funds--withdraw-zeptobanktransfer {
                max-width: 75%;
                justify-content: center;
                margin: auto;
            }
        }

        .hr-text {
            font-weight: bold;
            max-width: 700px;
        }

        @media screen and (min-width: 600px) {
            .hr-text:before {
                background: linear-gradient(to right, transparent, #fff, transparent);
            }
        }

        .hr-text-or:after {
            color: #2DA0F1;
            background-color: #ffffff;
        }
    }


    *[type="submit"], .payid-register, .bpay-register {
        margin: rem(15) auto;
        display: block !important;
    }

    .input-row {
        input {
            width: 100%;
        }
    }

    .container {
        max-width: rem(640);
    }

    .select-rendered {
        &:after {
            background-image: url(../icons/icon-angle-down.svg);
        }
    }
}

#account-funds--add-bpay {
    p {
        margin-top: 0;
    }
}

.verified, .unverified {
    position: relative;

    &:after {
        content: '\2714';
        margin-left: rem(15);
        color: $green;
        border: 2px solid $green;
        width: rem(20);
        height: rem(20);
        overflow: visible;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
}

.verified-new {
    position: relative;
    display: flex;
    justify-content: center;

    &:after {
        content: '\2714';
        margin-left: rem(5);
        color: $green;
        border: 2px solid $green;
        width: rem(18);
        height: rem(18);
        overflow: visible;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: #fff;
        margin-top: -3px;
    }
}

.incognito {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    color: #58595b !important;

    &:after {
        display: block;
        content: ' ';
        background-image: url(/Assets/icons/icon-incognito.svg);
        background-size: rem(22) rem(22);
        width: rem(22);
        height: rem(22);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: rem(5);
        margin-top: -3px;
    }
}

.unverified {
    &:after {
        cursor: pointer;
        content: 'i';
        color: $red;
        border-color: $red;
    }
}

.verify {
    text-align: center;
    margin: 0 auto;
    width: 80%;

    * {
        text-align: center !important;
    }

    a {
        color: $orange;
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
    }

    &.active {
        margin-bottom: rem(25);
    }
}

#account--upload-documents-container {
    padding: 1.25rem 1%;
    background: linear-gradient(to top left, #047ADA 0%, #047ADA 102%);
}

#account--idv-popup-content .container {
    padding: 1.25rem 2%;
    background: linear-gradient(to top left, #047ADA 0%, #047ADA 102%);

    .documents-uploaded {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            background: #eee;
            padding: 5px 10px;
            margin: 10px auto;

            span {
                color: #fff;
                padding: 0 3px;
                border-radius: 3px;
                line-height: 1;

                &.Pending {
                    background: $orange;
                }

                &.Approved {
                    background: $green;
                }

                &.Rejected {
                    background: $red;
                }
            }
        }
    }
}

#account--license-verify-container {
    padding: 1.25rem 1% !important;

    h3 {
        margin-bottom: .25rem;
    }

    form {
        padding-top: 1rem;
    }

    .select-dropdown .select-rendered {
        color: #fff !important;
    }

    .select-dropdown {
        border: 2px solid #ffffff;
    }

    .account-verify {
        display: block !important;
        margin: 1.5rem auto !important;
    }

    #account--upload-documents {
        text-decoration: none;
        padding: 0.75rem 2.5rem 0.875rem !important;
        display: inline-flex !important;
        font-size: 1rem;
        font-family: $proximaSoft !important;
    }

    .results-expand {
        &:after {
            display: none !important;
        }
    }

    hr {
        height: 2px;
        border: 0;
        background: #ccc;
    }
}
/*Account Results*/
.account-order-details {
    margin: 0 auto rem(20) auto;

    td {
        color: $darkGrey;
        text-align: left;
        border: 1px solid $grey;
        padding: rem(5) rem(10) rem(5) rem(10);
    }

    td:last-child {
        white-space: nowrap;
    }
}

#account--pending-entries-list {
    .account-order-details {
        margin-top: rem(30);
    }
}

.account-draws {
    .tab-toggle {
        margin-top: rem(25);
        max-width: rem(520);
    }

    .account-results--left {
        margin-bottom: rem(5);
    }

    .sort-container {
        display: inline-grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0px;
        width: 100%;
        margin-bottom: 1rem;

        .draws-sort {
            max-width: 260px;
            margin: 0;
            display: flex;
            align-items: flex-start;

            p {
                color: white;
                flex: 0 0 auto;
                margin: rem(10) rem(10) rem(10) 0;
            }

            > * {
                flex: 1;
            }
        }

        .date-container {
            width: 200px;
            margin: auto;
            border: 2px solid #ffffff;
            padding: 0px 20px;
            border-radius: 30px;
            background: $orange;
            display: grid;
            grid-template-columns: 40px 1fr 40px;
            align-items: center;
            //overflow-x: scroll;
            &::-webkit-scrollbar {
                display: none;
            }

            a {
                &#left {
                    justify-self: left;
                }

                &#right {
                    justify-self: right;
                }
            }

            .months {
                display: flex;
                scroll-behavior: smooth;
                overflow: auto;

                &::-webkit-scrollbar {
                    display: none;
                }

                div {
                    white-space: nowrap;
                    font-weight: bold;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 500;
                    color: #ffffff;
                    line-height: 1.4rem;
                    padding: 0px 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.active {
                        background: #0076d8;
                        color: #ffffff;
                    }

                    &:hover {
                        /*background: #0076d8;
                        color: #ffffff;
                        transition: all 0.5s ease;*/
                    }

                    span {
                        text-align: left;
                        display: block;
                        margin-right: 5px;
                        font-size: 1.2rem;
                        font-weight: 700;
                        position: relative;
                        top: -1px;
                    }
                }
            }

            svg {
                width: 25px;

                g {
                    stroke: #ffffff;
                }

                &:hover {
                    g {
                        stroke: #ffffff;
                        transition: all 0.3s ease;
                    }
                }
            }

            .hide {
                cursor: not-allowed;
                pointer-events: none;
                opacity: 0.4;
                // visibility:hidden;
                g {
                    stroke: #ccc;
                    transition: all 0.3s ease;
                }
            }
        }

        .select-dropdown {
            .select-rendered {
                letter-spacing: 0;

                &:after {
                    background-color: transparent;
                    background-image: url(../icons/icon-arrow-right-white.svg);
                    content: '';
                    width: 1.5rem;
                    height: 1.5rem;
                    position: absolute;
                    right: 0.625rem !important;
                    top: 50%;
                    transform: translateY(-50%) rotate(90deg);
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            &.dropdown-active {
                .select-rendered {
                    &:after {
                        transform: translateY(-50%) rotate(270deg)
                    }
                }
            }
        }
    }

    .lottery-card {
        padding: rem(20) 0;

        .lottery-card--draw {
            font-size: em(16);
        }

        &#lottery-card-no-result-top {
            background: #ddd;
            margin: 1rem auto 2rem auto;

            h3 {
                color: $blue;
                margin-bottom: 1rem;
            }

            .future-draw-container {
                background: #ddd;
                padding: 0 1rem;

                .next-month {
                    margin: 0 6px 10px;
                    background: #F76B1C;
                    padding: 5px;
                    color: #fff;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }

        &#lottery-card-no-result {
            margin: 1rem auto 2rem auto;

            .future-draw-container {
                background: #2C68A5;
                padding: 1rem;
                border-radius: 10px;
                margin-bottom: 2rem;
                max-width: 85%;
                margin: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            .future-draw-container p {
                color: #fff;
                width: 100%;
            }

            .next-month {
                display: flex;
                margin: 1rem .5rem;

                @media screen and (max-width: 600px) {
                    margin: .5rem auto;
                }
            }
        }
    }

    .is-label {
        margin-top:0 !important;
    }
    }

    .account--edit {
        .container {
            .account-edit--lower {
                #reset-password {
                    margin-top: rem(15);

                    .lottery-card {
                        max-width: 100%;
                    }
                }
            }
        }
    }

.lottery-upsell {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
    justify-content: center;

    .is-label {
        position: absolute !important;
    }

    h2 {
        &.upsell-title {
            color: #ffffff;
            font-size: 2em;
            text-align: center;
            padding: 0;
            margin: 0 !important;
            display: block;
            width: 100%;
            line-height: 2;
        }
    }

    a {
        &.lottery-card {
            min-width: unset;
            max-width: 17rem !important;
            //margin: 2rem auto;
            padding: 0 !important;
            overflow: unset;
            margin: unset;
            margin: 2rem auto auto !important;

            .lottery-card-front {
                padding: 3rem 0 0;
                display: flex;
                flex-flow: column nowrap;

                .lottery-infor {
                    min-height: 130px;

                    img {
                        width: 4rem;
                        height: auto;
                        margin: 0 auto;
                        transform: translate(-50%,-50%);
                        position: absolute;
                        top: 0%;
                        left: 50%;
                    }

                    h2 {
                        color: $darkGrey;
                    }
                }

                p {
                    &.lottery-card--amount {
                        color: $blue;
                    }

                    &.lottery-card--amount-pending {
                        padding-top: 0;
                    }

                    &.timer {
                        flex-direction: row;
                        padding: .5rem 0.5rem 1rem !important;
                        font-weight: 600;

                        .timer-countdown {
                            font-size: 1em;
                            font-weight: 600;
                        }
                    }
                }
            }

            .button {
                margin-top: .5rem;
            }

            &.USPB {
                border-top: 5px solid $colorUSPB;

                > .is-label {
                    background: $colorUSPB;
                }
            }

            &.USMM {
                border-top: 5px solid $colorUSMM;

                > .is-label {
                    background: $colorUSMM;
                }
            }

            &.IREL {
                border-top: 5px solid $colorIREL;

                > .is-label {
                    background: $colorIREL;
                }
            }

            &.PRIM {
                border-top: 5px solid $colorPRIM;

                > .is-label {
                    background: $colorPRIM;
                }
            }

            &.EURJ {
                border-top: 5px solid $colorEURJ;

                > .is-label {
                    background: $colorEURJ;
                }
            }

            &.EURO {
                border-top: 5px solid $colorEURO;

                > .is-label {
                    background: $colorEURO;
                }
            }

            &.UKLO {
                border-top: 5px solid $colorUKLO;

                > .is-label {
                    background: $colorUKLO;
                }
            }

            &.SENA {
                border-top: 5px solid $colorSENA;

                > .is-label {
                    background: $colorSENA;
                }
            }

            &.COMBOS {
                border-top: 5px solid $colorCombos;

                > .is-label {
                    background: $colorCombos;
                }
            }

            &.SYNDICATES {
                border-top: 5px solid $colorSyndicates;

                > .is-label {
                    background: $colorSyndicates;
                }
            }
        }
    }
}

    .account-history .lottery-upsell a.lottery-card .button {
        display: inline-flex !important;
        padding: 0.75rem 2.5rem 0.875rem;
    }

    #account--landing {
        .lottery-tabs {
            padding: 1rem 0 3rem;
        }
    }

    .popup {
        p.center {
            text-align: center;
            font-size: 1rem !important;
        }
    }

    #map-popup {

        .popup-close {
            right: calc(50% - 24.5rem) !important;
        }

        .popup-container {
            height: 90vh;

            h3 {
                margin: 0 0 1rem;
            }

            .container {
                padding: 1rem;
                max-width: 50rem;
            }

            .popup-inner {
                max-width: 50rem;
                margin: auto;
                width: 100%;

                p {
                    color: $blue;
                }
            }
        }
    }

    #blueshyft-location--address-input {
        font-size: 1.1rem;
        padding: 1rem;
        width: 95%;
        border: 2px solid $blue;
        border-radius: 10px;
    }
    //map controls
    .gm-bundled-control-on-bottom > .gmnoprint:last-child {
        display: block !important;
        position: relative !important;
    }

    #blueshyft-map {
        .button {
            display: block;
            padding: 0.875rem;
            margin-top: 0.625rem;
            font-size: .875em;
            font-weight: 600;
            font-family: "open-sans";
            min-width: 150px;
        }

        p {
            font-size: .875em;
            font-family: "open-sans";
            line-height: 1.5;
            margin-top: 0;
            color: $blue;
        }
    }

    .gm-ui-hover-effect {
        right: 3px !important;

        img {
            width: 20px !important;
            height: 20px !important;
        }
    }

    @media only screen and (min-width: 560px) {
        .account--edit {
            .container {
                .account-edit--lower {
                    #reset-password {
                        .lottery-card {
                            max-width: 100%;
                            width: rem(520);
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 760px) {
        #account--landing {
            .tab-nav {
                li {
                    font-size: em(16);
                    font-weight: 600;

                    br {
                        display: none;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 860px) {
        .account-funds {
            .account-funds--top {
                .choose-plays {
                    padding: rem(20) 0 0;
                    text-align: center;
                    width: 100%;

                    .choose-plays-container {
                        max-width: rem(360);
                        margin: 0 auto 1rem auto;

                        > * {
                            margin-right: 0;

                            &:first-child {
                                margin-left: 0;
                            }
                        }

                        .input-other {
                            margin: rem(10) 0 0;
                            width: 100%;
                            flex: 0 0 100% !important;
                        }
                    }
                }
            }

            .account-funds--bottom {
                padding: rem(45) 0;
                background-image: linear-gradient(to bottom, #2DA0F1 0%, #289BEE 44%, #178BE4 68%, #0076D8 100%);

                .select-left {
                    max-width: calc(100% - #{rem(250)});
                }

                .container {
                    position: relative;
                    box-shadow: 0 0 5px 2px rgba(0,0,0,0.2);
                    padding: rem(20);
                    border-radius: rem(10);

                    > label {
                        width: rem(220);
                        position: absolute;
                        right: rem(20);
                        top: 46px;
                        margin: 0;
                        text-align: center;
                        display: block;

                        .button {
                            margin-top: 0;
                            width: 100%;
                        }

                        &.saved-withdrawals-label {
                            position: unset !important;
                            margin: auto;
                        }
                    }
                }

                h3 {
                    text-align: left;
                    margin: 0 0 rem(10);
                    color: #e25f2c;
                    font-weight: 600;
                    font-size: 1em;
                }

                .tab-content {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    max-width: calc(100% - #{rem(250)});

                    > * {
                        flex: 0 0 100%;
                    }

                    &.active {
                        margin-top: rem(12.5);
                    }

                    .terms-conditions-check {
                        flex: 0 0 100%;
                        text-align: left;
                        justify-content: center;
                        margin: rem(10) 0;

                        span {
                            line-height: 1.5;
                        }

                        &:only-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .account--order-details {
            min-width: rem(360);
        }
    }

    @media only screen and (min-width: 980px) {

        .banner {
            &.banner-account {
                .icon-round {
                    cursor: pointer;
                    transition: all ease-in-out 0.2s;

                    &:hover {
                        background: white;

                        i {
                            color: $orange;
                        }

                        svg {
                            * {
                                stroke-color: $orange;
                                fill: $orange;
                            }
                        }
                    }
                }
            }
        }

        #account--edit {
            .container {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                max-width: rem(920);

                .lottery-card {
                    min-width: 0;
                    min-height: 100%;
                    max-width: 32%;
                    height: rem(250);
                    display: flex;
                    flex-direction: column;

                    .button {
                        margin-top: auto;
                        flex: 0 0 auto;
                        align-self: center;
                    }
                }

                > div {
                    flex: 0 0 32%;
                    margin: 0 2% rem(20) 0;
                    min-width: 0;
                    max-width: 100%;
                    overflow: visible;
                    max-height: none;

                    &:nth-child(3n + 3) {
                        margin-right: 0;
                    }
                }
            }
        }

        .account-draws, .account-results {
            .lottery-card {
                min-width: rem(520);
                max-width: 100%;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .banner {

            &.banner-account {
                margin: 1rem !important;

                &:before {
                    background-image: url(../icons/tlo-logo-transparent-opt.png);
                    left: 0;
                    background-position: center center;
                }

                .container {
                    padding: 0 !important;
                    min-height: 325px;
                }
            }
        }

        #checkout .bg-blue-gradient .lottery-upsell a.lottery-card {
            max-width: 17rem !important;
        }
    }


    @media only screen and (max-width: 1280px) {
        .banner {
            &.banner-account {
                margin: 0.5rem !important;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        #account--landing {
            .lottery-tabs {
                padding: 1rem 0 1rem;
            }
        }

        #account-payment-methods {
            .credit-card-layout {
                max-width: 100% !important;

                .cc-meta {
                    max-width: 100% !important;
                }
            }

            .payid-layout {
                max-width: 100% !important;

                p {
                    margin: 3px 0 !important;
                }

                .copy-payid {
                    display: block;
                    margin-left: 0 !important;
                }
            }
        }

        #map-popup {
            .popup-close {
                right: 5% !important;
            }

            .container {
                width: 100%;
                padding: 1rem 5px !important;
            }

            .popup-inner {
                width: 100%;
            }
        }
    }

    .hr-text {
        line-height: 1em;
        position: relative;
        outline: 0;
        border: 0;
        color: black;
        text-align: center;
        height: 1.5em;
        width: 100%;

        &:before {
            content: '';
            background: linear-gradient(to right, transparent, $blue, transparent);
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
        }

        &:after {
            content: attr(data-content);
            position: relative;
            display: inline-block;
            color: black;
            padding: 0 .5em;
            line-height: 1.5em;
            color: $blue;
            background-color: #fff;
        }
    }

    @media only screen and (max-width: 320px) {

        #account--personal-details-container {
            padding-top: 1rem;

            .update-titles-container {
                padding: 0 !important;

                .radio-container label input {
                    margin-right: 1.75em;
                }
            }
        }
    }
