section {

    &.all-lotteries-container {
        display: flex;
        flex-direction: row;
        max-width: 80rem;
        margin: auto;

        .section-content {
            flex: 3;
            padding-right: 1rem;
            padding-left: 1rem;
            text-align: left;
            position: relative;

            h1, h2 {
                color: $blue;
            }

            > p {
                position: relative;
                padding-left: 1rem;


                &:before {
                    position: absolute;
                    height: 1.5rem;
                    width: 4px;
                    left: 0;
                    top: 5px;
                    background: #e25f2c;
                    display: block;
                    content: "";
                }
            }

            .cta {
                display: none;
            }

            .all-lotteries-list {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: .5rem 1rem;

                .lottery-card {
                    display: flex;
                    max-width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding: 1.5625rem 3rem;
                    margin: 5px auto;

                    .is-label {
                        left: 0;
                        top: 0;
                    }

                    .lottery-flag {
                        margin: 0;
                        width: 5rem;
                        height: 5rem;
                    }

                    .lottery-infor {
                        display: flex;
                        flex-direction: column;
                        text-align: left;

                        h2 {
                            line-height: 1;
                            color: $darkGrey;
                        }

                        p {
                            &.lottery-card--amount {
                                padding: 0;
                                margin: 0;
                                color: $blue;
                            }
                        }

                        .timer {
                            justify-content: flex-start;
                            flex-direction: row;
                        }
                    }

                    .button {
                        display: inline-table;
                    }

                    &.USPB {
                        border-left: 5px solid #133a8d;
                    }

                    &.USMM {
                        border-left: 5px solid #e52a30;
                    }

                    &.IREL {
                        border-left: 5px solid #84bf45;
                    }

                    &.PRIM {
                        border-left: 5px solid #f6b61b;
                    }

                    &.EURJ {
                        border-left: 5px solid #005fe2;
                    }

                    &.EURO {
                        border-left: 5px solid #5824ae;
                    }

                    &.UKLO {
                        border-left: 5px solid #21d3e0;
                    }

                    &.SENA {
                        border-left: 5px solid #209d4d;
                    }

                    &.COMBOS {
                        border-left: 5px solid #1a81e9;
                    }

                    &.SYNDICATES {
                        border-left: 5px solid #e25f2c;
                    }

                    &.offer-text {
                        padding: 1.5625rem 3rem;
                        background: #f9f9f9;

                        p {
                            color: $darkGrey;
                            font-size: 1rem;
                            padding-left: 1rem;
                        }

                        img {
                        }
                    }
                }
            }

            .faqs-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 2rem 1rem;

                h2 {
                    margin: 0;
                    padding-top: 2rem;

                    &.schema {
                        padding-top: 1rem;
                    }
                }

                h3 {
                    margin: 0;
                    padding-top: 2rem;
                    color: $blue;

                    &.schema {
                        padding-top: 1rem;
                    }
                }


                .faq-answer {
                    padding-bottom: 1rem;

                    p {
                        position: relative;

                        &:first-child {
                            &:before {
                                position: absolute;
                                height: 1.5rem;
                                width: 3px;
                                left: -10px;
                                top: 5px;
                                background: $orange;
                                display: block;
                                content: "";
                            }
                        }
                    }
                }

                .play-widget {
                    padding: 1rem;
                    display: flex;
                    justify-content: center;
                    border-radius: .5rem;
                    place-items: center;

                    &.us-powerball {
                        background: linear-gradient(-45deg,#d8262d,#2c68a5);
                    }

                    &.us-megamillions {
                        background: linear-gradient(-45deg,$blue,#58019f);
                    }

                    &.euromillions {
                        background: linear-gradient(-45deg,$lightBlue,$blue);
                    }

                    &.superenalotto {
                        background: linear-gradient(-45deg,$red,$green);
                    }

                    &.eurojackpot {
                        background: linear-gradient(-45deg,$green,$blue);
                    }

                    &.la-primitiva {
                        background: linear-gradient(-45deg,$orangeLight,$red);
                    }

                    &.uk-lotto {
                        background: linear-gradient(-45deg,$blue,$red);
                    }

                    &.irish-lotto {
                        background: linear-gradient(-45deg,$androidGreen,$green);
                    }

                    h3 {
                        color: $white;
                        padding-top: 0;
                    }

                    a {
                        &.button {
                            margin-right: 2rem;
                        }
                    }
                }
            }
        }

        .section-sidebar {
            flex: 1;
            padding-top: 2rem;

            aside {
                margin-bottom: 1rem;

                &.featured {
                    display: flex;
                    background: url(/Assets/images/all-lotteries-blue-bg.jpg) top center no-repeat;
                    padding: 1rem;
                    color: $white;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 3rem 0;

                    img {
                        max-width: 6rem;
                    }

                    h2 {
                        color: $white;
                        font-size: 1.5rem;
                        margin: 1rem auto 2rem;
                    }

                    h3 {
                        font-size: 3rem;
                        color: $white;
                        margin: 1rem auto 2rem;
                        max-width: 90%;

                        @media only screen and (min-width: 600px) {
                            padding: 0 10px;
                        }
                    }

                    .button-container {
                        margin-top: 2rem;

                        .button {
                            &.button-white {
                                color: $white;
                                border-color: $white;
                            }

                            &:hover {
                                color: #26349d;
                            }
                        }

                        p {
                            color: $white;
                        }
                    }
                }

                &.join-winners {
                    background: url(/Assets/images/all-lotteries-orange-bg.jpg) top center no-repeat;
                    padding: 2rem 2.5rem 4rem;

                    h2 {
                        font-size: 2rem;
                        position: relative;
                        padding-left: 1rem;
                        font-weight: normal;

                        &:before {
                            position: absolute;
                            height: 100px;
                            width: 3px;
                            left: 0;
                            top: -3.7rem;
                            background: #ffffff;
                            display: block;
                            content: "";
                        }
                    }

                    a {
                        font-size: 1.5rem;
                        font-weight: normal;
                        width: 100%;
                        margin-left: 2rem;
                        font-weight: 600;

                        &:hover {
                            opacity: 1;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    &.lotto-systems-container {
        padding: 5rem 0 2rem !important;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 6rem;
            height: 6rem;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
            top: -3rem;
            background-image: url(/assets/images/lottery-logos/lottery-systems.png);
            background-repeat: no-repeat;
            background-position: center center;
            pointer-events: none;
            touch-action: none;
            border-radius: 50%;
            border: 8px solid #2DA0F1;
            background-size: cover;
        }


        @media only screen and (max-width: 600px) {
            padding: 3rem 0 1rem !important;

            &:before {
                width: 4.25rem;
                height: 4.25rem;
                top: -2rem;
                border: 6px solid #2DA0F1;
            }
        }



        .section-content {
            .all-lotteries-list {
                background: linear-gradient(to right, #512dc5 0%, #2987d1 100%);

                &.lotto-systems-list {
                    padding: 4rem 0;
                    margin: auto;

                    a {
                        max-width: 80%;

                        .button {
                            margin-top: 0 !important;
                        }
                    }
                }
            }

            .faqs-content {
                padding: 2rem 0rem;

                div {
                    display: flex;
                    text-align: left;
                    padding: 1rem 0;

                    > img {
                        max-width: 10rem;
                        margin-right: 3rem;
                        align-self: flex-start;
                    }

                    > div {
                        padding-left: 1rem;
                        position: relative;

                        h2 {
                            margin: 0;
                            font-size: 1.375em;
                            font-weight: 700;
                            color: $blue;
                        }

                        p {
                            &:before {
                                position: absolute;
                                height: 50px;
                                width: 3px;
                                left: 0;
                                background: $orange;
                                display: block;
                                content: "";
                            }

                            line-height: 1.5;
                            max-width: 90%;
                        }
                    }
                }

                .faq-answer {
                    p {
                        padding-left: 1rem;
                    }
                }
            }

            .results-expand {
                margin-top: .5rem;
            }
        }
    }

    &.lottosystems-information {
        table {
            max-width: 90%;

            @media only screen and (max-width: 600px) {
                max-width: 100% !important;
            }

            &.custom-shade {
                tr {
                    th {
                        background: #38A4D0 !important;

                        &.header {
                            background: #2C68A5 !important;
                        }
                    }
                }
            }

            tr {
                td {
                    &.vertical-middle {
                        vertical-align: middle;
                    }

                    text-align: center !important;
                }
            }
        }

        .single-game-img {
            max-width: 320px;
            display: block;
            margin: auto;
        }

        .download-pdf {
            &:before {
                content: '';
                display: inline-block;
                position: relative;
                top: 0.35rem;
                background: url('/assets/icons/download-pdf.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-repeat: no-repeat;
                height: 1.5rem;
                width: 1.5rem;
                margin-right: .5rem;
                margin-left: -0.1rem;
            }
        }

        p {
            &.abbrev {
                &:before {
                    display: none !important;
                }
            }
        }
    }

    .play-widget {
        padding: 1rem;
        display: flex;
        justify-content: space-evenly;
        border-radius: .5rem;
        place-items: center;

        @supports (-ms-ime-align:auto) {
            justify-content: space-around;
        }

        &.us-powerball {
            background: linear-gradient(-45deg,#d8262d,#2c68a5);
        }

        &.us-megamillions {
            background: linear-gradient(-45deg,$blue,#58019f);
        }

        &.euromillions {
            background: linear-gradient(-45deg,$lightBlue,$blue);
        }

        &.superenalotto {
            background: linear-gradient(-45deg,$red,$green);
        }

        &.eurojackpot {
            background: linear-gradient(-45deg,$green,$blue);
        }

        &.la-primitiva {
            background: linear-gradient(-45deg,$orangeLight,$red);
        }

        &.uk-lotto {
            background: linear-gradient(-45deg,$blue,$red);
        }

        &.irish-lotto {
            background: linear-gradient(-45deg,$androidGreen,$green);
        }

        h3 {
            color: $white;
            padding-top: 0;
            margin-top: auto;
            margin-bottom: auto;
        }

        a {
            &.button {
                margin-right: 2rem;
            }
        }
    }

    .results-expand {
        &.blue-arrow {
            cursor: pointer;
            text-decoration: underline !important;

            &:after {
                position: absolute;
                margin-left: -10px !important;

                @media only screen and (max-width: 600px) {
                    display: none;
                }
            }

            &.active {
                &:after {
                    transform: rotate(-180deg);
                }
            }
        }
    }
}

.table-container {
    width: 100%;
    overflow: auto;
}

table {
    border: 1px solid $darkGrey;
    width: 100%;
    margin-top: 1rem;

    tr {
        th {
            background: $lightBlue;
            padding: 10px 0;
            color: #ffffff;
            text-align: center;

            &.special {
                background: $blue;
            }
        }

        td {
            text-align: center;
            padding: 10px 0px;
            font-weight: 600;
            border: 1px solid;

            &.border-right-none {
                border-right: 1px solid $white;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section {
        &.lotto-systems-container {
            .section-content {
                .all-lotteries-list {
                    &.lotto-systems-list {
                        a {
                            max-width: 90%;

                            &.lottery-card {
                                width: 100%;
                                flex-direction: column;
                                padding: 1.5625rem 2rem;

                                .lottery-flag {
                                    width: 4rem;
                                    height: 4rem;
                                }

                                h2 {
                                    margin-top: 1rem;
                                }

                                .lottery-infor {
                                    text-align: center;
                                }

                                .button {
                                    margin-top: 1rem !important;
                                }

                                .timer {
                                    justify-content: center !important;
                                }
                            }
                        }
                    }
                }

                div {
                    img {
                        &.lottery-flag {
                            display: block;
                        }
                    }
                }
            }
        }

        .play-widget {
          //  margin: auto;
            padding: 1rem !important;

            h3 {
                margin: unset !important;
                line-height: 2;
            }

            .button {
                margin: auto !important;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    section {
        &.all-lotteries-container {
            flex-direction: column;
        }

        &.lotto-systems-container {
            .section-content {
                .all-lotteries-list {
                    &.lotto-systems-list {
                        padding: 2rem 0;
                    }
                }
            }
        }

        &.lottosystems-information {
            table {
                max-width: 100% !important;
            }
        }

        .section-sidebar {
            display: none;
        }

        .play-widget {
            padding: 1rem 0 !important;
            flex-direction: column-reverse;
            max-width: 100%;
            margin: unset;

            h3 {
                margin: auto !important;
            }

            a {
                &.button {
                    margin: auto !important;
                }
            }
        }
    }
}



