.account-history {
	.history-print-title {
		display: none;
	}

	&.active {
		margin-top: rem(20);
		margin-bottom: rem(-51);
	}

	.tab-nav {
		background-color: #F8F8F8;
		padding-top: rem(10);

		li {
			color: $blue;
			font-size: em(14) !important;

			&.active {
				border-color: $blue;
			}
		}
	}

	.tabs-container {
		padding: rem(25) 0;
		background: white;
		max-width: rem(1180);
		margin: 0 auto;

		.container {
			width: 100%;
		}
	}

	.history-row-titles {
		border-bottom: 1px solid rgba(0,0,0,0.1);
		padding-bottom: rem(15) !important;

		* {
			font-weight: 700 !important;
		}
	}

	.history-row {
		display: flex;
		justify-content: space-around;
		align-items: center;
		text-align: left;
		margin-bottom: rem(20);
		padding: 0 rem(15);
		position: relative;

		.lottery-flag {
			width: rem(25);
			height: auto;
			position: absolute;
			top: 50%;
			left: rem(25);
			transform: translateY(-50%);
		}

		p, a {
			margin: 0 rem(15) 0 0;
			font-size: em(12);
			line-height: 1.5;
			font-weight: 600;
			font-family: $openSans;
			flex: 1;

			&.date {
				color: $blue;
				flex: 0 0 rem(60);
			}

			&.lottery {
				color: $blue;
				flex: 1 0 rem(80);

				span {
					color: #7A7B78;
				}
			}

			&.cost {
				color: $orange;
				flex: 2;
			}

			&.balance {
				margin-right: 0 !important;
				flex: 2;
			}
		}
	}

	.buttons-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: rem(30) 0 rem(50) !important;
		width: 100%;
		max-width: 100%;
	}

	.button {
		margin-bottom: rem(50);
		display: block !important;
		margin: 0 rem(5) !important;
		padding: rem(15) rem(30);
		max-height: 50px;
		
		&.print {
			margin-bottom: 0 !important;
			background: white;
			color: $blue;
			display: flex !important;
			flex: 0 0 rem(150);
			max-width: 150px;
		}
	}
}

@media only screen and (max-width: 480px) {
	.account-history {
		.history-row {
			a, p {
				font-size: em(9);
			}
		}
	}
}

@media only screen and (min-width: 480px) {
	.account-history .history-row p.date, .account-history .history-row a.date {
		flex: 0 0 rem(100);
	}

	.account-history .history-row p.lottery, .account-history .history-row a.lottery {
		flex: 5;
	}
}

@media only screen and (min-width: 760px) {
	.account-history {
		.tabs-container {

			.history-row {
				padding: 0 rem(75);

				p, a {
					&.date {
						flex: 2;
					}

					&.cost {
						flex: 1;
					}

					&.balance {
						flex: 1;
					}
				}

				&.history-row-titles {
					font-size: 24px;
				}
			}

		}
	}
}

@media only screen and (min-width: 980px) {
	.account-history {
		.tabs-container {
			border-radius: rem(10);
			margin-top: rem(40);
			width: 92.5%;
		}
	}
}
