#banner-results {
    $gradient: #1a3d9b;
    $bgUrl: '/assets/images/results/banner-results.jpg';
    $bgmobileUrl: '/assets/images/results/banner-results-mobile.jpg';
    @include banner-generic($gradient, $bgUrl, $bgmobileUrl);

    .wrapper {
        span {
            padding-left: 3rem;
            line-height: 2;
        }
    }

    padding: 0 !important;

    .container {
        border-radius: 0 !important;
        max-height: 250px !important;
        min-height: 250px !important;
    }

    span.h3 {
        font-size: 1.75rem;
    }

    h1 {
        font-size: 2.875rem !important;
    }

    h2 {
        font-size: 21px;
        padding-left: 3rem;
        line-height: 2;
    }

    @media screen and (max-width: 600px) {
        padding: 0 !important;
        margin: 0 !important;

        .container {
            max-height: 150px !important;
            min-height: 150px !important;

            h1, span {
                padding-left: 0rem !important;
                margin: 0.5rem 0 0;
                line-height: 1;
            }

            padding: 0;
            margin: 0;
        }

        .wrapper {
            width: 100%;

            span.h3 {
                font-size: 21px;
                text-align: center !important;
            }

            h1 {
                font-size: 28px !important;
                text-align: center !important;
            }

            span.h4 {
                font-size: 1rem;
                text-align: center !important;
            }
        }

        h2 {
            text-align: center !important;
            line-height: 1.2;
            padding: 0;
            margin: 0.5rem 0 0;
            font-size: 1rem;
        }
    }
}

.results-listing-container {
    width: 100%;
    overflow: hidden;
    max-width: 100%;
    margin: auto;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 0.625rem;

    @media only screen and (max-width: 1250px) {
        max-width: 95%;
    }

    @media only screen and (max-width: 1100px) {
        max-width: 100%;
    }

    @media only screen and (max-width: 900px) {
        box-shadow: none;
        border-radius: 0;
        background: none;
    }

    h3 {
        line-height: 1;
        text-align: left;
    }

    .col {
        @media only screen and (max-width: 600px) {
            width: 100%;
        }

        &:first-child {
            width: 32%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media only screen and (max-width: 1100px) {
                width: 26%;
            }

            @media only screen and (max-width: 900px) {
                justify-content: center;
                width: 100%;
            }

            h3 {
                margin: 0;
            }

            img {
                margin-right: 1rem;

                @media only screen and (max-width: 1250px) {
                    margin-right: 1rem;
                }
            }
        }
    }

    .col.numbers-col {
        position: relative;
        width: 45%;

        @media only screen and (max-width: 900px) {
            margin: 1.6rem 0;
            width: auto;
        }
    }

    .inner {
        @media only screen and (max-width: 900px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0.5rem 0;
        }
    }

    .results-list-item {
        padding: 1.2rem 3rem;
        padding-bottom: 1.5rem;
        background-color: white;
        flex-wrap: wrap;
        border-bottom: solid 1px #eaeaea;
        margin: auto;

        a {
            &:hover {
                opacity: 1;
            }
        }

        @media only screen and (max-width: 1100px) {
            padding: 3rem 1.5rem;
        }

        @media only screen and (max-width: 900px) {
            width: 48.5%;
            margin-bottom: 20px;
            box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
            border-radius: 0.5rem;
            justify-content: center;
            height: 285px;
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
            padding: 1rem;
            max-width: 29rem;
            margin: auto;
            margin-bottom: 20px;
            box-shadow: none;
            height: auto;
            justify-content: start;

            > .col {
                justify-content: start;
            }

            > .col-view-more {
                display: none;
            }

            >.numbers-col{
                margin:1rem 0 0;
            }
            
        }

        &:last-child {
            border-bottom: none;
        }

        .lotto-title {
            h3 {
                color: #2c68a5;
            }

            @media only screen and (max-width: 900px) {
                text-align: left;

                p {
                    margin: 0;
                }
            }
        }

        .lottery-numbers {
            display: flex;
            align-items: flex-start;

            @media only screen and (max-width: 600px) {
                justify-content: center;
            }

            .lottery-number {
                width: 2.6rem;
                height: 2.6rem;
                border-radius: 100%;
                color: white;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                margin: 0 0.3rem;

                @media only screen and (max-width: 900px) {
                    width: 2rem;
                    height: 2rem;
                    font-size: 14px;
                    margin: 0 0.2rem;
                }

                &.orange {
                    background-color: $orange;
                }

                &.blue {
                    background-color: $lightBlue;
                }

                &.grey {
                    background-color: $darkGrey;
                }


                &.superstar {
                    background-color: $lightBlue;
                }

                &.reintegro {
                    background-color: $lightBlue;
                    order: 2;
                }

                &.bonus {
                    background-color: $darkGrey;
                    order: 1;
                }
            }
        }

        .multiplier, .joker {
            position: absolute;
            left: 0.3rem;
            bottom: -22px;

            &.hassupplement {
                left: 6rem;
            }

            @media only screen and (max-width: 900px) {
                position: relative;
                bottom: inherit;
                left: inherit;
                text-align: center;

                &.hassupplement {
                    left: 0;
                }
            }

            p {
                margin-bottom: 0;
                font-size: 12px;
                color: #2c68a5;
            }
        }

        .result-content {
            background-color: white;
            text-align: left;
            font-weight: 500;
            line-height: 21px;

            p {
                color: #555;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                color: #f66c1c;
            }
        }

        .date {
            color: #2c68a5;
            font-size: 15px;

            &.full {
                @media only screen and (max-width: 900px) {
                    display: none;
                }
            }

            &.res {
                text-align: left;
                margin: 5px 0 0;
                font-weight: 600;
                color: $grey;
            }
        }
    }


    &.home-page {
        @media only screen and (max-width: 900px) {

            .results-list-item {
                padding: 1rem !important;
                position: relative;

                .col {
                    width: 100% !important;
                    justify-content: flex-start;
                }

                div.col {
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }

                .numbers-col {
                    margin: 1rem 0 0;

                    .lottery-numbers {
                        justify-content: flex-start;
                    }
                }
            }
        }

        @media only screen and (max-width: 600px) {
            .results-list-item {
                position: relative;

                div.col {
                    display: none;
                }
            }
        }
    }
}

.result-content-container {
    @media only screen and (max-width: 900px) {
        width: 100%;

        .results-list-item {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.results-disclaimer {
    max-width: 85%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: left;
    padding: 0 20px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        max-width: 26rem;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 0;
    }

    p {
        opacity: 0.5;
        color: #ffffff;
        font-size: 11px;
        letter-spacing: 0.2px;
        line-height: 1.8;

        &:first-child {
            margin-top: 0;
        }
    }

    &.blue {
        p {
            opacity: 0.5;
            color: #4a4a4a;
        }
    }
}

.result-listing {
    padding: rem(30) 0;
    background-color: #f8f8f8;

    h1 {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: #ffffff;

        svg {
            margin-right: rem(15);
        }
    }

    h2 {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;

        @media only screen and (max-width: 600px) {
            font-size: 1.3em;
        }

        svg {
            margin-right: rem(15);
        }
    }

    p {
        color: $darkGrey;
        font-weight: 600;
    }

    .view-more {
        font-size: 1rem;
        color: $blue;
        background: #fff;
        border-radius: 2rem;
        padding: 0.5rem 2rem;
        border: 2px solid $blue;
        width: max-content;
        font-weight: bold;

        img {
            position: relative;
            width: 30px;
            height: 30px;
            top: 10px;
            border-radius: 30px;
            border: 2px solid $blue;
            margin-left: 1.5rem;
            display: none;
        }
    }

    &.bg-blue-gradient {
        h2, p {
            color: white;
        }

        @media only screen and (max-width: 750px) {
            display: block;
            padding: .5rem 0 2rem;
        }
    }
 
    @media only screen and (min-width: 760px) {
        &.bg-blue-gradient {
            h2 {
                padding-top: rem(30);
            }
        }

        padding: 3rem 0;
    }

    .results-actions {
        max-width: 88%;
        padding: 1rem 3rem;

        p {
            display: block;
            width: 100%;
            margin: 5px 0 0 !important;
            font-weight: 500;
        }
    }

    @media only screen and (max-width: 400px) {
        &.result-listing-section {
            > .container {
                width: 100% !important;
            }
        }
    }

    &.result-listing-section {
        //background:none;
        .results-listing-container {
            max-width: 100%;
        }

        .results-list-item {
            padding: 1.2rem 2rem;

            &:nth-child(odd) {
                background: #eaf3fc;
            }
        }

        h2 {
            font-size: 2rem;
            color: $blue;
        }

        p {
            margin: 5px auto;
        }

        .date.res {
            font-size: 1rem;
            color: $darkGrey;
        }

        .col {
            &:first-child {
                width: 30%;
            }

            &.numbers-col {
                width: 50%;
            }
        }
    }
}
