.sign-up--page {
    max-width: rem(580);
    margin: 0 auto;
    font-size: 18px;
    padding: rem(30) 0;

    .h1title {
        margin: 0 0 1rem;
        color: white;
        font-weight: 600 !important;

        &.icon-title {
            .icon {
                width: 2.5rem;
                height: 2.5rem;
                flex: 0 0 2.5rem !important;

                svg {
                    max-width: 2rem;
                    height: auto;
                    width: 55%;
                }
            }
        }

        + .right {
            color: white;
            padding: 0 2.5%;
            margin: 0.75rem 0;
        }
    }

    .sign-in--header {
        padding-bottom: rem(14);
    }

    .signup-page--header {
        color: white;
        width: 100%;
        text-align: center;
        border: 2px solid #FFFFFF;
        border-radius: 10px 10px 0 0;
        font-size: 26px;
        font-weight: 600;
        margin: 0;
        padding: rem(30);
        line-height: 1;

        + form {
            border-radius: 0 0 10px 10px;
        }
    }

    .sign-up--form {
        padding: rem(30) 0;
    }

    .sign-in--form {
        padding-top: rem(30);
    }

    .sign-in--social-form {
        button {
            font-size: em(15);
            cursor: pointer;
            transition: opacity ease-in-out .2s;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    form {
        background: white;
        border-radius: 10px;

        .social-buttons {
            flex: 0 0 auto !important;
            width: auto;
            align-self: center;
            margin-left: rem(5);
        }

        .side-borders {
            margin: rem(10) 0 rem(30);
        }

        .container {
            max-width: rem(380);
        }

        h3 {
            + p {
                margin-bottom: 1.5rem;
            }
        }

        p {
            line-height: 1.5;
        }

        .blue {
            font-size: 16px;
            transition: opacity ease-in-out 0.2s;

            &:hover {
                opacity: 0.6;
            }
        }

        label {
            p {
                font-size: rem(13);
                text-align: left;
                padding: 0 1.40625rem;
                margin: 0;
            }
        }

        input[type="text"], input[type="number"], input[type="password"], textarea, select {
            &::placeholder {
                opacity: 0.64;
            }
        }

        input[type="date"] {
            &::before {
                opacity: 0.64;
            }
        }


        button[type="submit"] {
            margin: rem(20) 0;
        }

        .placeholder {
            top: rem(16.5);
            font-size: 14px !important;
        }

        .password-strength--validation {
            p {
                text-align: center;
            }
        }
    }

    #popup-reopen-closed-account-message {
        padding: 1.5rem 0;
        background: #ffffff;
    }

    #popup-account-closed-message {
        padding: 1.5rem 0;
        background: #ffffff;
        display: none;
    }

    #popup-account-closed-message-options, #popup-reopen-closed-account-message-options {
        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }
    }

    #registration--signup-address-autoinput{
        padding-top:.5rem;
    }
    p.enter-address {
        margin-top: 0;
    }
}

    .social-buttons {
        display: flex;
        align-items: center;

        a {
            border-radius: 50%;
            width: rem(38);
            height: rem(38);
            flex: 0 0 rem(38);
            margin-right: rem(10);
            position: relative;

            &:last-child {
                margin-right: 0;
            }

            svg {
                margin: auto !important;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &.facebook {
                background-color: #425893;

                svg {
                    * {
                        fill: white;
                    }
                }
            }

            &.google {
                // background-color: #CD5642;
                svg {
                    fill: #DA483C;
                    left: initial;
                    width: 38px;
                    height: 38px;
                }
            }

            &.apple {
                background-color: none;

                svg {
                    width: 38px;
                    height: 38px;

                    circle {
                        fill: #58595B;
                    }

                    g {
                        fill: #ffffff;
                    }
                }
            }
        }
    }

    .popup {
        .sign-up--page {
            padding: 0;
        }

        form {
            padding-bottom: 0;
        }
    }

    @media only screen and (max-width: 560px) {
        .sign-up--page {
            form {
                .container {
                    max-width: 92.5%;
                    width: 100%;
                    font-size: 15px;
                }
            }

            .right {
                text-align: center;
            }

            .sign-in--header {
                padding-top: rem(20);

                .h1title {
                    margin-bottom: rem(5);
                }
            }

            .sign-in {
                .side-borders {
                    margin: 0 0 rem(10);
                }
            }

            .sign-in--form {
                button[type="submit"] {
                    margin-bottom: rem(10);
                }
            }

            h2 {
                &.need-help {
                    max-width: 100%
                }
            }
        }
    }



.sign-in--sms {
    padding: 1rem;
    background: #eee !important;
    margin-bottom: 1rem;
    min-height:300px;

    h2 {
        margin: .5rem auto .25rem;
        font-size: 1.2rem;
        color: #2C68A5
    }
    >span{
        font-size:1rem;
        display:block;
        line-height:1.5;
        padding-bottom:1rem;
    }

    input:invalid:focus {
        border-color: $red;
    }  
    button[type="submit"] {
        margin: 1rem 0 0.5rem !important;
    }
}

.sms--login {
    .sign-in--error {
        background: $orange;
        color: #fff;
        padding: .5rem;
        margin: 0 !important;
        a{
            color:#ffffff;
            text-decoration:underline;
        }
    }  
}

.sign-in--sms-code {
    padding: 1rem;
    background: #eee !important;
    margin-bottom: 1rem;
    display: none;
    outline: 2px solid;
    min-height: 300px;

    .sign-in--error {
        a {
            color: #ffffff;
            text-decoration: underline;
        }
    }

    input:invalid:focus {
        border-color: $red;
    }

    input [name="code"] {
        margin-bottom: 0;
    }

    h2 {
        margin: .5rem auto 1rem;
        font-size: 1.2rem;
        color: #2C68A5
    }

    #send-new-code {
        margin: 10px auto 0;
        font-weight: bold;
        cursor: pointer;
    }

    #resend-sms-code {
        margin: 0 0 1rem;
        font-weight: bold;
        display: none;
    }

    button[type="submit"] {
        margin: 1rem 0 0.5rem !important;
    }

    #resent-message {
        display: none;
        color: $blue;
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        padding: 0 0 1rem;
    }

/*    #resent-counter {
        display: none;
        color: $blue;
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        padding: 0 0 1rem;
    }*/
}

.display-none{
    display:none !important;
}
.display-block {
    display: block !important;
}

.safari-only {
    display: none;
    color: $blue;
    font-weight: 600;
    line-height: 1.5;
    padding-left:1rem;
    text-align:left;
}

/* Safari 11+ */

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        .safari-only { 
            display: block;
        }
    }
}

/* Safari 10.1 */
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
        .safari-only {
            display: block;
        }
    }
}

// SAFARI Only
@supports (-webkit-overflow-scrolling: touch) { //only safari
    input[type="date"]:before {
        color: $blue;
        content: attr(placeholder);
    }

    input[type="date"].filled:before {
        color: $blue;
        content: "" !important;
    }

    input[type="date"]:not(.filled):before {
        color: $blue;
        content: attr(placeholder);
    }

    input[type="date"] {
        -webkit-text-fill-color: $blue;
        -webkit-opacity: 1;
    }
}
 
